import classNames from 'classnames';

export type ScreeningListType = {
  answer?: {
    questionNo: number;
    questionDesc: string;
    answerDesc: string;
  };
  isLast?: boolean;
};

export default function ScreeningList(props: ScreeningListType) {
  const renderAnswerDesc = () => {
    if (props?.answer?.answerDesc === 'Yes') {
      return (
        <div className="lg:w-[108px] lg:h-[25px] flex justify-center items-center bg-[#FFC5C5] lg:rounded-[3px] font-[600] lg:text-[13px]  lg:leading-[20px] text-[#EB5757]">
          Yes
        </div>
      );
    } else if (props?.answer?.answerDesc === 'No') {
      return (
        <div className="lg:w-[108px] lg:h-[25px] flex justify-center items-center bg-[#B8E2DF] lg:rounded-[3px] font-[600] lg:text-[13px]  lg:leading-[20px] text-[#00857E]">
          No
        </div>
      );
    } else {
      return (
        <div className="lg:w-[108px] lg:h-[25px] flex justify-center items-center bg-[#B8E2DF] lg:rounded-[3px] font-[600] lg:text-[13px]  lg:leading-[20px] text-[#00857E]">
          Never tested
        </div>
      );
    }
  };

  return (
    <div className={classNames(!props?.isLast && 'lg:mb-[32px]', 'flex w-full justify-between')}>
      <div className="flex">
        <div className="font-[400] lg:text-[14px]  lg:leading-[28px] text-[#52575C]">
          {`${props?.answer?.questionNo}.`}&nbsp;
        </div>
        <div className="lg:w-[735px]  lg:text-[14px]  lg:leading-[28px] text-[#52575C] lg:mr-[25px] font-[400]">
          {props?.answer?.questionDesc}
        </div>
      </div>

      <div>
        {renderAnswerDesc()}
        {/* {props?.answer?.answerDesc === 'Yes' ? (
          <div className="lg:w-[65px] lg:h-[25px] flex justify-center items-center bg-[#FFC5C5] lg:rounded-[3px] font-[600] lg:text-[13px]  lg:leading-[20px] text-[#EB5757]">
            Yes
          </div>
        ) : (
          <div className="lg:w-[65px] lg:h-[25px] flex justify-center items-center bg-[#B8E2DF] lg:rounded-[3px] font-[600] lg:text-[13px]  lg:leading-[20px] text-[#00857E]">
            No
          </div>
        )} */}
      </div>
    </div>
  );
}
