import { ConsultationMethod, Gender } from '../../../../consts/enums';
import { AppointmentDetail } from '../../../../services/types/appointmentDetails.type';
import { formatBeDate } from '../../../../utils';
import styles from './Detail.module.scss';

export default ({ allValue }: { allValue: AppointmentDetail }) => {
  const { patient, consultation } = allValue;
  const methodText = patient.consultType === ConsultationMethod.Online ? 'Online' : 'In-clinic';
  const consultationText = `${consultation} | ${methodText}`;
  const form = {
    'Patient Infomation': {
      'Chinese Name': patient.lastNameCn + patient.firstNameCn,
      Gender: patient.gender == Gender.Male ? 'Male' : 'Female',
      HKID: patient.hkid,
      'Date of Birth': formatBeDate(patient.dob),
    },
    'Consultation Details': {
      'Case ID': patient.caseId,
      Consultation: consultationText,
    },
  };

  return (
    <div className={styles.box}>
      {Object.entries(form).map(([k, v]) => (
        <div key={k} className={styles.group}>
          <div className={styles.groupname}>{k}</div>
          <div className={styles.fields}>
            {Object.entries(v).map(([kk, vv]) => (
              <div key={kk}>
                <div className={styles.key}>{kk}</div>
                <div className={styles.value}>{vv}</div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
