export default function AlertTermsListItem(props: { children: React.ReactNode; orderPrefix: string }) {
  return (
    <div
      className="
      w-full lg:mb-[32px] flex text-[#000000]
      lg:text-[16px] lg:leading-[28px] text-left
      text-[14px] leading-[22px] mb-[24px]
    "
    >
      <p className="inline-block lg:w-[66px] w-[37px] flex-shrink-0">{props.orderPrefix}</p>
      <p>{props.children}</p>
    </div>
  );
}
