/* eslint-disable @typescript-eslint/indent */

import { AppointmentDetail } from '@services/types/appointmentDetails.type';
import { useEffect, useState } from 'react';
import ScreeningList from './ScreeningList';
import { UUTUBE_TYPE } from '@consts/consts';
import emptyScreeningResult from '@images/doctorImages/emptyScreeningResult.png';

export type ScreeningResultType = {
  answers?: {
    questionId?: number;
    questionAnswerId?: number;
  }[];
  allValue?: AppointmentDetail;
};

export default function ScreeningResult(props: ScreeningResultType) {
  const [answers, setAnswers] = useState<
    {
      questionId?: number;
      questionAnswerId?: number;
    }[]
  >([]);

  useEffect(() => {
    setAnswers(props?.answers || []);
  }, [props?.answers]);

  const renderContent = () => {
    // 只有UUTUBE_TYPE才有screening
    if (props?.allValue?.fromProductType === UUTUBE_TYPE) {
      return (
        <div className="sm:p-[46px_57px_92px_25px] bg-[#FFFFFF] sm:shadow-[0_12px_26px_rgba(16,30,115,0.06)] sm:rounded-[0px_8px_8px_8px]">
          <div className="flex flex-col">
            {props?.allValue?.screening &&
              props?.allValue?.screening.map((answer, index) => (
                <ScreeningList
                  answer={answer}
                  key={answer?.questionNo}
                  isLast={index === (answers && answers.length - 1)}
                />
              ))}
            {/* {answers.map((answer, index) => (
          <AnswerList answer={answer} key={answer?.questionId} isLast={index === (answers && answers.length - 1)} />
        ))} */}
          </div>
        </div>
      );
    } else {
      return (
        <div className="sm:p-[109px_396px_133px_396px] bg-[#FFFFFF] sm:shadow-[0_12px_26px_rgba(16,30,115,0.06)] sm:rounded-[0px_8px_8px_8px]">
          <div className="flex flex-col justify-center items-center">
            <img
              src={emptyScreeningResult}
              alt={'emptyScreeningResult'}
              className="object-contain sm:w-[64px] sm:mb-[8px]"
            />
            <div className="font-[400] sm:text-[16px] sm:leading-[24px] text-[#52575C] whitespace-nowrap">
              Screening not applicable
            </div>
          </div>
        </div>
      );
    }
  };

  return renderContent();
}
