import { get, post } from '@utils/request';
import { MeetingInfos } from './types/meetingService.type';

//16.162.89.53/meeting/sqhNwXUCsFNHXMweYZbYmQ==

export async function getMeetingInfos(params: { suffix: string }) {
  return get<MeetingInfos>('/care/booking/get-meeting-by-suffix', params);
}
/**
 * 更改会议状态
 * @param params suffix: 会议后缀，event: 0 开始； 1 结束
 * @returns
 */
export function meetingEvent(params: { suffix: string; event: 0 | 1 }) {
  return post('/care/booking/update-meeting-event', params);
}
export default {
  getMeetingInfos,
  meetingEvent,
};
