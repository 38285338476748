export enum BookingStatus {
  Created,
  Answered,
  InfoFilled, //对应现场问诊的开始
  WaitingForPayment,
  PaidSucc,
  PaidFail,
  Cancelled, //支付取消
  Consultation, //诊断
  Prescription, //开药方
  Completed, // 完成/对应现场问诊的结束
  Refund,
}
// 拿到key值的方法
// Direction[Direction.Up]

export enum ConsultationMethod {
  Online,
  Offline,
}
export enum GenderStatus {
  Male,
  Female,
}

export enum SimpleBookingStatus {
  Upcoming,
  Ongoing,
  Completed,
}

export enum WorkingConditionStatus {
  GP,
}

export enum Currency {
  hkd,
  usd,
}

export enum ModelType {
  Positive,
  Negative,
  Invalid,
}

export enum SelectDetailType {
  ScreeningResult,
  Prescriptions,
  Remarks,
  ShipmentDetails,
  Detail,
}

export enum RemarkType {
  Edit,
  Lock,
}

export enum ProductType {
  OnlineConsultationAndMedication,
  Medication,
  OnlineConsultation,
  InClinicConsultation,
}

export enum DoctorSidebarType {
  Appointments,
  Medications,
  MyProfile,
}
// TODO: 后续可能根据需求，进行内容的调整
// TODO: 祥哥他们会变动=>需要看一下如何调整
export enum TestResult {
  // UUTUBE类型
  uutube_positive = 1,
  uutube_negative,
  uutube_invalid,
  // combo类型
  combo_positive_covid_19,
  combo_positive_Flu_A,
  combo_positive_Flu_B,
  combo_positive_covid_19_Flu_A,
  combo_positive_covid_19_Flu_B,
  combo_negative,
  combo_invalid,
  // covid类型
  covid_19_positive_covid_19,
  covid_19_negative_covid_19,
  covid_19_invalid_covid_19,
}

export enum FromProductType {
  uutube = 1,
  combo,
  covid,
}

export enum Gender {
  Male,
  Female,
}
