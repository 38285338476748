import UserContainer from '../components/UserContainer';
import { ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import MeetingInfosContext from '../context/MeetingInfosContext';
import { useAllRtcUids } from '../hooks/RtcUser';
import RtcAdapter from '@utils/RtcAdapter/RtcTool';
import { InfoCircleFilled, Loading3QuartersOutlined, RightOutlined, SettingFilled } from '@ant-design/icons';
import DeviceButton from '../components/DeviceButton';
import {
  useLocalAudioStatus,
  useLocalVidioStatus,
  useMediaDevices,
  useMediaDevicesInit,
} from '@utils/RtcAdapter/hooks/mediaDevices';
import BaseButton from '@components/BaseButton/BaseButton';
import CustomEndCallIcon from '../components/CustomEndCallIcon';
import classNames from 'classnames';
import dayjs from 'dayjs';
import DeviceSelector from '../components/DeviceSelector';
import alertInfo from '@utils/globalComponents/Alert';
import { useMemoizedFn } from 'ahooks';
import { More } from '@icon-park/react';
import { Drawer, notification } from 'antd';
import { updateShouldOpenCameraStatus, updateShouldOpenMicrophoneStatus } from '@states/meeting.state';
import { useTimeoutReminder } from '../hooks/TimeoutReminder';
import { NavigateOptions, To, useParams } from 'react-router-dom';
import useRtcConnection from '@utils/RtcAdapter/hooks/rtcConnectionStatus';
import { useTranslation } from 'react-i18next';
import useJoinMeeting from '@utils/RtcAdapter/hooks/joinMeeting';
import meetingService from '@services/meetingService';
import { useLocalNavigate } from 'src/router/routerTools';
import { NotificationInstance } from 'antd/es/notification/interface';
import { MeetingInfos } from '@services/types/meetingService.type';

const BottomActionBox = (props: {
  id: string;
  icon: ReactNode;
  text: string;
  onClick?: () => void;
  active: boolean;
}) => {
  return (
    <div
      id={props.id}
      onClick={props.onClick}
      className={classNames(
        'lg:text-[12px] text-center cursor-pointer',
        props.active ? 'text-[#2DBDB6]' : 'text-[#B3B3B3]',
      )}
    >
      {props.icon}
      <p className="lg:block hidden">{props.text}</p>
    </div>
  );
};

const AppointmentDetails = () => {
  const [t] = useTranslation();
  const meetingInfos = useContext(MeetingInfosContext);
  if (!meetingInfos) {
    return null;
  }
  const startTime = dayjs(meetingInfos.meeting.appointmentTime);
  return (
    <>
      <p className="lg:hidden text-[16px] font-semibold px-[25.5px]">
        <InfoCircleFilled className="mr-[10px]" /> {t('MeetingPage.MeetingDetails')}
      </p>
      <div className="px-[59px] lg:px-0">
        <hr className="border-[#9D9D9D] my-[15px] lg:hidden" />
        <p className="lg:text-[22px] text-[14px] font-semibold mb-[22px] lg:mb-0">
          {t('MeetingPage.AppointmentDetails')}
        </p>
        <hr className="border-[#9D9D9D] lg:my-[24px] lg:block hidden" />
        <div className="lg:text-[initial] text-[#F5F5F5]">
          <div className="flex justify-center lg:mb-[24px] mb-[22px]">
            <p className="w-[50%] text-left font-semibold">{t('MeetingPage.BookingNumber')}</p>
            <p className="w-[50%] text-left lg:ml-[8px]">{meetingInfos.meeting.displayId}</p>
          </div>
          <div className="flex justify-center lg:mb-[24px] mb-[22px]">
            <p className="w-[50%] text-left font-semibold">{t('MeetingPage.BookWith')}</p>
            <p className="w-[50%] text-left lg:ml-[8px]">
              {meetingInfos.role === 1 ? meetingInfos.meeting.doctorName : meetingInfos.meeting.patientName}
            </p>
          </div>
          <div className="flex justify-center lg:mb-[24px] mb-[22px]">
            <p className="w-[50%] text-left font-semibold">{t('Date')}</p>
            <p className="w-[50%] text-left lg:ml-[8px]">{startTime.format('DD / MM / YYYY')}</p>
          </div>
          <div className="flex justify-center lg:mb-[24px] mb-[22px]">
            <p className="w-[50%] text-left font-semibold">{t('Time')}</p>
            <p className="w-[50%] text-left lg:ml-[8px]">{startTime.format('hh:mm a')}</p>
          </div>
          <div className="flex justify-center lg:mb-[24px] mb-[22px]">
            <p className="w-[50%] text-left font-semibold">{t('Duration')}</p>
            <p className="w-[50%] text-left lg:ml-[8px]">{'30 mins'}</p>
          </div>
        </div>
      </div>
    </>
  );
};

const LineButton = (props: { id: string; icon: React.ReactNode; text: string; onClick: () => void }) => {
  return (
    <button
      id={props.id}
      onClick={props.onClick}
      className="flex items-center justify-between py-[15px] px-[26px] active:bg-[#14141499] w-full"
    >
      <div className="flex items-center">
        {props.icon}
        <p className="ml-[26px]">{props.text}</p>
      </div>
      <RightOutlined className="text-[23px]" />
    </button>
  );
};

const Seetings = () => {
  const [t] = useTranslation();
  const { microphoneList, cameraList, speakerList } = useMediaDevices();
  return (
    <div className="px-[29px] lg:px-0">
      <p className="lg:text-[22px] text-[16px] font-semibold">{t('Settings')}</p>
      <hr className="border-[#9D9D9D] lg:my-[24px] my-[15px]" />
      <p className="lg:text-[16px] text-[16px] font-semibold">{t('InputDeviceSettings')}</p>
      <p className="lg:text-[14px] text-[14px] lg:mt-[17px] lg:mb-[8px] mt-[12px] mb-[12px] font-semibold">
        {t('Camera')}
      </p>
      <DeviceSelector
        className="bg-[#C6C6C6] lg:text-[14px] text-[14px] lg:w-[382px] w-[352px] rounded-[5px]"
        defaultValue={cameraList[0]?.deviceId}
        deviceList={cameraList}
        onChange={(v) => RtcAdapter.switchCamera(v)}
      />
      <p className="lg:text-[14px] text-[14px] lg:mt-[17px] lg:mb-[8px] mt-[12px] mb-[12px] font-semibold">
        {t('Microphone')}
      </p>
      <DeviceSelector
        className="bg-[#C6C6C6] lg:text-[14px] text-[14px] lg:w-[382px] w-[352px] rounded-[5px]"
        defaultValue={microphoneList[0]?.deviceId}
        deviceList={microphoneList}
        onChange={(v) => RtcAdapter.switchMicrophone(v)}
      />
      <p className="lg:text-[14px] text-[14px] lg:mt-[17px] lg:mb-[8px] mt-[12px] mb-[12px] font-semibold">
        {t('Speaker')}
      </p>
      <DeviceSelector
        className="bg-[#C6C6C6] lg:text-[14px] text-[14px] lg:w-[382px] w-[352px] rounded-[5px]"
        defaultValue={speakerList[0]?.deviceId}
        deviceList={speakerList}
        onChange={(v) => RtcAdapter.switchSpeaker(v)}
      />
    </div>
  );
};

const AlertLeaveReminderContent = (props: {
  meetingInfos?: MeetingInfos;
  notificationApi: NotificationInstance;
  close: () => void;
  navigate: (to: To, options?: NavigateOptions | undefined) => void;
}) => {
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <div className="lg:px-[66px] px-[22px]">
        <p className="text-[#2DBDB6] lg:text-[22px] text-[20px] font-semibold">{t('MeetingPage.LeaveMeeting')}</p>
        <p className="lg:leading-[20px] lg:text-[14px] text-[14px] leading-[20px] text-[#ffffffaa]">
          {props.meetingInfos?.role === 2 ? t('MeetingPage.DoctorReminderText') : t('MeetingPage.LeaveReminderText')}
        </p>
      </div>
      <div className="flex lg:mt-[29px] lg:pl-[66px] mt-[20px] px-[22px] items-center">
        <BaseButton
          id="meeting__leave-reminder-stay-button"
          onClick={props.close}
          buttonClassName={classNames(
            'lg:text-[14px] lg:w-[150px] lg:h-[30px] text-[14px] w-[90px] h-[30px] inline-block',
          )}
          style={{ backgroundColor: '#ff4d4f' }}
          status="default"
          value={t('MeetingPage.Stay')}
        />
        <p
          id="meeting__leave-reminder-leave-button"
          onClick={async () => {
            setIsLoading(true);
            if (props.meetingInfos?.role === 2) {
              try {
                await meetingService.meetingEvent({
                  suffix: props.meetingInfos.meeting.linkDoctor,
                  event: 1,
                });
              } catch (e: any) {
                props.notificationApi.error({
                  message: (
                    <>
                      <p id="meeting__meeting-event-error">{e.response.data.message}</p>
                    </>
                  ),
                  placement: 'bottomRight',
                });
                setIsLoading(false);

                return;
              }
            }
            await RtcAdapter.leave();
            setIsLoading(false);
            props.close();
            props.navigate(
              `/meeting/exception?type=${props.meetingInfos?.role === 2 ? 'doctorEnd' : 'end'}&bookingId=${
                props.meetingInfos?.meeting.bookingId
              }`,
            );
          }}
          className="underline font-medium text-[#ffffff] lg:text-[14px] lg:mt-[8px] mt-[8px] text-[14px] cursor-pointer lg:ml-[20px] ml-[16px] select-none"
        >
          {t('MeetingPage.LeaveConfirmText')}
          {isLoading && <Loading3QuartersOutlined className="animate-spin ml-[4px]" />}
        </p>
      </div>
    </>
  );
};

export default function InMeetingContainer() {
  const [t] = useTranslation();
  const meetingId = useParams().meetingId!;
  const [notificationApi, contextHolder] = notification.useNotification();

  useMediaDevicesInit();
  const { microphoneList, cameraList } = useMediaDevices();

  const meetingInfos = useContext(MeetingInfosContext);
  const allRtcUids = useAllRtcUids();
  const navigate = useLocalNavigate();
  const [chatBoxOpen /*setChatBoxOpen*/] = useState(false);
  const allRtcUserInfos = useMemo(() => {
    return allRtcUids.map((u) => {
      if (u === RtcAdapter.localRtcUid) {
        return { userName: t('you'), rtcUid: u, isMine: true };
      } else {
        const userName =
          meetingInfos?.role === 2 ? meetingInfos?.meeting.patientName : meetingInfos?.meeting.doctorName || 'Doctor';
        return { userName, rtcUid: u, isMine: false };
      }
    });
  }, [allRtcUids, meetingInfos?.meeting.doctorName, meetingInfos?.meeting.patientName, meetingInfos?.role, t]);

  const localAudioStatus = useLocalAudioStatus();
  const localVideoStatus = useLocalVidioStatus();

  const [isShowDetail, setIsShowDetail] = useState(false);
  const [isShowSettings, setIsShowSettings] = useState(false);

  const alertLeaveReminder = useMemoizedFn(() => {
    alertInfo((close) => ({
      content: (
        <AlertLeaveReminderContent
          close={close}
          notificationApi={notificationApi}
          navigate={navigate}
          meetingInfos={meetingInfos}
        />
      ),
    }));
  });

  const [isMobileShowDetail, setIsMobileShowDetail] = useState(false);
  const [isMobileShowSettings, setIsMobileShowSettings] = useState(false);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  useEffect(() => {
    if (localAudioStatus) {
      RtcAdapter.toogleAudioPublish(true);
    }
    if (localVideoStatus) {
      RtcAdapter.toogleVideoPublish(true);
    }
  }, [localAudioStatus, localVideoStatus]);

  const reminderDoctorTimeout = useMemoizedFn((timeoutRef: React.MutableRefObject<NodeJS.Timer | undefined>) => {
    alertInfo((close) => ({
      content: (
        <>
          <div className="lg:px-[66px] px-[22px]">
            <p className="lg:leading-[20px] lg:text-[14px] text-[14px] leading-[20px] text-[#ffffffaa]">
              {t('MeetingPage.OnlyYou')}
            </p>
          </div>
          <div className="flex lg:mt-[29px] lg:pl-[66px] mt-[20px] px-[22px] items-center">
            <BaseButton
              id="meeting__doctor-timeout-reminder-wait-button"
              onClick={close}
              buttonClassName={classNames(
                'lg:text-[14px] lg:w-[150px] lg:h-[30px] text-[14px] w-[90px] h-[30px] inline-block',
              )}
              style={{ backgroundColor: '#ff4d4f' }}
              status="default"
              value={t('MeetingPage.Wait')}
            />
            <p
              id="meeting__doctor-timeout-reminder-end-button"
              onClick={async () => {
                close();
                if (meetingInfos?.role === 2) {
                  try {
                    await meetingService.meetingEvent({
                      suffix: meetingId,
                      event: 1,
                    });
                  } catch (e: any) {
                    notificationApi.error({
                      message: (
                        <>
                          <p id="meeting__timeout-reminder-end-button-error">{e.response.data.message}</p>
                        </>
                      ),
                      placement: 'bottomRight',
                    });
                    return;
                  }
                }
                clearInterval(timeoutRef.current);
                await RtcAdapter.leave();

                navigate('/meeting/exception?type=end');
              }}
              className="underline font-medium text-[#ffffff] lg:text-[14px] text-[14px] cursor-pointer lg:ml-[20px] ml-[16px] select-none"
            >
              {t('MeetingPage.End')}
            </p>
          </div>
        </>
      ),
    }));
  });

  useTimeoutReminder(meetingInfos?.meeting.appointmentTime, meetingInfos?.role, reminderDoctorTimeout);

  const disconnectCbTimeout = useRef<NodeJS.Timeout>();
  useRtcConnection((cur, pre, reason) => {
    if (cur === 'DISCONNECTED' && reason === 'UID_BANNED') {
      RtcAdapter.leave();
      alertInfo({
        content: (
          <>
            <div className="lg:px-[66px] px-[22px]">
              <p className="lg:leading-[20px] lg:text-[14px] text-[14px] leading-[20px] text-[#ffffffaa]">
                {t('MeetingPage.JoinAnotherText')}
              </p>
            </div>
          </>
        ),
      });
    } else if (cur === 'DISCONNECTED') {
      clearTimeout(disconnectCbTimeout.current);
      disconnectCbTimeout.current = setTimeout(() => {
        navigate('/meeting/exception?type=error');
      }, 300000);
    } else if (cur === 'CONNECTED') {
      clearTimeout(disconnectCbTimeout.current);
    }
  });
  useJoinMeeting((_, remoteUserList) => {
    if (remoteUserList.length > 0) {
      meetingService.meetingEvent({ suffix: meetingId, event: 0 });
    }
  });

  return (
    <>
      {contextHolder}
      <div className="meeting-container w-full h-[100vh] bg-[#1a1a1a] lg:px-[147px]">
        <div
          className={classNames(
            'text-white flex items-center justify-between',
            'lg:h-[75px] font-medium lg:text-[18px] lg:leading-[28px] lg:px-0',
            'h-[58px] w-full text-[#ffffff] text-[11px] leading-[22px] px-[25px]',
          )}
        >
          <p>
            {t('MeetingPage.OnlineConsultationWith')}{' '}
            {meetingInfos?.role === 1
              ? meetingInfos?.meeting?.doctorName
              : meetingInfos?.meeting?.patientName || 'Doctor'}
          </p>
          <BaseButton
            id="meeting__end-call-button__mobile"
            // loading={loading}
            onClick={alertLeaveReminder}
            buttonClassName="lg:hidden text-[12px] w-[87px] h-[36px] ml-[20px] inline-block"
            style={{
              backgroundColor: '#FF414D',
            }}
            status="default"
            value={
              <div className="flex items-center justify-center">
                <CustomEndCallIcon
                  style={{
                    transform: 'translateX(-3px)',
                  }}
                />
                <p className="lg:text-[12px] font-medium">
                  {meetingInfos?.role === 2 ? t('MeetingPage.End') : t('MeetingPage.Leave')}
                </p>
              </div>
            }
          />
        </div>
        <div className="w-full lg:h-[calc(100vh-75px-96px)] h-[calc(100vh-58px-88px)] flex justify-between">
          <div
            className={classNames(
              'h-full transition-[width] w-full overflow-hidden',
              isShowDetail || isShowSettings ? 'lg:w-[calc(100vw-294px-454px)]' : 'lg:w-[calc(100vw-294px)]',
            )}
          >
            <UserContainer chatBoxOpen={chatBoxOpen} userRtcUserList={allRtcUserInfos} />
          </div>
          <div
            className={classNames(
              'bg-[#E7E7E7] lg:text-[16px] lg:leading-[24px] text-[#212121] rounded-[10px] lg:p-[27px] transition-[width,height] overflow-hidden',
              isShowDetail || isShowSettings ? 'lg:w-[447px] h-full' : 'lg:w-0 h-0 lg:p-0',
            )}
          >
            {isShowDetail && <AppointmentDetails />}
            {isShowSettings && <Seetings />}
          </div>
        </div>
        <div className="inmeeting-footer-tool lg:h-[96px] lg:px-[25px] px-[48px] h-[88px] flex justify-between items-center">
          {/* <BottomActionBox
            onClick={() => setChatBoxOpen(!chatBoxOpen)}
            active={chatBoxOpen}
            icon={<MessageFilled className="lg:text-[20px] text-[22px]" />}
            text={t('MeetingPage.chat')}
          /> */}
          <div className="lg:w-[174px] lg:block hidden"></div>
          <div className="lg:hidden">
            <DeviceButton
              id="meeting__camera-button__mobile"
              disable={cameraList.length === 0}
              type="camera"
              enable={localVideoStatus}
              onClick={async () => {
                const enable = !localVideoStatus;
                updateShouldOpenCameraStatus(enable);
                await RtcAdapter.toogleVideo(enable);
                await RtcAdapter.toogleVideoPublish(enable);
              }}
            />
          </div>
          <div className="lg:hidden">
            <DeviceButton
              id="meeting__microphone-button__mobile"
              disable={microphoneList.length === 0}
              type="microphone"
              enable={localAudioStatus}
              onClick={async () => {
                const enable = !localAudioStatus;
                updateShouldOpenMicrophoneStatus(enable);
                await RtcAdapter.toogleAudio(enable);
                await RtcAdapter.toogleAudioPublish(enable);
              }}
            />
          </div>
          <div className="lg:flex justify-between lg:w-[286px] hidden">
            <DeviceButton
              id="meeting__camera-button"
              disable={cameraList.length === 0}
              type="camera"
              enable={localVideoStatus}
              onClick={async () => {
                const enable = !localVideoStatus;
                updateShouldOpenCameraStatus(enable);
                await RtcAdapter.toogleVideo(enable);
              }}
            />
            <DeviceButton
              id="meeting__microphone-button"
              disable={microphoneList.length === 0}
              type="microphone"
              enable={localAudioStatus}
              onClick={async () => {
                const enable = !localAudioStatus;
                updateShouldOpenMicrophoneStatus(enable);
                await RtcAdapter.toogleAudio(enable);
                await RtcAdapter.toogleAudioPublish(enable);
              }}
            />
            <BaseButton
              id="meeting__end-call-button"
              // loading={loading}
              onClick={alertLeaveReminder}
              buttonClassName="lg:text-[12px] lg:w-[87px] lg:h-[36px] inline-block"
              style={{
                backgroundColor: '#FF414D',
              }}
              status="default"
              value={
                <div className="flex items-center">
                  <CustomEndCallIcon />
                  <p className="lg:text-[12px] font-medium">
                    {meetingInfos?.role === 2 ? t('MeetingPage.End') : t('MeetingPage.Leave')}
                  </p>
                </div>
              }
            />
          </div>
          <More
            id="meeting__more-button__mobile"
            onClick={() => setMobileDrawerOpen(true)}
            className="text-[#B3B3B3] text-[36px] lg:hidden cursor-pointer"
          />
          <div className="lg:flex justify-between lg:w-[180px] hidden">
            <BottomActionBox
              id="meeting__consulation-details-button"
              active={isShowDetail}
              onClick={() => {
                setIsShowDetail(!isShowDetail);
                if (isShowSettings) {
                  setIsShowSettings(false);
                }
              }}
              icon={<InfoCircleFilled className="lg:text-[20px]" />}
              text={t('MeetingPage.MeetingDetails')}
            />

            <BottomActionBox
              id="meeting__settings-button"
              active={isShowSettings}
              onClick={() => {
                setIsShowSettings(!isShowSettings);
                if (isShowDetail) {
                  setIsShowDetail(false);
                }
              }}
              icon={<SettingFilled className="lg:text-[20px]" />}
              text={t('Settings')}
            />
          </div>
        </div>
      </div>
      <Drawer
        title={
          <div className="w-full">
            <div className="w-[40px] h-[4px] bg-[#a9a9a9] rounded-lg m-auto"></div>
          </div>
        }
        onClose={() => {
          setIsMobileShowDetail(false);
          setIsMobileShowSettings(false);
          setMobileDrawerOpen(false);
        }}
        placement="bottom"
        closable={false}
        open={mobileDrawerOpen}
        className="bg-[#141414!important]"
        contentWrapperStyle={{ height: 'auto' }}
        bodyStyle={{ padding: 0 }}
      >
        <div className="text-[#b3b3b3] text-[14px] leading-[14px] pb-[18px]">
          {isMobileShowDetail && <AppointmentDetails />}
          {isMobileShowSettings && <Seetings />}

          {!isMobileShowDetail && !isMobileShowSettings && (
            <>
              {/* <LineButton
                icon={
                  <MessageFilled onClick={() => setChatBoxOpen(!chatBoxOpen)} className="lg:text-[20px] text-[22px]" />
                }
                text="Send Message"
                onClick={() => {}}
              /> */}
              <LineButton
                id="meeting__consulation-details-button__mobile"
                icon={<InfoCircleFilled className="text-[22px]" />}
                text={t('MeetingPage.MeetingDetails')}
                onClick={() => {
                  setIsMobileShowDetail(!isMobileShowDetail);
                }}
              />
              <LineButton
                id="meeting__settings-button__mobile"
                icon={<SettingFilled className="text-[22px]" />}
                text={t('Settings')}
                onClick={() => {
                  setIsMobileShowSettings(!isMobileShowSettings);
                }}
              />
            </>
          )}
        </div>
      </Drawer>
    </>
  );
}
