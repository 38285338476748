import BaseGrayCheckbox from '@components/BaseGrayCheckbox/BaseGrayCheckbox';
import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CONTROL_LINE, ComboBaseResult, FLU_A, FLU_B, ResultList, ResultType } from '../covidAndFluConst';
import { useMemoizedFn } from 'ahooks';
import { useTranslation } from 'react-i18next';
import { ComboTestResultStore } from '@utils/localStore/testResultSelectStore';
const ResultCheckBox = (props: { checked?: boolean; onChange: (b: boolean) => void; text: string; id?: string }) => {
  return (
    <div className="flex items-center">
      <div className="ml-[6px] lg:ml-[6px] mr-[6px] lg:mr-[6px] h-0 lg:w-[28px] w-[24px] lg:border-b-[1px] border-b-[1px] border-[#c0c0c0] border-dashed"></div>
      <div
        onClick={() => props.onChange(!props.checked)}
        className="cursor-pointer text-[#212121] hover:bg-[#f6fbfb] hover:text-[#2DBDB6] shadow-[1px_3px_6px_rgba(27,103,114,0.1)] bg-white lg:w-[164px] w-[164px] h-[44px] lg:px-[15px] px-[15px] lg:h-[44px] rounded-full flex items-center"
        id={props.id}
      >
        <BaseGrayCheckbox checked={props.checked} />
        <p className="lg:text-[16px] font-medium lg:ml-[16px] ml-[15px]">{props.text}</p>
      </div>
    </div>
  );
};

const isChecked = (n: number) => n > 0;

export default function CovidAndFluSelect(props: {
  onResultChange: (r: ResultType, selectedSet: ComboBaseResult[]) => void;
}) {
  const handleResultSelectChange = useMemoizedFn(props.onResultChange);

  const initResult = useMemo(() => {
    const res = ComboTestResultStore.get() || ResultType.Negative;
    return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const res = ComboTestResultStore.get() || ResultType.Negative;
    handleResultSelectChange(res, [CONTROL_LINE]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [selectBoxNumber, setSelectBoxNumber] = useState<ResultType>(initResult);
  const selectedSet = useRef<Set<ComboBaseResult>>(new Set<ComboBaseResult>([CONTROL_LINE]));
  const handleResultCheckBoxChange = useMemoizedFn((checked, value: ComboBaseResult) => {
    let finalResult;

    if (checked) {
      selectedSet.current.add(value);

      if (value === FLU_A && selectBoxNumber & FLU_B) {
        finalResult = (selectBoxNumber & ~FLU_B) | value;
      } else if (value === FLU_B && selectBoxNumber & FLU_A) {
        finalResult = (selectBoxNumber & ~FLU_A) | value;
      } else {
        finalResult = selectBoxNumber | value;
      }
    } else {
      selectedSet.current.delete(value);
      finalResult = selectBoxNumber & ~value;
    }
    setSelectBoxNumber(finalResult);
    ComboTestResultStore.set(finalResult);
    handleResultSelectChange(finalResult, Array.from(selectedSet.current));
  });

  const [t] = useTranslation();
  return (
    <div className="text-center">
      <div className="inline-flex lg:w-[370px] items-center">
        <div className="flex items-center justify-center lg:w-[158px] w-[130px] lg:h-[301px] h-[286px] lg:rounded-[44px] rounded-[36px] bg-[#f2f7f9] lg:shadow-[0px_8px_14px_rgba(69,92,119,0.15)] shadow-[0px_8px_14px_rgba(69,92,119,0.15)]">
          <div className="lg:w-[35px] lg:h-[239px] w-[29px] h-[233px] lg:shadow-[inset_0px_4px_8px_rgba(0,0,0,0.1)] shadow-[inset_0px_4px_8px_rgba(0,0,0,0.1)] lg:rounded-[12px] rounded-[12px] bg-[#ffffff] flex flex-col justify-around">
            {ResultList.map((r) => (
              <div
                key={r.value}
                className={classNames('lg:h-[6px] h-[6px] w-full', isChecked(selectBoxNumber & r.value) ? r.color : '')}
              ></div>
            ))}
          </div>
          <div className="lg:text-[30px] lg:h-[239px] h-[233px] text-[20px] font-semibold text-[#2BB7B3] lg:ml-[14px] ml-[9px] flex flex-col justify-around">
            <p className="text-[#212121]">C</p>
            <p>A</p>
            <p>B</p>
            <p>CoV</p>
          </div>
        </div>
        <div className="lg:text-[16px] font-medium text-[#212121] lg:h-[239px] h-[233px] flex flex-col justify-around">
          {ResultList.map((r) => {
            return (
              <ResultCheckBox
                id={r.id}
                key={r.value}
                onChange={(c) => handleResultCheckBoxChange(c, r.value)}
                checked={isChecked(selectBoxNumber & r.value)}
                text={t(r.text)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
