import BaseButton from '@components/BaseButton/BaseButton';
import alertInfo from '@utils/globalComponents/Alert';
import { useState } from 'react';

const ConfirmBox = (props: {
  content: React.ReactNode;
  onConfirm: () => Promise<void>;
  close: () => void;
  confirmId?: string;
  cancelId?: string;
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  return (
    <div className="sm:w-[479px] sm:px-[40px]">
      {props.content}
      <div className="flex justify-end sm:mt-[24px] sm:mb-[15px]">
        <BaseButton
          loading={confirmLoading}
          status="doctorDefault"
          onClick={async () => {
            setConfirmLoading(true);
            try {
              await props.onConfirm();
            } finally {
              props.close();
            }
          }}
          buttonClassName="sm:w-[72px!important] sm:h-[32px]  sm:mr-[8px] sm:px-[8px]"
          loadingText=""
          value={confirmLoading ? '' : 'Confirm'}
          id={props.confirmId}
        />
        <BaseButton
          status="doctorClear"
          onClick={props.close}
          buttonClassName="sm:w-[72px] sm:h-[32px] sm:px-[8px]"
          value="Cancel"
          id={props.cancelId}
        />
      </div>
    </div>
  );
};

export function alertConfimInfos(
  options: {
    content: React.ReactNode;
    onConfirm: () => Promise<void>;
    contentClassName?: string;
    contentWrapperClassName?: string;
  },
  confirmId?: string,
  cancelId?: string,
) {
  alertInfo((close) => {
    return {
      content: (
        <ConfirmBox
          content={options.content}
          onConfirm={options.onConfirm}
          close={() => close()}
          confirmId={confirmId}
          cancelId={cancelId}
        />
      ),
      background: '#FFFFFF',
      wraperBackground: '#00000020',
      closeIconShow: false,
      contentWrapperClassName: options.contentWrapperClassName,
      contentClassName: options.contentClassName,
    };
  });
}
