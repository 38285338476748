import React, { useState, useEffect, useMemo } from 'react';
import { Spin } from 'antd';
import BaseButton, { ButtonStatus } from '@components/BaseButton/BaseButton';
import classNames from 'classnames';

import positive1Img from './images/positive1.png';
import positive2Img from './images/positive2.png';
import negativeImg from './images/negative.png';
import invalid1Img from './images/invalid1.png';
import invalid2Img from './images/invalid2.png';
import { useTranslation } from 'react-i18next';
import { clearDataTrackConstantId, getDataTrackConstantId, track } from '@utils/dataTrack';
import { LoadingOutlined } from '@ant-design/icons';
import { TestResult } from '@consts/enums';
import { useLocalNavigate } from 'src/router/routerTools';
import OrderNewKitText from './components/OrderNewKit';
import alertHealthWarning from '@utils/globalComponents/HealthWarningAlert/HealthWarningAlert';
import { CovidtestResultStore } from '@utils/localStore/testResultSelectStore';
import { patientTokenStore } from '@utils/localStore/tokenStore';

const style = {
  container: {
    background: '#FDFDFD',
  },
};

const resultTypeMap: { [p: string]: number } = {
  '1': TestResult.covid_19_positive_covid_19,
  '2': TestResult.covid_19_positive_covid_19,
  '3': TestResult.covid_19_negative_covid_19,
  '4': TestResult.covid_19_invalid_covid_19,
  '5': TestResult.covid_19_invalid_covid_19,
};
const dataTrackHelpInfos: { [p: string]: string } = {
  '0': 'not selected',
  '1': 'positive',
  '2': 'positive',
  '3': 'negative',
  '4': 'invalid',
  '5': 'invalid',
};
const Covid19SelectResult: React.FC = () => {
  const navigate = useLocalNavigate();
  useEffect(() => {
    track({
      event_type: 'view covid tset result',
      event_properties: {
        type: 'covid19',
        'testing id': getDataTrackConstantId('TestingId'),
      },
    });
  }, []);
  const [t] = useTranslation();

  const initResult = useMemo(() => {
    const result = CovidtestResultStore.get();
    if (result) {
      return result;
    }
    return null;
  }, []);

  const [q1, setQ1] = useState<string | null>(initResult);
  const [buttonStatus, setButtonStatus] = useState<ButtonStatus>('indicaidGreen');
  const [loading] = useState(false);

  useEffect(() => {
    if (q1) {
      setButtonStatus('indicaidGreen');
    } else {
      setButtonStatus('disabled');
    }
  }, [q1]);
  const showOrderNewKit = q1 === '4' || q1 === '5';

  const resultTextStyle = 'absolute bottom-0 lg:text-[16px] font-bold lg:leading-[22px] text-[16px] leading-[20px]';
  return (
    <Spin spinning={loading} indicator={<LoadingOutlined />}>
      <div
        className="w-full h-full flex  bg-opacity-[0.8] flex-col py-[0] px-[24px] md:px-[32px] md:items-center lg:px-[0px] lg:items-center min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)]"
        style={style.container}
      >
        <div className="flex flex-col justify-start w-full">
          <div className="mt-[37px] font-[600] text-[28px] leading-[36px] w-full flex items-center justify-center text-[#2DBDB6] lg:mt-[80px] lg:text-[40px] lg:leading-[58px]">
            {t('Covid19SelectResultPage.SelectYourTestResult')}
          </div>
          <div className="mt-[44px] mb-[44px] font-[400] text-[16px] leading-[24px] w-full flex items-center justify-center text-[#212121] lg:mt-[45px] lg:mb-[65px] lg:text-[16px] lg:leading-[28px]  lg:w-[1127px] lg:text-center lg:mx-[auto]">
            {t('Covid19SelectResultPage.SelectSubTitle')}
          </div>
          <div className="select-result-radio-box flex flex-wrap flex-row lg:items-center lg:justify-center">
            <div className="flex flex-wrap flex-row">
              {/* 选项一 */}
              <div
                className="flex flex-col items-center px-[15px] pb-[25px] lg:px-[39px] lg:pb-[45px]"
                id="covid19__positive1"
                onClick={() => {
                  setQ1('1');
                  CovidtestResultStore.set('1');
                }}
              >
                <div
                  className={classNames(
                    q1 === '1' && 'border-[#2DBDB6] border-solid border-[3px] lg:border-[3px]',
                    'bg-[#DFDFDF] relative w-[150px] overflow-hidden h-[147px] rounded-[18px] flex items-center justify-center mb-[32px] lg:w-[163px] lg:h-[163px] lg:rounded-[18px] lg:mb-[32px]',
                  )}
                >
                  <img
                    src={positive1Img}
                    alt="selectOne"
                    className="object-cover w-[150px] h-[150px] cursor-pointer lg:w-[150px] pointer-events-none"
                  />
                  <p
                    className={classNames(
                      resultTextStyle,
                      q1 === '1' ? 'lg:mb-[8px] mb-[2px]' : 'lg:mb-[11px] mb-[5px]',
                      'text-[#DD524C]',
                    )}
                  >
                    {t('Covid19SelectResultPage.POSITIVE')}
                  </p>
                </div>
              </div>
              {/* 选项二 */}
              <div
                className="flex flex-col items-center px-[15px] pb-[25px] lg:px-[39px] lg:pb-[45px]"
                onClick={() => {
                  setQ1('2');
                  CovidtestResultStore.set('2');
                }}
                id="covid19__positive2"
              >
                <div
                  className={classNames(
                    q1 === '2' && 'border-[#2DBDB6] border-solid border-[3px] lg:border-[3px]',
                    'bg-[#DFDFDF] relative w-[150px] overflow-hidden h-[147px] rounded-[18px] flex items-center justify-center mb-[32px] lg:w-[163px] lg:h-[163px] lg:rounded-[18px] lg:mb-[32px]',
                  )}
                >
                  <img
                    src={positive2Img}
                    alt="selectOne"
                    className="object-cover w-[150px] h-[150px] cursor-pointer lg:w-[150px] pointer-events-none"
                  />
                  <p
                    className={classNames(
                      resultTextStyle,
                      q1 === '2' ? 'lg:mb-[8px] mb-[2px]' : 'lg:mb-[11px] mb-[5px]',
                      'text-[#DD524C]',
                    )}
                  >
                    {t('Covid19SelectResultPage.POSITIVE')}
                  </p>
                </div>
              </div>
              {/* 选项三 */}
              <div
                className="flex flex-col items-center px-[15px] pb-[25px] lg:px-[39px] lg:pb-[45px]"
                onClick={() => {
                  setQ1('3');
                  CovidtestResultStore.set('3');
                }}
                id="covid19__negative"
              >
                <div
                  className={classNames(
                    q1 === '3' && 'border-[#2DBDB6] border-solid border-[3px] lg:border-[3px]',
                    'bg-[#DFDFDF] relative w-[150px] overflow-hidden h-[147px] rounded-[18px] flex items-center justify-center mb-[32px] lg:w-[163px] lg:h-[163px] lg:rounded-[18px] lg:mb-[32px]',
                  )}
                >
                  <img
                    src={negativeImg}
                    alt="selectOne"
                    className="object-cover w-[150px] h-[150px] cursor-pointer lg:w-[150px] pointer-events-none"
                  />
                  <p
                    className={classNames(
                      resultTextStyle,
                      q1 === '3' ? 'lg:mb-[8px] mb-[2px]' : 'lg:mb-[11px] mb-[5px]',
                      'text-[#57606A]',
                    )}
                  >
                    {t('Covid19SelectResultPage.NEGATIVE')}
                  </p>
                </div>
              </div>
              {/* 选项四 */}
              <div
                className="flex flex-col items-center px-[15px] pb-[25px] lg:px-[39px] lg:pb-[45px]"
                onClick={() => {
                  setQ1('4');
                  CovidtestResultStore.set('4');
                }}
                id="covid19__invalid1"
              >
                <div
                  className={classNames(
                    q1 === '4' && 'border-[#2DBDB6] border-solid border-[3px] lg:border-[3px]',
                    'bg-[#DFDFDF] relative w-[150px] overflow-hidden h-[147px] rounded-[18px] flex items-center justify-center mb-[32px] lg:w-[163px] lg:h-[163px] lg:rounded-[18px] lg:mb-[32px]',
                  )}
                >
                  <img
                    src={invalid1Img}
                    alt="selectFour"
                    className="object-cover w-[150px] h-[150px] cursor-pointer lg:w-[150px] pointer-events-none"
                  />
                  <p
                    className={classNames(
                      resultTextStyle,
                      q1 === '4' ? 'lg:mb-[8px] mb-[2px]' : 'lg:mb-[11px] mb-[5px]',
                      'text-[#98979B]',
                    )}
                  >
                    {' '}
                    {t('Covid19SelectResultPage.INVALID')}
                  </p>
                </div>
              </div>
              {/* 选项五 */}
              <div
                className="flex flex-col items-center px-[15px] pb-[25px] lg:px-[39px] lg:pb-[45px]"
                onClick={() => {
                  setQ1('5');
                  CovidtestResultStore.set('5');
                }}
                id="covid19__invalid2"
              >
                <div
                  className={classNames(
                    q1 === '5' && 'border-[#2DBDB6] border-solid border-[3px] lg:border-[3px]',
                    'bg-[#DFDFDF] relative w-[150px] overflow-hidden h-[147px] rounded-[18px] flex items-center justify-center mb-[32px] lg:w-[163px] lg:h-[163px] lg:rounded-[18px] lg:mb-[32px]',
                  )}
                >
                  <img
                    src={invalid2Img}
                    alt="selectFive"
                    className="object-cover w-[150px] h-[150px] cursor-pointer lg:w-[150px] pointer-events-none"
                  />
                  <p
                    className={classNames(
                      resultTextStyle,
                      q1 === '5' ? 'lg:mb-[8px] mb-[2px]' : 'lg:mb-[11px] mb-[5px]',
                      'text-[#98979B]',
                    )}
                  >
                    {' '}
                    {t('Covid19SelectResultPage.INVALID')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex md:items-center md:justify-center pt-[0px] pb-[100px] lg:pt-[50px] lg:pb-[127px]">
            {showOrderNewKit ? (
              <OrderNewKitText
                type="covid"
                id="covid19-order-new-kit__button"
                dataTrackFunc={() => {
                  return track({
                    event_type: 'proceed after combo test',
                    event_properties: {
                      'testing id': getDataTrackConstantId('TestingId'),
                      'button type': 'order new',
                      'test result': dataTrackHelpInfos[q1],
                    },
                  }).promise;
                }}
              />
            ) : (
              <BaseButton
                htmlType="submit"
                buttonClassName="w-full h-[50px] text-[16px] md:w-[326px] md:h-[50px] md:text-[16px] w-[100%] h-[50px]"
                value={t('Covid19SelectResultPage.submitText')}
                status={buttonStatus}
                id="covid19__submit"
                disable={buttonStatus === 'disabled'}
                onClick={() => {
                  track({
                    event_type: 'proceed after combo test',
                    event_properties: {
                      'testing id': getDataTrackConstantId('TestingId'),
                      'button type': 'book appointment',
                      'test result': dataTrackHelpInfos[q1 || '0'],
                    },
                  }).promise;
                  alertHealthWarning({
                    onOk: () => {
                      clearDataTrackConstantId('TestingId');

                      if (q1) {
                        const destination = `/selectTimeSlot?selectResultType=${resultTypeMap[q1]}`;
                        if (patientTokenStore.get()) {
                          navigate(destination);
                        } else {
                          window.location.href = `${window.location.origin}/login?redirect=${encodeURIComponent(
                            window.location.origin + destination,
                          )}`;
                        }
                      }
                    },
                    pageIdName: 'selectResult-covid',
                  });
                }}
                sectionClassName="w-full md:w-[326px]"
              ></BaseButton>
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default Covid19SelectResult;
