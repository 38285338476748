import { Modal } from 'antd';
import permisionAcessBg from '@images/permisionAcessBg.png';
import allowBg from '@images/allowBg.png';
import debounce from 'lodash-es/debounce';
let isOpen = false;
export default debounce(
  function CameraAndMicrophoneModal(isLg: boolean) {
    if (isOpen) return;
    const m = Modal.info({
      content: (
        <div className="relative wrapper text-center">
          <img className="absolute top-0 left-0 w-full" src={permisionAcessBg} />
          <img className="z-10 relative lg:w-[211px] w-[150px] inline-block lg:mt-[28px] mt-[16px]" src={allowBg} />
          <div className="bg-[#1A1A1A] lg:h-[245px] h-[190px] rounded-b-[20px] text-[20px] leading-[25px] text-white lg:pt-[64px] pt-[30px]">
            <p>Allow access to</p>
            <p>camera and microphone </p>
            <p className="text-[#b3b3b3] lg:text-[14px] text-[12px] mt-[13px]">
              Select “Allow” for others to see and hear you
            </p>
            <p
              onClick={() => {
                m.destroy();
                isOpen = false;
              }}
              className="text-[#2DBDB6] text-[16px] lg:mt-[34px] mt-[20px] cursor-pointer"
            >
              Dismiss
            </p>
          </div>
        </div>
      ),
      width: isLg ? '476px' : '7rem',
      icon: null,
      footer: null,
      className: 'camera-and-microphone-modal',
    });
    isOpen = true;
  },
  1000,
  {
    leading: true,
    trailing: false,
  },
);
