import BookingSteps from '@components/BookingSteps/BookingSteps';
import Collapse from '@components/Collapse/Collapse';
import CustomCalendar from '@components/CustomCalendar/CustomCalendar';
import { useEffect, useState } from 'react';
import bgAppointment from './bgAppointment.png';
import AppointmentTimeItem from './components/AppointmentTimeItem';
import dayjs, { Dayjs } from 'dayjs';
import { TimePointEnum, TimeSlotStringAvailableData } from 'src/services/types/timeslot.type';
import { healthPost } from '@utils/request';
// import { get } from '@utils/request';
import { compareTime, handleLocalStorage } from '@utils/index';
import website from '@configs/website';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { MEDIA_SIZE } from '@consts/consts';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { useMemoizedFn } from 'ahooks';
import classNames from 'classnames';
import { resultTypeText } from '../SelectResult/SelectResult';
import { useSelectResultType } from 'src/hooks/tools/useSelectResultType';
import { UUTUBE_TYPE, CFF_TYPE, COVID_TYPE } from '@consts/consts';
import { getDataTrackConstantId, track } from '@utils/dataTrack';

const MorningTimeList: (keyof typeof TimePointEnum)[] = [
  '74742298314800019',
  '74742298314800020',
  '74742298314800021',
  '74742298314800022',
  '74742298314800023',
  '74742298314800024',
];
const AfternoonTimeList: (keyof typeof TimePointEnum)[] = [
  '74742298314800025',
  '74742298314800026',
  '74742298314800027',
  '74742298314800028',
  '74742298314800029',
  '74742298314800030',
  '74742298314800031',
  '74742298314800032',
  '74742298314800033',
  '74742298314800034',
  '74742298314800035',
  '74742298314800036',
];
const EveningTimeList: (keyof typeof TimePointEnum)[] = [
  '74742298314800037',
  '74742298314800038',
  '74742298314800039',
  '74742298314800040',
  '74742298314800041',
];

export default function SelectTimeSlot() {
  useEffect(() => {
    const t = setTimeout(() => {
      track({
        event_type: 'view time slot',
        event_properties: {
          'booking id': getDataTrackConstantId('BookingId'),
        },
      });
    }, 30);
    return () => {
      clearTimeout(t);
    };
  }, []);

  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const [t] = useTranslation();
  const [openIdx, setOpenIdx] = useState(1);
  const [selectedDate, setSelectDate] = useState(dayjs().format('YYYY-MM-DD'));
  const { productType, isHaveSelectResultType, selectResultType } = useSelectResultType();

  const [avaliableDateList, setAvaliableDateList] = useState<string[]>([]);
  const [allAvaliableDate, setAllAvaliableDate] = useState<TimeSlotStringAvailableData>({});
  const [beHiddenObj, setBeHiddenObj] = useState<{
    hideOffline?: boolean;
    hideOnline?: boolean;
  }>({});

  useEffect(() => {
    const getTimeslotParmas = (
      answers?: {
        questionId: string;
        questionAnswerId: string;
      }[],
    ) => {
      if (!isHaveSelectResultType) {
        return null;
      }

      let timeslotParmas;
      if (productType === UUTUBE_TYPE) {
        timeslotParmas = {
          fromProductType: productType,
          testResult: selectResultType,
          answers: answers,
        };
      } else if (productType === CFF_TYPE) {
        timeslotParmas = {
          fromProductType: productType,
          testResult: selectResultType,
        };
      } else if (productType === COVID_TYPE) {
        timeslotParmas = {
          fromProductType: productType,
          testResult: selectResultType,
        };
      } else {
        // 如果不符合我们的预期，则设置为UUTUBE_TYPE
        timeslotParmas = {
          fromProductType: productType,
          testResult: selectResultType,
          answers: answers || [],
        };
      }

      return timeslotParmas;
    };

    const getTimeSlotDataFunction = async () => {
      try {
        const localStorageAnswers = handleLocalStorage.get(website.answers);

        let answers: {
          questionId: string;
          questionAnswerId: string;
        }[] = [];

        // localStorageAnswers【解决为空的情况】
        localStorageAnswers &&
          localStorageAnswers.map((obj: any) => {
            answers.push({
              questionId: obj?.id,
              questionAnswerId: obj?.answer,
            });
          });
        let result = handleLocalStorage.get('testResult') as keyof typeof resultTypeText;
        let testResult = resultTypeText[result];
        if (testResult != 'positive') {
          testResult = 'negative';
        }
        console.log(testResult, 'testResult');
        const timeslotParams = getTimeslotParmas(answers);

        const data = await healthPost('/care/timeslot/ava-timeslot', timeslotParams);

        setBeHiddenObj({
          hideOffline: data.hideOffline,
          hideOnline: data.hideOnline,
        });

        let newData = data?.data;
        // TODO: 这里也需要进行调整，需要再测试一下
        setAllAvaliableDate(newData);
        console.log(newData, 'newData');
        const avaliableDateListTemp = Object.keys(newData)
          .map((secondTimeStamp) => secondTimeStamp)
          .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
        console.log(avaliableDateListTemp, 'avaliableDateListTemp');
        setAvaliableDateList(avaliableDateListTemp);
      } catch (err: any) {
        const responseMessage = err?.response?.data?.message;
        const errorMessage = (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || 'error!';
        message.error(<span id="selectTimeSlot____ava-timeslot-error-message">{errorMessage}</span>);
      }
    };
    getTimeSlotDataFunction();
    // 计算打开哪个时间段弹窗
    const nowHour = dayjs().format('HH');
    if (+nowHour < 12) {
      setOpenIdx(1);
    } else if (+nowHour < 18) {
      setOpenIdx(2);
    } else {
      setOpenIdx(3);
    }
  }, [isHaveSelectResultType, productType, selectResultType]);
  const handleSelectDateChange = useMemoizedFn((d: Dayjs) => {
    const dateFormatText = d.format('YYYY-MM-DD');
    const todayFormatText = dayjs().format('YYYY-MM-DD');
    if (dateFormatText !== todayFormatText) {
      setOpenIdx(1);
    } else {
      // 计算打开哪个时间段弹窗
      const nowHour = dayjs().format('HH');
      if (+nowHour < 12) {
        setOpenIdx(1);
      } else if (+nowHour < 18) {
        setOpenIdx(2);
      } else {
        setOpenIdx(3);
      }
    }
    setSelectDate(d.format('YYYY-MM-DD'));
  });

  // TODO: 存在bug=>需要再看一下
  const getOnlineDisable = (disableThisItem: boolean, tm: keyof typeof TimePointEnum) => {
    {
      /*展示的逻辑
      ①如果为undefined，则隐藏，
      ②true 展示， false  disable掉 */
    }
    let onlineDisable =
      typeof allAvaliableDate[selectedDate]?.[tm]?.online === 'boolean'
        ? !allAvaliableDate[selectedDate]?.[tm]?.online || disableThisItem
        : undefined;
    console.log(onlineDisable, allAvaliableDate[selectedDate], tm, 'onlineDisable');
    // 如果后端没传online，则需要根据beHiddenObj进行展示或者隐藏
    if (typeof onlineDisable === 'undefined' && !beHiddenObj.hideOnline) {
      // 把值展示为disable
      onlineDisable = true;
    }

    return onlineDisable;
  };

  const getOfflineDisable = (disableThisItem: boolean, tm: keyof typeof TimePointEnum) => {
    {
      /*展示的逻辑
      ①如果为undefined，则隐藏，
      ②true 展示， false  disable掉 */
    }
    let offlineDisable =
      typeof allAvaliableDate[selectedDate]?.[tm]?.offline === 'boolean'
        ? !allAvaliableDate[selectedDate]?.[tm]?.offline || disableThisItem
        : undefined;

    // 如果后端没传offline，则需要根据beHiddenObj进行展示或者隐藏
    if (typeof offlineDisable === 'undefined' && !beHiddenObj.hideOffline) {
      // 把值展示为disable
      offlineDisable = true;
    }
    return offlineDisable;
  };

  const blockedTimeThreshold = dayjs().format('hh:mma').replace('上午', 'am').replace('下午', 'pm');
  const isSelectToday = selectedDate === dayjs().format('YYYY-MM-DD');

  return (
    <div className="relative flex w-full justify-center">
      <div className="absolute top-0 left-0 w-full">
        <img className="w-full" src={bgAppointment} />
      </div>
      <div className="relative lg:w-[1280px] lg:py-[68px] lg:rounded-[30px] lg:mt-[13.3vw] lg:mb-[76px] pb-[62px] bg-[#ffffff]">
        <BookingSteps step={2} />
        <div className="lg:ml-[187px]">
          {!isLg && (
            <div className=" lg:text-[28px] ml-[24px] text-[28px] mt-[18px] mb-[16px] leading-[36px] font-[600] text-[#2DBDB6] ">
              {t('SelectTimeSlotPage.BookAppointment')}
            </div>
          )}
          <p className="font-semibold lg:text-[28px] ml-[24px] text-[18px]">
            {t('SelectTimeSlotPage.SelectDateAndTime')}
          </p>
          <p className="lg:text-[18px] lg:ml-[24px] text-[16px] mt-[8px] lg:mt-[2px] px-[24px] ml-0 lg:px-0">
            {t('SelectTimeSlotPage.SelectTimeSlotDesc')}
          </p>
        </div>
        <CustomCalendar
          avaliableDateList={avaliableDateList}
          onSelectChange={handleSelectDateChange}
          pageIdName="selectTimeSlot"
        />
        <div className="lg:w-[906px] m-auto lg:mt-[43px] mt-[78px] w-[366px]">
          <Collapse
            titleWrapperClassName={classNames(
              'relative cursor-pointer overflow-hidden',
              'lg:h-[63px] lg:px-[42px] lg:py-[16px]',
              'h-[63px] px-[19px] py-[19px]',
            )}
            id="selectTimeSlot__morning-Collapse"
            titleNotCollapseColor="#2DBDB6"
            onOpenChange={(o) => {
              if (o) {
                setOpenIdx(1);
              } else {
                setOpenIdx(0);
              }
            }}
            title={`${t('Morning')}  (9:00am - 11:30am)`}
            isOpen={openIdx === 1}
          >
            <div className="pb-[30px]">
              {MorningTimeList.map((tm) => {
                let disableThisItem = false;
                if (isSelectToday) {
                  disableThisItem = compareTime(TimePointEnum[tm], blockedTimeThreshold) === -1;
                }
                const onlineDisable = getOnlineDisable(disableThisItem, tm);
                const offlineDisable = getOfflineDisable(disableThisItem, tm);
                return (
                  <AppointmentTimeItem
                    // timeData={allAvaliableDate[selectedDate]?.[tm]}
                    timeStatus="morning"
                    onlineDisable={onlineDisable}
                    offlineDisable={offlineDisable}
                    key={tm}
                    timeText={TimePointEnum[tm]}
                    t={tm}
                    selectedDate={selectedDate}
                  />
                );
              })}
            </div>
          </Collapse>
          <div className="lg:h-[32px] h-[32px]"></div>
          <Collapse
            titleWrapperClassName={classNames(
              'relative cursor-pointer overflow-hidden',
              'lg:h-[63px] lg:px-[42px] lg:py-[16px]',
              'h-[63px] px-[19px] py-[19px]',
            )}
            id="selectTimeSlot__afternoon-Collapse"
            titleNotCollapseColor="#2DBDB6"
            onOpenChange={(o) => {
              if (o) {
                setOpenIdx(2);
              } else {
                setOpenIdx(0);
              }
            }}
            title={`${t('Afternoon')} (12:00pm - 5:30pm)`}
            isOpen={openIdx === 2}
          >
            <div className="pb-[30px]">
              {AfternoonTimeList.map((tm) => {
                let disableThisItem = false;
                if (isSelectToday) {
                  disableThisItem = compareTime(TimePointEnum[tm], blockedTimeThreshold) === -1;
                }
                const onlineDisable = getOnlineDisable(disableThisItem, tm);
                const offlineDisable = getOfflineDisable(disableThisItem, tm);

                return (
                  <AppointmentTimeItem
                    // timeData={allAvaliableDate[selectedDate]?.[tm]}
                    timeStatus="afternoon"
                    onlineDisable={onlineDisable}
                    offlineDisable={offlineDisable}
                    key={tm}
                    timeText={TimePointEnum[tm]}
                    t={tm}
                    selectedDate={selectedDate}
                  />
                );
              })}
            </div>
          </Collapse>
          <div className="lg:h-[32px] h-[32px]"></div>
          <Collapse
            titleWrapperClassName={classNames(
              'relative cursor-pointer overflow-hidden',
              'lg:h-[63px] lg:px-[42px] lg:py-[16px]',
              'h-[63px] px-[19px] py-[19px]',
            )}
            id="selectTimeSlot__evening-Collapse"
            titleNotCollapseColor="#2DBDB6"
            onOpenChange={(o) => {
              if (o) {
                setOpenIdx(3);
              } else {
                setOpenIdx(0);
              }
            }}
            title={`${t('Evening')} (6:00pm - 8:00pm)`}
            isOpen={openIdx === 3}
          >
            <div className="pb-[30px]">
              {EveningTimeList.map((tm) => {
                let disableThisItem = false;
                if (isSelectToday) {
                  disableThisItem = compareTime(TimePointEnum[tm], blockedTimeThreshold) === -1;
                }
                const onlineDisable = getOnlineDisable(disableThisItem, tm);
                const offlineDisable = getOfflineDisable(disableThisItem, tm);
                return (
                  <AppointmentTimeItem
                    // timeData={allAvaliableDate[selectedDate]?.[tm]}
                    timeStatus="evening"
                    onlineDisable={onlineDisable}
                    offlineDisable={offlineDisable}
                    key={tm}
                    timeText={TimePointEnum[tm]}
                    t={tm}
                    selectedDate={selectedDate}
                  />
                );
              })}
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
}
