import { AnyFunction } from 'types/types';

type EventFuncMap = {
  [props: string]: AnyFunction;
};

export default class EventBase<E extends EventFuncMap = EventFuncMap, T extends keyof E = keyof EventFuncMap> {
  private eventsMap: Map<T, E[T][]> = new Map();

  protected addListener(eventName: T, cb: E[T]) {
    const eventList = this.eventsMap.get(eventName) || [];
    eventList.push(cb);
    this.eventsMap.set(eventName, eventList);

    return () => {
      const idx = eventList.indexOf(cb);
      if (idx !== -1) {
        eventList.splice(idx, 1);
      }
    };
  }

  protected removeListener(eventName: T, cb: E[T]) {
    const eventList = this.eventsMap.get(eventName);
    if (eventList) {
      const idx = eventList.indexOf(cb);
      if (idx !== -1) {
        eventList.splice(idx, 1);
      }
    }
  }

  triggerEvent(eventName: T, ...args: Parameters<E[T]>) {
    const eventList = this.eventsMap.get(eventName);
    if (eventList) {
      eventList.forEach((event) => Promise.resolve().then(() => event(...args)));
    }
  }

  on(eventName: T, cb: E[T]) {
    return this.addListener(eventName, cb);
  }
  off(eventName: T, cb: E[T]) {
    return this.removeListener(eventName, cb);
  }
}
