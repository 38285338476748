import React, { useState, useEffect } from 'react';
import type { RadioChangeEvent } from 'antd';
import { Radio, Space, message } from 'antd';
import { useMemoizedFn } from 'ahooks';
import BaseButton, { ButtonStatus } from '@components/BaseButton/BaseButton';
import { formatData } from '@utils/index';
import BookingSteps from '@components/BookingSteps/BookingSteps';
import { MEDIA_SIZE } from '@consts/consts';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { saveConsultationMethod } from '@utils/questionnaire/consultationMethod';
import { handleLocalStorage } from '@utils/index';
import website from '@configs/website';
import { useTranslation } from 'react-i18next';
import { useLocalNavigate } from 'src/router/routerTools';
import { getDataTrackConstantId, track } from '@utils/dataTrack';
// import { useProductType } from 'src/hooks/tools/useProductType';
import { useSelectResultType } from 'src/hooks/tools/useSelectResultType';

// TODO 监听前进后退，这个功能需要再问一下

const questionSelectText = {
  questionOne: {
    selectOne: 'Yes',
    selectTwo: 'No',
  },
  questionTwo: {
    selectOne: 'Yes',
    selectTwo: 'No',
  },
  questionThree: {
    selectOne: 'Yes',
    selectTwo: 'No',
  },
  questionFour: {
    selectOne: 'Yes',
    selectTwo: 'No',
    selectThree: 'Never',
  },
};

// const questionSelectKey = Object.keys(questionSelectText);

// const questionValue=[`${questionSelectKey[0]}_${questionSelectText?.questionOne?.selectOne}`]

const style = {
  container: {
    background: '#FDFDFD',
  },
};

const HealthScreen: React.FC = () => {
  useEffect(() => {
    const t = setTimeout(() => {
      track({
        event_type: 'view health screening',
        event_properties: {
          'booking id': getDataTrackConstantId('BookingId'),
          source: window.document.referrer,
        },
      });
    }, 30);
    return () => {
      clearTimeout(t);
    };
  }, []);

  const { isHaveSelectResultType, selectResultType } = useSelectResultType();

  // const { productType, isHaveProductType } = useProductType();
  const [t] = useTranslation();
  const navigate = useLocalNavigate();

  const [q1, setQ1] = useState<string | null>(null);
  const [q2, setQ2] = useState<string | null>(null);
  const [q3, setQ3] = useState<string | null>(null);
  const [q4, setQ4] = useState<string | null>(null);

  const [buttonStatus, setButtonStatus] = useState<ButtonStatus>('default');
  const [buttonLoading, setButtonLoading] = useState(false);
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;

  const onQ1Change = useMemoizedFn((e: RadioChangeEvent) => {
    setQ1(e.target.value);
  });

  const onQ2Change = useMemoizedFn((e: RadioChangeEvent) => {
    setQ2(e.target.value);
  });

  const onQ3Change = useMemoizedFn((e: RadioChangeEvent) => {
    setQ3(e.target.value);
  });

  const onQ4Change = useMemoizedFn((e: RadioChangeEvent) => {
    setQ4(e.target.value);
  });

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement> =
    useMemoizedFn(async (e) => {
      e.preventDefault();
      const canSubmit = q1 && q2 && q3 && q4;
      if (!canSubmit) {
        // message.error('Please fill in answer for all mandatory questions');
        return;
      }
      try {
        setButtonLoading(true);
        let params: any = [];
        const qArr = [q1, q2, q3, q4];
        qArr.map((val) => {
          params.push(formatData(val));
        });
        handleLocalStorage.set(website.answers, params);
        saveConsultationMethod(params);
        const qMap: any = {
          '1_1': 'yes',
          '1_2': 'no',
          '2_3': 'yes',
          '2_4': 'no',
          '3_5': 'yes',
          '3_6': 'no',
          '4_7': 'yes',
          '4_8': 'no',
          '4_9': 'Never had a gastroscopy examination',
        };
        track({
          event_type: 'submit health screening',
          event_properties: {
            '1st question': qMap[q1],
            '2nd question': qMap[q2],
            '3rd question': qMap[q3],
            '4th question': qMap[q4],
            'booking id': getDataTrackConstantId('BookingId'),
          },
        });
        setTimeout(() => {
          navigate(`/selectTimeSlot${isHaveSelectResultType ? `?selectResultType=${selectResultType}` : ''}`);
          setButtonLoading(false);
        }, 1000);
      } catch (err: any) {
        const responseMessage = err?.response?.data?.message;
        const errorMessage = Array.isArray(responseMessage) ? responseMessage[0] : responseMessage;
        if (err?.response?.data?.statusCode === '403' || err?.response?.data?.statusCode === 403) {
          // const responseMessage = err?.response?.data?.message;
          // const errorMessage = (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || 'Please login!';
          message.error(<span id="healthScreen__no-permissions-error-message">{errorMessage || 'Please login!'}</span>);
          navigate(`/login${isHaveSelectResultType ? `?selectResultType=${selectResultType}` : ''}`);
          return;
        }

        setButtonLoading(false);
        message.error(<span id="healthScreen__jump-error-message">{errorMessage || 'error!'}</span>);
      }
    });
  // 监听页面刷新事件
  const beforeunload = useMemoizedFn((ev: any) => {
    const showModal = q1 || q2 || q3 || q4;
    if (showModal) {
      const returnValue = 'Are you sure you would like to leave? Your progress will not be saved';
      ev.returnValue = returnValue;
      return returnValue;
    } else {
      return;
    }
  });

  useEffect(() => {
    window.addEventListener('beforeunload', beforeunload);
    return () => {
      window.removeEventListener('beforeunload', beforeunload);
    };
  }, [beforeunload]);

  useEffect(() => {
    if (q1 && q2 && q3 && q4) {
      setButtonStatus('default');
    } else {
      setButtonStatus('disabled');
    }
    setButtonLoading(false);
  }, [q1, q2, q3, q4]);

  return (
    <div
      className="w-full h-full flex  bg-opacity-[0.8] flex-col py-[0] px-[24px] md:px-[32px] md:items-center lg:px-[0px] lg:items-center min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)]"
      style={style.container}
    >
      <form className="flex flex-col justify-start pt-[20px] md:pt-[20px]">
        <BookingSteps step={1} />
        {!isLg && (
          <div className="mt-[25px] mb-[20px] font-[600] text-[28px] leading-[36px] text-[#2DBDB6] md:mt-[25px] md:mb-[20px] md:text-[28px] md:leading-[36px] lg:mt-[106px] lg:mb-[24px] lg:font-[600] lg:text-[42px] lg:leading-[58px]">
            {t('HealthScreenPage.Title')}
          </div>
        )}
        <div className="font-[400] text-[14px] leading-[24px] text-[#212121] md:text-[14px] md:leading-[24px] lg:font-[500] lg:text-[18px] lg:leading-[28px] lg:text-[#444850] lg:w-[952px] lg:mt-[67px]">
          {t('HealthScreenPage.introduce')}
        </div>
        {/* questionOne */}
        <div className="mt-[32px] flex flex-col md:mt-[32px] lg:mt-[80px]">
          <div className="font-[600] text-[16px] leading-[24px] text-[#212121] mb-[36px] flex md:text-[16px] md:leading-[24px] md:mb-[36px] lg:text-[18px] lg:leading-[28px] lg:mb-[24px]">
            <div>
              1. {t('HealthScreenPage.q1')}
              <span className="text-[#FF0000]">*</span>
            </div>
          </div>
          <Radio.Group onChange={onQ1Change} value={q1}>
            <Space direction="vertical">
              <Radio value={'74739313635490001_74741249076100001'} id="healthScreen__q1-yes">
                <div className="font-[400] text-[18px] leading-[28px] text-[#212121] md:text-[18px] md:leading-[28px] lg:text-[18px] lg:leading-[28px]">
                  {t(`HealthScreenPage.${questionSelectText.questionOne.selectOne}`)}
                </div>
              </Radio>
              <Radio
                value={'74741249076100001_74741249076100002'}
                id="healthScreen__q1-no"
                className="email-select-radio"
              >
                <div className="font-[400] text-[18px] leading-[28px] text-[#212121] md:text-[18px] md:leading-[28px] lg:text-[18px] lg:leading-[28px]">
                  {t(`HealthScreenPage.${questionSelectText.questionOne.selectTwo}`)}
                  {/* {t(questionSelectText.questionOne.selectTwo)} */}
                </div>
              </Radio>
            </Space>
          </Radio.Group>
        </div>
        {/* questionTwo */}
        <div className="mt-[32px] flex flex-col md:mt-[32px] lg:mt-[63px]">
          <div className="font-[600] text-[16px] leading-[24px] text-[#212121] mb-[36px] flex md:text-[16px] md:leading-[24px] md:mb-[36px] lg:text-[18px] lg:leading-[28px] lg:mb-[24px]">
            <div>
              2. {t('HealthScreenPage.q2')}
              <span className="text-[#FF0000]">*</span>
            </div>
          </div>
          <Radio.Group onChange={onQ2Change} value={q2}>
            <Space direction="vertical">
              <Radio value={'74739313635490002_74741249076100003'} id="healthScreen__q2-yes">
                <div className="font-[400] text-[18px] leading-[28px] text-[#212121] md:text-[18px] md:leading-[28px] lg:text-[18px] lg:leading-[28px]">
                  {t(`HealthScreenPage.${questionSelectText.questionTwo.selectOne}`)}
                  {/* {t(questionSelectText.questionTwo.selectOne)} */}
                </div>
              </Radio>
              <Radio
                value={'74739313635490002_74741249076100004'}
                className="email-select-radio"
                id="healthScreen__q2-no"
              >
                <div className="font-[400] text-[18px] leading-[28px] text-[#212121] md:text-[18px] md:leading-[28px] lg:text-[18px] lg:leading-[28px]">
                  {t(`HealthScreenPage.${questionSelectText.questionTwo.selectTwo}`)}
                  {/* {t(questionSelectText.questionTwo.selectTwo)} */}
                </div>
              </Radio>
            </Space>
          </Radio.Group>
        </div>
        {/* questionThree */}
        <div className="mt-[32px] flex flex-col md:mt-[32px] lg:mt-[63px]">
          <div className="font-[600] text-[16px] leading-[24px] text-[#212121] mb-[36px] flex md:text-[16px] md:leading-[24px] md:mb-[36px] lg:text-[18px] lg:leading-[28px] lg:mb-[24px]">
            <div>
              3. {t('HealthScreenPage.q3')}
              <span className="text-[#FF0000]">*</span>
            </div>
          </div>
          <Radio.Group onChange={onQ3Change} value={q3}>
            <Space direction="vertical">
              <Radio value={'74739313635490003_74741249076100005'} id="healthScreen__q3-yes">
                <div className="font-[400] text-[18px] leading-[28px] text-[#212121] md:text-[18px] md:leading-[28px] lg:text-[18px] lg:leading-[28px]">
                  {t(`HealthScreenPage.${questionSelectText.questionThree.selectOne}`)}
                  {/* {t(questionSelectText.questionThree.selectOne)} */}
                </div>
              </Radio>
              <Radio
                value={'74739313635490003_74741249076100006'}
                className="email-select-radio"
                id="healthScreen__q3-no"
              >
                <div className="font-[400] text-[18px] leading-[28px] text-[#212121] md:text-[18px] md:leading-[28px] lg:text-[18px] lg:leading-[28px]">
                  {t(`HealthScreenPage.${questionSelectText.questionThree.selectTwo}`)}
                  {/* {t(questionSelectText.questionThree.selectTwo)} */}
                </div>
              </Radio>
            </Space>
          </Radio.Group>
        </div>
        {/* questionFour */}
        <div className="mt-[32px] flex flex-col md:mt-[32px] lg:mt-[63px]">
          <div className="font-[600] text-[16px] leading-[24px] text-[#212121] mb-[36px] flex md:text-[16px] md:leading-[24px] md:mb-[36px] lg:text-[18px] lg:leading-[28px] lg:mb-[24px]">
            <div>
              4. {t('HealthScreenPage.q4')}
              <span className="text-[#FF0000]">*</span>
            </div>
          </div>
          <Radio.Group onChange={onQ4Change} value={q4}>
            <Space direction="vertical">
              <Radio value={'74739313635490004_74741249076100007'} id="healthScreen__q4-yes">
                <div className="font-[400] text-[18px] leading-[28px] text-[#212121] md:text-[18px] md:leading-[28px] lg:text-[18px] lg:leading-[28px]">
                  {t(`HealthScreenPage.${questionSelectText.questionFour.selectOne}`)}
                  {/* {t(questionSelectText.questionFour.selectOne)} */}
                </div>
              </Radio>
              <Radio
                value={'74739313635490004_74741249076100008'}
                className="email-select-radio"
                id="healthScreen__q4-no"
              >
                <div className="font-[400] text-[18px] leading-[28px] text-[#212121] md:text-[18px] md:leading-[28px] lg:text-[18px] lg:leading-[28px]">
                  {t(`HealthScreenPage.${questionSelectText.questionFour.selectTwo}`)}
                  {/* {t(questionSelectText.questionFour.selectTwo)} */}
                </div>
              </Radio>
              <Radio
                value={'74739313635490004_74741249076100009'}
                className="email-select-radio"
                id="healthScreen__q4-never"
              >
                <div className="font-[400] text-[18px] leading-[28px] text-[#212121] md:text-[18px] md:leading-[28px] lg:text-[18px] lg:leading-[28px]">
                  {t(`HealthScreenPage.${questionSelectText.questionFour.selectThree}`)}
                  {/* {t(questionSelectText.questionFour.selectThree)} */}
                </div>
              </Radio>
            </Space>
          </Radio.Group>
        </div>
        <BaseButton
          htmlType="submit"
          buttonClassName="w-full mt-[42px] mb-[65px] h-[50px] text-[16px] md:w-[326px] md:h-[50px] md:text-[16px] md:mb-[122px] md:mt-[81px]"
          value={t('HealthScreenPage.submit')}
          disable={buttonStatus === 'disabled'}
          status={buttonStatus}
          loading={buttonLoading}
          onClick={handleClick}
          id="healthScreen__submit"
          style={{
            backgroundColor: q1 && q2 && q3 && q4 ? '#2DBDB6' : '#F5F5F5',
          }}
        ></BaseButton>

        {/* <p className="mb-[5.748rem] text-[0.386rem] cursor-pointer" onClick={handleClickBack}>
          <span className="text-[#2DBDB6]">Back</span> to previous page
        </p> */}
      </form>
    </div>
  );
};

export default HealthScreen;
