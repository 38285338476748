import LocalStore from './localStore';
import website from '@configs/website';

function createTokenStore(tokenKey: string) {
  return {
    get: () => {
      let token = LocalStore.get(tokenKey);
      if (!token) {
        token = localStorage.getItem(tokenKey);

        if (token && token.startsWith('"') && token.endsWith('"')) {
          token = JSON.parse(token);
        }

        localStorage.removeItem(tokenKey);
        LocalStore.set(tokenKey, token);
      }
      return token;
    },
    set: (token: string) => {
      LocalStore.set(tokenKey, token);
    },
    remove: () => {
      LocalStore.remove(tokenKey);
    },
  };
}

export const patientTokenStore = createTokenStore(website.access_token_key);
export const doctorTokenStore = createTokenStore(website.doctor_access_token_key);
