import { CloseOutlined } from '@ant-design/icons';
import { globalAlert } from '../GlobalAlert';
import AlertTermsListItem from './components/AlertTermsListItem';
import AlertTermsListTitle from './components/AlertTermsListTitle';
import AlertTermsTitle from './components/AlertTermsTitle';
import { useTranslation } from 'react-i18next';

type TermsAlertContentProps = { closeFunction: () => void; id?: string };
const TermsAlertContent: React.FC<TermsAlertContentProps> = (props) => {
  const [t] = useTranslation();
  return (
    <div className="lg:w-[1024px] lg:h-[762px] w-full h-[650px] rounded-[30px] lg:rounded-[30px] bg-white">
      <div className="lg:px-[41px] lg:pt-[36px] pt-[27px] px-[22px] text-[22px] font-bold text-right">
        <CloseOutlined className="cursor-pointer" onClick={props.closeFunction} id={props?.id} />
      </div>
      <div className="lg:px-[59px] px-[30px] lg:h-[670px] h-[566px] overflow-y-scroll custom-scroll-bar">
        <AlertTermsTitle>{t('termsText.title')}</AlertTermsTitle>
        <p
          className="
        lg:text-[16px] lg:leading-[28px] lg:mb-[34px]
        text-[14px] leading-[22px] mb-[30px]"
        >
          {t('termsText.0')}
        </p>
        <AlertTermsListTitle>{t('termsText.1')}</AlertTermsListTitle>
        <AlertTermsListItem orderPrefix="1.1">{t('termsText.1_1')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="1.2">{t('termsText.1_2')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="1.3">{t('termsText.1_3')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="1.4">{t('termsText.1_4')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="1.5">{t('termsText.1_5')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="1.6">{t('termsText.1_6')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="1.7">{t('termsText.1_7')}</AlertTermsListItem>
        <AlertTermsListTitle>{t('termsText.2')}</AlertTermsListTitle>
        <AlertTermsListItem orderPrefix="2.1">{t('termsText.2_1')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="2.2">{t('termsText.2_2')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="2.3">{t('termsText.2_3')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="2.4">{t('termsText.2_4')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="2.5">{t('termsText.2_5')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="2.6">{t('termsText.2_6')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="2.7">{t('termsText.2_7')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="2.8">{t('termsText.2_8')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="2.9">{t('termsText.2_9')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="2.10">{t('termsText.2_10')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="2.11">{t('termsText.2_11')}</AlertTermsListItem>
        <AlertTermsListTitle>{t('termsText.3')}</AlertTermsListTitle>
        <AlertTermsListItem orderPrefix="3.1">{t('termsText.3_1')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="3.2">{t('termsText.3_2')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="3.3">{t('termsText.3_3')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="3.4">{t('termsText.3_4')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="3.5">{t('termsText.3_5')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="3.6">{t('termsText.3_6')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="3.7">{t('termsText.3_7')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="3.8">{t('termsText.3_8')}</AlertTermsListItem>
        <AlertTermsListTitle>{t('termsText.4')}</AlertTermsListTitle>
        <AlertTermsListItem orderPrefix="4.1">{t('termsText.4_1')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="4.2">{t('termsText.4_2')}</AlertTermsListItem>
        <AlertTermsListTitle>{t('termsText.5')}</AlertTermsListTitle>
        <AlertTermsListItem orderPrefix="5.1">{t('termsText.5_1')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="5.2">{t('termsText.5_2')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="5.3">{t('termsText.5_3')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="5.4">{t('termsText.5_4')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="5.5">{t('termsText.5_5')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="5.6">{t('termsText.5_6')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="5.7">{t('termsText.5_7')}</AlertTermsListItem>
        <AlertTermsListTitle>{t('termsText.6')}</AlertTermsListTitle>
        <AlertTermsListItem orderPrefix="6.1">{t('termsText.6_1')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="6.2">{t('termsText.6_2')}</AlertTermsListItem>
        <AlertTermsListTitle>{t('termsText.7')}</AlertTermsListTitle>
        <AlertTermsListItem orderPrefix="7.1">{t('termsText.7_1')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="7.2">{t('termsText.7_2')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="7.3">{t('termsText.7_3')}</AlertTermsListItem>
        <AlertTermsListTitle>{t('termsText.8')}</AlertTermsListTitle>
        <AlertTermsListItem orderPrefix="8.1">{t('termsText.8_1')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="8.2">{t('termsText.8_2')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="8.3">{t('termsText.8_3')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="8.4">{t('termsText.8_4')}</AlertTermsListItem>
        <AlertTermsListTitle>{t('termsText.9')}</AlertTermsListTitle>
        <AlertTermsListItem orderPrefix="9.1">{t('termsText.9_1')}</AlertTermsListItem>
        <AlertTermsListTitle>{t('termsText.10')}</AlertTermsListTitle>
        <AlertTermsListItem orderPrefix="10.1">{t('termsText.10_1')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="10.2">{t('termsText.10_2')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="10.3">{t('termsText.10_3')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="10.4">{t('termsText.10_4')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="10.5">{t('termsText.10_5')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="10.6">{t('termsText.10_6')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="10.7">{t('termsText.10_7')}</AlertTermsListItem>
        <AlertTermsListTitle>{t('termsText.11')}</AlertTermsListTitle>
        <AlertTermsListItem orderPrefix="11.1">{t('termsText.11_1')}</AlertTermsListItem>
        <AlertTermsListItem orderPrefix="11.2">{t('termsText.11_2')}</AlertTermsListItem>
      </div>
    </div>
  );
};

export default function alertTerms(id?: string) {
  return globalAlert((close) => ({
    content: <TermsAlertContent closeFunction={close} id={id} />,
    wraperBackground: 'rgba(0, 0, 0, 0.4)',
  }));
}
