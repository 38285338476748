/* eslint-disable @typescript-eslint/indent */

import { ShipmentDetailsType } from '@services/types/appointmentDetails.type';
import ShipItems from './ShipItems';

export type ShipmentDetailsTotalType = {
  shipmentDetails?: ShipmentDetailsType;
  renderButton?: () => React.ReactElement;
};

export default function ShipmentDetails(props: ShipmentDetailsTotalType) {
  const shipmentDetails = props?.shipmentDetails || {};
  const {
    recipientName = '',
    contactNumber = '',
    country = '',
    city = '',
    districtName = '',
    line1 = '',
    line2 = '',
  } = shipmentDetails;
  // const {
  //   recipientName = 'Peng Alan',
  //   contactNumber = '+8618812345678',
  //   country = 'CN',
  //   city = '深圳市',
  //   districtName = 'Guangdong Sheng',
  //   line1 = '世界花园米兰居2栋1单元602',
  //   line2 = null,
  // } = shipmentDetails;

  console.log(props, 'props');

  return (
    <div className="lg:p-[43px_361px_47px_25px] bg-[#FFFFFF] lg:shadow-[0_12px_26px_rgba(16,30,115,0.06)] lg:rounded-[0px_8px_8px_8px]  relative">
      <div className="flex flex-col">
        <ShipItems title="Recipient Name" content={`${recipientName}`}></ShipItems>
        <ShipItems title="Contact Number" content={`${contactNumber}`}></ShipItems>
        <ShipItems title="Country/Region" content={`${country}`}></ShipItems>
        <ShipItems title="Area" content={`${districtName}`}></ShipItems>
        <ShipItems title="District" content={`${city}`}></ShipItems>
        <ShipItems title="Address Line 1" content={`${line1}`}></ShipItems>
        <ShipItems
          title="Address Line 2"
          content={`${line2 || ''}`}
          style={{
            marginBottom: '0px',
          }}
        ></ShipItems>
      </div>
      {/* <div className="absolute sm:right-[30px] sm:bottom-[22px]">{props?.renderButton && props?.renderButton()}</div> */}
    </div>
  );
}
