// import { DatePicker, Form, Input, Select, Row, Col, Button, Space } from 'antd';
import { DatePicker, Form, Row, Col } from 'antd';
import classNames from 'classnames';
import './searchBar.css';

// import moment from 'moment';

// import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

// export type AppointmentsSearchBarOnCheckValidType = (
//   v: string,
//   option: {
//     setStatus: React.Dispatch<React.SetStateAction<TextInputStatus>>;
//     setHelpText: React.Dispatch<React.SetStateAction<string>>;
//   },
// ) => void;

export type AppointmentsSearchBarType = {
  title?: string;
  placeholder?: string;
  value?: string;
  onChange?: (v: string) => void;
  /**
   * 检查数据合法性
   * @param v 值
   * @param option
   * @returns
   */
  // onCheckValid?: AppointmentsSearchBarOnCheckValidType;
  // array
  dataSource?: any;
  pagination?: number;
  loading?: boolean;
  actionRender?: any;
  setSelectResult?: React.Dispatch<React.SetStateAction<'Upcoming' | 'OnGoing' | 'Completed' | 'All'>>;
  selectResult?: string;
  onChangeRangePicker?: (val: any) => void;
};

const KEY_WORD_SEARCH_BAR_LAYOUT = {
  rowStyle: {
    gutter: [24, 0],
    style: { maxWidth: 1000 },
  },
  colSpan: {
    xl: { span: 6 },
    span: 8,
  },
  keyWordSpan: {
    xl: { span: 12 },
    span: 16,
  },
};

const TODAY = dayjs().startOf('day');
const TOMORROW = dayjs().add(1, 'days').startOf('day');

// function daysFromTomorrow (num:number) {
//   return moment(TOMORROW).subtract(num, 'days')
// }

export default function AppointmentsSearchBar(props: AppointmentsSearchBarType) {
  const { setSelectResult, selectResult, onChangeRangePicker } = props;

  // const [allTimeInitial, setAllTimeInitial] = useState<{
  //   begin?: any;
  //   end?: any;
  // }>({
  //   // 当天0点
  //   begin: moment(new Date(new Date().toLocaleDateString().getTime())),
  //   // 当天23:59
  //   end:
  //     moment(new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000)),
  // });

  const { RangePicker } = DatePicker;
  // const dateFormat = 'YYYY/MM/DD';

  // const dateFormat = 'YYYY/MM/DD HH:mm:ss';
  const { keyWordSpan } = KEY_WORD_SEARCH_BAR_LAYOUT;

  console.log(props, 'props');

  // const dataVal = (val: any) => {
  //   console.log(val, 'val');
  // };

  const onSearch = (val: any) => {
    console.log(val, 'val');
  };

  // useEffect(() => {
  //   setAllTimeInitial({
  //     // 当天0点
  //     begin: moment(new Date(new Date(new Date().toLocaleDateString()).getTime())),
  //     // 当天23:59
  //     end:
  //       moment(new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)),
  //   });
  // }, []);
  // const { value, onCheckValid } = props;

  // const [helpText, setHelpText] = useState('');
  // const [status, setStatus] = useState<TextInputStatus | undefined>();

  // useEffect(() => {
  //   onCheckValid?.(value, {
  //     setHelpText,
  //     setStatus,
  //   });
  // }, [value, onCheckValid]);

  return (
    // ref={this.form}
    // initialValues={initialValues}
    // TODO 等UI搞定了，再进行修改
    <Form
      layout="vertical"
      onFinish={onSearch}
      // initialValues={{
      //   time: [
      //     allTimeInitial?.begin,
      //     allTimeInitial?.end,
      //   ],
      // }}
    >
      <Row
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        // @Alan: 移除此代码可移除margin-left
        // gutter={24}
      >
        {/* <Col span={12}> */}
        <div className="flex lg:p-[3px_13px_3px_18px] bg-[#fff] lg:rounded-[5px] lg:h-[42px]">
          <div
            className={classNames(
              selectResult === 'Upcoming' ? 'text-[#009FAF]' : ' text-[#25282B]',
              'flex lg:mr-[32px] lg:h-[36px] lg:rounded-[5px] justify-center items-center text-center font-[500] lg:text-[14px] relative',
            )}
            data-clickable
            id="[indicaid][/doctor/appointments][SearchBar][Upcoming]"
            onClick={() => {
              setSelectResult && setSelectResult('Upcoming');
            }}
          >
            <div className="relative">
              Upcoming
              {selectResult === 'Upcoming' && (
                <div className="absolute w-full lg:h-[3px]  lg:shadow-[4px_10px_16px_rgba(16,30,115,0.06) lg:rounded-[5px] bg-[#009FAF] lg:bottom-[-10px]"></div>
              )}
            </div>
          </div>
          <div
            data-clickable
            id="[indicaid][/doctor/appointments][SearchBar][OnGoing]"
            onClick={() => {
              setSelectResult && setSelectResult('OnGoing');
            }}
            className={classNames(
              selectResult === 'OnGoing' ? 'text-[#009FAF]' : ' text-[#25282B]',
              'flex  lg:mr-[23px] lg:h-[36px] lg:rounded-[5px] justify-center items-center text-center font-[500] lg:text-[14px]',
            )}
          >
            <div className="relative">
              In Progress
              {selectResult === 'OnGoing' && (
                <div className="absolute w-full lg:h-[3px]  lg:shadow-[4px_10px_16px_rgba(16,30,115,0.06) lg:rounded-[5px] bg-[#009FAF] lg:bottom-[-10px]"></div>
              )}
            </div>
          </div>
          <div
            data-clickable
            id="[indicaid][/doctor/appointments][SearchBar][Completed]"
            onClick={() => {
              setSelectResult && setSelectResult('Completed');
            }}
            className={classNames(
              selectResult === 'Completed' ? 'text-[#009FAF]' : ' text-[#25282B]',
              'flex  lg:mr-[24px] lg:h-[36px] lg:rounded-[5px] justify-center items-center text-center font-[500] lg:text-[14px]',
            )}
          >
            <div className="relative">
              Completed
              {selectResult === 'Completed' && (
                <div className="absolute w-full lg:h-[3px]  lg:shadow-[4px_10px_16px_rgba(16,30,115,0.06) lg:rounded-[5px] bg-[#009FAF] lg:bottom-[-10px]"></div>
              )}
            </div>
          </div>
          <div
            data-clickable
            id="[indicaid][/doctor/appointments][SearchBar][All]"
            onClick={() => {
              setSelectResult && setSelectResult('All');
            }}
            className={classNames(
              selectResult === 'All' ? 'text-[#009FAF]' : ' text-[#25282B]',
              'flex lg:h-[36px] lg:rounded-[5px] justify-center items-center text-center font-[500] lg:text-[14px]',
            )}
          >
            <div className="relative lg:w-[56px] text-center">
              All
              {selectResult === 'All' && (
                <div className="absolute w-full lg:h-[3px]  lg:shadow-[4px_10px_16px_rgba(16,30,115,0.06) lg:rounded-[5px] bg-[#009FAF] lg:bottom-[-10px]"></div>
              )}
            </div>
          </div>
        </div>
        {/* </Col> */}
        <Col {...keyWordSpan}>
          <Form.Item name="time">
            {/* TODO 调整RangePicker的样式 */}
            <RangePicker
              data-clickable
              id="[indicaid][/doctor/appointments][SearchBar][RangePicker]"
              // locale={locale}
              defaultValue={[dayjs(dayjs().startOf('day').format('YYYY-MM-DD')), dayjs().startOf('day')]}
              // defaultValue={['' + dayjs(dayjs().startOf('day').format('YYYY-MM-DD')), '' + dayjs().startOf('day').add(1, 'day').format('YYYY-MM-DD')]}
              onChange={onChangeRangePicker}
              // showTime
              style={{ width: '100%', height: '42px' }}
              className={'createDateRangePicker'}
              dropdownClassName={'createDateRangePicker'}
              // onOk={(val) => getTime(val)}
              // const TODAY = dayjs().startOf('day');
              // const TOMORROW = dayjs().add(1, 'days').startOf('day');
              ranges={{
                Today: [TODAY, TODAY],
                Tomorrow: [TOMORROW, TOMORROW],
                // 昨天: [daysFromTomorrow(2), TODAY],
                // 近7天: [daysFromTomorrow(7), TOMORROW],
                // 近15天: [daysFromTomorrow(15), TOMORROW],
                // ...extral
              }}
              // format={dateFormat}
            />
          </Form.Item>
        </Col>
        {/* <Col span={6}>
            <Form.Item label=" ">
              <Space>
                <Button htmlType="button" onClick={this.handleReset}>
                  重置
                </Button>
                <Button type="primary" htmlType="submit">
                  查询
                </Button>
              </Space>
            </Form.Item>
          </Col> */}
      </Row>
    </Form>
  );
}
