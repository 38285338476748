import { useContext, useEffect, useState } from 'react';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Modal } from 'antd';
import BaseButton from '@components/BaseButton/BaseButton';

import CheckoutForm from './components/CheckoutForm';
import './payments.css';
// import { Down, Left } from '@icon-park/react';
import { Down } from '@icon-park/react';
import BookingSteps from '@components/BookingSteps/BookingSteps';
import BookingSummaryItem from './components/BookingSummaryItem';
import MedicineSummaryItem from './components/MedicineSummaryItem';
// import { ExclamationCircleFilled } from '@ant-design/icons';
import { Collapse } from 'antd';
import { handleLocalStorage } from '@utils/index';
import website from '@configs/website';
import { ProductType } from '@consts/enums';
import { formatBeDate } from '@utils/index';
import { TimePointEnum } from 'src/services/types/timeslot.type';
import getStripePromise from './getStripePromise';
import { post } from '../../utils/request';
import { useTranslation } from 'react-i18next';
import { LanguageContext, useLocalNavigate } from 'src/router/routerTools';
import { getDataTrackConstantId, track } from '@utils/dataTrack';
import { useSelectResultType } from 'src/hooks/tools/useSelectResultType';

interface ApiPaymentBase {
  bookingId: string;
  displayId: string;
  clientSecret: string;
  intentId: string;
  date: string;
  time: string;
  doctorName: string;
  total: {
    amount: number; // 440
    text: string; // $ 440
  };
}

export interface ApiGetPaymentResponse extends ApiPaymentBase {
  productType: ProductType.Medication;
  expire: number;
  firstName: string;
  lastName: string;
  symptoms: string;
  prescription?: string;
}
export interface ApiPostBookingResponse extends ApiPaymentBase {
  productType: ProductType.OnlineConsultationAndMedication | ProductType.InClinicConsultation;
  location: string;
}
export type PaymentData = ApiPostBookingResponse | ApiGetPaymentResponse;

export default function Payments() {
  useEffect(() => {
    const t = setTimeout(() => {
      track({
        event_type: 'view pay now',
        event_properties: {
          'booking id': getDataTrackConstantId('BookingId'),
        },
      });
    }, 30);
    return () => {
      clearTimeout(t);
    };
  }, []);

  const [t] = useTranslation();
  const [paymentData, setPaymentData] = useState<PaymentData>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isHaveSelectResultType, selectResultType } = useSelectResultType();

  const navigate = useLocalNavigate();

  useEffect(() => {
    const intentId = new URLSearchParams(window.location.search).get('intent_id');

    if (intentId) {
      post('/care/booking/get-payment', {
        intentId,
      })
        .then((rs: ApiGetPaymentResponse) => {
          if (rs.expire == 0) {
            handleLocalStorage.set(
              website.payment_error,
              JSON.stringify({
                type: 'custom_expire_medication',
              }),
            );
            navigate(`/paymentFailure${isHaveSelectResultType ? `?selectResultType=${selectResultType}` : ''}`);
          } else {
            // TODO 刷新的时候，好像会有bug=>需要再看一下
            // TODO: rs.....
            setPaymentData(rs);
            sessionStorage.setItem(website.payment_data, JSON.stringify(rs));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert('params intentId required!');
    }
  }, [navigate, isHaveSelectResultType, selectResultType]);

  useEffect(() => {
    if (paymentData) {
      // 倒计时15分钟
      let time = 1000 * 60 * 15;
      if ('expire' in paymentData) {
        time = paymentData.expire;
      }
      setTimeout(() => {
        setIsModalOpen(true);
      }, time);
    }
  }, [paymentData]);
  const language = useContext(LanguageContext);
  const [collapseActive, setCollapseActive] = useState(false);
  if (!paymentData) {
    return <></>;
  }
  const isConsultation = paymentData.productType != ProductType.Medication;
  const isOnline = paymentData.productType == ProductType.OnlineConsultationAndMedication;
  const { clientSecret } = paymentData;

  // TODO: 后续需要移除
  // TODO: 咨询一下liber，如何解决比较好=>要不然会有问题【刷新页面的时候存在bug】
  // TODO 问一下怎么解决
  // useEffect(() => {
  //   setClientSecret('pi_3Msgq3IQQJ5tauCK1cfFXV5Y_secret_hexOfmsHyoS6lQRdCIXBnKJIo');
  // }, []);
  const options: StripeElementsOptions = {
    clientSecret,
    appearance: {
      theme: 'stripe',
    },
    // zh-HK
    locale: language,
  };

  const handleOk = () => {
    navigate(`/selectTimeSlot${isHaveSelectResultType ? `?selectResultType=${selectResultType}` : ''}`);
  };

  const renderReminders = () => {
    const onlineTextArr = [
      t('PaymentPage.Reminders1'),
      t('PaymentPage.Reminders2'),
      t('PaymentPage.Reminders3'),
      t('PaymentPage.Reminders4'),
      t('PaymentPage.Reminders5'),
    ];

    const offlineTextArr = [
      t('PaymentPage.Reminders1InClinic'),
      t('PaymentPage.Reminders2InClinic'),
      t('PaymentPage.Reminders3InClinic'),
      t('PaymentPage.Reminders4InClinic'),
      t('PaymentPage.Reminders5InClinic'),
    ];
    if (isOnline) {
      return (
        <div className="lg:mt-[43px] lg:p-[19px_8px_15px_17px] bg-[#EFEFEF] lg:rounded-[10px] lg:w-[541px] lg:mb-[43px] mt-[35px] p-[19px_1px_28px_14px] rounded-[10px] mb-[44px]">
          <div className="lg:mb-[17px] font-[700] text-[#212121] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px] text-[16px] leading-[28px] tracking-[0.25px] mb-[17px]">
            {t('PaymentPage.RemindersTitle')}
          </div>
          <div className="lg:pl-[17px] lg:pr-[8px] pl-[14px] pr-[1px]">
            <ol className="">
              {onlineTextArr.map((vv, ii) => (
                <li
                  key={ii}
                  className="font-[400] text-[#212121] lg:text-[14px] lg:leading-[26px] lg:tracking-[0.25px] list-decimal text-[12px] leading-[22px] tracking-[0.25px] font-[Montserrat] mb-[10px] lg:mb-[10px]"
                >
                  {vv}
                </li>
              ))}
            </ol>
          </div>
          {/* <div className="font-[400] text-[#000000] lg:text-[14px] lg:leading-[28px] lg:tracking-[0.25px] text-[12px] leading-[22px] tracking-[0.25px]">
            {t('PaymentPage.RemindersNote')}
          </div> */}
        </div>
      );
    } else {
      return (
        <div className="lg:mt-[43px] lg:p-[19px_8px_15px_17px] bg-[#EFEFEF] lg:rounded-[10px] lg:w-[541px] lg:mb-[43px] mt-[35px] p-[19px_1px_28px_14px] rounded-[10px] mb-[44px]">
          <div className="lg:mb-[17px] font-[700] text-[#212121] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px] text-[16px] leading-[28px] tracking-[0.25px] mb-[17px]">
            {t('PaymentPage.RemindersTitle')}
          </div>
          <div className="lg:pl-[17px] lg:pr-[8px] pl-[14px] pr-[1px]">
            <ol className="">
              {offlineTextArr.map((vv, ii) => (
                <li
                  key={ii}
                  className="font-[400] text-[#212121] lg:text-[14px] lg:leading-[26px] lg:tracking-[0.25px] list-decimal text-[12px] leading-[22px] tracking-[0.25px] font-[Montserrat]"
                >
                  {vv}
                </li>
              ))}
            </ol>
          </div>
          {/* <div className="font-[400] text-[#000000] lg:text-[14px] lg:leading-[28px] lg:tracking-[0.25px] text-[12px] leading-[22px] tracking-[0.25px]">
            {t('PaymentPage.RemindersNote')}
          </div> */}
        </div>
      );
    }
  };

  const renderMobile = isConsultation ? (
    // {/* TODO mobie的需要再做调整 */ }
    // {/* mobile view ---- start */ }
    <div className="w-full lg:hidden lg:mt-[25px] mt-[25px]">
      <Collapse
        onChange={(activeList) => {
          setCollapseActive(activeList.includes('1'));
          track({
            event_type: 'click billing summary',
            event_properties: {
              'booking id': getDataTrackConstantId('BookingId'),
              'booking number': paymentData.intentId,
              'sechedule doctor': paymentData.doctorName,
              'appointment method': isOnline ? 'Online' : 'In-clinic',
              date: paymentData.date,
              time: paymentData.time,
              total: paymentData.total.text,
            },
          });
        }}
        bordered={false}
        className="lg:hidden w-[90.4vw] m-[auto] bg-[#EFEFEF]"
        expandIcon={() => <></>}
      >
        <Collapse.Panel
          header={
            <div className="text-[#212121] font-semibold text-[18px] flex justify-between">
              <span>{t('PaymentPage.BillingSummary')}</span>
              <Down
                size={24}
                className="transition-transform"
                style={{
                  transform: `rotate(${collapseActive ? '180' : 0}deg)`,
                }}
              />
            </div>
          }
          key="1"
        >
          <div className="p-[12px]">
            <BookingSummaryItem
              left={t('PaymentPage.BookedWith')}
              right={paymentData?.doctorName || 'Dr. scott M.C.Tang'}
            />
            <BookingSummaryItem
              left={t('PaymentPage.AppointmentMethod')}
              right={isOnline ? t('OnlineConsultation') : t('In-ClinicVisit')}
            />
            <p className="font-semibold w-full lg:text-[16px] justify-between mt-[40px] lg:mt-[40px]  lg:mb-[40px]">
              {t('PaymentPage.BookingDetails')}
            </p>
            <hr className="border-[#B5B5B5]  lg:mt-[2px]  mt-[2px]" />
            {!isOnline && <BookingSummaryItem left={t('location')} right={paymentData?.location || ''} />}
            <BookingSummaryItem
              left={t('Date')}
              right={formatBeDate(handleLocalStorage.get(website.step_timeslot_date)) || '17 / 05 / 2023'}
            />
            <BookingSummaryItem
              left={t('Time')}
              right={
                TimePointEnum[handleLocalStorage.get(website.step_timeslot_id) as keyof typeof TimePointEnum] ||
                '12:30 pm'
              }
            />
            <hr className="border-[#B5B5B5] sm:mt-[40px]  mt-[20px] mb-[20px]" />
            <div className="flex font-semibold w-full justify-between sm:mt-[20px]">
              <span className="text-[#212121] sm:text-[16px] w-[50%] inline-block">{t('Total')}</span>
              <span className="text-[#212121] sm:text-[16px] w-[50%] inline-block">{paymentData?.total.text}</span>
            </div>
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  ) : (
    // {/* mobile view ---- end */}
    <div className="w-full lg:hidden lg:mt-[25px] mt-[25px]">
      <Collapse
        onChange={(activeList) => {
          setCollapseActive(activeList.includes('1'));
        }}
        bordered={false}
        className="lg:hidden w-[90.4vw] m-[auto] bg-[#EFEFEF]"
        expandIcon={() => <></>}
      >
        <Collapse.Panel
          header={
            <div className="text-[#212121] font-semibold text-[18px] flex justify-between">
              <span>{t('PaymentPage.BillingSummary')}</span>
              <Down
                size={24}
                className="transition-transform"
                style={{
                  transform: `rotate(${collapseActive ? '180' : 0}deg)`,
                }}
              />
            </div>
          }
          key="1"
        >
          <div className="p-[12px]">
            <BookingSummaryItem
              left={t('PaymentPage.PrescribedBy')}
              right={paymentData?.doctorName || 'Dr. Scott M.C.Tang'}
            />
            <p className="font-semibold w-full lg:text-[16px] justify-between mt-[30px] lg:mt-[40px]">
              {t('PatientInformation')}
            </p>
            <hr className="border-[#B5B5B5]" />
            <BookingSummaryItem left={t('FirstName')} right={paymentData?.firstName || 'Jenny K W'} />
            <BookingSummaryItem left={t('LastName')} right={paymentData?.lastName || 'Liu'} />

            <p className="font-semibold w-full lg:text-[16px] justify-between mt-[30px] lg:mt-[40px]">
              {t('Prescriptions')}
            </p>
            <hr className="border-[#B5B5B5]" />
            <BookingSummaryItem
              left={t('PaymentPage.DiagnosisSymptoms')}
              right={paymentData?.symptoms || 'Helicobacter pylori (H. pylori) infection'}
            />
            <BookingSummaryItem
              left={t('Prescriptions')}
              right={paymentData?.prescription || '7days prescription for Helicobacter pylori'}
            />

            {/* <BookingSummaryItem
                  left="Time"
                  right={
                    TimePointEnum[handleLocalStorage.get(website.step_timeslot_id) as keyof typeof TimePointEnum] ||
                    '12:30 pm'
                  }
                /> */}
            <hr className="border-[#B5B5B5] sm:mt-[40px]" />
            <div className="flex font-semibold w-full justify-between sm:mt-[20px]">
              <span className="text-[#212121] sm:text-[16px] w-[50%] inline-block">{t('Total')}</span>
              <span className="text-[#000000] sm:text-[16px] w-[50%] inline-block">
                {paymentData?.total?.text || '$300 HKD'}
              </span>
            </div>
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );

  const renderDesktop = isConsultation ? (
    <div className="booking-summary sm:w-[514px] sm:ml-[40px] lg:block hidden">
      <p className="text-[#212121] sm:text-[22px] font-semibold my-[20px] sm:mb-[45px]">
        {t('PaymentPage.BookingSummary')}
      </p>
      <BookingSummaryItem left={t('PaymentPage.BookedWith')} right={paymentData?.doctorName || ''} />
      <BookingSummaryItem
        left={t('PaymentPage.AppointmentMethod')}
        right={isOnline ? t('OnlineConsultation') : t('In-ClinicVisit')}
      />
      <p className="font-semibold w-full sm:text-[16px] justify-between sm:mt-[40px]">
        {' '}
        {t('PaymentPage.BookingDetails')}
      </p>
      <hr className="border-[#B5B5B5] lg:mb-[40px] lg:mt-[2px] mt-[2px]" />

      {!isOnline && <BookingSummaryItem left={t('location')} right={paymentData?.location || ''} />}
      <BookingSummaryItem
        left={t('Date')}
        right={formatBeDate(handleLocalStorage.get(website.step_timeslot_date)) || '17 / 05 / 2023'}
      />
      <BookingSummaryItem
        left={t('Time')}
        right={
          TimePointEnum[handleLocalStorage.get(website.step_timeslot_id) as keyof typeof TimePointEnum] || '12:30 pm'
        }
      />
      <hr className="border-[#B5B5B5] sm:mt-[40px] mt-[20px] mb-[20px]" />
      <div className="flex font-semibold w-full justify-between sm:mt-[20px]">
        <span className="text-[#212121] sm:text-[16px] w-[50%] inline-block">{t('Total')}</span>
        <span className="text-[#212121] sm:text-[16px] w-[50%] inline-block">{paymentData?.total.text}</span>
      </div>
      {/* <p className="sm:text-[14px] sm:mt-[30px] font-light">
        <ExclamationCircleFilled size={12} /> {t('PaymentPage.feesText')}
      </p> */}
      {renderReminders()}
    </div>
  ) : (
    <div className="booking-summary sm:w-[514px] sm:ml-[40px] lg:block hidden lg:mb-[142px]">
      <p className="text-[#212121] sm:text-[22px] font-semibold my-[20px] lg:mt-[21px] lg:mb-[45px] lg:leading-[36px]">
        {t('PaymentPage.MedicationFee')}
      </p>
      <MedicineSummaryItem
        left={t('PaymentPage.PrescribedBy')}
        right={paymentData?.doctorName || 'Dr. Scott M.C.Tang'}
      />
      <p className="font-semibold w-full sm:text-[18px] justify-between sm:mt-[45px] lg:mb-[23px]">
        {t('PatientInformation')}
      </p>
      <hr className="border-[#B5B5B5]" />

      <MedicineSummaryItem left={t('FirstName')} right={paymentData?.firstName || 'Jenny K W'} />
      <MedicineSummaryItem left={t('LastName')} right={paymentData?.lastName || 'Liu'} />
      <p className="font-semibold w-full sm:text-[18px] justify-between sm:mt-[45px] lg:mb-[23px]">
        {t('Prescriptions')}
      </p>
      <hr className="border-[#B5B5B5]" />
      <MedicineSummaryItem
        left={t('PaymentPage.DiagnosisSymptoms')}
        right={paymentData?.symptoms || 'Helicobacter pylori (H. pylori) infection'}
      />
      <MedicineSummaryItem
        left={t('Prescriptions')}
        right={paymentData?.prescription || '7days prescription for Helicobacter pylori'}
      />
      <hr className="border-[#B5B5B5] mt-[30px] sm:mt-[44px]" />
      <div className="flex font-semibold w-full justify-between mt-[38px] lg:mt-[18px]">
        <span className="text-[#212121] sm:text-[16px] w-[50%] inline-block  lg:leading-[24px]">{t('Total')}</span>
        <span className="text-[#000000] sm:text-[18px] lg:leading-[28px] w-[50%] inline-block text-[500] lg:tracking-[0.25px]">
          {paymentData?.total?.text}
        </span>
      </div>
    </div>
  );

  return (
    <div className="sm:mt-[50px] min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)]">
      {isConsultation ? (
        <>
          {/* <p className="lg:flex ml-[6.8%] items-center hidden">
            <Left theme="filled" size="24" fill="#2DBDB6" color="" />
            <span className="text-[#2DBDB6] sm:text-[16px] font-medium sm:ml-[6px]">{t('BackToPreviousPage')}</span>
          </p> */}
          <BookingSteps step={5}></BookingSteps>
        </>
      ) : (
        <div className="  font-[600] text-[#2DBDB6] lg:text-[40px] lg:leading-[58px] lg:mb-[68px] lg:mt-[68px] lg:text-center hidden lg:block">
          {t('PaymentPage.MedicationPayment')}
        </div>
      )}

      {renderMobile}

      <div className="flex justify-center">
        <div className="payments w-[100%] lg:w-[30vw] lg:min-[500px]">
          {clientSecret && (
            <Elements options={options} stripe={getStripePromise()}>
              <CheckoutForm paymentData={paymentData} renderReminders={renderReminders} />
            </Elements>
          )}
        </div>
        {renderDesktop}
      </div>
      {/* TODO 取消叉叉按钮 */}
      <Modal
        title={t('PaymentPage.Warning')}
        open={isModalOpen}
        onOk={handleOk}
        // onCancel={handleCancel}
        okText="yes"
        cancelText="no"
        footer={[
          <div className="flex justify-end">
            <BaseButton
              htmlType="submit"
              buttonClassName="w-full mt-[42px] mb-[20px] w-[70px] h-[30px] text-[16px] md:w-[150px] md:h-[30px] md:text-[16px]"
              value={t('PaymentPage.bookAgain')}
              status={'default'}
              onClick={handleOk}
            ></BaseButton>
          </div>,
        ]}
      >
        <p>{t('PaymentPage.BecauseSecurity')}</p>
      </Modal>
    </div>
  );
}
