import { AudioFilled, VideoCameraFilled, Loading3QuartersOutlined } from '@ant-design/icons';
import { MEDIA_SIZE } from '@consts/consts';
import CameraOffIcon from '@pages/meeting/components/CameraOffIcon';
import VoiceOffIcon from '@pages/meeting/components/VoiceOffIcon';
import { useMemoizedFn } from 'ahooks';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import CameraAndMicrophoneModal from './CameraAndMicrophoneModal';
import { useTranslation } from 'react-i18next';

type DeviceButtonProps = {
  type: 'microphone' | 'camera';
  enable: boolean;
  onClick: () => Promise<void>;
  disable: boolean;
  id?: string;
};

export default function DeviceButton(props: DeviceButtonProps) {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const mediaSize = useMediaQuery();
  const handleClick = useMemoizedFn(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await props.onClick();
    } catch (e: any) {
      if (e.code === 'PERMISSION_DENIED') {
        CameraAndMicrophoneModal(mediaSize === MEDIA_SIZE.LG_SIZE);
      }
    } finally {
      setLoading(false);
    }
  });

  const mediaIcon = useMemo(() => {
    if (props.type === 'microphone') {
      return props.enable ? (
        <AudioFilled
          style={{
            color: '#b2b2b2',
            fontSize: '20px',
          }}
        />
      ) : (
        <VoiceOffIcon />
      );
    } else {
      return props.enable ? (
        <VideoCameraFilled
          size={20}
          style={{
            color: '#b2b2b2',
            fontSize: '20px',
          }}
        />
      ) : (
        <CameraOffIcon />
      );
    }
  }, [props.enable, props.type]);

  const text = useMemo(() => {
    if (props.type === 'microphone') {
      return props.enable ? (
        <p className="lg:text-[12px] lg:leading-[7px] text-[#b2b2b2] lg:block hidden mt-[2px]">{t('mute')}</p>
      ) : (
        <p className="lg:text-[12px] lg:leading-[7px] text-[#ff414d] lg:block hidden mt-[2px]">{t('unmute')}</p>
      );
    } else {
      return props.enable ? (
        <p className="lg:text-[12px] lg:leading-[7px] text-[#b2b2b2] lg:block hidden mt-[2px]">{t('StopVideo')}</p>
      ) : (
        <p className="lg:text-[12px] lg:leading-[7px] text-[#ff414d] lg:block hidden mt-[2px]">{t('StartVideo')}</p>
      );
    }
  }, [props.enable, props.type, t]);
  return (
    <div className="device-button-box lg:w-[80px] w-[30px] select-none">
      <div
        id={props.id}
        onClick={handleClick}
        className={classNames(props.disable ? 'cursor-not-allowed' : 'cursor-pointer', 'text-center')}
      >
        {loading ? (
          <Loading3QuartersOutlined
            spin
            style={{
              color: '#b2b2b2',
              fontSize: '20px',
            }}
          />
        ) : (
          mediaIcon
        )}
        {text}
      </div>
    </div>
  );
}
