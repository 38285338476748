import './variable.scss';
import './theme.scss';
import './chunk.scss';
import './custom.scss';
import { useRef } from 'react';
import HeaderH5 from './HeaderH5';
import HeaderPC from './HeaderPC';
import useHeaderCtx, { HeaderContext } from './useHeaderCtx';
export default () => {
  const headerCtx = useHeaderCtx();

  const headerRef = useRef<HTMLDivElement>(null);
  return (
    <HeaderContext.Provider value={headerCtx}>
      <div className="sf-header transition-opacity opacity-100 z-20" ref={headerRef}>
        <div className="w-[100%] h-[58px] lg:h-[63px]"></div>
        <div className="inset-x-0 z-[70] header__wrapper w-full fixed top-0 left-0">
          <HeaderH5 />
          <HeaderPC headerRef={headerRef} />
        </div>
        <div className="sf-header__overlay hidden md:block pointer-events-none fixed bg-black -inset-0" />
      </div>
    </HeaderContext.Provider>
  );
};
