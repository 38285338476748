import { atom } from 'recoil';

export const microphoneListState = atom<MediaDeviceInfo[]>({
  key: 'microphoneListState',
  default: [],
});
export const cameraListState = atom<MediaDeviceInfo[]>({
  key: 'cameraListState',
  default: [],
});
export const speakerListState = atom<MediaDeviceInfo[]>({
  key: 'speakerListState',
  default: [],
});
