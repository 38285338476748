/* eslint-disable @typescript-eslint/indent */
import React, { useState, useEffect } from 'react';
import type { RadioChangeEvent } from 'antd';
import { /*Divider,*/ Form, Radio } from 'antd';
import 'react-phone-number-input/style.css';
import { useSearchParams } from 'react-router-dom';
import { useMemoizedFn } from 'ahooks';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { isValidPhoneNumber } from 'libphonenumber-js';

import TextInput, { TextInputStatus } from '@components/TextInput/TextInput';
import BaseButton, { ButtonStatus } from '@components/BaseButton/BaseButton';
import PhoneNumberInput, { PhoneNumberInputStatus } from '@components/PhoneNumberInput/PhoneNumberInput';
import { isEmail } from '@utils/index';
import { usePhoneNumberVerify } from 'src/hooks/pageHooks/accountVerify';
import { handleLocalStorage } from '@utils/index';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { MEDIA_SIZE } from '@consts/consts';
import { post } from '@utils/request';
import BaseCheckbox from '@components/BaseCheckbox/BaseCheckbox';
import { useTranslation } from 'react-i18next';
import { useLocalNavigate } from 'src/router/routerTools';
import { getDataTrackConstantId, track } from '@utils/dataTrack';
import { useIsHkLanguage } from 'src/hooks/tools/useIsHkLanguage';
import classNames from 'classnames';
import alertTerms from '@utils/globalComponents/TermsAndPrivacyAlert/TermsAlert';
import alertPrivacy from '@utils/globalComponents/TermsAndPrivacyAlert/ProvacyAlert';
// import { FacebookLoginButton, GoogleLoginButton, LinkedinLoginButton } from './components/ThirdPardyLoginButton';

interface IFormValues {
  username: string;
  password: string;
  remember: boolean;
}

const style = {
  container: {
    background: '#FDFDFD',
  },
};

const accountTypeText = {
  phoneNumber: 'loginPage.ContactNumber',
  email: 'loginPage.Email',
};

type AccountTypeText = typeof accountTypeText;

const Login: React.FC = () => {
  useEffect(() => {
    const t = setTimeout(() => {
      track({
        event_type: 'view request otp',
        event_properties: {
          'sign id': getDataTrackConstantId('signId'),
        },
      });
    }, 30);
    return () => {
      clearTimeout(t);
    };
  }, []);

  const isHkLanguage = useIsHkLanguage();
  const [t] = useTranslation();
  const redirect = useSearchParams()[0].get('redirect');

  const { phoneNumber, setPhoneNumber, isPhoneNumberValid, handleIsPhoneNumberValidChange } = usePhoneNumberVerify();

  // method的值为’p‘ 或者’eu‘
  const [method, setMethod] = useState<keyof AccountTypeText>('email');
  const [buttonStatus, setButtonStatus] = useState<ButtonStatus>('disabled');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [boxValue, setBoxValue] = useState<boolean>(false);

  // Email表单的样式
  const [emailInputObj, setEmailInputObj] = useState<{
    helpText: string;
    status: TextInputStatus;
    emailValue: string | null;
  }>({
    helpText: '',
    status: undefined,
    emailValue: '',
  });

  const [phoneInputObj, setPhoneInputObj] = useState<{
    helpText: string;
    status: PhoneNumberInputStatus;
  }>({
    helpText: '',
    status: undefined,
  });

  const mediaSize = useMediaQuery();
  const isMd = mediaSize === MEDIA_SIZE.MD_SIZE;
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;

  const { helpText, status, emailValue } = emailInputObj;

  const navigate = useLocalNavigate();

  const checkEmailValid = useMemoizedFn((e) => {
    const inputValue = e.target.value;
    if (isEmail(inputValue)) {
      return {
        status: 'success',
        helpText: '',
      };
    } else {
      if (e.type == 'blur') {
        return {
          status: 'error',
          helpText: t('FillInfosPage.invaldEmail') as string,
        };
      } else {
        return {
          status: '',
          helpText: '',
        };
      }
    }
  });

  // 当Email表单的内容改变时
  const emailInputChange: React.ChangeEventHandler<HTMLInputElement> = useMemoizedFn((e) => {
    const isValidObj = checkEmailValid(e);
    const inputValue = e.target.value;
    setEmailInputObj({
      ...emailInputObj,
      ...(isValidObj as any),
      emailValue: inputValue,
    });
  });
  // 当method改变时
  const onChange = useMemoizedFn((e: RadioChangeEvent) => {
    setMethod(e.target.value);
    setButtonStatus('disabled');
  });

  // 登录
  const onFinish = useMemoizedFn(async (values: IFormValues) => {
    // 校验勾选项
    if (!boxValue) {
      return;
    }
    // 校验邮箱以及手机号
    if (method === 'email') {
      if (!isEmail(emailValue)) {
        setEmailInputObj({
          ...emailInputObj,
          helpText: t('FillInfosPage.invaldEmail') as string,
          status: 'error',
        });
        return;
      }
    } else {
      if (!isPhoneNumberValid) {
        return;
      }
    }

    try {
      setButtonLoading(true);
      const params =
        method === 'email'
          ? {
              email: emailValue,
            }
          : {
              phone: phoneNumber,
            };
      await post('/user/send-otp', params);

      const curValue = method === 'email' ? emailValue : phoneNumber;
      handleLocalStorage.set(method, curValue);
      handleLocalStorage.set('method', method);
      handleLocalStorage.set('fromPage', 'login');

      setButtonLoading(false);
      setTimeout(() => {
        setButtonStatus('success');
      }, 500);
      // setButtonStatus('success');
      setTimeout(() => {
        // 携带url参数过去，从而确定是手机还是验证码
        if (redirect) {
          navigate('/verify?redirect=' + redirect);
        } else {
          navigate('/verify');
        }
      }, 1000);
      track({
        event_type: 'request otp',
        user_properties: {
          [method === 'email' ? 'email' : 'phone']: method === 'email' ? emailValue : phoneNumber,
        },
        event_properties: {
          'sign id': getDataTrackConstantId('signId'),
          method: method === 'email' ? 'email' : 'phone',
          'continue status': true,
          source: window.document.referrer || 'direct',
        },
      });
      console.log(values);
      // setButtonLoading(false);
    } catch (err: any) {
      setButtonLoading(false);
      const responseMessage = err?.response?.data?.message;
      const errorMessage = (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || 'error!';
      setEmailInputObj({
        ...emailInputObj,
        status: 'error',
        helpText: errorMessage,
      });
      setPhoneInputObj({
        status: 'error',
        helpText: errorMessage,
      });
    }
  });

  const onBoxChange = useMemoizedFn((e: CheckboxChangeEvent) => {
    setBoxValue(e.target.checked);
  });

  // 根据校验规则，显示按钮的样式
  useEffect(() => {
    const passwordButtonCanClick = method === 'phoneNumber' && isPhoneNumberValid && boxValue;
    const emailButtonCanClick = method === 'email' && emailValue && boxValue && status == 'success';
    // 代表着可点击的状态
    if (passwordButtonCanClick || emailButtonCanClick) {
      setButtonStatus('default');
    } else {
      setButtonStatus('disabled');
    }
  }, [method, isPhoneNumberValid, emailValue, boxValue, status]);

  useEffect(() => {
    if (phoneNumber && phoneNumber.length > 12) {
      const afterSlicePhoneNumber = phoneNumber.slice(0, 12);
      setPhoneNumber(afterSlicePhoneNumber);
      setButtonStatus('default');
      const passwordButtonCanClick = isValidPhoneNumber(afterSlicePhoneNumber) && boxValue;
      if (passwordButtonCanClick) {
        setButtonStatus('default');
      } else {
        setButtonStatus('disabled');
      }
    } else if (phoneNumber.length < 12 && method === 'phoneNumber') {
      setButtonStatus('disabled');
    }
  }, [phoneNumber, setPhoneNumber, boxValue, method]);

  return (
    <div
      className="w-full h-full flex justify-center  bg-opacity-[0.8] flex-col py-[0] px-[24px] md:px-[32px] md:items-center lg:px-[0px] lg:items-center min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)]"
      style={style.container}
    >
      <div>
        <div>
          <div
            className={
              'font-[600] text-[26px] leading-[36px] m-auto mt-[37px] mb-[15px] default-color md:text-[42px] md:leading-[58px] md:ml-[0px] md:mt-[80px] md:mb-[18px] whitespace-nowrap'
            }
          >
            {t('loginPage.WelcomeToINDICAIDCare')}
          </div>
          {/* isHkLanguage */}
          <div
            className={classNames(
              isHkLanguage
                ? 'text-[16px] leading-[28px] md:text-[16px] md:leading-[28px]'
                : 'text-[14px] leading-[24px] md:text-[16px] md:leading-[28px]',
              'font-[400]  text-[#212121] mb-[43px]  md:mb-[34px] lg:font-[400]',
            )}
          >
            {t('loginPage.PleaseFillText')}
          </div>
          <div className="font-[600] text-[16px] leading-[21px] text-[#1D1D1D] mb-[8px] md:text-[16px] md:mb-[26px] md:leading-[21px]">
            {t('loginPage.signUpOrLoginText')}
          </div>
          {/* <div className="flex lg:mb-[20px] lg:flex-row flex-col">
            <FacebookLoginButton />
            <div className="lg:w-[8px] w-0 h-[15px] lg:h-0"></div>
            <GoogleLoginButton isAggree={boxValue} />
            <div className="lg:w-[8px] w-0 h-[15px] lg:h-0"></div>
            <LinkedinLoginButton />
          </div> */}
          {/* <Divider plain>
            <span className="text-[#706F6F] lg:text-[12px] font-semibold">Or</span>
          </Divider> */}

          <Radio.Group onChange={onChange} value={method}>
            <div
              onClick={() => setMethod('email')}
              className={classNames(
                method === 'email' && 'border-[#2DBDB6]',
                'inline-flex items-center',
                'hover:bg-[rgba(184,226,223,0.20)] hover:border-[#b8e2df] cursor-pointer',
                'lg:w-[274px] lg:h-[50px]',
                'lg:border-[1px] border-[#d5d4dc] lg:rounded-[6px]',
                'lg:pl-[16px] lg:mr-[10px]',
              )}
            >
              <Radio value={'email'} className="email-select-radio" id="login__email-radio">
                <div className="font-[400] text-[16px] leading-[20px] text-[#1D1D1D] ml-[2px] md:text-[16px] md:leading-[20px] lg:ml-[5px]">
                  {t('loginPage.Email')}
                </div>
              </Radio>
            </div>
            <div
              onClick={() => setMethod('phoneNumber')}
              className={classNames(
                method === 'phoneNumber' && 'border-[#2DBDB6]',
                'inline-flex items-center',
                'hover:bg-[rgba(184,226,223,0.20)] hover:border-[#b8e2df] cursor-pointer',
                'lg:w-[274px] lg:h-[50px]',
                'lg:border-[1px] border-[#d5d4dc] lg:rounded-[6px]',
                'lg:pl-[16px]',
              )}
            >
              <Radio
                value={'phoneNumber'}
                style={{
                  marginRight: isLg ? '51px' : '1.1707rem',
                }}
                id="login__phone-radio"
              >
                <div className="font-[400] text-[16px] leading-[20px] text-[#1D1D1D] ml-[2px]  md:text-[16px] md:leading-[20px] lg:ml-[5px]">
                  {t('loginPage.Phone')}
                </div>
              </Radio>
            </div>
          </Radio.Group>
        </div>
        {/* Login Form */}

        <Form
          name="normal_login"
          className="w-[100%]"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          style={{
            marginBottom: '0px',
          }}
        >
          <div className="font-[600] text-[16px] text-[#1D1D1D] mt-[35px] mb-[8px] tracking-[0.4px] md:mt-[34px] md:mb-[8px] md:text-[16px] md:leading-[20px] md:tracking-[0.4px] flex">
            {t(accountTypeText[method])}
            {(method === 'phoneNumber' || method === 'email') && (
              <div className="font-[600] text-[16px] text-[#FF0000] tracking-[0.4px] md:text-[16px]  md:leading-[20px] md:tracking-[0.4px]">
                *
              </div>
            )}
          </div>
          {method === 'email' ? (
            <Form.Item name="username">
              <TextInput
                placeholder={t('FillInfosPage.EnterValidEmailAddress') as string}
                helpText={helpText}
                status={status}
                onChange={emailInputChange}
                onBlur={emailInputChange}
                inputClassName={'h-[1.15rem] md:text-[16px] md:w-[558px] md:h-[48px] lg:h-[43px]'}
                id="login__email-input"
                errorId="login__email-error"
              ></TextInput>
            </Form.Item>
          ) : (
            <PhoneNumberInput
              style={{ width: isMd || isLg ? '558px' : '100%' }}
              value={phoneNumber}
              onChange={setPhoneNumber}
              onIsValidChange={handleIsPhoneNumberValidChange}
              helpText={phoneInputObj?.helpText}
              status={phoneInputObj?.status}
              id="login__phone-input"
              errorId="login__phone-error"
            />
          )}

          <div className="flex mt-[41px] md:mt-[74px] md:items-center">
            {/* <Checkbox onChange={onBoxChange}></Checkbox> */}
            <BaseCheckbox onChange={onBoxChange} id="login__agree-privacy"></BaseCheckbox>
            {/* TODO 字体不一样的问题=>需要再看一下 */}
            <div className="font-[400] text-[16px] leading-[24px] text-[#2D3748] ml-[14px] md:text-[16px] md:leading-[24px]  md:ml-[11px]">
              {t('loginPage.agreementPre')}
              <a
                onClick={() => alertTerms('login__close-TAO')}
                id="login__click-TAO"
                className="text-[#2DBDB6] font-[400] text-[16px] leading-[24px] md:text-[16px] md:leading-[24px]"
              >
                &nbsp;{t('loginPage.termsText')}
              </a>
              &nbsp;{t('loginPage.and')}&nbsp;
              {/* href="/privacy" */}
              <a
                id="login__click-policy"
                onClick={() => alertPrivacy('login__close-policy')}
                className="text-[#2DBDB6] font-[400] text-[16px] leading-[24px] md:text-[16px] md:leading-[24px]"
              >
                {t('loginPage.policyText')}
              </a>
            </div>
          </div>

          <BaseButton
            htmlType="submit"
            buttonClassName="w-full h-[50px] mt-[34px] mb-[63px] md:w-[326px] md:h-[50px] md:text-[16px] md:mt-[56px] md:mb-[116px]"
            value={t('loginPage.RequestOTP')}
            disable={buttonStatus === 'disabled'}
            status={buttonStatus}
            loading={buttonLoading}
            id="login__request-otp"
            // buttonClassName={classNames(
            //   'mt-[10px] text-[16px] w-[328px] h-[50px]',
            //   'sm:mt-[10px] sm:text-[16px] sm:w-[280px] sm:h-[40px]',
            // )}
          ></BaseButton>
        </Form>
      </div>
    </div>
  );
};

export default Login;
