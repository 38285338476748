import ReactDOM from 'react-dom/client';
type RenderComponentConfig<P> = P & { needAppend?: boolean };
export type RenderComponentOptions<P> = RenderComponentConfig<P> | ((close: () => void) => RenderComponentConfig<P>);
export function renderComponent<P>(
  options: RenderComponentOptions<P>,
  Component: React.FC<P & { closeFunction: () => void }>,
) {
  const container = document.createElement('div');
  document.body.appendChild(container);
  const root = ReactDOM.createRoot(container);
  const destory = () => {
    root.unmount();
    try {
      document.body.removeChild(container);
    } catch {}
  };

  const render = (config: P) => {
    const closeFunction = () => {
      destory();
      window.removeEventListener('popstate', closeFunction);
    };
    window.addEventListener('popstate', closeFunction);

    setTimeout(() => {
      root.render(<Component {...config} closeFunction={closeFunction} />);
    });
  };
  if (typeof options === 'function') {
    render({ ...options(destory) });
  } else {
    render({ ...options });
  }
  return destory;
}
