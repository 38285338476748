import { AudioOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { MEDIA_SIZE } from '@consts/consts';
import { More } from '@icon-park/react';
import {
  shouldOpenCameraOrMicrophone,
  updateShouldOpenCameraStatus,
  updateShouldOpenMicrophoneStatus,
} from '@states/meeting.state';
import { useLocalAudioStatus, useLocalVidioStatus, useMediaDevices } from '@utils/RtcAdapter/hooks/mediaDevices';
import { requestMediaPermission } from '@utils/RtcAdapter/mediaPermission';
import RtcAdapter from '@utils/RtcAdapter/RtcTool';
import classNames from 'classnames';
import { useContext, useEffect, useRef } from 'react';
import { getRecoil } from 'recoil-nexus';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import usePageVisiblity from 'src/hooks/tools/usePageVisiblity';
import CameraAndMicrophoneModal from './CameraAndMicrophoneModal';
import DeviceButton from './DeviceButton';
import DeviceSelector from './DeviceSelector';
import { t } from 'i18next';
import { LanguageContext } from 'src/router/routerTools';

export const DeviceSwitcher = () => {
  const { microphoneList, cameraList } = useMediaDevices();

  return (
    <div className="lg:mt-[20px] justify-center flex">
      <div className="flex items-center">
        <VideoCameraOutlined className="lg:text-[24px] text-[20px] mb-[1px]" />
        <DeviceSelector
          className="lg:w-[180px] w-[120px] text-[12px]"
          defaultValue={cameraList[0]?.deviceId}
          deviceList={cameraList}
          onChange={(v) => RtcAdapter.switchCamera(v)}
        />
      </div>
      <div className="lg:w-0 w-[30px]"></div>
      <div className="lg:ml-[20px] flex items-center">
        <AudioOutlined className="lg:text-[24px] text-[20px] mb-[2px]" />
        <DeviceSelector
          className="lg:w-[180px] w-[120px] text-[12px]"
          defaultValue={microphoneList[0]?.deviceId}
          deviceList={microphoneList}
          onChange={(v) => RtcAdapter.switchMicrophone(v)}
        />
      </div>
    </div>
  );
};

export default function RTCPreviewCard(props: { onClickMore: () => void }) {
  const localAudioStatus = useLocalAudioStatus();
  const localVideoStatus = useLocalVidioStatus();
  const { microphoneList, cameraList } = useMediaDevices();
  const language = useContext(LanguageContext);
  const RtcPreviewCardRef = useRef<HTMLDivElement>(null);
  const mediaSize = useMediaQuery();
  useEffect(() => {
    return RtcAdapter.on('local-video-status-changed', (enable) => {
      if (enable && RtcPreviewCardRef.current) {
        RtcAdapter.playLocalVideo(RtcPreviewCardRef.current);
      }
    });
  }, []);
  usePageVisiblity((pageVisible) => {
    console.log(pageVisible, 'pageVisible');
    if (pageVisible) {
      if (getRecoil(shouldOpenCameraOrMicrophone).camera) {
        setTimeout(() => {
          RtcAdapter.toogleVideo(true).catch((e) => {
            if (e.code === 'PERMISSION_DENIED') {
              CameraAndMicrophoneModal(mediaSize === MEDIA_SIZE.LG_SIZE);
            }
          });
        }, 100);
      }
      if (getRecoil(shouldOpenCameraOrMicrophone).microphone) {
        setTimeout(() => {
          RtcAdapter.toogleAudio(true).catch((e) => {
            if (e.code === 'PERMISSION_DENIED') {
              CameraAndMicrophoneModal(mediaSize === MEDIA_SIZE.LG_SIZE);
            }
          });
        }, 100);
      }
    } else {
      RtcAdapter.toogleVideo(false);
      RtcAdapter.toogleAudio(false);
    }
  });
  useEffect(() => {
    const openVideAndAudio = async () => {
      const hasPermission = await requestMediaPermission();
      if (!hasPermission) {
        CameraAndMicrophoneModal(mediaSize === MEDIA_SIZE.LG_SIZE);
        return;
      }
      if (getRecoil(shouldOpenCameraOrMicrophone).camera) {
        setTimeout(() => {
          RtcAdapter.toogleVideo(true).catch((e) => {
            if (e.code === 'PERMISSION_DENIED') {
              CameraAndMicrophoneModal(mediaSize === MEDIA_SIZE.LG_SIZE);
            }
          });
        }, 100);
      }
      if (getRecoil(shouldOpenCameraOrMicrophone).microphone) {
        setTimeout(() => {
          RtcAdapter.toogleAudio(true).catch((e) => {
            if (e.code === 'PERMISSION_DENIED') {
              CameraAndMicrophoneModal(mediaSize === MEDIA_SIZE.LG_SIZE);
            }
          });
        }, 100);
      }
    };
    openVideAndAudio();
  }, [mediaSize]);
  return (
    <div className="relative">
      <div
        className={classNames(
          'rtc-preview-card bg-[#202124] relative left-[50%] translate-x-[-50%]',
          'lg:w-[770px] lg:h-[432px] lg:rounded-t-[10px] lg:rounded-b-[0px] lg:mt-0',
          'md:w-[256px] md:h-[388px]',
          'overflow-hidden w-[178px] h-[269px] rounded-[12px] mt-[25px]',
        )}
      >
        <div ref={RtcPreviewCardRef} className="w-full h-full"></div>
        {!localVideoStatus && (
          <div className="w-full h-full absolute top-0 left-0 flex items-center justify-center">
            <p className={classNames(language === 'en' ? 'text-[24px]' : 'text-[18px]', 'text-[#ffffff] font-medium')}>
              {t('CameraIsOff')}
            </p>
          </div>
        )}
      </div>
      <div
        className={classNames(
          'items-center flex justify-around',
          'lg:justify-center lg:h-[85px] lg:w-[770px] lg:rounded-b-[10px] lg:relative lg:bg-[#1D1D1D] lg:px-0',
          'absolute w-[178px] md:w-full bottom-[18px] md:px-[40px] px-[30px]',
        )}
      >
        <DeviceButton
          id="meeting__preview-card-camera-button"
          type="camera"
          disable={cameraList.length === 0}
          enable={localVideoStatus}
          onClick={async () => {
            const enable = !localVideoStatus;
            updateShouldOpenCameraStatus(enable);
            await RtcAdapter.toogleVideo(enable);
          }}
        />
        <div className="hidden lg:w-[60px]"></div>
        <DeviceButton
          id="meeting__preview-card-microphone-button"
          disable={microphoneList.length === 0}
          type="microphone"
          enable={localAudioStatus}
          onClick={async () => {
            const enable = !localAudioStatus;
            updateShouldOpenMicrophoneStatus(enable);
            await RtcAdapter.toogleAudio(enable);
          }}
        />
        <More
          id="meeting__preview-card-more-button"
          onClick={props.onClickMore}
          className="lg:hidden cursor-pointer"
          theme="outline"
          size="24"
          fill="#C4C4C4"
        />
      </div>
      <div className="hidden lg:block">
        <DeviceSwitcher />
      </div>
    </div>
  );
}
