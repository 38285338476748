/* eslint-disable @typescript-eslint/indent */
import React, { useState, useEffect, useContext } from 'react';

import BaseButton from '@components/BaseButton/BaseButton';
import { useMemoizedFn } from 'ahooks';
import classNames from 'classnames';
import DownOutlined from '@ant-design/icons/DownOutlined';
import UpOutlined from '@ant-design/icons/UpOutlined';
import { BookingStatus, ConsultationMethod } from '@consts/enums';
import { MEDIA_SIZE } from '@consts/consts';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { formatBeDate } from '@utils/index';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from 'src/router/routerTools';
import { track } from '@utils/dataTrack';
import { AppointmentType } from '@services/types/appointmentsInfos.type';

type UpcomingAppointmentProps = {
  value?: string | ReadonlyArray<string> | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  width?: number | string;
  placeholder?: string;
  disable?: boolean;
  helpText?: string;
  upcoming?: AppointmentType;
  isRemove?: boolean;
  completedLength?: number;
  isLastOne?: boolean;
  index?: number;
};

export default function UpcomingAppointment(props: UpcomingAppointmentProps = {}) {
  const [t] = useTranslation();
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const [arrowStatus, setArrowStatus] = useState(false);
  const language = useContext(LanguageContext);
  // TODO 这里的逻辑还没做
  const onClick = useMemoizedFn(async () => {
    let l = language === 'en' ? '/en' : '';

    if (props?.upcoming?.status === BookingStatus.Prescription) {
      track({
        event_type: 'order medication',
        event_properties: {
          'sechedule doctor': props.upcoming?.doctorName,
          'consultation method':
            props.upcoming?.consultType === ConsultationMethod.Online ? 'online consultation' : 'inclinic vi sit',
          date: formatBeDate(props.upcoming?.date),
          time: props.upcoming?.time || 'unknown',
        },
      });
      // 跳转至order页面
      window.open(`${location.origin}${l}${props.upcoming.medicationPaymentLink}`, '_blank');
    } else {
      console.log(`${location.origin}${l}${props.upcoming?.meetingLink}`);
      track({
        event_type: 'join consultation',
        event_properties: {
          'sechedule doctor': props.upcoming?.doctorName,
          'consultation method':
            props.upcoming?.consultType === ConsultationMethod.Online ? 'online consultation' : 'inclinic vi sit',
          date: formatBeDate(props.upcoming?.date),
          time: props.upcoming?.time || 'unknown',
        },
      });
      // 加入会议
      window.open(`${location.origin}${l}${props.upcoming?.meetingLink}`, '_blank');
    }
  });

  // TODO 需要移除
  useEffect(() => {
    setArrowStatus(false);
  }, []);

  // 做到这里，需要再看一下
  return (
    <div
      className={classNames(!props?.isRemove && 'mt-[28px] lg:mt-[0px]', 'w-full lg:w-[auto] mb-[25px] lg:mb-[50px]')}
    >
      {/* Appointments的表格 */}
      <div
        className={classNames(
          props?.isLastOne && props?.completedLength === 0 ? 'lg:mb-[144px]' : 'lg:mb-[0px]',
          'flex rounded-[10px] shadow-[0_10px_30px_rgba(171,190,209,0.2)] flex-col bg-[#FFFFFF] pt-[25px] px-[22px] lg:rounded-[10px] lg:shadow-[0_10px_30px_rgba(171,190,209,0.2)] lg:pt-[27px] lg:px-[51px] lg:w-[844px]',
        )}
        style={
          !isLg
            ? {
                paddingBottom: arrowStatus ? '1.0488rem' : '0.6098rem',
              }
            : {
                paddingBottom: arrowStatus ? '46px' : '24px',
              }
        }
      >
        {/* {!isLg && !props?.isRemove && (
          <div className="font-[600] text-[22px] leading-[36px] text-[#212121] mb-[40px]">{t('Appointments')}</div>
        )} */}

        {/* {props?.upcoming?.status === BookingStatus.Prescription
              ? t('MyAccountPage.AppointmentPart.On-goingAppointments')
              : t('MyAccountPage.AppointmentPart.UpcomingAppointments')} */}
        {/* {!isLg && !props?.isRemove && (
          <div className="font-[600] text-[16px] leading-[24px] text-[#2DBDB6] mb-[31px]">
            {t('MyAccountPage.AppointmentPart.CurrentAppointment')}
          </div>
        )} */}
        <div className="flex mb-[21px] lg:mb-[20px]">
          <div className="w-[168px] lg:w-[360px]">
            <div className="w-[142px] font-[600] text-[14px] leading-[22px] text-[#212121] lg:w-[275px] lg:text-[16px] lg:leading-[24px]">
              {t('MyAccountPage.AppointmentPart.ScheduledDoctor')}
            </div>
          </div>
          <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] w-[142px]  lg:w-[275px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px]">
            {props?.upcoming?.doctorName}
          </div>
        </div>
        <div className="flex mb-[21px] lg:mb-[20px]">
          <div className="w-[168px] lg:w-[360px]">
            <div className="w-[142px] font-[600] text-[14px] leading-[22px] text-[#212121] lg:w-[275px] lg:text-[16px] lg:leading-[24px]">
              {t('MyAccountPage.AppointmentPart.ConsultationMethod')}
            </div>
          </div>
          <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px]">
            {props?.upcoming?.consultType === ConsultationMethod.Online ? t('OnlineConsultation') : t('In-ClinicVisit')}
          </div>
        </div>
        {props?.upcoming?.consultType === ConsultationMethod.Offline && (
          <div className="flex mb-[21px] lg:mb-[20px]">
            <div className="w-[168px] lg:w-[360px]">
              <div className="w-[142px] font-[600] text-[14px] leading-[22px] text-[#212121] lg:w-[275px] lg:text-[16px] lg:leading-[24px]">
                {t('location')}
              </div>
              <div className="w-[168px] lg:w-[360px]"></div>
            </div>
            <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px]   lg:text-[16px] lg:leading-[28px]  lg:tracking-[0.25px]">
              {props?.upcoming?.location}
            </div>
          </div>
        )}
        <div className="flex mb-[21px] lg:mb-[20px]">
          <div className="w-[168px] lg:w-[360px]">
            <div className="w-[142px] font-[600] text-[14px] leading-[22px] text-[#212121] lg:w-[275px] lg:text-[16px] lg:leading-[24px]">
              {t('Date')}
            </div>
          </div>
          <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px]">
            {formatBeDate(props?.upcoming?.date)}
          </div>
        </div>
        <div
          className={classNames(
            props?.upcoming?.consultType === ConsultationMethod.Offline ? 'lg:mb-[0px]' : 'lg:mb-[54px]',
            'flex mb-[46px]',
          )}
        >
          <div className="w-[168px] lg:w-[360px]">
            <div className="w-[142px] font-[600] text-[14px] leading-[22px] text-[#212121] lg:w-[275px] lg:text-[16px] lg:leading-[24px]">
              {t('Time')}
            </div>
          </div>
          <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px]">
            {props?.upcoming?.time}
          </div>
        </div>
        {/* 看一下桌面端的内容 */}
        {!arrowStatus && props?.upcoming?.consultType === ConsultationMethod.Online && (
          <BaseButton
            htmlType="submit"
            buttonClassName="w-full h-[50px] mb-[38px] text-[16px] leading-[24px] font-[500] md:w-[326px] md:h-[50px] md:text-[16px] md:leading-[24px] md:mb-[48px]"
            value={
              props?.upcoming?.status === BookingStatus.Consultation
                ? t('MyAccountPage.AppointmentPart.JoinConsultation')
                : t('MyAccountPage.AppointmentPart.OrderMedication')
            }
            status={'default'}
            onClick={onClick}
            id={`myAppointment__JoinConsultationOrOrderMedication-button-${props.index ? props.index + 1 : 1}`}
          ></BaseButton>
        )}
        {props?.upcoming?.consultType === ConsultationMethod.Online && (
          <div
            className="flex justify-center items-center"
            onClick={() => {
              setArrowStatus((pre) => !pre);
            }}
            id="myAppointment__ReadMore-button"
          >
            <div className="text-[16px] leading-[20px] font-[500] text-[#2DBDB6] lg:mr-[8px] lg:text-[16px] lg:leading-[20px] mr-[6px]">
              {arrowStatus ? t('MyAccountPage.AppointmentPart.ReadLess') : t('MyAccountPage.AppointmentPart.ReadMore')}
            </div>
            {arrowStatus && props?.upcoming?.consultType === ConsultationMethod.Online ? (
              <UpOutlined
                className="active:text-[#3c4148] active:bg-[#eaf3f3]"
                style={{
                  color: '#2DBDB6',
                  fontSize: isLg ? '14px' : '0.3902rem',
                }}
              />
            ) : (
              <DownOutlined
                className="active:text-[#3c4148] active:bg-[#eaf3f3]"
                style={{
                  color: '#2DBDB6',
                  fontSize: isLg ? '14px' : '0.3902rem',
                }}
              />
            )}
          </div>
        )}
      </div>
      {/* TODO PC端还没兼容 */}
      {/* TODO 数据从后端获取=>需要修改 */}
      {arrowStatus && props?.upcoming?.consultType === ConsultationMethod.Online && (
        <>
          {/* 第一步 */}
          <div className="flex mt-[46px] lg:mt-[56px]">
            {/* {props?.upcoming?.status === BookingStatus.Prescription} */}
            <div
              className={classNames(props?.upcoming?.status === BookingStatus.Prescription && isLg && 'flex flex-col')}
            >
              <div className="w-[28px] h-[28px] rounded-[32px] bg-[#2DBDB6] flex justify-center items-center mr-[14px] lg:w-[34px] lg:h-[34px] lg:rounded-[32px] lg:mr-[39px] lg:relative">
                <div className="font-[400] text-[16px] text-[#FFFFFF] leading-[24px] lg:text-[16px] lg:leading-[24px]">
                  {'1'}
                </div>
              </div>
              {/* 实线 */}
              {props?.upcoming?.status === BookingStatus.Prescription && isLg && (
                <div className="lg:h-[196px] border-[#2DBDB6] border-solid lg:border-[1px] w-[0px] lg:ml-[16px] lg:mt-[25px]"></div>
              )}
              {/* 虚线 */}
              {props?.upcoming?.status === BookingStatus.Consultation && isLg && (
                <div className="lg:h-[352px] border-[#000000] border-solid bg-transparent lg:border-[1px] w-[0px] lg:ml-[16px] lg:mt-[25px] opacity-[0.06] absolute"></div>
              )}
              {/* 移动端实线 */}
              {props?.upcoming?.status === BookingStatus.Prescription && !isLg && (
                <div className="h-[238px] border-[#000000] border-solid bg-transparent border-[1px] w-[0px] ml-[14px] mt-[16px] opacity-[0.06] absolute"></div>
              )}
              {/* 移动端虚线 */}
              {props?.upcoming?.status === BookingStatus.Consultation && !isLg && (
                <div className="h-[380px] border-[#000000] border-solid bg-transparent border-[1px] w-[0px] ml-[14px] mt-[16px] opacity-[0.06] absolute"></div>
              )}
            </div>
            <div
              // "flex flex-col rounded-[10px] shadow-[0_10px_30px_rgba(171,190,209,0.2)] p-[26px_12px_41px] lg:rounded-[10px] lg:shadow-[0_10px_30px_rgba(171,190,209,0.2)] lg:p-[0px_37px_45px] lg:w-[766px]"
              className={classNames(
                isLg && props?.upcoming?.status === BookingStatus.Prescription
                  ? 'lg:shadow-none lg:p-[0px_37px] lg:mb-[45px]'
                  : 'lg:shadow-[0_10px_30px_rgba(171,190,209,0.2)] lg:p-[0px_37px_45px]',
                'flex flex-col rounded-[10px] shadow-[0_10px_30px_rgba(171,190,209,0.2)] p-[26px_12px_41px] lg:rounded-[10px] lg:shadow-[0_10px_30px_rgba(171,190,209,0.2)] lg:p-[0px_37px_45px] lg:w-[766px]',
              )}
              // Prescription
              style={{
                paddingTop: isLg && props?.upcoming?.status === BookingStatus.Consultation ? '30px' : undefined,
              }}
            >
              <div className="font-[600] text-[18px] leading-[24px] text-[#212121] mb-[41px] lg:text-[22px] lg:leading-[36px] lg:mb-[20px]">
                {props?.upcoming?.relatedSymptom || t('MyAccountPage.AppointmentPart.HelicobaterPyloriConsultation')}
              </div>
              <div className="flex mb-[20px] lg:mb-[20px]">
                <div className="w-[146px] lg:w-[275px]">
                  <div className="w-[125px] font-[600] text-[14px] leading-[22px] text-[#212121]  tracking-[0.25px] lg:w-[205px] lg:text-[16px] lg:leading-[22px] lg:tracking-[0.25px]">
                    {t('MyAccountPage.AppointmentPart.ScheduledDoctor')}
                  </div>
                </div>
                <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px] w-[142px] lg:w-[275px]">
                  {props?.upcoming?.doctorName}
                </div>
              </div>
              <div className="flex mb-[20px] lg:mb-[20px]">
                <div className="w-[146px] lg:w-[275px]">
                  <div className="w-[125px] font-[600] text-[14px] leading-[22px] text-[#212121]  tracking-[0.25px] lg:w-[205px] lg:text-[16px] lg:leading-[22px] lg:tracking-[0.25px]">
                    {t('MyAccountPage.AppointmentPart.ConsultationMethod')}
                  </div>
                </div>
                <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px]">
                  {props?.upcoming?.consultType === ConsultationMethod.Online
                    ? t('OnlineConsultation')
                    : t('In-ClinicVisit')}
                </div>
              </div>
              <div className="flex mb-[20px] lg:mb-[20px]">
                <div className="w-[146px] lg:w-[275px]">
                  <div className="w-[125px] font-[600] text-[14px] leading-[22px] text-[#212121]  tracking-[0.25px] lg:w-[205px] lg:text-[16px] lg:leading-[22px] lg:tracking-[0.25px]">
                    {t('Date')}
                  </div>
                </div>
                <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px]">
                  {formatBeDate(props?.upcoming?.date)}
                </div>
              </div>
              <div
                className={classNames(
                  props?.upcoming?.status === BookingStatus.Consultation ? 'mb-[23px]' : 'mb-[8px]',
                  'flex',
                )}
              >
                <div className="w-[146px] lg:w-[275px]">
                  <div className="w-[125px] font-[600] text-[14px] leading-[22px] text-[#212121]  tracking-[0.25px] lg:w-[205px] lg:text-[16px] lg:leading-[22px] lg:tracking-[0.25px]">
                    {t('Time')}
                  </div>
                </div>
                <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px]">
                  {props?.upcoming?.time}
                </div>
              </div>
              {/* TODO 按钮  需要区分状态*/}
              {/* TODO height现在 */}
              {props?.upcoming?.status === BookingStatus.Consultation && (
                <BaseButton
                  htmlType="submit"
                  buttonClassName="w-[156px] h-[37px] text-[14px] leading-[22px] font-[500] md:w-[326px] md:h-[50px] md:text-[16px] md:leading-[24px]"
                  value={isLg ? t('MyAccountPage.AppointmentPart.JoinConsultation') : t('Join')}
                  status={'default'}
                  // TODO 这里需要调整
                  onClick={onClick}
                  id="myAppointment__ReadMore-JoinConsultation-button"
                ></BaseButton>
              )}
            </div>
          </div>
          {/* 第二步 */}
          {props?.upcoming?.status === BookingStatus.Consultation ? (
            // 第二步为阴影时
            <div className="flex mt-[51px] mb-[72px] lg:mt-[75px] lg:mb-[40px]">
              <div className="mr-[20px] mt-[22px] lg:mr-[39px] lg:mt-[0px]">
                <div className="w-[29px] h-[29px] border-[#BABABA] border-solid border-[1px]  rounded-[32px] flex justify-center items-center lg:w-[34px] lg:h-[34px] lg:border-[1px] lg:rounded-[32px]">
                  <div className="font-[400] text-[16px] text-[#BABABA] leading-[24px] lg:text-[16px] lg:leading-[24px]">
                    {'2'}
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="font-[600] text-[18px] leading-[24px] text-[#BABABA] mb-[12px] lg:text-[18px] lg:leading-[28px] lg:mb-[4px]">
                  {t('MyAccountPage.AppointmentPart.DiagnosisAndMedication')}
                </div>
                <div className="font-[400] text-[14px] leading-[22px] text-[#BABABA] mb-[12px] lg:text-[16px] lg:leading-[28px]  lg:mb-[12px] lg:w-[651px]">
                  {t('MyAccountPage.AppointmentPart.OrderNowText')}
                </div>
              </div>
            </div>
          ) : (
            // 第二步正常部分
            <div className="flex mb-[60px] lg:mb-[40px]">
              <div className="mr-[14px] lg:mr-[30px]">
                <div className="w-[28px] h-[28px] rounded-[32px] bg-[#2DBDB6] flex justify-center items-center mr-[14px] lg:w-[34px] lg:h-[34px] lg:mr-[30px]">
                  <div className="font-[400] text-[16px] text-[#FFFFFF] leading-[24px] lg:text-[16px] lg:leading-[24px]">
                    {'2'}
                  </div>
                </div>
              </div>
              <div className="flex flex-col p-[27px_8px_25px_13px] lg:p-[19px_36px_33px] lg:w-[775px] lg:shadow-[0_10px_30px_rgba(171,190,209,0.2)] lg:rounded-[10px] lg:bg-[#FFFFFF]">
                <div className="font-[600] text-[18px] leading-[24px] text-[#212121] mb-[12px] lg:text-[22px] lg:leading-[36px] lg:mb-[20px]">
                  {t('MyAccountPage.AppointmentPart.DiagnosisAndMedication')}
                </div>
                <div className="font-[400] text-[14px] leading-[22px] text-[#212121] mb-[20px]  tracking-[0.25px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px] lg:mb-41px]">
                  {t('MyAccountPage.AppointmentPart.OrderNowText')}
                </div>
                <BaseButton
                  htmlType="submit"
                  buttonClassName="w-[156px] h-[37px] text-[16px] leading-[24px] font-[500] md:w-[326px] md:h-[50px] md:text-[16px] md:leading-[24px]"
                  value={
                    isLg ? t('MyAccountPage.AppointmentPart.OrderMedication') : t('MyAccountPage.AppointmentPart.Order')
                  }
                  status={'default'}
                  onClick={onClick}
                  id="myAppointment__ReadMore-OrderMedication-button"
                ></BaseButton>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
