import { ExclamationCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import BaseButton from '@components/BaseButton/BaseButton';
import TextInput from '@components/TextInput/TextInput';
import back from '@images/doctorImages/back.svg';
import { addDrug } from '@services/medicationService';
import { NewDrugInfo } from '@services/types/medicationService.type';

import { useMemoizedFn } from 'ahooks';
import { notification, Select } from 'antd';
import { useState } from 'react';
import { alertConfimInfos } from '../components/LoadingConfirmBox';
import { useLocalNavigate } from 'src/router/routerTools';
// import { track } from '@utils/dataTrack';

export default function AddNewDrug() {
  const navigate = useLocalNavigate();
  const [drugName, setDrugName] = useState('');
  const [dosage, setDosage] = useState('');
  const [dosageUnit, setDosageUnit] = useState<0 | 1>(0); // 0: mg 1: ml
  const [timePerDay, setTimePerDay] = useState('');
  const [unit, setUnit] = useState('');
  const [duration, setDuration] = useState('');

  const [notificationApi, contextHolder] = notification.useNotification();

  const alertConfimInfosInner = useMemoizedFn(() => {
    alertConfimInfos(
      {
        content: (
          <div className="text-[#25282b] sm:text-[16px] font-medium sm:leading-[22px] flex items-start">
            <ExclamationCircleOutlined className="text-[#FF7A45] sm:text-[22px] sm:mr-[10px]" />
            <div>
              <p>Confirm you would like to create:</p>
              <ul className="sm:ml-[35px]">
                <li className="break-all">{`${drugName} - ${dosage} ${dosageUnit ? 'ml' : 'mg'}`}</li>
              </ul>
            </div>
          </div>
        ),
        contentWrapperClassName: 'lg:min-w-[471px] min-w-[360px] rounded-[10px] sm:pt-[18px] sm:pb-[10px]',
        onConfirm: async () => {
          const newDrugInfo: NewDrugInfo = {
            name: drugName,
            dosage: dosage,
            dosageUnit: dosageUnit,
            times: +timePerDay,
            units: unit,
            duration: duration,
          };
          try {
            await addDrug(newDrugInfo);
            notificationApi.info({
              message: 'Drug has been added successfully',
              placement: 'bottomRight',
              duration: 10000,
            });
            setTimeout(() => {
              navigate('/doctor/medicationManager');
            }, 500);
          } catch (e: any) {
            // track({
            //   event_type: 'encounter_error',
            //   event_properties: {
            //     'event name': 'add_drug_error',
            //     'error type': 'add_drug_error',
            //   },
            // });
            notificationApi.error({
              message: (
                <>
                  <p>New Drug Creation Unsuccessful</p>
                  <p>{e?.response?.data?.message ?? ''}</p>
                </>
              ),
              placement: 'bottomRight',
              description: e.reason ?? undefined,
            });
          }
        },
      },
      'indicaid-doctor-addNewDrug__alertConfirm-modal-button',
      'indicaid-doctor-addNewDrug__alertCancel-modal-button',
    );
  });

  const alertLeaveReminder = useMemoizedFn(() => {
    alertConfimInfos(
      {
        content: (
          <div className="text-[#25282b] sm:text-[16px] font-medium sm:leading-[22px] flex items-start">
            <ExclamationCircleOutlined className="text-[#FF7A45] sm:text-[22px] sm:mr-[10px]" />
            <div>
              <p className="text-[#25282b] sm:text-[14px] sm:leading-[22px]">
                You have unsaved details, you will not be able to retrieve unsaved data
              </p>
            </div>
          </div>
        ),
        contentWrapperClassName: 'lg:min-w-[471px] min-w-[360px] rounded-[10px] sm:pt-[18px] sm:pb-[10px]',
        onConfirm: async () => {
          navigate('/doctor/medicationManager');
        },
      },
      'indicaid-doctor-addNewDrug__leaveConfirm-modal-button',
      'indicaid-doctor-addNewDrug__leaveCancel-modal-button',
    );
  });
  const enableSave = drugName && dosage && timePerDay && unit && duration;

  return (
    <div
      className="w-full h-full flex  bg-opacity-[0.8] flex-col py-[0] px-[24px] sm:min-h-[calc(100vh-318px-79px)] sm:h-[1024px] sm:p-[60px_38px_35px_65px]"
      style={{ background: '#f5f7fa' }}
    >
      {contextHolder}
      <div className="flex flex-col">
        <div
          className="flex sm:mb-[24px] items-center"
          onClick={() => {
            if (drugName || dosage || dosageUnit || timePerDay || unit || duration) {
              alertLeaveReminder();
            } else {
              navigate('/doctor/medicationManager');
            }
          }}
          id="indicaid-doctor-addNewDrug__BacktoMedicationManager-button"
        >
          <div>
            <img src={back} alt="back" className="object-cover sm:w-[32px] cursor-pointer" />
          </div>
          <div className="sm:ml-[22px] font-[600] text-[#52575C] sm:text-[16px] sm:leading-[20px] sm:tracking-[0.1px]">
            Back to Medication Manager
          </div>
        </div>
        <div className="font-[700] sm:text-[28px] sm:leading-[42px] text-[#25282B] sm:mb-[33px]">Add New Drug</div>
        <div
          style={{
            boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)',
          }}
          className="sm:w-[1045px] sm:h-[337px] sm:py-[37px] sm:px-[24px] bg-[#ffffff] rounded-[8px]"
        >
          <div className="flex">
            <div>
              <p className="sm:text-[14px] font-semibold">
                Name<span className="text-[#EB5757]">*</span>
              </p>
              <TextInput
                maxLength={300}
                value={drugName}
                onChange={(e) => setDrugName(e.target.value)}
                inputClassName="sm:w-[717px] sm:h-[42px]"
                placeholder="Official Drug Name"
                id="indicaid-doctor-addNewDrug__OfficialDrugName-input"
              />
            </div>
            <div className="sm:ml-[16px]">
              <p className="sm:text-[14px] font-semibold">
                Dosage<span className="text-[#EB5757]">*</span>
              </p>
              <div className="bg-[#ffffff] text-[#52575c] sm:text-[15px] sm:leading-[22px] sm:h-[42px] sm:px-[12px] flex items-center border-[#D5D4DC] sm:rounded-[8px] sm:w-[239px] border-[2px]">
                <input
                  id="indicaid-doctor-addNewDrug__dosage-input"
                  type="number"
                  value={dosage}
                  onChange={(e) => setDosage(e.target.value)}
                  className="outline-0 sm:w-[172px] border-transparent bg-transparent"
                />
                <Select
                  id="indicaid-doctor-addNewDrug__dosageSelect-input"
                  onChange={setDosageUnit}
                  className="dosage-select border-none outline-none sm:h-[42px]"
                  defaultValue={0}
                  options={[
                    {
                      label: 'mg',
                      value: 0,
                    },
                    {
                      label: 'ml',
                      value: 1,
                    },
                  ]}
                ></Select>
              </div>
            </div>
          </div>
          <p className="sm:text-[12px] sm:leading-[22px] sm:pl-[17px] sm:mt-[9px] text-[#8F989C] flex items-center">
            <ExclamationCircleFilled className="text-[#009FAF] sm:text-[15px] sm:mr-[10px]" />
            Reminder: the medication name will not be editable after saving
          </p>

          <div className="flex sm:w-[717px] justify-between sm:mt-[25px]">
            <div>
              <p className="sm:text-[14px] font-semibold">
                Time/Day<span className="text-[#EB5757]">*</span>
              </p>
              <TextInput
                id="indicaid-doctor-addNewDrug__Numberoftimes-input"
                number
                value={timePerDay}
                onChange={(e) => setTimePerDay(e.target.value)}
                inputClassName="sm:w-[252px] sm:h-[42px]"
                placeholder="Number of times per day"
              />
            </div>
            <div>
              <p className="sm:text-[14px] font-semibold">
                Units<span className="text-[#EB5757]">*</span>
              </p>
              <TextInput
                id="indicaid-doctor-addNewDrug__unit-input"
                number
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                inputClassName="sm:w-[205px] sm:h-[42px]"
                placeholder="Quantity of medication"
              />
            </div>
            <div>
              <p className="sm:text-[14px] font-semibold">
                Duration<span className="text-[#EB5757]">*</span>
              </p>
              <div className="bg-[#ffffff] text-[#52575c] sm:text-[14px] sm:leading-[22px] sm:px-[12px] flex items-center border-[#D5D4DC] sm:rounded-[8px] sm:w-[205px] sm:h-[42px] border-[2px]">
                <input
                  id="indicaid-doctor-addNewDrug__duration-input"
                  type="number"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  className="outline-0 sm:w-[150px] border-transparent bg-transparent"
                />
                <p>Days</p>
              </div>
            </div>
          </div>
          <p className="sm:text-[12px] sm:leading-[22px] sm:pl-[17px] sm:mt-[9px] text-[#8F989C] flex items-center">
            <ExclamationCircleFilled className="text-[#009FAF] sm:text-[15px] sm:mr-[10px]" />
            Save your most frequently used prescription as template, editable later on
          </p>

          <div className="flex justify-end sm:mt-[30px] ">
            {' '}
            <BaseButton
              disable={!enableSave}
              status={!enableSave ? 'disabled' : 'doctorDefault'}
              onClick={alertConfimInfosInner}
              buttonClassName="sm:w-[129px] sm:h-[32px] sm:mr-[12px]"
              value="Save"
              id="indicaid-doctor-addNewDrug__save-button"
            />
            <BaseButton
              status="doctorClear"
              onClick={() => {}}
              buttonClassName="sm:w-[129px] sm:h-[32px]"
              value="Cancel"
              id="indicaid-doctor-addNewDrug__cancel-button"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
