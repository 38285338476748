import { Left, Right } from '@icon-park/react';
import { useMemoizedFn } from 'ahooks';
import { Calendar } from 'antd';
import { HeaderRender } from 'antd/es/calendar/generateCalendar';

import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from 'src/router/routerTools';
import zhTW from 'antd/es/calendar/locale/zh_TW';

function CaseItem(props: { text: string; color: string }) {
  return (
    <div className="flex lg:ml-[15px] ml-[15px] items-center">
      <div
        className="lg:w-[17px] lg:h-[17px] w-[17px] h-[17px] rounded-full border-[1px] border-solid"
        style={{
          backgroundColor: props.color === '#cccccc' ? '' : props.color,
          borderColor: props.color,
        }}
      ></div>
      <p className="text-[#212121] lg:ml-[5px] lg:text-[14px] text-[14px] ml-[7px]">{props.text}</p>
    </div>
  );
}

type CustomCalendarProps = {
  onSelectChange?: (date: Dayjs) => void;
  avaliableDateList: string[];
  pageIdName?: string;
};

export default function CustomCalendar(props: CustomCalendarProps) {
  const language = useContext(LanguageContext);
  const [t] = useTranslation();
  const { onSelectChange } = props;
  const renderHeader: HeaderRender<Dayjs> = useMemoizedFn(({ value, onChange }) => {
    const localeData = value.localeData();
    const year = value.year();
    let timeText = '';
    if (language === 'zh-HK') {
      timeText = `${year} 年 ${value.month() + 1} 月`;
    } else {
      timeText = `${localeData.monthsShort(value)} ${year}`;
    }

    const handleMonthChnage = (leftOrRight: 1 | -1) => {
      const newTime = value.clone().month(value.month() + leftOrRight);
      onChange(newTime);
    };
    return (
      <div className="lg:mb-[30px] mt-[25px] lg:mt-0 px-[15px] lg:px-0 lg:pt-[25px] pt-[15px]">
        <div className="flex lg:justify-center justify-between items-center select-none lg:mb-[20px]">
          <Left
            className="lg:mr-[56px]"
            theme="filled"
            size="26"
            fill="#cccccc"
            onClick={() => handleMonthChnage(-1)}
            id={`${props.pageIdName}__left-changeMonth-arrow`}
          />
          <p className="text-[#737373] lg:text-[18px] text-[18px] font-semibold">{timeText}</p>
          <Right
            className="lg:ml-[56px]"
            theme="filled"
            size="26"
            fill="#cccccc"
            onClick={() => handleMonthChnage(1)}
            id={`${props.pageIdName}__right-changeMonth-arrow`}
          />
        </div>
        <div className="flex lg:justify-end lg:w-[93.7%] py-[29px] justify-center">
          <CaseItem color="#cccccc" text={t('SelectTimeSlotPage.Today')} />
          <CaseItem color="#2DBDB6" text={t('SelectTimeSlotPage.Selected')} />
        </div>
      </div>
    );
  });

  const [dateValue, setDateValue] = useState<Dayjs>(dayjs());
  useEffect(() => {
    if (props.avaliableDateList[0]) {
      setDateValue(dayjs(props.avaliableDateList[0]));
    }
  }, [props.avaliableDateList]);
  useEffect(() => {
    onSelectChange && onSelectChange(dateValue);
  }, [dateValue, onSelectChange]);

  const renderDateCell = useMemoizedFn((date: Dayjs) => {
    const dateFormat = date.format('YYYY-MM-DD');
    const todayFormat = dayjs().format('YYYY-MM-DD');
    return (
      <div
        onClick={() => {
          if (props.avaliableDateList.includes(date.format('YYYY-MM-DD'))) {
            setDateValue(date);
          }
        }}
        id={`${props.pageIdName}__dataCell-${date.format('YYYY-MM-DD')}`}
        className={classNames(
          'relative rounded-full inline-flex justify-center items-center',
          'lg:w-[32px] lg:h-[32px] lg:m-[6px] lg:text-[16px]',
          'w-[32px] h-[32px] text-[16px]',
          'before:text-[#444444] text-[#444444] before:bg-transparent',
          dateFormat === dateValue?.format('YYYY-MM-DD') && 'bg-[#2DBDB6] text-[#ffffff!important]',
          dateFormat === todayFormat && 'border-solid border-[#cccccc] border-[1px]',
          props.avaliableDateList.includes(date.format('YYYY-MM-DD')) ? 'text-[#444444]' : 'text-[#44444478]',
        )}
      >
        {date.date()}
        {props.avaliableDateList.includes(dateFormat) && (
          <div className="lg:w-[5px] lg:h-[5px] w-[5px] h-[5px] bottom-[-8px] rounded-full bg-[#97D0C3] absolute lg:bottom-[-6px]"></div>
        )}
      </div>
    );
  });

  return (
    <div className="custom-calendar lg:w-[906px] lg:h-[570px] w-[365px] h-[415px] lg:shadow-[0px_6px_25px_rgba(171,190,209,0.2)] shadow-[0px_10px_30px_rgba(171,190,209,0.2)] lg:rounded-[10px] rounded-[10px] m-auto lg:mt-[62px]">
      <Calendar
        defaultValue={dayjs()}
        fullscreen={false}
        headerRender={renderHeader}
        disabledDate={(date) => {
          return !props.avaliableDateList.includes(date.format('YYYY-MM-DD'));
        }}
        locale={language === 'en' ? undefined : zhTW}
        dateFullCellRender={renderDateCell}
      />
    </div>
  );
}
