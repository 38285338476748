import { Checkbox } from 'antd';
import { ButtonHTMLType } from 'antd/es/button';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import './BaseCheckbox.css';
// const bgColor = {
//   default: '#2DBDB6',
//   disabled: '#F5F5F5',
// };
// const textColor = {
//   default: '#ffffff',
//   disabled: 'rgba(0, 0, 0, 0.25)',
// };
export type ButtonStatus = 'default' | 'disabled' | undefined;
type BaseCheckboxProps = {
  value?: string;
  onChange?: (e: CheckboxChangeEvent) => void;
  status?: ButtonStatus;
  placeholder?: string;
  disable?: boolean;
  helpText?: string;
  loading?: boolean;
  htmlType?: ButtonHTMLType;
  className?: string;
  id?: string;
};

export default function BaseCheckbox(props: BaseCheckboxProps) {
  return (
    <Checkbox
      id={props.id}
      prefixCls={'custom-base-checkbox'}
      className={props.className}
      onChange={props.onChange}
      style={{
        borderRadius: '50%',
      }}
    ></Checkbox>
  );
}
