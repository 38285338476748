import React, { useState, useEffect, useRef } from 'react';
import { Spin } from 'antd';
import BaseButton, { ButtonStatus } from '@components/BaseButton/BaseButton';
import { handleLocalStorage } from '@utils/index';
import { MEDIA_SIZE } from '@consts/consts';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import classNames from 'classnames';
import selectOne from '../../images/selectOne.svg';
import selectTwo from '../../images/selectTwo.svg';
import selectThree from '../../images/selectThree.svg';
import selectFour from '../../images/selectFour.svg';
import selectFive from '../../images/selectFive.svg';
import ResultAlert from '@utils/globalComponents/ResultAlert';
import { useMemoizedFn } from 'ahooks';
import { ModelType } from '@consts/enums';
import alertSelect1 from '../../images/alertSelect1.png';
import alertSelect2 from '../../images/alertSelect2.png';
import alertSelect3 from '../../images/alertSelect3.png';
import alertSelect4 from '../../images/alertSelect4.png';
import alertSelect5 from '../../images/alertSelect5.png';
import greenMedicine from '../../images/greenMedicine.svg';
import greenSigns from '../../images/greenSigns.svg';
import greenTicks from '../../images/greenTicks.svg';
import { useLocalNavigate } from 'src/router/routerTools';
import { useTranslation } from 'react-i18next';
import { clearDataTrackConstantId, getDataTrackConstantId, track } from '@utils/dataTrack';
import { useIsHkLanguage } from 'src/hooks/tools/useIsHkLanguage';
import { patientTokenStore } from '@utils/localStore/tokenStore';
import { useProductType } from 'src/hooks/tools/useProductType';
import { TestResult } from '@consts/enums';
import alertConfirmReminder from './components/ConfirmAlert';

const style = {
  container: {
    background: '#FDFDFD',
  },
};

export const resultTypeText = {
  '1': 'positive',
  '2': 'positive',
  '3': 'negative',
  '4': 'invalid',
  '5': 'invalid',
};

const SelectResult: React.FC = () => {
  useEffect(() => {
    track({
      event_type: 'view select test result',
      event_properties: {
        'testing id': getDataTrackConstantId('TestingId'),
        source: window.document.referrer,
      },
    });
  }, []);
  const { productType, isHaveProductType } = useProductType();
  console.log(productType, isHaveProductType);
  const isHkLanguage = useIsHkLanguage();
  const [t] = useTranslation();
  const navigate = useLocalNavigate();
  // TODO 这个需要调整回来
  const [q1, setQ1] = useState<string | null>(null);
  const [buttonStatus, setButtonStatus] = useState<ButtonStatus>('indicaidGreen');
  const [loading, setLoading] = useState(false);

  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const resultClickQueueRef = useRef<string[]>([]);

  const alertLeaveReminder = useMemoizedFn(() => {
    // 没筛选，不展示弹窗
    if (!q1) {
      return;
    }
    const modalType = {
      '1': ModelType.Positive,
      '2': ModelType.Positive,
      '3': ModelType.Negative,
      '4': ModelType.Invalid,
      '5': ModelType.Invalid,
    };

    const testResultText = resultTypeText[q1 as keyof typeof resultTypeText];
    track({
      event_type: 'submit test result',
      event_properties: {
        'test result': testResultText,
        'testing id': getDataTrackConstantId('TestingId'),
      },
    });

    //

    const titleText = {
      [ModelType.Positive]: t('Positive'),
      [ModelType.Negative]: t('Negative'),
      [ModelType.Invalid]: t('Invalid'),
    };
    const imgSrc = {
      '1': alertSelect1,
      '2': alertSelect2,
      '3': alertSelect3,
      '4': alertSelect4,
      '5': alertSelect5,
    };

    const modalStatusType = {
      [ModelType.Positive]: [ModelType.Positive],
      [ModelType.Negative]: [ModelType.Negative],
      [ModelType.Invalid]: [ModelType.Invalid],
    };

    const modalStatus = q1 ? modalType[q1 as keyof typeof modalType] : 1;

    const handleAlertConfirm = async () => {
      if (modalStatus === ModelType.Invalid) {
        await track({
          event_type: 'shpe now time',
          event_properties: {
            'test result': testResultText,
            'testing id': getDataTrackConstantId('TestingId'),
          },
        }).promise.catch(() => {});
        // let lang = navigator.language; //常规浏览器语言和IE浏览器
        // lang = lang.substr(0, 2);
        if (isHkLanguage) {
          window.location.href = 'https://www.indicaid.com/products/uutube';
        } else {
          window.location.href = 'https://www.indicaid.com/en/products/uutube';
        }
      } else {
        track({
          event_type: 'click book appointment',
          event_properties: {
            'test result': testResultText,
            'testing id': getDataTrackConstantId('TestingId'),
          },
        });
        clearDataTrackConstantId('TestingId');
        setLoading(true);
        setTimeout(() => {
          handleLocalStorage.set('testResult', q1);
          const token = patientTokenStore.get();
          let uuTubeTestResultType = TestResult.uutube_positive;
          // 根据modalStatus，在url里面传入不同的uuTubeTestResultType
          if (modalStatus === ModelType.Positive) {
            uuTubeTestResultType = TestResult.uutube_positive;
          } else if (modalStatus === ModelType.Negative) {
            uuTubeTestResultType = TestResult.uutube_negative;
          } else if (modalStatus === ModelType.Invalid) {
            uuTubeTestResultType = TestResult.uutube_invalid;
          }

          if (token) {
            navigate(`/healthScreen?selectResultType=${uuTubeTestResultType}`);
          } else {
            const returnUrl = `?redirect=${encodeURIComponent(
              `${window.location.origin}${
                isHkLanguage ? '' : '/en'
              }/healthScreen?selectResultType=${uuTubeTestResultType}`,
            )}`;
            navigate(`/login${returnUrl}`);
          }
        }, 3000);
      }
    };
    const handleAlertCancel = async () => {
      track({
        event_type: 'do later time',
        event_properties: {
          'test result': testResultText,
          'testing id': getDataTrackConstantId('TestingId'),
        },
      });
    };

    if (isLg) {
      const typeTextMap: { [p: number]: 'Positive' | 'Negative' | 'Invalid' } = {
        [ModelType.Positive]: 'Positive',
        [ModelType.Negative]: 'Negative',
        [ModelType.Invalid]: 'Invalid',
      };
      alertConfirmReminder({
        type: typeTextMap[modalStatus],
        onConfirm: handleAlertConfirm,
        onCancel: handleAlertCancel,
      });
      return;
    }

    const renderMiddleText = () => {
      if (modalStatus === ModelType.Positive) {
        return (
          <div className="flex flex-col mb-[19px] lg:mb-[23px]">
            <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px]">
              {t('SelectResultPage.positiveDesc.title')}
            </div>
            <div className="flex items-center">
              <div className="border-[#212121] border-solid border-[1px] w-[1px] h-[1px] rounded-[100%] bg-[#212121] mx-[7px] lg:border-[1px] lg:w-[1px] lg:h-[1px] lg:mx-[7px]"></div>
              <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px] lg:font-[600]">
                {t('SelectResultPage.positiveDesc.1')}
              </div>
            </div>
            <div className="flex items-center">
              <div className="border-[#212121] border-solid border-[1px] w-[1px] h-[1px] rounded-[100%] bg-[#212121] mx-[7px] lg:border-[1px] lg:w-[1px] lg:h-[1px] lg:mx-[7px]"></div>
              <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px] lg:font-[600]">
                {t('SelectResultPage.positiveDesc.2')}
              </div>
            </div>
            <div className="flex items-center">
              <div className="border-[#212121] border-solid border-[1px] w-[1px] h-[1px] rounded-[100%] bg-[#212121] mx-[7px] lg:border-[1px] lg:w-[1px] lg:h-[1px] lg:mx-[7px]"></div>
              <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px] lg:font-[600]">
                {t('SelectResultPage.positiveDesc.3')}
              </div>
            </div>
            <div className="flex items-center">
              <div className="border-[#212121] border-solid border-[1px] w-[1px] h-[1px] rounded-[100%] bg-[#212121] mx-[7px] lg:border-[1px] lg:w-[1px] lg:h-[1px] lg:mx-[7px]"></div>
              <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px] lg:font-[600]">
                {t('SelectResultPage.positiveDesc.4')}
              </div>
            </div>
            <div className="flex items-center">
              <div className="border-[#212121] border-solid border-[1px] w-[1px] h-[1px] rounded-[100%] bg-[#212121] mx-[7px] lg:border-[1px] lg:w-[1px] lg:h-[1px] lg:mx-[7px]"></div>
              <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px] lg:font-[600]">
                {t('SelectResultPage.positiveDesc.5')}
              </div>
            </div>
            {/* TODO 修改文案=>这个再看一下 */}
          </div>
        );
      } else if (modalStatus === ModelType.Negative) {
        return (
          <div className="flex flex-col mb-[19px] lg:mb-[31px]">
            <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:w-[967px] lg:text-[16px] lg:leading-[28px]">
              {t('SelectResultPage.negativeDesc')}
            </div>
          </div>
        );
      } else {
        return (
          <div className="flex flex-col mb-[19px] lg:mb-[37px]">
            <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px] lg:w-[967px]">
              {t('SelectResultPage.invalidDesc')}
            </div>
          </div>
        );
      }
    };

    const renderNextStepText = () => {
      if (modalStatus === ModelType.Positive) {
        return (
          <div className="flex flex-col">
            {!isLg ? (
              <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px]">
                {t('SelectResultPage.positiveNestStep.1')} {t('SelectResultPage.positiveNestStep.2')}
                {t('SelectResultPage.positiveNestStep.3')}
              </div>
            ) : (
              <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px]  lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px] lg:w-[1053px]">
                {t('SelectResultPage.positiveNestStep.1')}
                <span className="lg:font-[600] text-[#212121]  lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px]">
                  {t('SelectResultPage.positiveNestStep.2')}
                </span>
                {t('SelectResultPage.positiveNestStep.3')}
              </div>
            )}

            <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px]">
              {t('SelectResultPage.positiveNestStep.4')}
            </div>
          </div>
        );
      } else if (modalStatus === ModelType.Negative) {
        return (
          <div className="">
            {isLg ? (
              <div className="font-[400] text-[14px] text-[#212121] tracking-[0.25px] leading-[24px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px]">
                {t('SelectResultPage.nagativeNextStep.1')}
                <span className="font-[600] text-[14px] text-[#212121] tracking-[0.25px] leading-[24px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px]">
                  {t('SelectResultPage.nagativeNextStep.2')}
                </span>
                {t('SelectResultPage.nagativeNextStep.3')}
              </div>
            ) : (
              <div className="font-[600] text-[14px] text-[#212121] tracking-[0.25px] leading-[24px]">
                {t('SelectResultPage.nagativeNextStep.4')}
                <span className="font-[400] text-[14px] text-[#212121] tracking-[0.25px] leading-[24px]">
                  {t('SelectResultPage.nagativeNextStep.5')}
                </span>
              </div>
            )}
          </div>
        );
      } else {
        return (
          <div className="flex flex-col mb-[10px] lg:mb-[42px]">
            <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px] lg:w-[1045px]">
              {t('SelectResultPage.invalidStep')}
            </div>
          </div>
        );
      }
    };

    ResultAlert((close) => ({
      content: (
        <div className="w-[88.4%] rounded-[10px] bg-[#F2F7F9] p-[28px_0px_50px_28px]   lg:w-[1204px]  lg:rounded-[30px] lg:p-[70px_0px_0px_0px] max-h-[80vh]">
          <>
            <div className="flex flex-col overflow-scroll max-h-[50vh] lg:max-h-[45vh] custom-scroll-bar  custom-scroll-bar-bg_width-4 custom-scroll-bar-bg_white lg-custom-scroll-bar-bg_width-8 pr-[28px] lg:pr-[0px]">
              {/* Header栏 */}
              <div className="lg:flex lg:pl-[81px] lg:pr-[96px] lg:justify-between w-full">
                <div className="flex flex-col justify-center items-center lg:justify-start lg:items-start">
                  {!isLg && (
                    <img
                      src={imgSrc[q1 as keyof typeof imgSrc]}
                      alt={titleText[modalStatus as keyof typeof modalStatusType]}
                      className="object-cover w-[111px] mb-[26px]"
                    />
                  )}
                  <div className="mb-[8px] font-[600] text-[22px] leading-[36px] text-[#2DBDB6] text-center lg:mb-[29px] lg:text-[40px] lg:leading-[58px] lg:text-left">
                    {t('SelectResultPage.YourResult')}
                  </div>
                  <div className="mb-[11px] font-[600] text-[16px] leading-[24px] text-[#212121] text-center lg:mb-[34px] lg:text-[22px] lg:leading-[36px] lg:text-left">
                    {t('SelectResultPage.HelicobacterPyloriAntigenTest')}
                  </div>
                  <div className="font-[700] text-[32px] leading-[36px] text-[#1E1F20] text-center mb-[27px] lg:text-[48px] lg:leading-[36px] lg:text-left lg:mb-[60px]">
                    {titleText[modalStatus as keyof typeof modalStatusType]}
                  </div>
                </div>
                {isLg && (
                  <div className="lg:w-[194px] lg:mb-[26px] lg:mt-[5px]">
                    <img
                      // src={imgSrc[modalStatus as keyof typeof modalStatusType]}
                      src={imgSrc[q1 as keyof typeof imgSrc]}
                      alt={titleText[modalStatus as keyof typeof modalStatusType]}
                      className="object-cover lg:w-[194px]"
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-col lg:bg-[#FFFFFF] lg:p-[33px_98px_0px_83px] lg:rounded-[0_0_30px_30px]">
                {/* 标题栏 */}
                <div className="flex mb-[18px] items-center lg:mb-[18px]">
                  <img
                    src={modalStatus === ModelType.Positive ? greenSigns : greenMedicine}
                    alt={modalStatus === ModelType.Positive ? 'Complications' : 'Result Interpretation'}
                    className="object-cover w-[28px] lg:w-[36px]"
                  />
                  <div className="ml-[18px] font-[600] text-[18px] leading-[36px] text-[#2DBDB6] lg:ml-[18px] lg:text-[18px] lg:leading-[36px]">
                    {modalStatus === ModelType.Positive
                      ? t('SelectResultPage.Complications')
                      : t('SelectResultPage.ResultInterpretation')}{' '}
                  </div>
                </div>
                {/* 内容栏 */}
                {renderMiddleText()}
                {/* next step区域 */}
                <div className="flex mb-[18px] items-center lg:mb-[18px]">
                  <img src={greenTicks} alt={'Next Step'} className="object-cover w-[28px] lg:w-[36px]" />
                  <div className="ml-[18px] font-[600] text-[16px] leading-[36px] text-[#2DBDB6] lg:ml-[18px] lg:text-[18px] lg:leading-[36px]">
                    {t('NextStep')}{' '}
                  </div>
                </div>
                {/* next step 内容栏 */}
                {renderNextStepText()}
              </div>
            </div>
            <div className="flex flex-col  mt-[45px] lg:mt-[0px] lg:pt-[45px] lg:bg-[#FFFFFF] lg:pb-[55px] lg:rounded-[0_0_30px_30px]  pr-[28px] lg:pr-[0px]">
              <BaseButton
                htmlType="submit"
                buttonClassName="w-[295px] h-[50px] text-[16px] mb-[19px] md:w-[274px] md:h-[50px] md:text-[16px] md:mb-[34px]"
                value={
                  modalStatus === ModelType.Invalid
                    ? t('SelectResultPage.OrderNewKit')
                    : t('SelectResultPage.FindADoctorDow')
                }
                // disable={buttonStatus === 'disabled'}
                status={'indicaidGreen'}
                // loading={buttonLoading}

                onClick={handleAlertConfirm}
                // onClick={alertLeaveReminder}
                sectionClassName="flex justify-center"
                // style={{
                //   backgroundColor: q1 && q2 && q3 && q4 ? '#2DBDB6' : '#F5F5F5',
                // }}
                id="select-result_popup-confirmation"
              ></BaseButton>
              <div
                className="font-[600] text-[16px] leading-[20px] underline text-[#2DBDB6] text-center lg:text-[16px] lg:leading-[20px] cursor-pointer"
                id="select-result_do-it-later"
                onClick={() => {
                  close();
                  handleAlertCancel();

                  // await RtcAdapter.leave();
                  // navigate('/meeting/exception?type=end');
                }}
              >
                {t('SelectResultPage.DoItLater')}
              </div>
            </div>
          </>
        </div>
      ),
    }));
  });

  useEffect(() => {
    if (q1) {
      setButtonStatus('indicaidGreen');
    } else {
      setButtonStatus('disabled');
    }
  }, [q1]);

  return (
    <Spin spinning={loading}>
      <div
        className="w-full h-full flex  bg-opacity-[0.8] flex-col py-[0] px-[24px] md:px-[32px] md:items-center lg:px-[0px] lg:items-center min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)]"
        style={style.container}
      >
        <div className="flex flex-col justify-start w-full">
          <div className="mt-[37px] font-[600] text-[28px] leading-[36px] w-full flex items-center justify-center text-[#2DBDB6] lg:mt-[80px] lg:text-[40px] lg:leading-[58px]">
            {t('SelectResultPage.SelectYourTestResult')}
          </div>
          <div className="mt-[44px] mb-[44px] font-[400] text-[16px] leading-[24px] w-full flex items-center justify-center text-[#212121] lg:mt-[45px] lg:mb-[65px] lg:text-[16px] lg:leading-[28px]  lg:w-[1127px] lg:text-center lg:mx-[auto]">
            {t('SelectResultPage.SelectSubTitle')}
          </div>
          <div className="select-result-radio-box flex flex-wrap flex-row lg:items-center lg:justify-center">
            {/* <Radio.Group onChange={onQ1Change} value={q1}> */}
            <div className="flex flex-wrap flex-row">
              {/* 选项一 */}
              <div
                className="flex flex-col items-center px-[15px] pb-[25px] lg:px-[39px] lg:pb-[45px]"
                id="select-result_positive1"
                onClick={() => {
                  setQ1('1');
                  resultClickQueueRef.current.push('positive');
                }}
              >
                <div
                  className={classNames(
                    q1 === '1' && 'border-[#2DBDB6] border-solid border-[3px] lg:border-[3px]',
                    'bg-[#DFDFDF] w-[150px] h-[147px] rounded-[18px] flex items-center justify-center mb-[32px] lg:w-[163px] lg:h-[163px] lg:rounded-[18px] lg:mb-[32px]',
                  )}
                >
                  <img
                    src={selectOne}
                    alt="selectOne"
                    className="object-cover w-[68px] cursor-pointer lg:w-[68px] pointer-events-none"
                    // onClick={() => {
                    //   navigate('/');
                    // }}
                  />
                </div>
                {/* <Radio value={'1'}></Radio> */}
              </div>
              {/* 选项二 */}
              <div
                className="flex flex-col items-center px-[15px] pb-[25px] lg:px-[39px] lg:pb-[45px]"
                id="select-result_positive2"
                onClick={() => {
                  setQ1('2');
                  resultClickQueueRef.current.push('positive');
                }}
              >
                <div
                  className={classNames(
                    q1 === '2' && 'border-[#2DBDB6] border-solid border-[3px] lg:border-[3px]',
                    'bg-[#DFDFDF] w-[150px] h-[147px] rounded-[18px] flex items-center justify-center mb-[32px] lg:w-[163px] lg:h-[163px] lg:rounded-[18px] lg:mb-[32px]',
                  )}
                >
                  <img
                    src={selectTwo}
                    alt="selectOne"
                    className="object-cover w-[68px] cursor-pointer lg:w-[68px] pointer-events-none"
                    // onClick={() => {
                    //   navigate('/');
                    // }}
                  />
                </div>
                {/* <Radio value={'2'}></Radio> */}
              </div>
              {/* 选项三 */}
              <div
                className="flex flex-col items-center px-[15px] pb-[25px] lg:px-[39px] lg:pb-[45px]"
                id="select-result_negative"
                onClick={() => {
                  setQ1('3');
                  resultClickQueueRef.current.push('negative');
                }}
              >
                <div
                  className={classNames(
                    q1 === '3' && 'border-[#2DBDB6] border-solid border-[3px] lg:border-[3px]',
                    'bg-[#DFDFDF] w-[150px] h-[147px] rounded-[18px] flex items-center justify-center mb-[32px] lg:w-[163px] lg:h-[163px] lg:rounded-[18px] lg:mb-[32px]',
                  )}
                >
                  <img
                    src={selectThree}
                    alt="selectOne"
                    className="object-cover w-[68px] cursor-pointer lg:w-[68px] pointer-events-none"
                    // onClick={() => {
                    //   navigate('/');
                    // }}
                  />
                </div>
                {/* <Radio value={'3'}></Radio> */}
              </div>
              {/* 选项四 */}
              <div
                className="flex flex-col items-center px-[15px] pb-[25px] lg:px-[39px] lg:pb-[45px]"
                id="select-result_invalid1"
                onClick={() => {
                  setQ1('4');
                  resultClickQueueRef.current.push('invalid');
                }}
              >
                <div
                  className={classNames(
                    q1 === '4' && 'border-[#2DBDB6] border-solid border-[3px] lg:border-[3px]',
                    'bg-[#DFDFDF] w-[150px] h-[147px] rounded-[18px] flex items-center justify-center mb-[32px] lg:w-[163px] lg:h-[163px] lg:rounded-[18px] lg:mb-[32px]',
                  )}
                >
                  <img
                    src={selectFour}
                    alt="selectFour"
                    className="object-cover w-[68px] cursor-pointer lg:w-[68px] pointer-events-none"
                    // onClick={() => {
                    //   navigate('/');
                    // }}
                  />
                </div>
                {/* <Radio value={'4'}></Radio> */}
              </div>
              {/* 选项五 */}
              <div
                className="flex flex-col items-center px-[15px] pb-[25px] lg:px-[39px] lg:pb-[45px]"
                id="select-result_invalid2"
                onClick={() => {
                  setQ1('5');
                  resultClickQueueRef.current.push('invalid');
                }}
              >
                <div
                  className={classNames(
                    q1 === '5' && 'border-[#2DBDB6] border-solid border-[3px] lg:border-[3px]',
                    'bg-[#DFDFDF] w-[150px] h-[147px] rounded-[18px] flex items-center justify-center mb-[32px] lg:w-[163px] lg:h-[163px] lg:rounded-[18px] lg:mb-[32px]',
                  )}
                >
                  <img
                    src={selectFive}
                    alt="selectFive"
                    className="object-cover w-[68px] cursor-pointer lg:w-[68px] pointer-events-none"
                    // onClick={() => {
                    //   navigate('/');
                    // }}
                  />
                </div>
                {/* <Radio value={'5'}></Radio> */}
              </div>
            </div>
            {/* </Radio.Group> */}
          </div>
          <div className="w-full flex md:items-center md:justify-center pt-[0px] pb-[100px] lg:pt-[50px] lg:pb-[127px]">
            <BaseButton
              htmlType="submit"
              buttonClassName="w-full h-[50px] text-[16px] md:w-[326px] md:h-[50px] md:text-[16px] w-[100%] h-[50px]"
              value={t('Submit')}
              // disable={buttonStatus === 'disabled'}
              status={buttonStatus}
              // loading={buttonLoading}
              onClick={alertLeaveReminder}
              sectionClassName="w-full md:w-[326px]"
              id="select-result_submit"
              // style={{
              //   backgroundColor: q1 && q2 && q3 && q4 ? '#2DBDB6' : '#F5F5F5',
              // }}
            ></BaseButton>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default SelectResult;
