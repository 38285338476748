import { AnyFunction } from 'types/types';

export default class EventsMap<F extends AnyFunction> {
  private eventMap: Map<string | number, F[]> = new Map();

  addEventMapListener(key: string, cb: F) {
    let eventList = this.eventMap.get(key);
    if (!eventList) {
      eventList = [];
      this.eventMap.set(key, eventList);
    }
    eventList.push(cb);
    return () => {
      const idx = eventList!.indexOf(cb);
      if (idx !== -1) {
        eventList!.splice(idx, 1);
      }
    };
  }

  removeListener(key: string, cb: F) {
    const eventList = this.eventMap.get(key);
    if (eventList) {
      const idx = eventList.indexOf(cb);
      if (idx !== -1) {
        eventList.splice(idx, 1);
      }
    }
  }

  triggerEventMap(key: string, ...args: Parameters<F>) {
    const eventList = this.eventMap.get(key);
    eventList?.forEach((cb) => cb(...args));
  }

  removeKeyListeners(key: string) {
    const eventList = this.eventMap.get(key);
    if (eventList) {
      this.eventMap.set(key, []);
    }
  }

  removeAllListeners() {
    this.eventMap.clear();
  }
}
