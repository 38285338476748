import classNames from 'classnames';
import { MEDIA_SIZE } from '@consts/consts';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import './bookingSteps.css';
import { Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelectResultType } from 'src/hooks/tools/useSelectResultType';
import { UUTUBE_TYPE } from '@consts/consts';

type StepItemsProps = {
  active: boolean;
  idx: number;
  text: string;
};
function StepItems(props: StepItemsProps) {
  return (
    <div className="flex justify-center items-center sm:m-[23px] m-[23px]">
      <p
        className={classNames(
          props.active ? 'bg-[#2DBDB6] text-[#ffffff]' : 'bg-[#BABABA] text-[#ffffff]',
          'inline-block w-[36px] h-[36px] text-[22px] leading-[36px] sm:w-[36px] sm:h-[36px] sm:text-[22px] sm:leading-[36px] text-center rounded-[50%]',
        )}
      >
        {props.idx}
      </p>
      <p className="text-[#000000] text-[16px] ml-[10px] sm:text-[16px] sm:ml-[10px]">{props.text}</p>
    </div>
  );
}

type BookingStepsProps = {
  step: number;
};

// const stepsText = ['Health screening', 'Select Time slot', 'Choose Your Doctor', 'Personal Information', 'Payment'];
// const mobileStepsText = ['Health screening', 'Select Time slot', 'Choose Doctor', 'Personal Information', 'Payment'];

export default function BookingSteps(props: BookingStepsProps) {
  const { productType } = useSelectResultType();
  const isUUtube = productType === UUTUBE_TYPE;

  const [t] = useTranslation();
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;

  const stepsText = ['Health screening', 'Select Time slot', 'Choose Your Doctor', 'Personal Information', 'Payment'];
  const mobileStepsText = [
    t('Steps.desktopStep1'),
    t('Steps.desktopStep2'),
    t('Steps.desktopStep3'),
    t('Steps.desktopStep4'),
    t('Steps.desktopStep5'),
  ];

  // TODO: 需要做国际化=>把国际化的内容列出来
  if (isLg) {
    let renderClassName, renderTextClassName;

    if (props.step === 1) {
      renderTextClassName = 'lg:mt-[40px] lg:mb-[60px]';
      renderClassName = 'lg:mb-[23px]';
    } else if (props.step === 2) {
      renderTextClassName = 'lg:mt-[4px] lg:mb-[63px]';
      renderClassName = 'lg:mb-[87px]';
    } else if (props.step === 3) {
      renderTextClassName = 'lg:mt-[4px] lg:mb-[63px]';
      renderClassName = 'lg:mb-[0px]';
    } else if (props.step === 4) {
      renderTextClassName = 'lg:mt-[17px] lg:mb-[63px]';
      renderClassName = 'lg:mb-[113px]';
    } else if (props.step === 5) {
      renderTextClassName = 'lg:mt-[38px] lg:mb-[75px]';
      renderClassName = 'lg:mb-[93px]';
    }

    let itemsArr = [
      {
        title: t('Steps.desktopStep2'),
        // description: 'This is a description.',
      },
      {
        title: t('Steps.desktopStep3'),
        // description: 'This is a description.',
      },
      {
        title: t('Steps.desktopStep4'),
        // description: 'This is a description.',
      },
      {
        title: t('Steps.desktopStep5'),
        // description: 'This is a description.',
      },
    ];

    // 只有UUtube有第一步
    if (isUUtube) {
      itemsArr.unshift({
        title: t('Steps.desktopStep1'),
        // description: 'This is a description.',
      });
    }

    // const itemsArr=isUUtube?:

    return (
      <div className="flex flex-col">
        <p
          className={classNames(
            renderTextClassName,
            'text-[#2DBDB6] text-center font-medium lg:text-[40px] lg:font-[600] lg:leading-[58px]',
          )}
        >
          {props.step > 1 ? t('Steps.bookTitle') : t('Steps.yourHealthText')}
        </p>
        <div className="lg:w-[984px] mx-[auto]">
          <Steps
            className={classNames(renderClassName)}
            // className={classNames(
            //   curPage === CurPage[CurPage.PersonalInfo] ? 'lg:mt-[0px]' : 'lg:mt-[40px]',
            //   'mt-[18px] w-[100%] lg:w-[120%]',
            // )}
            // TODO horizontal失效的问题
            direction="horizontal"
            // style={{
            //   fontSize: isLg ? '16px' : '0.3415rem',
            //   lineHeight: isLg ? '24px' : '0.5854rem',
            //   fontWeight: 500,
            // }}
            responsive={false}
            progressDot
            current={isUUtube ? props.step - 1 : props.step - 2}
            // current={CurPage[curPage as keyof typeof CurPage]}
            items={itemsArr}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="lg:hidden">
        {/* <div className='w-full h-[74px] bg-[#2DBDB6]'></div> */}
        {/* w-full */}
        <div
          className={classNames(
            [2, 5].includes(props.step) ? 'left-[0px]' : 'left-[-24px]',
            'h-[74px] bg-[#2DBDB6] flex items-center pl-[24px] relative  w-[100vw]',
          )}
        >
          {/* <div className={classNames(props.step === 2 ? 'left-[0px]' : 'left-[-24px]', 'h-[74px] bg-[#2DBDB6] flex items-center pl-[24px]  w-[100vw]')}> */}
          <p className="text-[16px] font-medium text-[#ffffff]">
            {t('Steps.Step')} {isUUtube ? props.step : props.step - 1} :{' '}
            {isLg ? stepsText[props.step - 1] : mobileStepsText[props.step - 1]}
          </p>
        </div>
        <p className="text-[#2DBDB6] font-medium text-[28px] pl-[24px] my-[25px] lg:block hidden">
          {props.step > 1 ? t('Steps.bookTitle') : t('Steps.statusTitle')}
        </p>
      </div>
      <div className="lg:block hidden relative">
        <p className="text-[#2DBDB6] text-center font-medium lg:text-[42px] lg:font-[600]">
          {props.step > 1 ? t('Steps.bookTitle') : t('Steps.statusTitle')}
        </p>
        <div className="flex justify-center">
          <StepItems active={props.step >= 1} idx={1} text={stepsText[0]} />
          <StepItems active={props.step >= 2} idx={2} text={stepsText[1]} />
          <StepItems active={props.step >= 3} idx={3} text={stepsText[2]} />
          <StepItems active={props.step >= 4} idx={4} text={stepsText[3]} />
          <StepItems active={props.step >= 5} idx={5} text={stepsText[4]} />
        </div>
      </div>
    </>
  );
}
