// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
import { Stripe } from '@stripe/stripe-js';

// This is your test publishable API key.
let stripePromise: Promise<Stripe | null> | undefined = undefined;
const getStripePromise = () => {
  if (!stripePromise) {
    return import('@stripe/stripe-js').then((stripe) => stripe.loadStripe(process.env.STRIPE_PK || ''));
  }
  return stripePromise;
};

export default getStripePromise;
