import { useSearchParams } from 'react-router-dom';
import { TestResult } from '@consts/enums';
import { UUTUBE_TYPE, CFF_TYPE, COVID_TYPE } from '@consts/consts';

export function useSelectResultType() {
  const selectResultType = parseInt((useSearchParams()[0].get('selectResultType') as string) || '');

  // 如果没有selectResultType，默认是UUtube
  let productType = UUTUBE_TYPE;

  if ([TestResult.uutube_positive, TestResult.uutube_negative, TestResult.uutube_invalid].includes(selectResultType)) {
    productType = UUTUBE_TYPE;
  } else if (
    [
      TestResult.combo_invalid,
      TestResult.combo_negative,
      TestResult.combo_positive_Flu_A,
      TestResult.combo_positive_Flu_B,
      TestResult.combo_positive_covid_19,
      TestResult.combo_positive_covid_19_Flu_A,
      TestResult.combo_positive_covid_19_Flu_B,
    ].includes(selectResultType)
  ) {
    productType = CFF_TYPE;
  } else if (
    [
      TestResult.covid_19_invalid_covid_19,
      TestResult.covid_19_negative_covid_19,
      TestResult.covid_19_positive_covid_19,
    ].includes(selectResultType)
  ) {
    productType = COVID_TYPE;
  }

  const isHaveSelectResultType =
    (Object.prototype.toString.call(selectResultType) === '[object Number]' && !isNaN(selectResultType)) ||
    Object.prototype.toString.call(selectResultType) === '[object String]';
  return {
    productType,
    isHaveSelectResultType,
    selectResultType,
  };
}
