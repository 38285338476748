export default function AlertTermsTitle(props: { children: React.ReactNode }) {
  return (
    <p
      className="
    text-[#2DBDB6] lg:mb-[35px] lg:mt-[20px] lg:text-[32px] lg:leading-[42px] font-semibold text-center
      text-[22px] leading-[36px] my-[23px]
    "
    >
      {props.children}
    </p>
  );
}
