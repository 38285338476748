import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { CSSProperties } from 'react';

const CustomEndCallIconSvg = () => (
  <svg width="23" height="9" viewBox="0 0 23 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3094 0C13.276 0 15.2134 0.396 17.1214 1.188C19.03 1.97933 20.726 3.16667 22.2094 4.75C22.4094 4.95 22.5094 5.18333 22.5094 5.45C22.5094 5.71667 22.4094 5.95 22.2094 6.15L19.9094 8.4C19.726 8.58333 19.5137 8.68333 19.2724 8.7C19.0304 8.71667 18.8094 8.65 18.6094 8.5L15.7094 6.3C15.576 6.2 15.476 6.08333 15.4094 5.95C15.3427 5.81667 15.3094 5.66667 15.3094 5.5V2.65C14.676 2.45 14.026 2.29167 13.3594 2.175C12.6927 2.05833 12.0094 2 11.3094 2C10.6094 2 9.92604 2.05833 9.25937 2.175C8.59271 2.29167 7.94271 2.45 7.30937 2.65V5.5C7.30937 5.66667 7.27604 5.81667 7.20937 5.95C7.14271 6.08333 7.04271 6.2 6.90937 6.3L4.00937 8.5C3.80937 8.65 3.58871 8.71667 3.34738 8.7C3.10538 8.68333 2.89271 8.58333 2.70938 8.4L0.409375 6.15C0.209375 5.95 0.109375 5.71667 0.109375 5.45C0.109375 5.18333 0.209375 4.95 0.409375 4.75C1.87604 3.16667 3.56771 1.97933 5.48438 1.188C7.40104 0.396 9.34271 0 11.3094 0Z"
      fill="white"
    />
  </svg>
);

export default function CustomEndCallIcon(props: Partial<CustomIconComponentProps> & { style?: CSSProperties }) {
  // </div>
  return (
    <Icon
      style={
        props.style ?? {
          paddingBottom: '2px',
          paddingRight: '5px',
          transform: 'translateX(-3px)',
        }
      }
      component={CustomEndCallIconSvg}
      {...props}
    />
  );
}
