import { CloseOutlined } from '@ant-design/icons';
import { renderComponent, RenderComponentOptions } from './globalComponents';
import { ConfigProvider } from 'antd';

type AlertInfoProps = {
  content: string | React.ReactNode;
  background?: string;
  closeIconShow?: boolean;
  wraperBackground?: string;
  contentClassName?: string;
  contentWrapperClassName?: string;
};
export function AlertInfo(props: AlertInfoProps & { closeFunction: () => void; isDoctor?: boolean }) {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: props.isDoctor ? '009FAF' : '#2DBDB6',
          fontFamily: props.isDoctor
            ? "'Inter','Montserrat', -apple-system, blinkmacsystemfont, 'Helvetica Neue', helvetica, 'segoe ui', arial, roboto,'PingFang SC', miui, 'Hiragino Sans GB', 'Microsoft Yahei', sans-serif"
            : "'Montserrat', -apple-system, blinkmacsystemfont, 'Helvetica Neue', helvetica, 'segoe ui', arial, roboto,'PingFang SC', miui, 'Hiragino Sans GB', 'Microsoft Yahei', sans-serif",
        },
      }}
    >
      <div
        onClick={props.closeFunction}
        className="w-[100vw] h-[100vh] fixed top-0 left-0 z-[10000] flex justify-center items-center"
        style={{ background: props.wraperBackground }}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={
            props.contentWrapperClassName ??
            'lg:min-w-[471px] min-w-[360px] sm:rounded-[5px] lg:pt-[30px] lg:pb-[30px] pt-[30px] sm:pb-[30px]'
          }
          style={{
            background: props.background ?? '#333333',
          }}
        >
          {props.closeIconShow !== false && (
            <div className="lg:px-[30px] px-[18px] text-[#e0ecff] text-[22px] font-bold text-right">
              <CloseOutlined className="cursor-pointer" onClick={props.closeFunction} />
            </div>
          )}
          <div className={props.contentClassName ?? 'lg:py-[0px] py-[0px]'}>{props.content}</div>
        </div>
      </div>
    </ConfigProvider>
  );
}

export default function alertInfo(config: RenderComponentOptions<AlertInfoProps & { isDoctor?: boolean }>) {
  return renderComponent(config, AlertInfo);
}
