import { TestResult } from '@consts/enums';

export const CONTROL_LINE = 0b1000;
export const FLU_A = 0b0100;
export const FLU_B = 0b0010;
export const COVID_19 = 0b0001;

type CONTROL_LINE_type = 0b1000;
type FLU_A_type = 0b0100;
type FLU_B_type = 0b0010;
type COVID_19_type = 0b0001;

export type ComboBaseResult = CONTROL_LINE_type | FLU_A_type | FLU_B_type | COVID_19_type;
export const ResultList: {
  text: string;
  value: ComboBaseResult;
  color: string;
  id: string;
}[] = [
  {
    text: 'Covid19AndFluSelectResult.controlLine',
    value: CONTROL_LINE,
    color: 'bg-[#551257]',
    id: 'covid19Flu-select-result__Control-Line',
  },
  {
    text: 'Covid19AndFluSelectResult.FluA',
    value: FLU_A,
    color: 'bg-[#D3BCC6]',
    id: 'covid19Flu-select-result__Flu-A',
  },
  {
    text: 'Covid19AndFluSelectResult.FluB',
    value: FLU_B,
    color: 'bg-[#D3BCC6]',
    id: 'covid19Flu-select-result__Flu-B',
  },

  {
    text: 'Covid19AndFluSelectResult.Covid19',
    value: COVID_19,
    color: 'bg-[#D3BCC6]',
    id: 'covid19Flu-select-result__COVID-19',
  },
];

export const ResultMap = {
  [CONTROL_LINE]: 'Negative',
  [CONTROL_LINE | FLU_A]: 'FLU_A_POSITIVE',
  [CONTROL_LINE | FLU_B]: 'FLU_B_POSITIVE',
  [CONTROL_LINE | COVID_19]: 'Covid_POSITIVE',
  [CONTROL_LINE | FLU_A | COVID_19]: 'FLU_A_Covid_POSITIVE',
  [CONTROL_LINE | FLU_B | COVID_19]: 'FLU_B_Covid_POSITIVE',
};

export enum ResultType {
  INVALID = -1,
  Negative = CONTROL_LINE,
  FLU_A_POSITIVE = CONTROL_LINE | FLU_A,
  FLU_B_POSITIVE = CONTROL_LINE | FLU_B,
  FLU_B_Covid_POSITIVE = CONTROL_LINE | FLU_B | COVID_19,
  FLU_A_Covid_POSITIVE = CONTROL_LINE | FLU_A | COVID_19,
  Covid_POSITIVE = CONTROL_LINE | COVID_19,
}

export const COVID19AndFluSelectResultType = {
  [ResultType.INVALID]: TestResult.combo_invalid,
  [ResultType.Negative]: TestResult.combo_negative,
  [ResultType.FLU_A_POSITIVE]: TestResult.combo_positive_Flu_A,
  [ResultType.FLU_B_POSITIVE]: TestResult.combo_positive_Flu_B,
  [ResultType.Covid_POSITIVE]: TestResult.combo_positive_covid_19,
  [ResultType.FLU_A_Covid_POSITIVE]: TestResult.combo_positive_covid_19_Flu_A,
  [ResultType.FLU_B_Covid_POSITIVE]: TestResult.combo_positive_covid_19_Flu_B,
};
