import RtcAdapter from '@utils/RtcAdapter/RtcTool';
import { useEffect, useState } from 'react';

export function useAllRtcUids() {
  const [userRtcUidList, setUserRtcUidList] = useState<string[]>([]);

  useEffect(() => {
    if (RtcAdapter.localRtcUid) {
      setUserRtcUidList([RtcAdapter.localRtcUid, ...RtcAdapter.getRemoteRtcUidList()]);
    }

    const c1 = RtcAdapter.on('local-user-joined', () => {
      setUserRtcUidList([RtcAdapter.localRtcUid, ...RtcAdapter.getRemoteRtcUidList()]);
    });
    const c2 = RtcAdapter.on('user-joined', (remoteRtcUid) => {
      setUserRtcUidList((allRtcUidList) => {
        if (allRtcUidList.includes(remoteRtcUid)) {
          return allRtcUidList;
        } else {
          return [...allRtcUidList, remoteRtcUid];
        }
      });
    });
    // 用户离开不用管，直接显示离线
    // const c3 = RtcAdapter.on('user-left', (remoteRtcUid) => {
    //   setUserRtcUidList((allRtcUidList) => {
    //     const idx = allRtcUidList.indexOf(remoteRtcUid);
    //     if (idx !== -1) {
    //       allRtcUidList.splice(idx, 1);
    //       return [...allRtcUidList];
    //     }
    //     return allRtcUidList;
    //   });
    // });
    return () => {
      c1();
      c2();
      // c3();
    };
  }, []);

  return userRtcUidList;
}
