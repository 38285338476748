/* eslint-disable @typescript-eslint/indent */
// import React, { useState } from 'react';
import React, { useState, useEffect } from 'react';

import femaleAvatar from '../../../images/femaleAvatar.svg';
import maleAvatar from '../../../images/maleAvatar.svg';
import consultation from '../../../images/consultation.svg';
import fee from '../../../images/fee.svg';
import qualification from '../../../images/qualification.svg';
// import location from '../../../images/location.svg';
import feeTick from '../../../images/feeTick.svg';
import feeCross from '../../../images/feeCross.svg';
import DownOutlined from '@ant-design/icons/DownOutlined';
import UpOutlined from '@ant-design/icons/UpOutlined';
import EnvironmentFilled from '@ant-design/icons/EnvironmentFilled';

import { ConsultationMethod, FromProductType, WorkingConditionStatus } from '@consts/enums';

import { GenderStatus } from '@consts/enums';

import { MEDIA_SIZE } from '@consts/consts';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import BaseButton from '@components/BaseButton/BaseButton';
import { useMemoizedFn } from 'ahooks';
// import { ConsultationMethod } from '@consts/enums';
// import { MEDIA_SIZE } from '@consts/consts';
// import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import classNames from 'classnames';
import { handleLocalStorage } from '@utils/index';
import website from '@configs/website';
import { useTranslation } from 'react-i18next';
import { useLocalNavigate } from 'src/router/routerTools';
import { getDataTrackConstantId, track } from '@utils/dataTrack';
import { useSelectResultType } from 'src/hooks/tools/useSelectResultType';
// import { formatBeDate } from '@utils/index';

type DoctorCardProps = {
  // 代表线上和线下
  consultType?: number;
  productType: FromProductType;
  doctorList?: {
    workingCondition?: string;
    doctorName?: string;
    // 现场问诊，需要返回地址
    location?: string;
    // 咨询费用
    price?: string;
    qualification?: string[];
    gender?: number;
    doctorUserId?: string;
  };
  isLast?: boolean;
  curPosition?: number;
};

// TODO 图标没居中，location图标存在问题，需要调整
export default function DoctorCard(props: DoctorCardProps) {
  const { isHaveSelectResultType, selectResultType } = useSelectResultType();
  const [t] = useTranslation();
  const [arrowStatus, setArrowStatus] = useState(false);
  const [qualificationData, setQualificationData] = useState<string[]>([]);
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const navigate = useLocalNavigate();

  const onClick = useMemoizedFn(async () => {
    handleLocalStorage.set(website.step_doctor_id, props?.doctorList?.doctorUserId || null);
    track({
      event_type: 'select doctor',
      event_properties: {
        'booking id': getDataTrackConstantId('BookingId'),
        'doctor name': props?.doctorList?.doctorName || '',
        'consulation fee': props?.consultType === ConsultationMethod.Online ? props?.doctorList?.price : 'HK$30' || '',
        'consulation method': props?.consultType === ConsultationMethod.Online ? 'Online' : 'Offline',
      },
    });
    navigate(`/fillInfos${isHaveSelectResultType ? `?selectResultType=${selectResultType}` : ''}`);
  });

  useEffect(() => {
    if (props?.doctorList?.qualification && props?.doctorList?.qualification.length > 4) {
      if (arrowStatus) {
        setQualificationData(props?.doctorList?.qualification || []);
      } else {
        setQualificationData(props?.doctorList?.qualification && props?.doctorList?.qualification.slice(0, 4));
      }
    } else {
      setQualificationData(props?.doctorList?.qualification || []);
    }
  }, [arrowStatus, props?.doctorList?.qualification]);

  console.log(props, 'props');
  return (
    <div
      className={classNames('w-full lg:w-[907px]', props?.isLast ? 'mb-[55px] lg:mb-[62px]' : 'mb-[38px] lg:mb-[54px]')}
    >
      <div className="flex rounded-[10px] shadow-[0_10px_30px_rgba(171,190,209,0.2)] flex-col bg-[#FFFFFF] p-[23px_18px_34px] lg:w-[907px]  lg:rounded-[10px] lg:shadow-[0_10px_30px_rgba(171,190,209,0.2)]  lg:p-[47px_33px_39px] lg:relative">
        {/* 用户头像模块一 */}
        <div
          className="flex"
          onClick={() => {
            if (!isLg) {
              onClick();
            }
          }}
          id={`patientDoctorList__${props.curPosition}-avatar-button`}
        >
          <div className="mr-[23px] ml-[11px] lg:mr-[32px] lg:ml-[4px]">
            {props?.doctorList?.gender === GenderStatus.Male ? (
              <img src={maleAvatar} alt="maleAvatar" className="object-cover w-[40px] lg:w-[77px]" />
            ) : (
              <img src={femaleAvatar} alt="femaleAvatar" className="object-cover w-[40px] lg:w-[77px]" />
            )}
          </div>
          {/* TODO 做到这里 */}
          <div className="flex flex-col">
            <div className="font-[600] text-[18px] leading-[24px] text-[#212121] lg:text-[22px] lg:leading-[36px]">
              {props?.doctorList?.doctorName}
            </div>
            <div className="font-[400] text-[14px] leading-[22px] text-[#212121] mt-[11px] lg:text-[14px] lg:leading-[17px] lg:mt-[5px]">
              {parseInt(props?.doctorList?.workingCondition || '0') === WorkingConditionStatus.GP
                ? t('DoctorListPage.GeneralPracticeGP')
                : ''}
            </div>
          </div>
        </div>
        {/* 用户信息模块二 */}
        <div
          className={classNames(
            'flex flex-col mt-[31px] lg:ml-[109px] lg:mt-[40px]',
            props?.doctorList?.qualification && props?.doctorList?.qualification.length > 4
              ? 'mb-[0px] lg:mb-[37px]'
              : 'mb-[24px] lg:mb-[0px]',
          )}
          onClick={() => {
            if (!isLg) {
              onClick();
            }
          }}
          id={`patientDoctorList__${props.curPosition}-doctorInformation-button`}
        >
          {/* Booking fee */}
          <div className="flex">
            <div className="mr-[11px] ml-[11px] lg:mr-[10px] lg:ml-[0px] mt-[1px] lg:mt-[1px]">
              <img src={fee} alt="fee" className="object-cover w-[19px] lg:w-[19px]" />
            </div>
            {/* <div className="mr-[11px] ml-[11px] lg:mr-[10px] lg:ml-[0px]">
              <img src={consultation} alt="consultation" className="object-cover w-[19px] lg:w-[19px]" />
            </div> */}
            <div className="flex flex-col">
              <div className="font-[600] text-[14px] leading-[24px] text-[#212121] mb-[14px] lg:text-[14px] lg:leading-[24px] lg:mb-[14px]">
                {t('DoctorListPage.ConsultationFee')}
              </div>
              <div className="font-[400] text-[14px] leading-[22px] text-[#212121] mb-[24px] lg:tracking-[0.25px] lg:mb-[27px] lg:text-[14px] lg:leading-[22px]">
                {props?.consultType === ConsultationMethod.Online
                  ? `${props?.doctorList?.price} (${t('OnlineConsultation')})`
                  : `HK$30 (${t('In-ClinicVisit')})`}
              </div>
            </div>
          </div>
          {/* Fee include */}
          <div className="flex">
            <div className="mr-[11px] ml-[11px] lg:mr-[10px] lg:ml-[0px] mt-[2px]">
              <img src={consultation} alt="consultation" className="object-cover w-[19px] lg:w-[19px]" />
            </div>
            {/* <div className="mr-[11px] ml-[11px] lg:mr-[10px] lg:ml-[0px]">
              <img src={fee} alt="fee" className="object-cover w-[19px] lg:w-[19px]" />
            </div> */}
            <div className="flex flex-col">
              <div className="font-[600] text-[14px] leading-[24px] text-[#212121] mb-[14px] lg:text-[14px] lg:leading-[24px] lg:mb-[14px]">
                {t('DoctorListPage.ConsultationInclude')}
              </div>
              {/* TODO 根据状态进行展示 */}
              {/* TODO 需要调整回来=>Online  Offline*/}
              {/* lg:tracking-[0.25px] lg:mb-[14px] lg:text-[14px] lg:leading-[22px] */}
              {props?.consultType === ConsultationMethod.Offline ? (
                <div className="font-[400] text-[14px] leading-[22px] text-[#212121] mb-[24px] flex flex-col lg:tracking-[0.25px] lg:mb-[27px] lg:text-[14px] lg:leading-[22px]">
                  {/* 第一项 */}
                  <div className="flex items-center">
                    <div className="mr-[8px] lg:mr-[8px]">
                      <img src={feeTick} alt="feeTick" className="object-cover w-[13px] lg:w-[13px]" />
                    </div>
                    <div className="font-[400] text-[14px] leading-[22px] text-[#212121] lg:text-[14px] lg:leading-[22px] lg:tracking-[0.25px]">
                      {t('DoctorListPage.InstantConfirmationOfBooking')}
                    </div>
                  </div>
                  {/* 第二项 */}
                  <div className="flex items-center">
                    <div className="mr-[8px] lg:mr-[8px]">
                      <img src={feeCross} alt="feeCross" className="object-cover w-[10px]  lg:w-[10px]" />
                    </div>
                    <div className="font-[400] text-[14px] leading-[22px] text-[#212121] lg:text-[14px] lg:leading-[22px] lg:tracking-[0.25px]">
                      {t('DoctorListPage.1-on-1OnlineMedicalConsultation')}
                    </div>
                  </div>
                  {/* 第三项 */}
                  <div className="flex items-center">
                    <div className="mr-[8px] lg:mr-[8px]">
                      <img src={feeCross} alt="feeCross" className="object-cover w-[10px] lg:w-[10px]" />
                    </div>
                    <div className="font-[400] text-[14px] leading-[22px] text-[#212121] lg:text-[14px] lg:leading-[22px] lg:tracking-[0.25px]">
                      {t('DoctorListPage.ProfessionalDiagnosis')}
                    </div>
                  </div>
                  {/* 第四项 */}
                  <div className="flex items-center">
                    <div className="mr-[8px] lg:mr-[8px]">
                      <img src={feeCross} alt="feeCross" className="object-cover w-[10px] lg:w-[10px]" />
                    </div>
                    <div className="font-[400] text-[14px] leading-[22px] text-[#212121] lg:text-[14px] lg:leading-[22px] lg:tracking-[0.25px]">
                      {t('DoctorListPage.MedicalPrescription')}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {[FromProductType.combo, FromProductType.covid].includes(props.productType) ? (
                    <div className="font-[400] text-[14px] leading-[22px] text-[#212121] mb-[24px] flex flex-col lg:tracking-[0.25px] lg:mb-[27px] lg:text-[14px] lg:leading-[22px]">
                      {/* 第一项 */}
                      <div className="flex items-center">
                        <div className="mr-[8px] lg:mr-[8px]">
                          <img src={feeTick} alt="feeTick" className="object-cover w-[10px] lg:w-[10px]" />
                        </div>
                        <div className="font-[400] text-[14px] leading-[22px] text-[#212121] lg:text-[14px] lg:leading-[22px] lg:tracking-[0.25px]">
                          {t('DoctorListPage.proMedicalDia')}
                        </div>
                      </div>
                      {/* 第二项 */}
                      <div className="flex items-center">
                        <div className="mr-[8px] lg:mr-[8px]">
                          <img src={feeTick} alt="feeTick" className="object-cover w-[10px] lg:w-[10px]" />
                        </div>
                        <div className="font-[400] text-[14px] leading-[22px] text-[#212121] lg:text-[14px] lg:leading-[22px] lg:tracking-[0.25px]">
                          {t('DoctorListPage.3DaysMedication')}
                        </div>
                      </div>
                      {/* 第三项 */}
                      <div className="flex items-center">
                        <div className="mr-[8px] lg:mr-[8px]">
                          <img src={feeTick} alt="feeTick" className="object-cover w-[10px] lg:w-[10px]" />
                        </div>
                        <div className="font-[400] text-[14px] leading-[22px] text-[#212121] lg:text-[14px] lg:leading-[22px] lg:tracking-[0.25px]">
                          {t('DoctorListPage.2xTestKit')}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="font-[400] text-[14px] leading-[22px] text-[#212121] mb-[24px] flex flex-col lg:tracking-[0.25px] lg:mb-[27px] lg:text-[14px] lg:leading-[22px]">
                      {/* 第一项 */}
                      <div className="flex items-center">
                        <div className="mr-[8px] lg:mr-[8px]">
                          <img src={feeTick} alt="feeTick" className="object-cover w-[10px] lg:w-[10px]" />
                        </div>
                        <div className="font-[400] text-[14px] leading-[22px] text-[#212121] lg:text-[14px] lg:leading-[22px] lg:tracking-[0.25px]">
                          {t('Diagnosis')}
                        </div>
                      </div>
                      {/* 第二项 */}
                      <div className="flex items-center">
                        <div className="mr-[8px] lg:mr-[8px]">
                          <img src={feeTick} alt="feeTick" className="object-cover w-[10px] lg:w-[10px]" />
                        </div>
                        <div className="font-[400] text-[14px] leading-[22px] text-[#212121] lg:text-[14px] lg:leading-[22px] lg:tracking-[0.25px]">
                          {t('Consultation')}
                        </div>
                      </div>
                      {/* 第三项 */}
                      <div className="flex items-center">
                        <div className="mr-[8px] lg:mr-[8px]">
                          <img src={feeTick} alt="feeTick" className="object-cover w-[10px] lg:w-[10px]" />
                        </div>
                        <div className="font-[400] text-[14px] leading-[22px] text-[#212121] lg:text-[14px] lg:leading-[22px] lg:tracking-[0.25px]">
                          {t('DoctorListPage.MedicationDelivery')}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {/* location */}
          {props?.consultType === ConsultationMethod.Offline && (
            <div className="flex">
              <div className="mr-[11px] ml-[11px] lg:mr-[10px] lg:ml-[0px] flex items-start mt-[2px]">
                {/* TODO location需要移除 */}
                <EnvironmentFilled
                  // className="active:text-[#3c4148] active:bg-[#eaf3f3]"
                  style={{
                    color: '#222222',
                    fontSize: isLg ? '19px' : '0.4634rem',
                    // marginBottom: isLg ? '0px' : '0.1951rem',
                  }}
                />
                {/* <img src={location} alt="location" className="object-cover w-[25px] md:w-[15px]" /> */}
              </div>
              <div className="flex flex-col">
                <div className="font-[600] text-[14px] leading-[24px] text-[#212121] mb-[14px] lg:text-[14px] lg:leading-[24px] lg:mb-[14px]">
                  {t('location')}
                </div>
                {/* TODO 根据状态进行展示 */}
                <div className="font-[400] text-[14px] leading-[22px] text-[#212121] mb-[24px] lg:tracking-[0.25px] lg:mb-[27px] lg:text-[14px] lg:leading-[22px] lg:w-[342px]">
                  {props?.doctorList?.location}
                </div>
              </div>
            </div>
          )}
          {/* TODO 跟readmore相关，需要再看一下 */}
          {/* Qualification */}
          <div className="flex">
            <div className="mr-[11px] ml-[11px] lg:mr-[10px] lg:ml-[0px] mt-[3px]">
              <img src={qualification} alt="qualification" className="object-cover w-[19px] lg:w-[19px]" />
            </div>
            <div className="flex flex-col">
              <div className="font-[600] text-[14px] leading-[24px] text-[#212121] mb-[14px] lg:text-[14px] lg:leading-[24px] lg:mb-[14px]">
                {t('DoctorListPage.Qualification')}
              </div>
              {/* TODO 根据状态进行展示 */}
              {/*  lg:tracking-[0.25px] lg:mb-[14px] lg:text-[14px] lg:leading-[22px] */}
              {qualificationData &&
                qualificationData.map((val) => (
                  <div key={val} className="flex flex-row  items-center ">
                    <div className="w-[3px] h-[3px] rounded-[100%] bg-[#212121] mr-[5px]"></div>
                    <div className="font-[400] text-[14px] leading-[22px] text-[#212121]">{val}</div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* Read More模块三 */}
        {props?.doctorList?.qualification && props?.doctorList?.qualification.length > 4 && (
          <div
            className="flex justify-center items-center mt-[21px] mb-[22px]  lg:mt-[0px] lg:mb-[0px]"
            onClick={() => {
              setArrowStatus((pre) => !pre);
            }}
            id={`patientDoctorList__${props.curPosition}-readMore-arrow`}
          >
            <div className="text-[16px] leading-[20px] font-[500] text-[#2DBDB6] lg:mr-[8px] lg:text-[14px] lg:leading-[24px] mr-[6px]">
              {arrowStatus ? 'Read Less' : 'Read More'}
            </div>
            {arrowStatus ? (
              <UpOutlined
                className="active:text-[#3c4148] active:bg-[#eaf3f3]"
                style={{
                  color: '#2DBDB6',
                  fontSize: isLg ? '14px' : '0.3902rem',
                }}
              />
            ) : (
              <DownOutlined
                className="active:text-[#3c4148] active:bg-[#eaf3f3]"
                style={{
                  color: '#2DBDB6',
                  fontSize: isLg ? '14px' : '0.3902rem',
                }}
              />
            )}
          </div>
        )}

        {/* BaseButton模块四 */}
        {!isLg ? (
          <BaseButton
            htmlType="submit"
            buttonClassName="w-full h-[50px] text-[16px] md:w-[326px] md:h-[50px] md:text-[16px]"
            value={t('Select')}
            status={'default'}
            onClick={onClick}
            id={`patientDoctorList__${props.curPosition}-select-button`}
          ></BaseButton>
        ) : (
          <BaseButton
            htmlType="submit"
            buttonClassName="w-full h-[50px] text-[16px] md:w-[187px] md:h-[37px] md:text-[14px]"
            value={t('Select')}
            status={'default'}
            onClick={onClick}
            sectionClassName="absolute lg:top-[calc(50%-4px)] lg:left-[654px]"
            id={`patientDoctorList__${props.curPosition}-select-button`}
            // sectionClassName="absolute lg:top-[380px] lg:left-[654px]"
          ></BaseButton>
        )}
      </div>
    </div>
  );
}
