import { RtcTokenBuilder, RtcRole } from 'agora-token';
import { AgoraTokenExpirationTime, AgoraPrivilegeExpirationTime } from './consts';

export default function getAgoraRtcToken(uid: string, channelName: string) {
  return RtcTokenBuilder.buildTokenWithUid(
    'fa71a5bff320451c86105ca7cd330cc5',
    '65a839872e644adcb3d442733095369c',
    channelName,
    uid,
    RtcRole.PUBLISHER,
    AgoraTokenExpirationTime,
    AgoraPrivilegeExpirationTime,
  );
}
