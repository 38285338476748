import { get, post, put } from '@utils/request';
import { PrescriptionSaveParams, PrescriptionTemplate } from './types/prescription.type';

const searchPrescriptionTemplate = (params: { searchDiagnosis: string }) => {
  return get<PrescriptionTemplate[]>('/care/prescription/template/search', params);
};

const getPrescriptionTemplate = (params: { diagnosis: string }) => {
  return get<PrescriptionTemplate[]>('/care/prescription/template', params);
};

export const createOrUpdatePrescription = (params: PrescriptionSaveParams) => {
  return put('/care/prescription', params);
};
export const getBookingInfos = (params: { bookingId: string }) => {
  return get('/care/booking/from-doctor/' + params.bookingId);
};
export const requestPayment = (params: { bookingId: string }) => {
  return post('/care/booking/medication', params);
};
export default {
  searchPrescriptionTemplate,
  createOrUpdatePrescription,
  getPrescriptionTemplate,
  getBookingInfos,
  requestPayment,
};
