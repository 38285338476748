/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import { ConsultationMethod } from '@consts/enums';
import { MEDIA_SIZE } from '@consts/consts';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import classNames from 'classnames';
import { formatBeDate } from '@utils/index';
import { useTranslation } from 'react-i18next';

type CompletedAppointmentProps = {
  value?: string | ReadonlyArray<string> | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  width?: number | string;
  placeholder?: string;
  disable?: boolean;
  helpText?: string;
  completed?: {
    doctorName?: string;
    consultType?: number;
    date?: string;
    time?: string;
    status?: number;
    meetingId?: number;
    orderMedicationId?: number;
    location?: string;
  };
  isKeepTitle?: boolean;
  isLastOne?: boolean;
  isFirstOne?: boolean;
};

export default function CompletedAppointment(props: CompletedAppointmentProps = {}) {
  const [t] = useTranslation();
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;

  return (
    <div className={classNames(props.isKeepTitle && 'mt-[28px] lg:mt-[0px]', 'w-full mb-[25px] lg:mb-[50px]')}>
      <div
        className={classNames(
          props?.isLastOne ? 'lg:mb-[144px]' : 'lg:mb-[0px]',
          'flex rounded-[10px] shadow-[0_10px_30px_rgba(171,190,209,0.2)] flex-col bg-[#FFFFFF] pt-[11px] px-[22px] lg:rounded-[10px] lg:shadow-[0_10px_30px_rgba(171,190,209,0.2)] lg:pt-[27px] lg:px-[51px] lg:w-[844px]',
        )}
        style={
          !isLg
            ? {
                paddingBottom: '0.6098rem',
              }
            : {
                paddingBottom: '34px',
              }
        }
      >
        {/* TODO 需要根据顺序，进行移除 */}
        {/* {!isLg && props?.isKeepTitle && (
          <div className="font-[600] text-[22px] leading-[36px] text-[#212121] mb-[40px]">{t('Appointments')}</div>
        )} */}
        {/* {!isLg && props?.isFirstOne && (
          <div className="font-[600] text-[16px] leading-[24px] text-[#2DBDB6] mb-[31px]">
            {t('MyAccountPage.AppointmentPart.CompletedAppointments')}
          </div>
        )} */}
        <div className="flex mb-[21px] lg:mb-[20px]">
          <div className="w-[168px] lg:w-[360px]">
            <div className="w-[142px] font-[600] text-[14px] leading-[22px] text-[#212121] lg:w-[275px] lg:text-[16px] lg:leading-[24px]">
              {t('MyAccountPage.AppointmentPart.ScheduledDoctor')}
            </div>
          </div>
          <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px]  w-[142px] lg:w-[275px]">
            {props?.completed?.doctorName}
          </div>
        </div>
        <div className="flex mb-[21px] lg:mb-[20px]">
          <div className="w-[168px] lg:w-[360px]">
            <div className="w-[142px] font-[600] text-[14px] leading-[22px] text-[#212121] lg:w-[275px] lg:text-[16px] lg:leading-[24px]">
              {t('MyAccountPage.AppointmentPart.ConsultationMethod')}
            </div>
          </div>
          <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px]">
            {props?.completed?.consultType === ConsultationMethod.Online
              ? t('OnlineConsultation')
              : t('In-ClinicVisit')}
          </div>
        </div>
        {props?.completed?.consultType === ConsultationMethod.Offline && (
          <div className="flex mb-[21px] lg:mb-[20px]">
            <div className="w-[168px] lg:w-[360px]">
              <div className="w-[142px] font-[600] text-[14px] leading-[22px] text-[#212121] lg:w-[275px] lg:text-[16px] lg:leading-[24px]">
                {t('location')}
              </div>
              <div className="w-[168px] lg:w-[360px]"></div>
            </div>
            <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px]  lg:text-[16px] lg:leading-[28px]  lg:tracking-[0.25px]">
              {props?.completed?.location}
            </div>
          </div>
        )}
        <div className="flex mb-[21px] lg:mb-[20px]">
          <div className="w-[168px] lg:w-[360px]">
            <div className="w-[142px] font-[600] text-[14px] leading-[22px] text-[#212121] lg:w-[275px] lg:text-[16px] lg:leading-[24px]">
              {t('Date')}
            </div>
          </div>
          <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px]">
            {formatBeDate(props?.completed?.date)}
          </div>
        </div>
        <div className="flex mb-[0px] lg:mb-[0px]">
          <div className="w-[168px] lg:w-[360px]">
            <div className="w-[142px] font-[600] text-[14px] leading-[22px] text-[#212121] lg:w-[275px] lg:text-[16px] lg:leading-[24px]">
              {t('Time')}
            </div>
          </div>
          <div className="font-[400] text-[14px] leading-[22px] text-[#212121] tracking-[0.25px] lg:text-[16px] lg:leading-[28px] lg:tracking-[0.25px]">
            {props?.completed?.time}
          </div>
        </div>
      </div>
    </div>
  );
}
