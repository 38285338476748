import { useContext, useState } from 'react';
import { IconAccount, IconMenu, IconShopCart } from './icons/Icons';
import LocaleSwitcher from './LocaleSwitcher';
import { HeaderContext } from './useHeaderCtx';
import HeaderH5Drawer from './HeaderH5Drawer';
import logo from '@images/INDICAIDLogo.png';

export default () => {
  const { host, hostSelf, isLogin, logoUrl } = useContext(HeaderContext);
  const [show, setShow] = useState(false);
  return (
    <header
      className="flex lg:hidden sf-header__mobile container-fluid bg-white items-center"
      data-screen="sf-header__mobile"
      data-transparent="false"
    >
      <HeaderH5Drawer show={show} setShow={setShow} />
      <div className="flex flex-1 w-1/4">
        <a
          className="flex py-3.5 sf-menu-button"
          onClick={() => {
            setShow(true);
          }}
        >
          {IconMenu}
        </a>
        <div className="flex justify-center items-center px-4">
          <LocaleSwitcher />
        </div>
      </div>
      <div className="sf-logo sf-logo--mobile px-4 w-1/2 justify-center">
        <a href={logoUrl} className="block py-2.5 logo-img relative" title="INDICAID®妥析®">
          <div className="sf-image sf-logo-default">
            <img src={logo} alt="INDICAID®妥析®" />
          </div>
        </a>
      </div>
      <div className="w-1/4 flex flex-1 items-center justify-end sf-header__mobile-right">
        <a href={`${hostSelf}/${isLogin ? 'myAccount' : 'login'}`} className="py-3.5">
          <span className="sf__tooltip-item block sf__tooltip-bottom sf__tooltip-style-2">
            <IconAccount />
          </span>
        </a>
        <a href={`${host}/cart`} className="relative py-2 sf-cart-icon px-2 whitespace-nowrap cursor-pointer cart-icon">
          <span className="sf__tooltip-item block sf__tooltip-bottom sf__tooltip-style-2">{IconShopCart}</span>
          <span className="sf-cart-count font-medium">0</span>
        </a>
      </div>
    </header>
  );
};
