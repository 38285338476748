import { DrugInfo } from '@services/types/medicationService.type';
import { Table } from 'antd';
import { useState } from 'react';

type PackageList = {
  key: string | number;
  packageName: string;
  num: number;
  durations: string;
  price: string;
  drugs: DrugInfo[];
};
const Column = Table.Column;

const expandedRowRender = (data: DrugInfo[]) => {
  return (
    <Table rowKey="id" rowClassName="bg-[#f9f9f9]" pagination={false} dataSource={data}>
      <Column title="Drug ID" dataIndex="id" key="id" />
      <Column className="sm:w-[250px]" title="Name" dataIndex="name" key="name" />
      <Column title="Dosage" dataIndex="dosage" key="dosage" />
      <Column title="Time/Day" dataIndex="times" key="times" />
      <Column title="Units" dataIndex="units" key="units" />
      <Column title="Duration (Days)" dataIndex="duration" key="duration" />
    </Table>
  );
};

export default function usePackageData() {
  const [packageList, setPackageList] = useState<PackageList[]>([]);

  return { packageList, setPackageList, expandedRowRender };
}
