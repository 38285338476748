import React, { useState } from 'react';

import back from '@images/doctorImages/back.svg';
import { useParams } from 'react-router-dom';
import { AutoComplete, Table } from 'antd';
import { PlusCircleFilled, PlusCircleOutlined, PlusOutlined, DownCircleOutlined } from '@ant-design/icons';
import { TextInputWithRef } from '@components/TextInput/TextInput';
import BaseButton from '@components/BaseButton/BaseButton';
import { useMemoizedFn } from 'ahooks';
import usePackageData from './hooks/prescriptionPackageTableData';
import classNames from 'classnames';
import { createDebounceWrapper } from '@utils/index';
import prescriptionService from '@services/prescriptionService';
import { PrescriptionTemplate } from '@services/types/prescription.type';
import { useSetRecoilState } from 'recoil';
import { PrescriptionsTemplateState } from '@states/prescriptions.state';
import { useLocalNavigate } from 'src/router/routerTools';
import { useSearchParams } from 'react-router-dom';

const Column = Table.Column<PrescriptionTemplate>;
const style = {
  container: {
    background: '#f5f7fa',
  },
};

const debounceSearchWrapper = createDebounceWrapper(500);

type PrescriptionTemplateWithAdd = {
  label: React.ReactNode;
  labelValue?: PrescriptionTemplate;
  value: string;
};

const Prescriptions: React.FC = () => {
  const navigate = useLocalNavigate();
  const bookingId = useParams().bookingId!;
  const appointmentsId = useSearchParams()[0].get('appointmentsId');

  const [optionsDiagnosis, setOptionsDiagnosis] = useState<PrescriptionTemplateWithAdd[]>([]);
  const [diagnosisText, setDiagnosisText] = useState('');
  const renderOptionsLabel = (t: string) => {
    return (
      // TODO 看一下props.appointmentsId
      <div
        onClick={() => {
          navigate(
            `/doctor/editPrescriptions/${bookingId}${
              t ? '?diagnosis=' + t + `&appointmentsId=${appointmentsId}` : `?appointmentsId=${appointmentsId}`
            }`,
          );
        }}
        className="sm:text-[14px] leading-[22px]"
      >
        <PlusCircleFilled className="text-[#0096a5]" /> <span className="font-semibold mr-[4px]">Add</span>
        {t}
      </div>
    );
  };
  const getsDiagnosisTextOptions = (newValue: string) => {
    if (newValue) {
      setOptionsDiagnosis([
        {
          label: renderOptionsLabel(newValue),
          value: newValue,
        },
      ]);
    }
    prescriptionService.searchPrescriptionTemplate({ searchDiagnosis: newValue }).then((priscriptionTemplateList) => {
      const uniqueMap = new Map();
      priscriptionTemplateList = priscriptionTemplateList.filter(
        (p) => !uniqueMap.has(p.diagnosis) && uniqueMap.set(p.diagnosis, true),
      );
      if (priscriptionTemplateList.find((p) => p.diagnosis === newValue) || newValue === '') {
        setOptionsDiagnosis(
          priscriptionTemplateList.map((pt) => ({
            label: pt.diagnosis,
            labelValue: pt,
            value: pt.diagnosis,
          })),
        );
      } else {
        setOptionsDiagnosis([
          ...priscriptionTemplateList.map((pt) => ({
            label: pt.diagnosis,
            labelValue: pt,
            value: pt.diagnosis,
          })),
          {
            label: renderOptionsLabel(newValue),
            value: newValue,
          },
        ]);
      }
    });
  };
  const handleDiagnosisTextChange = useMemoizedFn((newValue: string) => {
    setDiagnosisText(newValue);
    debounceSearchWrapper(() => getsDiagnosisTextOptions(newValue));
  });

  const { packageList, setPackageList, expandedRowRender } = usePackageData();
  const setPrescriptionsTemplate = useSetRecoilState(PrescriptionsTemplateState);

  return (
    <div
      className="w-full h-full flex  bg-opacity-[0.8] flex-col py-[0] px-[24px] sm:min-h-[calc(100vh-318px-79px)] sm:h-[1024px] sm:p-[60px_38px_35px_65px]"
      style={style.container}
    >
      <div className="flex flex-col">
        <div
          className="flex sm:mb-[24px] items-center"
          onClick={() => {
            navigate(`/doctor/appointmentsDetails?appointmentsId=${appointmentsId}`);
          }}
          id="indicaid-doctor-prescriptions__BacktoAppointmentsDetails-button"
        >
          <div>
            <img src={back} alt="back" className="object-cover sm:w-[32px] cursor-pointer" />
          </div>
          <div className="sm:ml-[22px] font-[600] text-[#52575C] sm:text-[16px] sm:leading-[20px] sm:tracking-[0.1px]">
            Back to Appointments Details
          </div>
        </div>
        <div className="font-[700] sm:text-[28px] sm:leading-[42px] text-[#25282B] sm:mb-[30px]">Prescriptions</div>
        <div>
          <p className="text-[#25282B] sm:text-[18px] sm:leading-[26px] font-semibold sm:mb-[8px]">Diagnosis </p>
          <AutoComplete
            value={diagnosisText}
            onChange={handleDiagnosisTextChange}
            onSelect={(val, prescriptionOption) => {
              if (prescriptionOption.labelValue) {
                prescriptionService.getPrescriptionTemplate({ diagnosis: val }).then((priscriptionTemplateList) => {
                  setPackageList(
                    priscriptionTemplateList.map((pt) => ({
                      key: pt.id,
                      packageName: pt.diagnosis,
                      num: pt.drugs.length,
                      durations: pt.duration,
                      price: pt.price,
                      drugs: pt.drugs,
                    })),
                  );
                });
              }
            }}
            fieldNames={{
              label: 'label',
            }}
            options={optionsDiagnosis}
            className="sm:text-[14px]"
          >
            <TextInputWithRef
              inputClassName="sm:w-[600px] sm:h-[42px]"
              placeholder="Input disgnosis"
              id="indicaid-doctor-prescriptions__InputDisgnosis-input"
            />
          </AutoComplete>
        </div>
        <div>
          <div className="flex justify-between sm:mt-[63px] sm:mb-[25px]">
            <p className="text-[#25282B] sm:text-[18px] sm:leading-[26px] font-semibold sm:mb-[8px]">
              Prescription Packages
            </p>
            <BaseButton
              onClick={() => {
                setPrescriptionsTemplate(null);
                navigate(
                  `/doctor/editPrescriptions/${bookingId}${
                    diagnosisText
                      ? '?diagnosis=' + diagnosisText + `&appointmentsId=${appointmentsId}`
                      : `?appointmentsId=${appointmentsId}`
                  }`,
                );
              }}
              id="indicaid-doctor-prescriptions__CreateNew-button"
              buttonClassName="sm:w-[193px] sm:h-[32px]"
              status="doctorDefault"
              value={
                <p>
                  <PlusCircleOutlined /> Create New
                </p>
              }
            />
          </div>
          {packageList.length > 0 && (
            <Table
              expandable={{
                expandedRowRender: (record) => expandedRowRender(record.drugs),
                expandIcon: ({ expanded, onExpand, record }) => (
                  <div className="text-[#009FAF] sm:text-[18px]">
                    <DownCircleOutlined
                      className={classNames(expanded && 'rotate-[180deg]', 'transition-transform translate-y-[3px]')}
                      onClick={(e) => onExpand(record, e)}
                      id="indicaid-doctor-prescriptions__expand-button"
                    />
                  </div>
                ),
              }}
              dataSource={packageList}
            >
              <Column title="Prescription Package" dataIndex="packageName" key="packageName" />
              <Column title="No. of Medication" dataIndex="num" key="num" />
              <Column title="Durations (Days)" dataIndex="durations" key="durations" />
              <Column title="Price (HKD)" dataIndex="price" key="price" />
              <Column
                title=""
                key="select"
                render={(_, record) => {
                  return (
                    <BaseButton
                      status="doctorClear"
                      id="indicaid-doctor-prescriptions__Select-button"
                      onClick={() => {
                        setPrescriptionsTemplate(record);
                        navigate(
                          `/doctor/editPrescriptions/${bookingId}${
                            diagnosisText
                              ? '?diagnosis=' + diagnosisText + `&appointmentsId=${appointmentsId}`
                              : `?appointmentsId=${appointmentsId}`
                          }`,
                        );
                      }}
                      buttonClassName="sm:w-[95px] sm:h-[32px]"
                      value={
                        <p>
                          <PlusOutlined /> Select
                        </p>
                      }
                    />
                  );
                }}
              />
            </Table>
          )}
        </div>
      </div>
    </div>
  );
};

export default Prescriptions;
