/* eslint-disable @typescript-eslint/indent */
// import { DatePicker, Form, Input, Select, Row, Col, Button, Space } from 'antd';
// import { DatePicker, Form, Row, Col } from 'antd';
import classNames from 'classnames';

import { useEffect, useState } from 'react';
// import { useEffect, useState, useRef } from 'react';
import { RemarkType } from '@consts/enums';
import remarkPen from '../../../../images/doctorImages/remarkPen.svg';
import rubbishBin from '../../../../images/doctorImages/rubbishBin.svg';
// import dayjs from 'dayjs';
import { formatCurDate } from '@utils/index';
import { Input, Form, message } from 'antd';
import BaseButton from '@components/BaseButton/BaseButton';
import { useMemoizedFn } from 'ahooks';
import { patch } from '@utils/request';
import dayjs from 'dayjs';
import { AppointmentDetail } from '@services/types/appointmentDetails.type';
import { handleSessionStorage } from '@utils/index';
import website from '@configs/website';

export type RemarksType = {
  remarks?: {
    text?: string;
    lastEditTime?: string;
  };
  allValue?: AppointmentDetail;
};

const { TextArea } = Input;

export default function Remarks(props: RemarksType) {
  const [remarksStatus, setRemarksStatus] = useState<keyof typeof RemarkType>(
    RemarkType[RemarkType.Edit] as keyof typeof RemarkType,
  );
  const [remarks, setRemarks] = useState<{
    text?: string;
    lastEditTime?: string;
  }>({
    text: '',
    lastEditTime: '',
  });

  const [previousText, setPreviousText] = useState<string>('');
  const [saveButtonDisable, setSaveButtonDisable] = useState<boolean>(false);

  const [form] = Form.useForm();

  const patchData = async (text: string) => {
    try {
      await patch('/care/booking/doctor-remarks', {
        bookingId: props?.allValue?.bookingId,
        remarks: text,
      });
    } catch (err: any) {
      const responseMessage = err?.response?.data?.message;
      const errorMessage = (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || 'error!';
      message.error(errorMessage);
    }
  };

  const onClickRubbishBin = () => {
    const curLastEditTime = formatCurDate();
    setRemarks({
      text: '',
      lastEditTime: curLastEditTime,
    });
    // 向后台发送post请求=>清空数据
    patchData('');
    setRemarksStatus(RemarkType[RemarkType.Edit] as keyof typeof RemarkType);
    handleSessionStorage.set(website.doctor_remark_status, RemarkType[RemarkType.Edit] as keyof typeof RemarkType);
  };

  const onClickPen = () => {
    setRemarksStatus(RemarkType[RemarkType.Edit] as keyof typeof RemarkType);
    handleSessionStorage.set(website.doctor_remark_status, RemarkType[RemarkType.Edit] as keyof typeof RemarkType);
    // 只有pen的时候，需要考虑按钮置灰的问题
    setPreviousText(remarks?.text || '');
  };

  const onClickClear: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement> =
    useMemoizedFn(async (e) => {
      e.preventDefault();
      const curLastEditTime = formatCurDate();
      setRemarks({
        text: '',
        lastEditTime: curLastEditTime,
      });
      form.setFieldsValue({ textarea: '' });
    });

  const onClickSave: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement> =
    useMemoizedFn(async (e) => {
      e.preventDefault();
      const curLastEditTime = formatCurDate();
      setRemarks({
        ...remarks,
        lastEditTime: curLastEditTime,
      });

      const notChangeStatus = remarks.text === '' || !remarks.text || (remarks.text && remarks.text.length === 0);

      if (!notChangeStatus) {
        setRemarksStatus(RemarkType[RemarkType.Lock] as keyof typeof RemarkType);
        handleSessionStorage.set(website.doctor_remark_status, RemarkType[RemarkType.Lock] as keyof typeof RemarkType);
      } else {
        // 为空的时候，需要把按钮置灰
        setPreviousText(remarks?.text || '');
      }
      // 向后端发送请求,更新数据
      patchData(remarks?.text || '');
    });

  // 设置初始值
  useEffect(() => {
    const editTime =
      (props?.allValue?.remarks?.updatedAt &&
        dayjs(props?.allValue?.remarks?.updatedAt).format('DD MMM YYYY hh:mma')) ||
      undefined;
    setRemarks(
      props?.allValue?.remarks
        ? {
            text: props?.allValue?.remarks?.text,
            lastEditTime: editTime,
          }
        : {
            text: '',
            lastEditTime: '',
          },
    );
  }, [props?.allValue?.remarks]);

  useEffect(() => {
    setRemarksStatus(
      handleSessionStorage.get(website.doctor_remark_status) ||
        (RemarkType[RemarkType.Edit] as keyof typeof RemarkType),
    );
  }, []);

  useEffect(() => {
    form.setFieldsValue({ textarea: remarks?.text });
  }, [remarks?.text, form]);

  useEffect(() => {
    setPreviousText(props?.allValue?.remarks?.text || '');
  }, [props?.allValue?.remarks?.text]);

  // 值相等时，按钮需要置灰
  useEffect(() => {
    if (previousText === remarks?.text) {
      setSaveButtonDisable(true);
    } else {
      setSaveButtonDisable(false);
    }
  }, [remarks?.text, previousText]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRemarks({
      ...remarks,
      text: e.target.value,
    });
  };

  const renderContent = () => {
    if (remarksStatus === RemarkType[RemarkType.Edit]) {
      return (
        <div className="flex flex-col">
          <Form form={form}>
            <Form.Item name="textarea">
              <TextArea
                data-clickable
                id="[indicaid][/doctor/appointmentsDetails][Tab][Remarks][TextArea]"
                placeholder="Type here"
                onChange={onChange}
                defaultValue={remarks?.text}
                autoSize={{ minRows: 10, maxRows: 12 }}
                style={{
                  // 修改样式
                  borderColor: '#D5D4DC',
                  fontFamily: 'Inter',
                  color: '#52575C',
                  fontWeight: '400',
                  fontSize: '16px',
                  lineHeight: '28px',
                }}
                allowClear
              />
            </Form.Item>
          </Form>

          <div className="lg:mt-[75px] lg:mb-[20px] w-full lg:h-[1px] bg-[rgba(0,0,0,0.1)]"></div>
          <div className="flex justify-end">
            <BaseButton
              htmlType="submit"
              buttonClassName="md:w-[134px] md:h-[32px] md:text-[14px] lg:leading-[22px] font-[500]"
              style={{
                paddingLeft: '25px',
                paddingRight: '25px',
                paddingTop: '4px',
                paddingBottom: '4px',
              }}
              value={'Save'}
              status={!saveButtonDisable ? 'doctorDefault' : 'disabled'}
              disable={saveButtonDisable}
              onClick={onClickSave}
              data-clickable
              id="[indicaid][/doctor/appointmentsDetails][Tab][Remarks][Save]"
              sectionClassName="lg:mr-[15px]"
            ></BaseButton>
            <BaseButton
              htmlType="submit"
              buttonClassName="md:w-[134px] md:h-[32px] md:text-[14px] lg:leading-[22px] font-[500]"
              style={{
                paddingLeft: '25px',
                paddingRight: '25px',
                paddingTop: '4px',
                paddingBottom: '4px',
              }}
              value={'Clear All'}
              status={remarks?.text && remarks?.text?.length > 0 ? 'doctorClear' : 'doctorClearDisabled'}
              disable={!(remarks?.text && remarks?.text?.length > 0)}
              data-clickable
              id="[indicaid][/doctor/appointmentsDetails][Tab][Remarks][Clear]"
              onClick={onClickClear}
            ></BaseButton>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex justify-between">
          <div className="flex flex-col lg:mr-[105px]">
            <div className="lg:w-[752px] font-[500] lg:text-[16px] lg:leading-[28px] text-[#52575C] whitespace-pre-line break-words">
              {remarks?.text}
            </div>
            <div className="font-[600] lg:text-[14px] lg:leading-[18px] text-[rgba(0,0,0,0.4)] lg:mt-[25px]">{`Last edit ${remarks?.lastEditTime}`}</div>
          </div>
          <div className="flex">
            <div className="lg:mr-[21px] lg:w-[16px]">
              <img
                src={remarkPen}
                alt="remarkPen"
                className="object-cover lg:w-[16px] cursor-pointer"
                data-clickable
                id="[indicaid][/doctor/appointmentsDetails][Tab][Remarks][Edit]"
                onClick={onClickPen}
              />
            </div>
            <div className="lg:w-[18px]">
              <img
                src={rubbishBin}
                alt="rubbishBin"
                className="object-cover lg:w-[18px]  cursor-pointer"
                data-clickable
                id="[indicaid][/doctor/appointmentsDetails][Tab][Remarks][Delete]"
                onClick={onClickRubbishBin}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div
      className={classNames(
        remarksStatus === RemarkType[RemarkType.Edit] ? 'lg:p-[36px_30px_21px_38px]' : 'lg:p-[36px_30px_50px_38px]',
        'bg-[#FFFFFF] lg:shadow-[0_12px_26px_rgba(16,30,115,0.06)] lg:rounded-[0px_8px_8px_8px]',
      )}
    >
      {renderContent()}
    </div>
  );
}
