// import { CloseOutlined } from '@ant-design/icons';
import { renderComponent, RenderComponentOptions } from './globalComponents';

type ResultAlertInfoProps = {
  content: string | React.ReactNode;
};
export function ResultAlertInfo(props: ResultAlertInfoProps & { closeFunction: () => void }) {
  return (
    <>
      <div
        onClick={props.closeFunction}
        className="w-[100vw] max-h-[80vh] fixed top-[73px] left-0 z-[10000] flex justify-center items-center lg:top-[108px]"
      >
        {props.content}
        {/* <div className="w-[88.4%] rounded-[10px] bg-[#F2F7F9] p-[28px_27px_50px_28px] lg:w-[1204px]  lg:rounded-[30px] lg:p-[70px_0px_0px_0px] max-h-[80vh] overflow-scroll">
          {props.content}
        </div> */}
      </div>
      <div className="w-[100vw] h-[100vh] fixed top-0 left-0 z-[100] bg-[rgba(0,0,0,0.5)]"></div>
    </>
  );
}

export default function alertInfo(config: RenderComponentOptions<ResultAlertInfoProps>) {
  return renderComponent(config, ResultAlertInfo);
}
