import { Spin } from 'antd';
import { useThirdyPartyLogin } from './hooks/thirdPardyLogin';
import { Loading3QuartersOutlined } from '@ant-design/icons';

export default function AuthLoading() {
  const { isLoading } = useThirdyPartyLogin();
  return (
    <Spin spinning={isLoading} indicator={<Loading3QuartersOutlined spin style={{ fontSize: 24 }} />}>
      <div className="w-[100vw] h-[100vh]">loading...</div>
    </Spin>
  );
}
