import { createBrowserRouter, Outlet, RouteObject } from 'react-router-dom';
import App from 'src/App';
import Login from '@pages/Login/Login';
import Verify from '@pages/Login/Verify';
import HealthScreen from '@pages/HealthScreen/HealthScreen';
import SelectResult from '@pages/SelectResult/SelectResult';
import ProductList from '@pages/ProductList/ProductList';
import Payments from '@pages/Payments/Payments';
import PaymentStatus from '@pages/Payments/PaymentStatus';
import PaymentFailure from '@pages/Payments/PaymentFailure';
import TestMedicinePayments from '@pages/Payments/TestMedicinePayments';

import SelectTimeSlot from '@pages/SelectTimeSlot/SelectTimeSlot';
import AboutUs from '@pages/AboutUs/AboutUs';
import MyAccount from '@pages/MyAccount/MyAccount';
// 医生的页面
import DoctorLogin from '@pages/Doctor/Login/Login';
import DoctorAppointments from '@pages/Doctor/Appointments/Appointments';
import DoctorAppointmentsDetails from '@pages/Doctor/Appointments/AppointmentsDetails';
import FillPersonalInfos from '@pages/FillPersonalInfos/FillPersonalInfos';
import Meeting from '@pages/meeting/meeting';
import TermsAndCondition from '@pages/TermsAndCondition/TermsAndCondition';
import MeetingError from '@pages/meeting/meetingError';
import MeetingEndOrExpired from '@pages/meeting/meetingEndOrExpired';
import DoctorList from '@pages/DoctorList/DoctorList';
import DoctorApp from 'src/DoctorApp';
import LandingPage from '@pages/LandingPage/LandingPage';
import Prescriptions from '@pages/Doctor/Appointments/subpages/PrescriptionsPage';
import MedicationManager from '@pages/Doctor/Appointments/subpages/MedicationManager';
import AddNewDrug from '@pages/Doctor/Appointments/subpages/AddNewDrug';
import EditPrescriptions from '@pages/Doctor/Appointments/subpages/EditPrescriptionsPage';
import { useEffect } from 'react';
import i18n from '@utils/i18n';
import { getLocale, LanguageContext } from './routerTools';
import PrivacyPolicy from '@pages/TermsAndCondition/PrivacyPolicy';
import Covid19SelectResult from '@pages/SelectResult/Covid19SelectResult';
import Covid19AndFluSelectResult from '@pages/SelectResult/Covid19AndFluSelectResult';
import AuthLoading from '@pages/Login/AuthLoading';

const AllRoutes: RouteObject[] = [
  {
    path: '',
    element: <LandingPage />,
  },
  {
    element: <App />,
    errorElement: <div>error....</div>,
    children: [
      {
        path: 'login',
        element: <Login />,
        errorElement: <div>error....</div>,
      },
      {
        path: 'verify',
        element: <Verify />,
        errorElement: <div>login error....</div>,
      },
      {
        path: 'healthScreen',
        element: <HealthScreen />,
      },
      {
        path: 'payments',
        element: <Payments />,
        errorElement: <div>login error....</div>,
      },
      {
        path: 'paymentStatus',
        element: <PaymentStatus />,
        errorElement: <div>login error....</div>,
      },
      {
        path: 'paymentFailure',
        element: <PaymentFailure />,
        errorElement: <div>login error....</div>,
      },
      {
        path: 'product-listing',
        element: <ProductList />,
        errorElement: <div>login error....</div>,
      },
      {
        path: 'selectResult',
        element: <SelectResult />,
        errorElement: <div>login error....</div>,
      },
      {
        path: 'selectResult-covid',
        element: <Covid19SelectResult />,
        errorElement: <div>login error....</div>,
      },
      {
        path: 'selectResult-covidflu',
        element: <Covid19AndFluSelectResult />,
        errorElement: <div>login error....</div>,
      },
      {
        path: 'selectTimeSlot',
        element: <SelectTimeSlot />,
      },
      {
        path: 'contactUs',
        element: <AboutUs />,
      },
      {
        path: 'myAccount',
        element: <MyAccount />,
        errorElement: <div>login error....</div>,
      },
      {
        path: 'fillInfos',
        element: <FillPersonalInfos />,
        errorElement: <div>login error....</div>,
      },
      {
        path: 'patientDoctorList',
        element: <DoctorList />,
        errorElement: <div>login error....</div>,
      },
      {
        path: 'terms-of-use',
        element: <TermsAndCondition />,
        errorElement: <div>login error....</div>,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />,
        errorElement: <div>login error....</div>,
      },
      {
        path: 'testMedicinePayments',
        element: <TestMedicinePayments />,
        errorElement: <div>login error....</div>,
      },
    ],
  },
  {
    path: 'doctor',
    element: <DoctorApp />,
    children: [
      {
        path: '',
        element: <DoctorAppointments />,
      },
      {
        path: 'appointments',
        element: <DoctorAppointments />,
      },
      {
        path: 'appointmentsDetails',
        element: <DoctorAppointmentsDetails />,
      },

      {
        path: 'prescriptions/:bookingId',
        element: <Prescriptions />,
      },
      {
        path: 'editPrescriptions/:bookingId',
        element: <EditPrescriptions />,
      },
      {
        path: 'medicationManager',
        element: <MedicationManager />,
      },

      {
        path: 'addNewDrug',
        element: <AddNewDrug />,
      },
    ],
  },
  // doctor页面没有侧边栏，需要手动引入DoctorApp.css
  {
    path: 'doctor/login',
    element: <DoctorLogin />,
  },
  {
    path: 'meeting/exception',
    element: <MeetingEndOrExpired />,
  },
  {
    path: 'meeting/error',
    element: <MeetingError />,
  },
  {
    path: 'meeting/:meetingId',
    element: <Meeting />,
  },

  {
    path: 'authLoading',
    element: <AuthLoading />,
  },
];

const EnLanguageComponentWrapper = () => {
  useEffect(() => {
    i18n.changeLanguage('en');
  }, []);
  return (
    <>
      <Outlet />
    </>
  );
};
const ZhHKLanguageComponentWrapper = () => {
  useEffect(() => {
    i18n.changeLanguage('zh-HK');
  }, []);
  return (
    <>
      <Outlet />
    </>
  );
};
const router = createBrowserRouter([
  {
    path: '/',
    children: AllRoutes,
    element: (
      <LanguageContext.Provider value={getLocale().locale}>
        <Outlet />
      </LanguageContext.Provider>
    ),
  },
  {
    path: '/zh-HK',
    children: AllRoutes,
    element: (
      <LanguageContext.Provider value="zh-HK">
        <ZhHKLanguageComponentWrapper />
      </LanguageContext.Provider>
    ),
  },
  {
    path: '/en',
    children: AllRoutes,
    element: (
      <LanguageContext.Provider value="en">
        <EnLanguageComponentWrapper />
      </LanguageContext.Provider>
    ),
  },
]);

export default router;
