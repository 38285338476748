import { useEffect, useRef } from 'react';
import RtcAdapter from '../RtcTool';
import { useMemoizedFn } from 'ahooks';
type RemoteJoinMeetingCb = (remoteUid: string, remoteUidArr: string[]) => void;
export default function useJoinMeeting(cb: RemoteJoinMeetingCb) {
  const remoteUserRtcIdSet = useRef(new Set<string>());
  const memoCb = useMemoizedFn(cb);
  useEffect(() => {
    return RtcAdapter.on('user-joined', (remoteUid) => {
      remoteUserRtcIdSet.current.add(remoteUid);
      memoCb(remoteUid, Array.from(remoteUserRtcIdSet.current));
    });
  }, [memoCb]);
}
