import {
  CheckCircleFilled,
  CloseCircleFilled,
  CloseOutlined,
  InfoCircleFilled,
  PlusCircleOutlined,
} from '@ant-design/icons';
import BaseButton from '@components/BaseButton/BaseButton';
import prescriptionService from '@services/prescriptionService';
import { PrescriptionStatus, PrescriptionsInDetail } from '@services/types/appointmentDetails.type';
import { PrescriptionsTemplateState } from '@states/prescriptions.state';
import { globalAlert } from '@utils/globalComponents/GlobalAlert';
import notification from 'antd/es/notification';
import { useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { useLocalNavigate } from 'src/router/routerTools';
type ConfirmRequestPaymentProps = {
  closeFunction: () => void;
  drugs: { id: string; name: string; dosage: string; price: string; dosageUnit: number }[];
  total: string;
  onOk: () => Promise<void>;
};

const renderDrugsRowInPaymentConfirm = (drug: {
  id: string;
  name: string;
  dosage: string;
  price: string;
  dosageUnit: number;
}) => {
  return (
    <div key={drug.id} className="flex justify-between sm:text-[14px] sm:mb-[15px] text-[#52575C]">
      <p className="sm:w-[265px] block overflow-hidden whitespace-nowrap text-ellipsis">{drug.name}</p>
      <p className="sm:w-[178px]">{drug.dosageUnit === 0 ? `${drug.dosage} mg` : `${drug.dosage} ml`}</p>
      <p className="sm:w-[50px]">{drug.price}</p>
    </div>
  );
};
function ConfirmRequestPayment(props: ConfirmRequestPaymentProps) {
  const [loading, setLoading] = useState(false);
  return (
    <div className="font-[Inter] sm:w-[496px] sm:max-h-[491px] bg-[#ffffff] sm:rounded-[8px] sm:py-[30px] sm:px-[45px]">
      <div className="flex justify-between">
        <p className="text-[#25282B] font-semibold sm:text-[18px] sm:leading-[22px]">Request Payment Summary</p>
        <CloseOutlined
          className="cursor-pointer text-[14px] text-[#8F989C]"
          onClick={props.closeFunction}
          id="indicaid-doctor-appointmentsDetails__close-RequestPaymentSummary"
        />
      </div>
      <div className="text-[#009FAF] flex sm:w-[410px] sm:h-[72px] sm:mt-[23px] sm:px-[11px] sm:pt-[14px] bg-[#F5FBFA] sm:rounded-[4px]">
        <div className="sm:mr-[15px]">
          <InfoCircleFilled className="sm:text-[18px]" />
        </div>
        <div>
          <p className="sm:text-[12px] sm:leading-[15px]">
            Reminder: Below items show the full prescription while the price indicates the additional cost to patient
            for top-up medication that is not part of the 3 days basic prescription.
          </p>
        </div>
      </div>
      <div className="sm:text-[14px] sm:leading-[22px] sm:mt-[16px]">
        <div className="flex justify-between font-bold font-[Lato] sm:mb-[14px]">
          <p className="sm:w-[265px]">Name</p>
          <p className="sm:w-[178px]">Dosage</p>
          <p className="sm:w-[50px]">Price</p>
        </div>
      </div>
      <div className="overflow-y-scroll sm:max-h-[184px] custom-scroll-bar custom-scroll-bar-bg_width-4 custom-scroll-bar-bg_white">
        {props.drugs.map((drug) => renderDrugsRowInPaymentConfirm(drug))}
      </div>
      <hr />
      <div className="flex justify-between font-semibold sm:mt-[15px] text-[#25282B] sm:text-[16px] sm:leading-[22px]">
        <p>Total</p>
        <p>{props.total}HKD</p>
      </div>
      <BaseButton
        loading={loading}
        onClick={async () => {
          setLoading(true);
          await props.onOk();
          props.closeFunction();
          setLoading(false);
        }}
        status="doctorDefault"
        value="Send to Patient"
        buttonClassName="sm:mt-[15px] sm:w-[410px] sm:h-[40px] sm:rounded-[4px] sm:text-[16px] sm:leading-[22px]"
        id="indicaid-doctor-appointmentsDetails__SendToPatient-button"
      />
    </div>
  );
}

const ReminderTextMap: {
  [p: number]: {
    bgColor: string;
    icon: React.ReactNode;
    color: string;
    text: string;
  };
} = {
  [PrescriptionStatus.WaitingForPayment]: {
    bgColor: '#FFFAF1',
    icon: (
      <InfoCircleFilled
        style={{
          color: '#FFB020',
        }}
        className="sm:text-[18px] sm:mr-[14px]"
      />
    ),
    color: '#996A13',
    text: 'Requested: Payment request sent to patient, pending patient’s action.',
  },
  [PrescriptionStatus.Expired]: {
    bgColor: '#F2F2F2',
    icon: (
      <CloseCircleFilled
        style={{
          color: '#52575C',
        }}
        className="sm:text-[18px] sm:mr-[14px]"
      />
    ),
    color: '#25282B',
    text: 'Expired: Patient has decided not to proceed with the top up medication. Please arrange delivery for basic medication if patient has paid for all-in-one package.',
  },
  [PrescriptionStatus.PaySuccess]: {
    bgColor: '#F5FBFA',
    icon: (
      <CheckCircleFilled
        style={{
          color: '#009FAF',
        }}
        className="sm:text-[18px] sm:mr-[14px]"
      />
    ),
    color: '#009FAF',
    text: 'Payment Completed: Please refer to [Shipment Details] for patient’s logistics information.',
  },
};

export function PrescriptionReminderText(props: { status: PrescriptionStatus }) {
  return (
    <div
      className="flex sm:min-h-[48px] w-fit sm:tracking-[.55px] sm:rounded-[8px] sm:px-[14px] sm:mt-[14px] sm:py-[12px] items-start"
      style={{
        backgroundColor: ReminderTextMap[props.status].bgColor,
        color: ReminderTextMap[props.status].color,
      }}
    >
      {ReminderTextMap[props.status].icon}
      <p>{ReminderTextMap[props.status].text}</p>
    </div>
  );
}

export type PrescriptionsType = {
  prescription?: PrescriptionsInDetail;
  bookingId: string;
  appointmentsId?: string | null;
  getAllData: () => void;
};

type DrugTypeInDetail = {
  id: string;
  drugId: string;
  times: number;
  units: string;
  duration: string;
  price: string;
  drug: { id: string; name: string; dosage: string; dosageUnit: number };
};
const renderDrugsRow = (drug: DrugTypeInDetail) => {
  return (
    <div key={drug.id} className="flex justify-between sm:my-[7px] text-[#52575C]">
      <p className="sm:w-[92px]">{drug.id}</p>
      <p className="sm:w-[265px] block overflow-hidden whitespace-nowrap text-ellipsis">{drug.drug.name}</p>
      <p className="sm:w-[108px]">{drug.drug.dosageUnit === 0 ? `${drug.drug.dosage} mg` : `${drug.drug.dosage} ml`}</p>
      <p className="sm:w-[101px]">{drug.times}</p>
      <p className="sm:w-[88px]">{drug.units}</p>
      <p className="sm:w-[136px]">{drug.duration}</p>
    </div>
  );
};
export default function Prescriptions(props: PrescriptionsType) {
  const navigate = useLocalNavigate();
  const setPrescriptionsTemplate = useSetRecoilState(PrescriptionsTemplateState);

  const [notificationApi, contextHolder] = notification.useNotification();

  const textReminder = useMemo(() => {
    if (
      !props.prescription?.status ||
      props.prescription?.status === PrescriptionStatus.Created ||
      props.prescription?.status === PrescriptionStatus.Arrived
    ) {
      return null;
    }

    return <PrescriptionReminderText status={props.prescription.status} />;
  }, [props.prescription?.status]);

  return (
    <div className="sm:p-[32px_25px_22px_25px] sm:min-w-[334px] sm:min-h-[346px] bg-[#FFFFFF] sm:shadow-[0_12px_26px_rgba(16,30,115,0.06)] sm:rounded-[0px_8px_8px_8px]">
      {contextHolder}
      {props.prescription ? (
        <>
          <div className="sm:min-h-[230px]">
            <div className="sm:text-[16px] text-[#25282B] sm:leading-[22px] font-semibold flex justify-between">
              <p>
                {props.prescription.diagnosis} | {props.prescription.duration} Days
              </p>
              <p>{+props.prescription.price} HKD</p>
            </div>
            <p className="sm:text-[14px] sm:mt-[14px] sm:leading-[22px] font-medium">Include</p>
            <hr className="bg-[#00000010]" />
            <div className="sm:text-[14px] sm:leading-[30px] sm:tracking-[0.1px] text-[#25282B]">
              <div className="flex justify-between font-bold">
                <p className="sm:w-[92px]">Drug ID</p>
                <p className="sm:w-[265px]">Name</p>
                <p className="sm:w-[108px]">Dosage</p>
                <p className="sm:w-[101px]">Time/Day</p>
                <p className="sm:w-[88px]">Units</p>
                <p className="sm:w-[136px]">Duration (Days)</p>
              </div>
              {props.prescription.prescriptionDrugs.map((d) => renderDrugsRow(d))}
            </div>
          </div>
          <hr className="bg-[#00000010]" />
          {textReminder}
          <div className="flex justify-between sm:mt-[22px]">
            {props.prescription.status === PrescriptionStatus.Created && (
              <p
                className="text-[#009FAF] underline sm:text-[14px] sm:leading-[22px] cursor-pointer"
                onClick={() => {
                  if (!props.prescription) {
                    return;
                  }
                  setPrescriptionsTemplate({
                    id: props.prescription.id,
                    diagnosis: props.prescription.diagnosis,
                    drugs: props.prescription.prescriptionDrugs.map((pd) => ({
                      id: pd.drug.id,
                      name: pd.drug.name,
                      dosage: pd.drug.dosage,
                      dosageUnit: pd.drug.dosageUnit,
                      times: pd.times,
                      units: pd.units,
                      duration: pd.duration,
                      price: pd.price,
                    })),
                    duration: props.prescription.duration,
                    price: props.prescription.price,
                    remarks: props.prescription.remarks,
                  });
                  navigate(
                    `/doctor/editPrescriptions/${props.bookingId}?diagnosis=${props.prescription.diagnosis}&appointmentsId=${props.appointmentsId}`,
                  );
                }}
                id="indicaid-doctor-appointmentsDetails__EditPrescription-button"
              >
                Edit Prescription
              </p>
            )}
            <div>
              {props.prescription.status === PrescriptionStatus.Created && +props.prescription.price && (
                <BaseButton
                  id="indicaid-doctor-appointmentsDetails__alertConfirmRequestPayment-popup"
                  onClick={() => {
                    if (!props.prescription) {
                      return;
                    }
                    globalAlert((close) => ({
                      content: (
                        <ConfirmRequestPayment
                          total={props.prescription!.price}
                          drugs={props.prescription!.prescriptionDrugs.map((pd) => ({
                            id: pd.drug.id,
                            name: pd.drug.name,
                            dosage: pd.drug.dosage,
                            dosageUnit: pd.drug.dosageUnit,
                            price: pd.price,
                          }))}
                          closeFunction={close}
                          onOk={async () => {
                            return prescriptionService
                              .requestPayment({ bookingId: props.bookingId })
                              .then(() => {
                                notificationApi.success({
                                  icon: <CheckCircleFilled className="text-[#009FAF]" />,
                                  message: <span className="font-semibold">Payment Request Successful</span>,
                                  description:
                                    'Patient has been notified of this additional payment. You will be notified via email once accepted.',
                                  placement: 'bottomRight',
                                  duration: 10000,
                                });
                                props.getAllData();
                              })
                              .catch(() => {
                                notificationApi.error({
                                  message: 'Payment Request failed',
                                  placement: 'bottomRight',
                                  duration: 10000,
                                });
                              });
                          }}
                        />
                      ),
                      wraperBackground: 'rgba(0, 0, 0, 0.4)',
                    }));
                  }}
                  status="doctorDefault"
                  buttonClassName="sm:w-[164px] sm:h-[32px]"
                  value="Request Payment"
                />
              )}
              {/* <BaseButton status="doctorClear" buttonClassName="sm:w-[129px] sm:h-[32px]" value="Clear" /> */}
            </div>
          </div>
        </>
      ) : (
        <div className="flex w-full h-full items-center justify-center">
          <BaseButton
            status="doctorDefault"
            onClick={() => {
              navigate('/doctor/prescriptions/' + props.bookingId + `?appointmentsId=${props.appointmentsId}`);
            }}
            buttonClassName="sm:w-[193px] sm:h-[32px]"
            value={
              <p>
                <PlusCircleOutlined /> Add Prescriptions
              </p>
            }
            id="indicaid-doctor-appointmentsDetails__addPrescriptions-button"
          />
        </div>
      )}
    </div>
  );
}
