import { useMemoizedFn } from 'ahooks';
import { createContext, useContext } from 'react';
import { useNavigate, To, NavigateOptions } from 'react-router-dom';
import { isDoctorPortal2 } from '@utils/index';
export const supportLocale = ['zh-HK', 'en'] as const;
export const defaultLanguage = !isDoctorPortal2() ? supportLocale[0] : supportLocale[1];
export const hkLanguage = supportLocale[0];
export const LanguageContext = createContext<(typeof supportLocale)[number]>(!isDoctorPortal2() ? 'zh-HK' : 'en');

export function getLocale() {
  let locale: (typeof supportLocale)[number] = defaultLanguage;
  const hasPath = supportLocale.some((v) => {
    if (location.pathname.toLowerCase().startsWith('/' + v.toLowerCase())) {
      locale = v;
      return true;
    }
  });
  // 医生的meeting页面强制语言为en
  if (location.search.includes('?_d=1')) {
    locale = 'en';
  }
  return { locale, hasPath };
}
export function useLocalNavigate() {
  const navigate = useNavigate();
  const language = useContext(LanguageContext);
  return useMemoizedFn((to: To, options?: NavigateOptions) => {
    if ((typeof to === 'string' && !to.startsWith('/')) || (typeof to !== 'string' && !to.pathname?.startsWith('/'))) {
      throw new Error('please use absolute path');
    }
    if (language === 'en') {
      if (typeof to === 'string' && !to.startsWith('/en')) {
        navigate('/en' + to, options);
        return;
      } else if (typeof to !== 'string' && !to.pathname?.startsWith('/en')) {
        navigate(
          {
            ...to,
            pathname: '/en' + to.pathname,
          },
          options,
        );
        return;
      }
    }
    navigate(to, options);
  });
}
