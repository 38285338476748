import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

export default function MedicalIcon(props: Partial<CustomIconComponentProps> & { scale?: number; color?: string }) {
  const MedicalIconSvg = () => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="medical1">
        <path
          id="Vector"
          d="M14.42 13.3256L6.60748 10.2006L7.76811 7.29932L15 10.1918L22.2325 7.29932L23.3925 10.2006L15.58 13.3256C15.2075 13.4746 14.7919 13.4746 14.4194 13.3256H14.42Z"
          fill="white"
        />
        <path
          id="Vector_2"
          d="M13.8819 2.76373C14.229 2.59013 14.6119 2.49976 15 2.49976C15.3881 2.49976 15.771 2.59013 16.1181 2.76373L24.8681 7.13873C25.2834 7.34639 25.6326 7.66559 25.8767 8.06057C26.1208 8.45554 26.25 8.91068 26.25 9.37498V12.5837C26.25 15.8187 25.2497 18.9745 23.3862 21.6187C21.5227 24.263 18.8871 26.2664 15.8406 27.3544C15.297 27.5484 14.703 27.5484 14.1594 27.3544C11.1129 26.2664 8.47733 24.263 6.61382 21.6187C4.75031 18.9745 3.75002 15.8187 3.75 12.5837V9.37498C3.74996 8.91068 3.87922 8.45554 4.12329 8.06057C4.36736 7.66559 4.7166 7.34639 5.13188 7.13873L13.8819 2.76373ZM23.75 9.37498V12.5837C23.75 15.303 22.9092 17.9558 21.3427 20.1786C19.7763 22.4013 17.5609 24.0854 15 25C12.4391 24.0854 10.2237 22.4013 8.65727 20.1786C7.09083 17.9558 6.25001 15.303 6.25 12.5837V9.37498L15 4.99998L23.75 9.37498Z"
          fill="#2DBDB6"
        />
        <path
          id="Vector_3"
          d="M6.25 9.375L15 5L23.75 9.375V12.5837C23.75 15.303 22.9092 17.9558 21.3427 20.1786C19.7763 22.4014 17.5609 24.0854 15 25C12.4391 24.0854 10.2237 22.4014 8.65727 20.1786C7.09083 17.9558 6.25001 15.303 6.25 12.5837V9.375Z"
          fill="#2DBDB6"
        />
        <path
          id="Vector_4"
          d="M10 14.1165C10 14.006 10.0895 13.9165 10.2 13.9165H19.8C19.9105 13.9165 20 14.006 20 14.1165V15.8832C20 15.9936 19.9105 16.0832 19.8 16.0832H10.2C10.0895 16.0832 10 15.9936 10 15.8832V14.1165Z"
          fill="white"
        />
        <path
          id="Vector_5"
          d="M15.8828 10C15.9932 10 16.0828 10.0895 16.0828 10.2V19.8C16.0828 19.9105 15.9932 20 15.8828 20H14.1173C14.0068 20 13.9173 19.9105 13.9173 19.8V10.2C13.9173 10.0895 14.0068 10 14.1173 10H15.8828Z"
          fill="white"
        />
      </g>
    </svg>
  );
  return (
    <Icon
      style={{
        transform: `scale(${props.scale ?? 1})`,
      }}
      component={MedicalIconSvg}
      {...props}
    />
  );
}
