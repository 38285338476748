export default function TermsTitle(props: { children: React.ReactNode }) {
  return (
    <p
      className="
    text-[#2DBDB6] lg:my-[60px] lg:text-[40px] lg:leading-[58px] font-semibold text-center
      text-[22px] leading-[36px] my-[24px]
    "
    >
      {props.children}
    </p>
  );
}
