import thirdPartyService from '@services/thirdPartyService';
import userService from '@services/userService';
import { patientTokenStore } from '@utils/localStore/tokenStore';
import { useMemoizedFn } from 'ahooks';
import { message } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLocalNavigate } from 'src/router/routerTools';

export function useThirdyLoginParams() {
  const code = useSearchParams()[0].get('code');
  const state = useSearchParams()[0].get('state');
  return {
    code,
    state,
  };
}

const setUserInfo = async (accessToken: string) => {
  patientTokenStore.set(accessToken);

  await userService.getUseInfos({ redirect: false });
};

export function useThirdyPartyLoginFuncs() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useLocalNavigate();
  const [messageApi, messageContextHolder] = message.useMessage();

  const processChildMeeeage = useMemoizedFn((w: Window) => {
    const timer = setInterval(() => {
      if (w.closed) {
        clearInterval(timer);
        setIsLoading(false);
      }
    }, 1000);
    window.addEventListener('message', async (evt) => {
      console.log('message', evt);
      const access_token = evt.data?.access_token;
      if (!access_token) {
        return;
      }
      setIsLoading(false);
      if (access_token === 'failed') {
        messageApi.error('Login failed, please try again!');
        w.close();
        return;
      }
      await setUserInfo(access_token);
      navigate('/product-listing');
      w.close();
      clearInterval(timer);
    });
  });

  const childWindowRef = useRef<Window | null>(null);

  const handleFacebookLoginClick = useMemoizedFn(async () => {
    setIsLoading(true);
    const { url } = await thirdPartyService.getAuthLoginUrl('facebook');
    childWindowRef.current = window.open(
      url,
      'auth login',
      'height=600, width=600, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no',
    );
    childWindowRef.current && processChildMeeeage(childWindowRef.current);
  });

  const handleGoogleLoginClick = useMemoizedFn(async () => {
    setIsLoading(true);
    const { url } = await thirdPartyService.getAuthLoginUrl('google');
    childWindowRef.current = window.open(
      url,
      'auth login',
      'height=600, width=600, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no',
    );
    childWindowRef.current && processChildMeeeage(childWindowRef.current);
  });
  const handleLinkedInLoginClick = useMemoizedFn(async () => {
    setIsLoading(true);
    const { url } = await thirdPartyService.getAuthLoginUrl('linkedin');
    childWindowRef.current = window.open(
      url,
      'auth login',
      'height=600, width=600, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no',
    );
    childWindowRef.current && processChildMeeeage(childWindowRef.current);
  });

  return {
    isLoading,
    handleLinkedInLoginClick,
    handleGoogleLoginClick,
    messageContextHolder,
    handleFacebookLoginClick,
  };
}

export function useThirdyPartyLogin() {
  const { code, state } = useThirdyLoginParams();
  const [isLoading, setIsloading] = useState(false);
  const googleLoginRef = useRef<AbortController>(null);
  const navigate = useLocalNavigate();

  const postMessageToParent = useMemoizedFn((access_token: string | null) => {
    window.opener?.postMessage({ access_token }, '*');
  });

  useEffect(() => {
    if (!code || !state) {
      return;
    }
    setIsloading(true);
    thirdPartyService
      .thirdPartyLogin({ code, state }, googleLoginRef)
      .then(async ({ token }) => {
        if (token) {
          postMessageToParent(token);
        }
      })
      .catch((err) => {
        if (err?.code !== 'ERR_CANCELED') {
          setIsloading(false);
          postMessageToParent('failed');
        }
      });

    const current = googleLoginRef.current;

    return () => {
      current?.abort();
    };
  }, [code, navigate, postMessageToParent, state]);
  return { isLoading };
}
