import classNames from 'classnames';
import React, { useImperativeHandle, useRef } from 'react';
import { useState } from 'react';
import { MEDIA_SIZE } from '@consts/consts';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';

type OptSingleBoxProps = {
  active: boolean;
  onValueChange?: (val: string) => void;
  onBackspaceDown: () => void;
  onClick: () => void;
  id?: string;
};
export type OptSingleBoxRef = {
  focus: () => void;
  reset: () => void;
};
const KEYCODE = Object.freeze({
  BACKSPACE: 'Backspace',
  LEFT_ARROW: 'ArrowLeft',
  RIGHT_ARROW: 'ArrowRight',
  END: 'End',
  HOME: 'Home',
  SPACE: ' ',
});
const OptSingleBox = React.forwardRef<OptSingleBoxRef, OptSingleBoxProps>((props: OptSingleBoxProps, ref) => {
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const [innerVal, setInnerVal] = useState('');
  const handleValueChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const newVal = e.target.value;
    if (newVal.length <= 1) {
      props.onValueChange?.(newVal);
      setInnerVal(newVal);
    }
  };
  const handleOnKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    switch (e.key) {
      case KEYCODE.BACKSPACE:
        props.onBackspaceDown();
        break;
      case KEYCODE.LEFT_ARROW:
      case KEYCODE.RIGHT_ARROW:
      case KEYCODE.HOME:
      case KEYCODE.END:
      case KEYCODE.SPACE:
        e.preventDefault();
        break;
      default:
        break;
    }
  };
  const InputRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => ({
    focus: () => {
      InputRef.current?.focus();
    },
    reset: () => {
      setInnerVal('');
    },
  }));

  return (
    <input
      ref={InputRef}
      className={classNames(innerVal && 'text-transparent relative lg:top-[4px]', 'pinInput')}
      style={{
        textShadow: '0 0 0 #000000',
        fontWeight: '400',
        top: isLg ? '0px' : '0px',
        // marginBottom: isLg ? '8px' : undefined,
      }}
      onClick={props.onClick}
      id={props.id}
      type="number"
      value={innerVal}
      onChange={handleValueChange}
      maxLength={1}
      pattern="\d*"
      onKeyDown={handleOnKeyDown}
      data-testid={'code-input-single'}
    />
  );
});
export default OptSingleBox;
