import LocalStore from './localStore';

const ComboTestResultStoreKey = 'ComboTestResultSession';
export const ComboTestResultStore = {
  get() {
    return LocalStore.session.get(ComboTestResultStoreKey);
  },
  set(data: number) {
    LocalStore.session.set(ComboTestResultStoreKey, data);
  },
  remove: () => {
    LocalStore.session.remove(ComboTestResultStoreKey);
  },
};

const CovidTestResultStoreKey = 'CovidTestResultSession';
export const CovidtestResultStore = {
  get() {
    return LocalStore.session.get(CovidTestResultStoreKey);
  },
  set(data: string) {
    LocalStore.session.set(CovidTestResultStoreKey, data);
  },
  remove: () => {
    LocalStore.session.remove(CovidTestResultStoreKey);
  },
};
