import classNames from 'classnames';
import { RenderComponentOptions, renderComponent } from './globalComponents';
import { useEffect, useState } from 'react';
import React from 'react';
import { useMemoizedFn } from 'ahooks';

type AlertInfoProps = {
  content: string | React.ReactNode;
  closeIconShow?: boolean;
  wraperBackground?: string;
  contentClassName?: string;
  iconColor?: string;
};

const AlertCloseFuncContext = React.createContext<() => void>(() => {});

export const useAlertCloseFunc = () => {
  return React.useContext(AlertCloseFuncContext);
};

export default function GlobalAlert(props: AlertInfoProps & { closeFunction: () => void }) {
  const [isClose, setIsClose] = useState(true);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setIsClose(false);
    }, 100);
    return () => {
      clearTimeout(timeId);
    };
  }, []);

  const handleClose = useMemoizedFn(() => {
    setIsClose(true);
    const timeId = setTimeout(() => {
      props.closeFunction();
    }, 200);
    return () => {
      clearTimeout(timeId);
    };
  });
  return (
    <AlertCloseFuncContext.Provider value={handleClose}>
      <div
        onClick={handleClose}
        className={classNames(
          'w-[100vw] h-[calc(var(--vh,100vh))] fixed top-0 left-0 z-[10000] flex justify-center items-center',
          'transition-opacity duration-200',
          isClose ? 'opacity-0' : 'opacity-100',
        )}
        style={{ background: props.wraperBackground }}
      >
        <div onClick={(e) => e.stopPropagation()}>
          <div className={props.contentClassName}>{props.content}</div>
        </div>
      </div>
    </AlertCloseFuncContext.Provider>
  );
}

export function globalAlert(config: RenderComponentOptions<AlertInfoProps>) {
  return renderComponent(config, GlobalAlert);
}
