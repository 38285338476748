import { get } from '@utils/request';
import { UserInfoType } from './types/userInfos.type';
import { patientInfosState } from '@states/patient.state';
import { setRecoil } from 'recoil-nexus';

function getUseInfos(params?: { redirect?: boolean }) {
  return get<UserInfoType>('/user/info', params).then((data) => {
    setRecoil(patientInfosState, data);
    return data;
  });
}

export default {
  getUseInfos,
};
