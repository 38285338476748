import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

export default function VoiceOffIcon(props: Partial<CustomIconComponentProps> & { scale?: number; color?: string }) {
  const VoiceOffSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3978 12.3545L15.1882 11.164C15.3136 10.97 15.4211 10.7496 15.5108 10.5026C15.6004 10.2557 15.6631 10 15.6989 9.73545C15.7348 9.52381 15.829 9.34744 15.9817 9.20635C16.1337 9.06526 16.3082 8.99471 16.5054 8.99471C16.7563 8.99471 16.9624 9.07866 17.1237 9.24656C17.2849 9.41376 17.3477 9.61199 17.3118 9.84127C17.2401 10.3175 17.1283 10.7626 16.9763 11.1767C16.8237 11.5915 16.6308 11.9841 16.3978 12.3545ZM12.9301 8.91534L7.68817 3.73016V2.6455C7.68817 1.90476 7.94803 1.27866 8.46774 0.767196C8.98745 0.255732 9.62366 0 10.3763 0C11.129 0 11.7652 0.255732 12.2849 0.767196C12.8047 1.27866 13.0645 1.90476 13.0645 2.6455V8.30688C13.0645 8.43034 13.0513 8.54039 13.0247 8.63704C12.9975 8.73439 12.9659 8.82716 12.9301 8.91534ZM18.6559 19.7884L0.215054 1.64021C0.0716846 1.49912 0 1.31817 0 1.09735C0 0.877249 0.0716846 0.687831 0.215054 0.529101C0.376344 0.37037 0.569175 0.291005 0.793548 0.291005C1.0172 0.291005 1.20072 0.37037 1.34409 0.529101L19.7849 18.6508C19.9283 18.8095 20 18.9993 20 19.2201C20 19.4402 19.9283 19.6296 19.7849 19.7884C19.6237 19.9295 19.4312 20 19.2075 20C18.9832 20 18.7993 19.9295 18.6559 19.7884ZM9.56989 18.5185V15.8201C7.93907 15.6437 6.55448 14.9954 5.41613 13.8751C4.27849 12.7556 3.62007 11.4109 3.44086 9.84127C3.40502 9.61199 3.46774 9.41376 3.62903 9.24656C3.79032 9.07866 3.99642 8.99471 4.24731 8.99471C4.44444 8.99471 4.6147 9.06526 4.75806 9.20635C4.90143 9.34744 5 9.52381 5.05376 9.73545C5.23297 11.0229 5.82437 12.103 6.82796 12.9757C7.83154 13.849 9.01434 14.2857 10.3763 14.2857C11.0573 14.2857 11.7025 14.1623 12.3118 13.9153C12.9211 13.6684 13.4588 13.3333 13.9247 12.9101L15.0806 14.0476C14.5609 14.5238 13.9695 14.916 13.3065 15.2243C12.6434 15.5333 11.9355 15.7319 11.1828 15.8201V18.5185C11.1828 18.7478 11.1068 18.9376 10.9548 19.0878C10.8021 19.2374 10.6093 19.3122 10.3763 19.3122C10.1434 19.3122 9.9509 19.2374 9.79892 19.0878C9.64624 18.9376 9.56989 18.7478 9.56989 18.5185Z"
        fill={props.color ?? '#FF414D'}
      />
    </svg>
  );
  return (
    <Icon
      style={{
        transform: `scale(${props.scale ?? 1.1})`,
      }}
      component={VoiceOffSvg}
      {...props}
    />
  );
}
