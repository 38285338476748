export default function BookingSummaryItem(props: { left: string; right: string }) {
  return (
    <div className="flex font-medium w-full justify-between mt-[20px] lg:mt-[20px] text-[14px]">
      <span className="text-[#212121] font-[500] lg:text-[16px] lg:leading-[24px] lg:w-[50%] w-[35%] inline-block">
        {props.left}
      </span>
      <p className="text-[#212121] font-light lg:text-[16px] lg:leading-[28px] w-[50%] inline-block">
        <span className="inline-block w-[80%] break-words">{props.right}</span>
      </p>
    </div>
  );
}
