import RtcAdapter from '@utils/RtcAdapter/RtcTool';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

/**
 * 会议开始20分钟后，如果用户还没有进房间，会触发参数中的cb
 * @param startTimeStr 会议开始时间字符串
 * @param cb 回调函数
 */
export const useTimeoutReminder = (
  startTimeStr: string | undefined,
  role: 1 | 2 | undefined,
  cb: (timeoutRef: React.MutableRefObject<NodeJS.Timer | undefined>) => void,
) => {
  const timeoutRef = useRef<NodeJS.Timer>();
  const meetingId = useParams().meetingId;
  const userHasJoinedKey = meetingId + '_has_joinded';

  useEffect(() => {
    // 患者端不需要
    if (role === 1 || !startTimeStr) {
      return;
    }
    const now = new Date().getTime();
    const startTimeout = new Date(startTimeStr).getTime() + 20 * 60 * 1000;
    if (now > startTimeout) {
      return;
    }
    // 远端用户没有加入过房间才需要设置reminder
    if (!localStorage.getItem(userHasJoinedKey)) {
      timeoutRef.current = setInterval(() => cb(timeoutRef), startTimeout - now);
    }
  }, [cb, role, startTimeStr, userHasJoinedKey]);
  useEffect(() => {
    // 患者端不需要
    if (role === 1) {
      return;
    }
    // 远端用户加入房间后清除定时器
    RtcAdapter.on('user-joined', () => {
      clearInterval(timeoutRef.current);
      localStorage.setItem(userHasJoinedKey, 'true');
    });
  }, [role, userHasJoinedKey]);
};
