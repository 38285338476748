import { useNetworkQualityListen } from '@utils/RtcAdapter/hooks/networksQuality';
import { useReducer } from 'react';
// import ChatBox from './ChatBox/ChatBox';
import UserCard from './UserCard';

type UserContainerProps = {
  userRtcUserList: { userName: string; rtcUid: string; isMine: boolean }[];
  chatBoxOpen: boolean;
};
function computeCardSize(idx: number, length: number) {
  if (length === 2) {
    const p = idx % 2;
    if (p === 0) {
      return 'lg:w-[434px] lg:h-[244px] lg:bottom-[6px] lg:right-[10px] w-[143px] h-[224px] bottom-[6px] right-[10px] z-10';
    } else {
      return 'w-full h-full top-0 left-0';
    }
  } else if (length === 1) {
    return 'w-full h-full';
  }
}

export default function UserContainer(props: UserContainerProps) {
  useNetworkQualityListen();
  const [positionOffset, updatePositionOffset] = useReducer((v) => v + 1, 0);
  return (
    <div className="w-full h-full relative">
      {props.userRtcUserList.map((u, idx) => (
        <UserCard
          key={u.rtcUid}
          rtcUid={u.rtcUid}
          userName={u.userName}
          isMine={u.isMine}
          onClick={updatePositionOffset}
          isSmall={(idx + positionOffset) % 2 === 0}
          sizeClassName={computeCardSize(idx + positionOffset, props.userRtcUserList.length)}
        />
      ))}
      {/* <ChatBox open={props.chatBoxOpen} /> */}
    </div>
  );
}
