/**
 * 获取时区字符串
 * @returns {string}    代表着星期几
 */
export const getUTC = () => {
  const offset =
    new Date().getTimezoneOffset() / 60 > 0
      ? `-${new Date().getTimezoneOffset() / 60}`
      : `+${(new Date().getTimezoneOffset() / 60) * -1}`;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const result = `${timeZone} (GMT${offset})`;

  // (UTC-08:00)
  // (UTC+08:00)
  // America/Los_Angeles
  // Asia/Shanghai'
  return result;
};
