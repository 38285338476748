import React, { useEffect, useState } from 'react';

import back from '@images/doctorImages/back.svg';
import { useParams, useSearchParams } from 'react-router-dom';
import { Checkbox, Select, Table } from 'antd';
import { PlusCircleOutlined, LoadingOutlined, InfoCircleFilled } from '@ant-design/icons';
import TextInput, { TextInputWithRef } from '@components/TextInput/TextInput';
import BaseButton from '@components/BaseButton/BaseButton';
import { Delete } from '@icon-park/react';
import alertInfo from '@utils/globalComponents/Alert';
import { useMemoizedFn } from 'ahooks';
import { createDebounceWrapper } from '@utils/index';
import medicationService from '@services/medicationService';
import { DrugInfo } from '@services/types/medicationService.type';
import prescriptionService from '@services/prescriptionService';
import { useRecoilValue } from 'recoil';
import { PrescriptionsTemplateState } from '@states/prescriptions.state';
import { useLocalNavigate } from 'src/router/routerTools';

const Column = Table.Column<DrugInfo>;
const style = {
  container: {
    background: '#f5f7fa',
  },
};

const EditableInput = (props: { defaultValue: string; onChange: (v: string) => void; id: string }) => {
  const [value, setValue] = useState(props.defaultValue);
  return (
    <TextInput
      id={props.id}
      value={value}
      inputClassName="sm:w-[77px] sm:h-[47px]"
      onChange={(e) => {
        setValue(e.target.value);
        props.onChange?.(e.target.value);
      }}
    />
  );
};
const debounceSearchWrapper = createDebounceWrapper(500);

const AddMedicineContent = (props: { onClickSave: (record: DrugInfo) => void; close: () => void }) => {
  const [drugList, setDrugList] = useState<DrugInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const searchDrugs = useMemoizedFn((searchName: string) => {
    debounceSearchWrapper(() => {
      setLoading(true);
      medicationService
        .getDrugsList({ searchName: searchName, sortBy: 'asc', page: 1, size: 10 })
        .then((data) => {
          setDrugList(data.list);
        })
        .finally(() => setLoading(false));
    });
  });
  const [selectDrugInfo, setSelectDrugInfo] = useState<DrugInfo>();

  return (
    <div className="sm:px-[40px] font-[inter]">
      <p className="sm:text-[22px] sm:leading-[30px] font-semibold text-center">Add Medicine</p>
      <hr className="bg-[#00000010] sm:mt-[14px] sm:mb-[31px]" />
      <div>
        <p className="sm:text-[18px] sm:leading-[26px] font-semibold">Select Medicine</p>
        <Select
          id="indicaid-doctor-editPrescriptions__SelectMedicine-Select"
          className="sm:w-[418px] sm:h-[42px] sm:text-[15px] sm:leading-[18px] flex items-center"
          showSearch
          onDropdownVisibleChange={(v) => {
            if (v) {
              searchDrugs('');
            }
          }}
          onSearch={searchDrugs}
          placeholder="Select from existing inventory"
          filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
          popupClassName="z-[10001]"
          fieldNames={{
            label: 'name',
            value: 'id',
          }}
          options={drugList}
          onSelect={(_, sd) => setSelectDrugInfo(sd)}
          notFoundContent={
            loading ? (
              <div className="w-full sm:h-[81px] flex items-center justify-center">
                <LoadingOutlined spin />
              </div>
            ) : undefined
          }
        />
      </div>
      <div className="flex justify-center sm:mt-[40px]">
        <BaseButton
          onClick={() => {
            selectDrugInfo && props.onClickSave(selectDrugInfo);
            props.close();
          }}
          id="indicaid-doctor-editPrescriptions__save-button"
          buttonClassName="sm:w-[129px] sm:h-[32px] sm:mr-[12px]"
          value="Save"
          status="doctorDefault"
        />
        <BaseButton
          status="doctorClear"
          onClick={() => {
            props.close();
          }}
          buttonClassName="sm:w-[129px] sm:h-[32px]"
          value="Exit"
          id="indicaid-doctor-editPrescriptions__exit-button"
        />
      </div>
    </div>
  );
};

type ConfirmPrescriptionProps = {
  drugList: DrugInfo[];
  defaultDiagnosis: string;
  bookingId: string;
  close: () => void;
  appointmentsId: string | null;
  prescriptionsId?: string | number;
  amount?: number;
};

const ConfirmPrescription = (props: ConfirmPrescriptionProps) => {
  const renderDrugsRow = (drug: DrugInfo) => {
    return (
      <div key={drug.id} className="flex justify-between sm:my-[7px]">
        <p className="sm:w-[92px]">{drug.id}</p>
        <p className="sm:w-[215px] block overflow-hidden whitespace-nowrap text-ellipsis">{drug.name}</p>
        <p className="sm:w-[108px]">{drug.dosageUnit === 0 ? `${drug.dosage} mg` : `${drug.dosage} ml`}</p>
        <p className="sm:w-[101px]">{drug.times}</p>
        <p className="sm:w-[88px]">{drug.units}</p>
        <p className="sm:w-[136px]">{drug.duration}</p>
      </div>
    );
  };
  const [diagnosis, setDiagnosis] = useState(props.defaultDiagnosis);
  const [duration, setDuration] = useState('');
  const [price, setPrice] = useState('');
  const [isSaveAsTemplate, setIsSaveAsTemplate] = useState(false);

  const disableConfirm = diagnosis === '' || duration === '' || props.drugList.length === 0;

  const [showPriceInput, setShowPriceInput] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const addNewPrescription = useMemoizedFn(async () => {
    setIsSubmiting(true);
    prescriptionService
      .createOrUpdatePrescription({
        id: props.prescriptionsId,
        bookingId: props.bookingId,
        diagnosis: diagnosis,
        drugs: props.drugList.map((d) => ({
          id: d.id,
          times: d.times,
          units: d.units,
          duration: d.duration,
          price: d.price,
          remarks: d.remarks,
        })),
        duration: duration,
        price: price || '0',
        currency: 0,
        remarks: '',
        saveTemplate: isSaveAsTemplate,
      })
      .then(() => {
        window.location.href =
          window.location.origin + `/doctor/appointmentsDetails?appointmentsId=${props.appointmentsId}&tab=1`;
      })
      .finally(() => {
        setIsSubmiting(false);
        props.close();
      });
  });
  return (
    <div className="sm:w-[830px] sm:px-[40px] font-[Inter]">
      <p className="font-bold sm:text-[22px] sm:leading-[30px]">Confirm Prescription</p>
      <hr className="bg-[#00000010] sm:mt-[14px] sm:mb-[27px]" />
      <div className="flex">
        <div>
          <p className="sm:text-[14px] font-semibold">Diagnosis</p>
          <TextInput
            value={diagnosis}
            placeholder="input diagnosis"
            onChange={(e) => setDiagnosis(e.target.value)}
            inputClassName="sm:w-[328px] sm:h-[46px]"
            id="indicaid-doctor-editPrescriptions__Diagnosis-input"
          />
        </div>
        <div className="sm:ml-[16px]">
          <p className="sm:text-[14px] font-semibold">Duration</p>
          <div className="bg-[#ffffff] border text-[#52575c] sm:text-[14px] sm:leading-[22px] sm:px-[12px] flex items-center border-[#D5D4DC] sm:rounded-[8px] sm:w-[103px] sm:h-[46px]">
            <input
              type="number"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              className="outline-0 sm:w-[45px] border-transparent bg-transparent"
              id="indicaid-doctor-editPrescriptions__Days-input"
            />
            <p>Days</p>
          </div>
        </div>
      </div>

      <p className="font-bold sm:text-[18px] sm:leading-[30px] sm:mt-[29px]">Confirm Prescription</p>
      <hr className="bg-[#00000010] sm:mt-[4px] sm:mb-[16px]" />
      <div className="sm:text-[14px] sm:leading-[30px] sm:tracking-[0.1px]">
        <div className="flex justify-between font-bold font-[Lato]">
          <p className="sm:w-[92px]">Drug ID</p>
          <p className="sm:w-[215px]">Name</p>
          <p className="sm:w-[108px]">Dosage</p>
          <p className="sm:w-[101px]">Time/Day</p>
          <p className="sm:w-[88px]">Units</p>
          <p className="sm:w-[136px]">Duration (Days)</p>
        </div>
        {props.drugList.map(renderDrugsRow)}
      </div>
      <div className="font-semibold sm:text-[14px] sm:leading-[20px] sm:mt-[29px] sm:mb-[8px] flex">
        <p className="sm:w-[310px]">Paid Fee</p>
        <p className="sm:ml-[20px]">
          <Checkbox
            id="indicaid-doctor-editPrescriptions__TopupPrescriptionFee-Checkbox"
            checked={showPriceInput}
            onChange={() => setShowPriceInput(!showPriceInput)}
            className="sm:mr-[5px]"
          />{' '}
          Top up Prescription Fee
        </p>
      </div>
      <div className="flex">
        <div className="bg-[#F5F5F5] border cursor-not-allowed text-[#8F989C] sm:text-[14px] sm:leading-[22px] sm:px-[12px] flex items-center border-[#D5D4DC] sm:rounded-[8px] sm:w-[310px] sm:h-[46px]">
          <p className="sm:mr-[15px]">HKD</p>
          <input
            disabled
            value={props.amount}
            type="number"
            className="outline-0 font-medium  border-transparent bg-transparent"
            id="indicaid-doctor-editPrescriptions__hkd-input"
          />
        </div>
        {showPriceInput && (
          <div className="sm:ml-[20px] bg-[#ffffff] border text-[#52575c] sm:text-[14px] sm:leading-[22px] sm:px-[12px] flex items-center border-[#D5D4DC] sm:rounded-[8px] sm:w-[310px] sm:h-[46px]">
            <p className="sm:mr-[15px]">HKD</p>
            <input
              id="indicaid-doctor-editPrescriptions__showPriceHkd-input"
              value={price}
              onChange={(e) => {
                const val = e.target.value;

                if (val === '') {
                  setPrice('');
                  return;
                }
                const intValue = parseInt(val, 10);
                setPrice(`${isNaN(intValue) ? '' : intValue}`);
              }}
              className="outline-0 font-medium  border-transparent bg-transparent"
            />
          </div>
        )}
      </div>

      <div className="flex sm:text-[14px] items-center sm:mt-[28px]">
        <Checkbox checked={isSaveAsTemplate} onChange={(e) => setIsSaveAsTemplate(e.target.checked)} />
        <p className="sm:ml-[6px]">Save as new prescription package</p>
      </div>
      <div className="flex sm:mt-[43px]">
        <BaseButton
          loading={isSubmiting}
          onClick={addNewPrescription}
          buttonClassName="sm:w-[129px] sm:h-[32px] sm:mr-[12px] font-[Montserrat]"
          status={disableConfirm ? 'disabled' : 'doctorDefault'}
          value="Confirm"
          disable={disableConfirm}
          id="indicaid-doctor-editPrescriptions__confirm-button"
        />
        <BaseButton
          status="doctorClear"
          onClick={props.close}
          buttonClassName="sm:w-[129px] sm:h-[32px] font-[Montserrat]"
          value="Cancel"
          id="indicaid-doctor-editPrescriptions__Cancel-button"
        />
      </div>
    </div>
  );
};

const EditPrescriptions: React.FC = () => {
  const navigate = useLocalNavigate();
  const bookingId = useParams().bookingId!;
  const appointmentsId = useSearchParams()[0].get('appointmentsId');

  const defaultDiagnosis = useSearchParams()[0].get('diagnosis');
  const [diagnosisText, setDiagnosisText] = useState(defaultDiagnosis || '');
  const priscriptionTemplate = useRecoilValue(PrescriptionsTemplateState);

  const [drugListForPrescription, setDrugListForPrescription] = useState<DrugInfo[]>(priscriptionTemplate?.drugs || []);
  const onClickSaveDrug = useMemoizedFn((drug: DrugInfo) => {
    setDrugListForPrescription((list) => {
      if (!list.find((d) => drug.id === d.id)) {
        return [...list, drug];
      }
      return list;
    });
  });

  const [appointmentAmount, setAppointmentAmount] = useState(0);
  useEffect(() => {
    if (bookingId) {
      prescriptionService.getBookingInfos({ bookingId }).then((res) => {
        setAppointmentAmount(res.amount);
      });
    }
  }, [bookingId]);

  return (
    <div
      className="w-full h-full overflow-scroll flex font-[inter]  bg-opacity-[0.8] flex-col py-[0] px-[24px] sm:min-h-[calc(100vh-318px-79px)] sm:p-[60px_38px_35px_65px]"
      style={style.container}
    >
      <div className="flex flex-col">
        <div
          className="flex sm:mb-[24px] items-center"
          // TODO  需要看一下这个appointment在哪里
          onClick={() => {
            navigate('/doctor/prescriptions/' + bookingId + `?appointmentsId=${appointmentsId}`);
          }}
        >
          <div>
            <img src={back} alt="back" className="object-cover sm:w-[32px] cursor-pointer" />
          </div>
          <div className="sm:ml-[22px] font-[600] text-[#52575C] sm:text-[16px] sm:leading-[20px] sm:tracking-[0.1px]">
            Back to Prescriptions
          </div>
        </div>
        <div className="font-[700] sm:text-[28px] sm:leading-[42px] text-[#25282B] sm:mb-[30px]">
          Edit Prescriptions
        </div>
        <div>
          <p className="text-[#25282B] sm:text-[18px] sm:leading-[26px] font-semibold sm:mb-[8px]">Diagnosis </p>

          <TextInputWithRef
            value={diagnosisText}
            onChange={(e) => setDiagnosisText(e.target.value)}
            inputClassName="sm:w-[600px] sm:h-[42px]"
            id="indicaid-doctor-editPrescriptions__DiagnosisRef-input"
          />
        </div>
        <div>
          <div className="flex justify-between sm:mt-[63px] sm:mb-[25px]">
            <p className="text-[#25282B] sm:text-[18px] sm:leading-[26px] font-semibold sm:mb-[8px]">
              Create New Prescription
            </p>
            <BaseButton
              onClick={() => {
                alertInfo((close) => ({
                  content: <AddMedicineContent close={close} onClickSave={onClickSaveDrug} />,
                  background: '#F6F8FB',
                  wraperBackground: '#00000020',
                  closeIconShow: false,
                }));
              }}
              id="indicaid-doctor-editPrescriptions__AddMedicine-button"
              buttonClassName="sm:w-[193px] sm:h-[32px]"
              status="doctorDefault"
              value={
                <p>
                  <PlusCircleOutlined /> Add Medicine
                </p>
              }
            />
          </div>
          <p className="text-[#52575C] sm:text-[14px] sm:leading-[22px] sm:mb-[21px]">
            <InfoCircleFilled className="text-[#009FAF] sm:text-[14px] sm:mr-[5px]" /> Reminder: Patient has purchased
            an all-in-one package with basic medication included. You may add medicine to request additional payment if
            needed.
          </p>
          <Table rowKey="id" dataSource={drugListForPrescription} pagination={false}>
            <Column title="Drug ID" dataIndex="id" key="id" />
            <Column
              className="sm:w-[250px]"
              title="Name"
              dataIndex="name"
              key="name"
              render={(name) => (
                <p className="block overflow-hidden whitespace-nowrap text-ellipsis sm:max-w-[330px]">{name}</p>
              )}
            />
            <Column
              title="Dosage"
              dataIndex="dosage"
              key="dosage"
              render={(_, record) => (record.dosageUnit === 0 ? `${record.dosage} mg` : `${record.dosage} ml`)}
            />
            <Column
              title="Time/Day"
              dataIndex="times"
              key="times"
              render={(timePerDay, record) => (
                <EditableInput
                  defaultValue={timePerDay}
                  onChange={(v) => (record.times = +v)}
                  id="indicaid-doctor-editPrescriptions__TimeDay-button"
                />
              )}
            />
            <Column
              title="Units"
              dataIndex="units"
              render={(Units, record) => (
                <EditableInput
                  defaultValue={Units}
                  onChange={(v) => (record.units = v)}
                  id="indicaid-doctor-editPrescriptions__Units-button"
                />
              )}
              key="units"
            />
            <Column
              title="Duration (Days)"
              dataIndex="duration"
              render={(Duration, record) => (
                <EditableInput
                  defaultValue={Duration}
                  onChange={(v) => (record.duration = v)}
                  id="indicaid-doctor-editPrescriptions__Duration-button"
                />
              )}
              key="duration"
            />

            <Column
              className="sm:w-[50px]"
              title=""
              key="delete"
              render={(_, record) => {
                return (
                  <div
                    onClick={() => {
                      setDrugListForPrescription((oldList) => oldList.filter((o) => o.id !== record.id));
                    }}
                    className="text-[red] text-center cursor-pointer"
                    id="indicaid-doctor-editPrescriptions__deleteRubbish-button"
                  >
                    <Delete theme="two-tone" size="26" fill={['#FF6760', '#fff']} />
                  </div>
                );
              }}
            />
          </Table>
          <div className="flex justify-end sm:mt-[43px]">
            <BaseButton
              onClick={() =>
                alertInfo((close) => ({
                  content: (
                    <ConfirmPrescription
                      prescriptionsId={priscriptionTemplate?.id || undefined}
                      close={close}
                      bookingId={bookingId}
                      drugList={drugListForPrescription}
                      defaultDiagnosis={diagnosisText}
                      appointmentsId={appointmentsId}
                      amount={appointmentAmount}
                    />
                  ),
                  background: '#F6F8FB',
                  wraperBackground: '#00000020',
                  closeIconShow: false,
                  isDoctor: true,
                }))
              }
              buttonClassName="sm:w-[129px] sm:h-[32px]"
              status="doctorDefault"
              value="Confirm"
            />
            {/* <BaseButton
              status="doctorClear"
              onClick={() => {
                setDrugListForPrescription([]);
              }}
              buttonClassName="sm:w-[129px] sm:h-[32px]"
              value="Clear"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPrescriptions;
