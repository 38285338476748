import RtcAdapterBase from './RtcAdapterBase';
import { NetworkStatus, RtcDevicesChangedHandle, RtcEvents, RtcUserEventsHandle } from './types.rtc';
import AgoraRTC, { ICameraVideoTrack, IMicrophoneAudioTrack, IAgoraRTCClient } from 'agora-rtc-sdk-ng';
import { AnyFunction } from 'types/types';
import EventsMap from '@utils/EventsMap';
import getAgoraRtcToken from '@utils/AgoraToken/AgoraToken';
import { setRecoil } from 'recoil-nexus';
import isMeetingJoinedState from '@states/meeting.state';
import { createWaitPromise } from '..';
export default class RtcAgoraAdapter extends RtcAdapterBase {
  constructor() {
    super();
    window.document.addEventListener('beforeunload', () => {
      this.toogleAudio(false);
      this.toogleVideo(false);
    });
  }

  private _agoraClient: IAgoraRTCClient | undefined = undefined;
  private localVideoTrack: ICameraVideoTrack | undefined = undefined;
  private localAudioTrack: IMicrophoneAudioTrack | undefined = undefined;
  public get localAudioEnable() {
    return !!this.localAudioTrack?.enabled;
  }
  public get localVideoEnable() {
    return !!this.localVideoTrack?.enabled;
  }
  public get connectionState() {
    return this.agoraClient.connectionState;
  }
  private remoteUserVideoStartEventsMap = new EventsMap<(rtcUid: string) => void>();

  private get agoraClient() {
    if (!this._agoraClient) {
      this._agoraClient = AgoraRTC.createClient({ mode: 'rtc', codec: 'h264' });
    }
    return this._agoraClient;
  }

  public async join(channelId: string, rtcUid: string) {
    // TODO: get token from backend
    const token = getAgoraRtcToken(rtcUid, channelId);
    await this.agoraClient.join(process.env.agora_rtc_appid!, channelId, token, rtcUid);
    this.localRtcUid = rtcUid;
    this.triggerEvent('local-user-joined');
    this.addRtcEnentsListeners();
    setRecoil(isMeetingJoinedState, true);
  }

  private RemoteVideoStatusMap: Map<string, boolean> = new Map<string, boolean>();
  private RemoteAudioStatusMap: Map<string, boolean> = new Map<string, boolean>();

  public getRemoteVideoStatus(rtcUid: string): boolean {
    if (rtcUid === this.localRtcUid) {
      return this.localVideoEnable;
    }
    return !!this.RemoteVideoStatusMap.get(rtcUid);
  }
  public getRemoteAudioStatus(rtcUid: string): boolean {
    if (rtcUid === this.localRtcUid) {
      return this.localAudioEnable;
    }
    return !!this.RemoteAudioStatusMap.get(rtcUid);
  }

  private addRtcEnentsListeners() {
    this.agoraClient.on('user-joined', (remoteUser) => {
      this.triggerEvent('user-joined', `${remoteUser.uid}`);
    });
    this.agoraClient.on('user-left', (remoteUser) => {
      this.triggerEvent('user-left', `${remoteUser.uid}`);
    });

    this.agoraClient.on('user-published', async (remoteUser, type) => {
      await this.agoraClient.subscribe(remoteUser, type);
      if (type === 'audio') {
        remoteUser.audioTrack?.play();
        this.triggerEvent('remote-audio-start', `${remoteUser.uid}`);
        this.RemoteAudioStatusMap.set(`${remoteUser.uid}`, true);
      } else {
        this.triggerEvent('remote-video-start', `${remoteUser.uid}`);
        this.RemoteVideoStatusMap.set(`${remoteUser.uid}`, true);
        this.remoteUserVideoStartEventsMap.triggerEventMap(`${remoteUser.uid}`, `${remoteUser.uid}`);
      }
    });
    this.agoraClient.on('user-unpublished', async (remoteUser, type) => {
      if (type === 'audio') {
        this.triggerEvent('remote-audio-stop', `${remoteUser.uid}`);
        this.RemoteAudioStatusMap.set(`${remoteUser.uid}`, false);
      } else {
        this.triggerEvent('remote-video-stop', `${remoteUser.uid}`);
        this.RemoteAudioStatusMap.set(`${remoteUser.uid}`, false);
      }
    });
    this.agoraClient.on('volume-indicator', (indicator) => {
      const indicatorTemp = indicator.map((i) => ({ rtcUid: `${i.uid}`, level: i.level }));
      this.triggerEvent('volumes-changed', indicatorTemp);
    });
    this.agoraClient.on('network-quality', (stats) => {
      this.triggerEvent(
        'networks-changed',
        this.localRtcUid,
        Math.round((stats.downlinkNetworkQuality + stats.uplinkNetworkQuality) / 2) as NetworkStatus,
      );
    });
    this.agoraClient.on('connection-state-change', (cur, pre, reason) => {
      console.log(cur, pre, reason, 'cur, pre, reason');
      this.triggerEvent('connect-status-change', cur, pre, reason);
    });
    this.listenRemoteNetworks();
    this.listenMediaDevicesChanged();
  }

  private listenRemoteNetworksIntervalId: NodeJS.Timer | undefined = undefined;
  private listenRemoteNetworks() {
    this.listenRemoteNetworksIntervalId = setInterval(() => {
      const remoteUserStatus = this.agoraClient.getRemoteNetworkQuality();
      Object.keys(remoteUserStatus).forEach((remoteRtcUid) => {
        const remoteNetwork = remoteUserStatus[remoteRtcUid];
        this.triggerEvent(
          'networks-changed',
          `${remoteRtcUid}`,
          Math.round(remoteNetwork.downlinkNetworkQuality + remoteNetwork.uplinkNetworkQuality) as NetworkStatus,
        );
      });
    }, 3000);
  }
  private stopListenRemoteNetworks() {
    clearInterval(this.listenRemoteNetworksIntervalId);
  }

  private removeRtcEnentsListeners() {
    this.agoraClient.removeAllListeners('user-joined');
    this.agoraClient.removeAllListeners('user-left');
    this.agoraClient.removeAllListeners('user-published');
    this.agoraClient.removeAllListeners('volumes-changed');
    this.agoraClient.removeAllListeners('network-quality');
    this.remoteUserVideoStartEventsMap.removeAllListeners();
    this.stopListenRemoteNetworks();
    this.removeListenMediaDevicesChanged();
  }

  private listenMediaDevicesChanged() {
    AgoraRTC.onCameraChanged = (deviceInfo) => {
      this.triggerEvent('media-device-changed', 'camera', {
        device: deviceInfo.device,
        state: deviceInfo.state,
      });
    };
    AgoraRTC.onMicrophoneChanged = (deviceInfo) => {
      this.triggerEvent('media-device-changed', 'microphone', {
        device: deviceInfo.device,
        state: deviceInfo.state,
      });
    };
    AgoraRTC.onPlaybackDeviceChanged = (deviceInfo) => {
      this.triggerEvent('media-device-changed', 'speaker', {
        device: deviceInfo.device,
        state: deviceInfo.state,
      });
    };
  }
  private removeListenMediaDevicesChanged() {
    AgoraRTC.onCameraChanged = undefined;
    AgoraRTC.onMicrophoneChanged = undefined;
    AgoraRTC.onPlaybackDeviceChanged = undefined;
  }

  public async leave() {
    await Promise.all([this.toogleAudio(false), this.toogleVideo(false)]);

    try {
      await this.agoraClient.leave();
    } catch (e) {
      console.warn(e, 'leave fail');
    }

    this.removeRtcEnentsListeners();
    this.triggerEvent('local-user-left');
    this.channelId = undefined;
    this.localRtcUid = '';
    setRecoil(isMeetingJoinedState, false);
  }

  private async toogleVideoInner(enable: boolean): Promise<void> {
    if (enable) {
      if (!this.localVideoTrack) {
        this.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
          cameraId: this._defaultCameraDeviceId,
        });
        this.localVideoTrack.on('track-ended', () => this.triggerEvent('video-stoped', this.localRtcUid));
      }
      !this.localVideoTrack.enabled && (await this.localVideoTrack.setEnabled(true));
    } else {
      if (this.localRtcUid) {
        try {
          await this.agoraClient.unpublish(this.localVideoTrack);
        } catch (e) {
          console.warn(e);
        }
      }
      await this.localVideoTrack?.setEnabled(false);
      this.triggerEvent('video-stoped', this.localRtcUid);
      this.localVideoTrack = undefined;
    }
    this.triggerEvent('local-video-status-changed', enable);
    if (enable) {
      this.triggerEvent('remote-video-start', this.localRtcUid);
    } else {
      this.triggerEvent('remote-video-stop', this.localRtcUid);
    }
  }
  public toogleVideo = createWaitPromise(this.toogleVideoInner.bind(this));
  private async toogleAudioInner(enable: boolean) {
    if (enable) {
      // if (this.localAudioTrack && this.localAudioTrack.enabled) {
      //   return;
      // }
      if (!this.localAudioTrack) {
        this.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({
          microphoneId: this._defaultMicrophoneDeviceId,
        });
        this.localAudioTrack.on('track-ended', () => this.triggerEvent('audio-stoped', this.localRtcUid));
      }
      !this.localAudioTrack.enabled && (await this.localAudioTrack.setEnabled(true));
    } else {
      if (this.localRtcUid) {
        try {
          await this.agoraClient.unpublish(this.localAudioTrack);
        } catch (e) {
          console.warn(e);
        }
      }
      await this.localAudioTrack?.setEnabled(false);
      this.triggerEvent('audio-stoped', this.localRtcUid);
      this.localAudioTrack = undefined;
    }
    this.triggerEvent('local-audio-status-changed', enable);
    if (enable) {
      this.triggerEvent('remote-audio-start', this.localRtcUid);
    } else {
      this.triggerEvent('remote-audio-stop', this.localRtcUid);
    }
  }
  public toogleAudio = createWaitPromise(this.toogleAudioInner.bind(this));
  public async toogleVideoPublish(enable: boolean) {
    if (!this.localVideoTrack) {
      return;
    }
    if (enable) {
      await this.agoraClient.publish(this.localVideoTrack);
    } else {
      await this.agoraClient.unpublish(this.localVideoTrack);
    }
  }
  public async toogleAudioPublish(enable: boolean) {
    if (!this.localAudioTrack) {
      return;
    }
    if (enable) {
      await this.agoraClient.publish(this.localAudioTrack);
    } else {
      await this.agoraClient.unpublish(this.localAudioTrack);
    }
  }

  public setVolume(rtcUid: string, level: number) {
    this.agoraClient.remoteUsers.find((remoteUser) => remoteUser.uid === rtcUid)?.audioTrack?.setVolume(level);
  }
  public setAllVolume(level: number) {
    this.agoraClient.remoteUsers.forEach((remoteUser) => {
      remoteUser.audioTrack?.setVolume(level);
    });
  }
  public playVideo(container: HTMLElement, rtcUid: string) {
    if (rtcUid === this.localRtcUid) {
      this.localVideoTrack?.play(container);
      const cancelListen = this.on('local-video-status-changed', (enable) => {
        container.innerHTML = '';
        enable && this.localVideoTrack?.play(container);
      });
      return () => {
        this.localVideoTrack?.stop();
        cancelListen();
      };
    } else {
      const remoteUser = this.agoraClient.remoteUsers.find((ru) => ru.uid === rtcUid);
      if (remoteUser?.hasVideo) {
        remoteUser.hasVideo && remoteUser.videoTrack?.play(container);
      }
      const cancelListen = this.remoteUserVideoStartEventsMap.addEventMapListener(rtcUid, () => {
        const cbremoteUser = this.agoraClient.remoteUsers.find((ru) => +ru.uid === +rtcUid);
        cbremoteUser?.hasVideo && cbremoteUser.videoTrack?.play(container);
      });
      return () => {
        remoteUser?.videoTrack?.stop();
        cancelListen();
      };
    }
  }
  public playLocalVideo(container: HTMLElement) {
    container.innerHTML = '';
    this.localVideoTrack?.play(container);
  }
  private _defaultCameraDeviceId: string | undefined = undefined;
  private _defaultMicrophoneDeviceId: string | undefined = undefined;
  private _defaultSpeakerDeviceId: string | undefined = undefined;
  get cameraDeviceId() {
    return this._defaultCameraDeviceId || 'default';
  }
  get microphoneDeviceId() {
    return this._defaultMicrophoneDeviceId || 'default';
  }
  public switchCamera(deviceId: string) {
    this._defaultCameraDeviceId = deviceId;
    this.localVideoTrack?.setDevice(deviceId);
  }
  public switchMicrophone(deviceId: string): void {
    this._defaultMicrophoneDeviceId = deviceId;
    this.localAudioTrack?.setDevice(deviceId);
  }
  public switchSpeaker(deviceId: string): void {
    this._defaultSpeakerDeviceId = deviceId;
    this.localAudioTrack?.setPlaybackDevice(deviceId);
  }

  public getCameraList(): Promise<MediaDeviceInfo[]> {
    return AgoraRTC.getCameras();
  }
  public getMicrophoneList(): Promise<MediaDeviceInfo[]> {
    return AgoraRTC.getMicrophones();
  }
  public getSpeakerList(): Promise<MediaDeviceInfo[]> {
    return AgoraRTC.getPlaybackDevices();
  }
  public getRemoteRtcUidList() {
    return this.agoraClient.remoteUsers.map((r) => `${r.uid}`);
  }

  public on(eventName: 'video-stoped', cb: RtcUserEventsHandle): () => void;
  public on(eventName: 'audio-stoped', cb: RtcUserEventsHandle): () => void;
  public on(eventName: 'volumes-changed', cb: (changedList: { rtcUid: string; level: number }[]) => void): () => void;
  public on(eventName: 'user-joined', cb: RtcUserEventsHandle): () => void;
  public on(eventName: 'user-left', cb: RtcUserEventsHandle): () => void;
  public on(eventName: 'local-user-joined', cb: () => void): () => void;
  public on(eventName: 'local-user-left', cb: () => void): () => void;
  public on(eventName: 'networks-changed', cb: (rtcUid: string, networkStatus: NetworkStatus) => void): () => void;
  public on(eventName: 'remote-video-start', cb: RtcUserEventsHandle): () => void;
  public on(eventName: 'remote-audio-start', cb: RtcUserEventsHandle): () => void;
  public on(eventName: 'remote-video-stop', cb: RtcUserEventsHandle): () => void;
  public on(eventName: 'remote-audio-stop', cb: RtcUserEventsHandle): () => void;
  public on(eventName: 'media-device-changed', cb: RtcDevicesChangedHandle): () => void;
  public on(eventName: 'local-video-status-changed', cb: RtcEvents['local-video-status-changed']): () => void;
  public on(eventName: 'local-audio-status-changed', cb: RtcEvents['local-audio-status-changed']): () => void;
  public on(eventName: 'connect-status-change', cb: RtcEvents['connect-status-change']): () => void;
  public on(eventName: keyof RtcEvents, cb: AnyFunction) {
    this.addListener(eventName, cb);
    return () => this.removeListener(eventName, cb);
  }
}
