/* eslint-disable @typescript-eslint/indent */
import React, { useEffect, useState } from 'react';
import { SelectDetailType } from '@consts/enums';

import ScreeningResult from './components/ScreeningResult';
import Prescriptions from './components/Prescriptions';
import Remarks from './components/Remarks';
import ShipmentDetails from './components/ShipmentDetails';

import { handleLocalStorage } from '@utils/index';
import website from '@configs/website';

import back from '../../../images/doctorImages/back.svg';
// import { formatBeDate } from '@utils/index';
import { ConsultationMethod } from '@consts/enums';
import BaseButton from '@components/BaseButton/BaseButton';
import video from '../../../images/doctorImages/video.svg';
import { BookingStatus } from '@consts/enums';
import { APPOINTMENTS_STATUS } from '@consts/consts';
import classNames from 'classnames';
// import { getScreeningResult } from '@services/screeningResult';
// import { getRemarks } from '@services/remarks';
import { Spin, message } from 'antd';
import { AppointmentDetail } from '@services/types/appointmentDetails.type';
import appointmentDetailsService from '@services/appointmentDetailsService';
import { useLocalNavigate } from 'src/router/routerTools';
import { useSearchParams } from 'react-router-dom';
import { UUTUBE_TYPE } from '@consts/consts';
import { useMemoizedFn } from 'ahooks';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import Detail from './components/Detail';

// import { message } from 'antd';

// import AppointmentsDetailTable from './components/AppointmentsDetailTable';
// import SearchBar from './components/SearchBar';
// import { SimpleBookingStatus } from '@consts/enums';
// import moment from 'moment';
// import { PAGE_SIZE } from '@consts/consts';

const style = {
  container: {
    background: '#f5f7fa',
  },
};

// const selectType = {
//   Upcoming: SimpleBookingStatus.Upcoming,
//   OnGoing: SimpleBookingStatus.Ongoing,
//   Completed: SimpleBookingStatus.Completed,
//   All: null,
// };

const AppointmentsDetail: React.FC = () => {
  const appointmentsId = useSearchParams()[0].get('appointmentsId');
  const [allValue, setAllValue] = useState<AppointmentDetail>();

  const defaultSelectTab =
    useSearchParams()[0].get('tab') ||
    (allValue?.fromProductType === UUTUBE_TYPE ? SelectDetailType.Detail : SelectDetailType.Remarks);
  const navigate = useLocalNavigate();
  // const selectTabs = {
  //   [SelectDetailType.ScreeningResult]: 'screeningResult',
  //   [SelectDetailType.Prescriptions]: 'prescriptions',
  //   [SelectDetailType.Remarks]: 'remarks',
  //   [SelectDetailType.ShipmentDetails]: 'shipmentDetails',
  // };

  const [selectStatus, setSelectStatus] = useState<SelectDetailType>(+defaultSelectTab);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const getAllData = useMemoizedFn(async () => {
    try {
      if (!appointmentsId) {
        return;
      }
      setIsDataLoading(true);
      const data = await appointmentDetailsService.getAppointmentDetails(appointmentsId + '');
      setAllValue(data);
    } catch (err: any) {
      const responseMessage = err?.response?.data?.message;
      const errorMessage = (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || 'error!';
      message.error(errorMessage);
    }
    setIsDataLoading(false);
  });

  useEffect(() => {
    appointmentsId && getAllData();
  }, [selectStatus, appointmentsId, getAllData]);
  const [doctorDetails, setDoctorDetails] = useState<{
    id?: number;
    userId?: number;
    doctorId?: number;
    timeslotDate?: string;
    timeslotId?: number;
    timeslotStartTime?: string;
    timeslotEndTime?: string;
    amount?: string;
    currency?: number;
    consultType?: number;
    status?: number;
    createdAt?: string;
    phone?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    dob?: string;
    hkid?: string;
  }>();

  const renderButton = () => {
    if (doctorDetails?.status === BookingStatus.Consultation) {
      return (
        <BaseButton
          htmlType="submit"
          buttonClassName="md:w-[134px] md:h-[31px] md:text-[16px]"
          style={{
            paddingLeft: '13px',
            paddingRight: '20px',
          }}
          value={
            <div className="flex cursor-pointer items-center justify-center">
              <div>
                <img src={video} alt="video" className="object-cover lg:w-[17px]" />
              </div>
              <div
                className="lg:ml-[10px] font-[500] lg:text-[14px] lg:leading-[22px] text-[#FFFFFF]"
                data-clickable
                id="[indicaid][/doctor/appointmentsDetails][Start Now]"
                onClick={() => {
                  if (allValue?.patient?.meetingLink.includes('?_d=1')) {
                    window.location.href = `/meeting/${encodeURIComponent(allValue.patient.meetingLink)}`;
                  } else {
                    allValue?.patient?.meetingLink &&
                      (window.location.href = `/meeting/${encodeURIComponent(allValue.patient.meetingLink)}?_d=1`);
                  }
                }}
              >
                Start Now
              </div>
            </div>
          }
          status="doctorDefault"
        ></BaseButton>
      );
    } else {
      return (
        <BaseButton
          htmlType="submit"
          buttonClassName="md:w-[134px] md:h-[32px] md:text-[16px]"
          style={{
            paddingLeft: '24px',
            paddingRight: '24px',
          }}
          value={
            (doctorDetails?.status && APPOINTMENTS_STATUS[doctorDetails?.status as keyof typeof APPOINTMENTS_STATUS]) ||
            (doctorDetails?.status && BookingStatus[doctorDetails?.status]) ||
            ''
          }
          status="disabled"
          disable={true}
        ></BaseButton>
      );
    }
  };

  const renderShipmentDetailsButton = () => {
    if (doctorDetails?.status === BookingStatus.Completed) {
      return (
        <BaseButton
          htmlType="submit"
          buttonClassName="md:w-[134px] md:h-[32px] md:text-[16px]"
          style={{
            paddingLeft: '24px',
            paddingRight: '24px',
          }}
          value={
            (doctorDetails?.status && APPOINTMENTS_STATUS[doctorDetails?.status as keyof typeof APPOINTMENTS_STATUS]) ||
            (doctorDetails?.status && BookingStatus[doctorDetails?.status]) ||
            ''
          }
          status="disabled"
          disable={true}
        ></BaseButton>
      );
    } else {
      return (
        <BaseButton
          htmlType="submit"
          buttonClassName="md:w-[164px] md:h-[32px] md:text-[16px]"
          // style={{
          //   paddingLeft: '24px',
          //   paddingRight: '24px',
          // }}
          value={
            <div className="flex cursor-pointer items-center justify-center">
              <div className=" font-[500] lg:text-[14px] lg:leading-[22px] text-[#FFFFFF]">Mark As Shipped</div>
            </div>
          }
          status="doctorDefault"
        ></BaseButton>
      );
    }
  };
  // TODO: ScreeningResult这里需要处理一下空状态
  const renderSelectTabs = (bookingId?: string) => {
    if (selectStatus === SelectDetailType.ScreeningResult) {
      return <ScreeningResult allValue={allValue} />;
    } else if (selectStatus === SelectDetailType.Prescriptions) {
      return (
        bookingId && (
          <Prescriptions
            getAllData={getAllData}
            prescription={allValue?.prescriptions}
            bookingId={bookingId}
            appointmentsId={appointmentsId}
          />
        )
      );
    } else if (selectStatus === SelectDetailType.Remarks) {
      return <Remarks allValue={allValue} />;
    } else if (selectStatus === SelectDetailType.Detail && allValue) {
      return <Detail allValue={allValue} />;
    } else {
      return <ShipmentDetails shipmentDetails={allValue?.shipment} renderButton={renderShipmentDetailsButton} />;
    }
  };

  useEffect(() => {
    setDoctorDetails(handleLocalStorage.get(website.doctor_details));
    // console.log(doctorDetails)
  }, []);

  useEffect(() => {
    setSelectStatus(+defaultSelectTab);
  }, [defaultSelectTab]);

  const avatarTxt = (allValue?.patient?.firstName || allValue?.patient?.lastName || '').substring(0, 1);
  return (
    <Spin
      className="max-h-[9999999px!important]"
      spinning={isDataLoading}
      indicator={<Loading3QuartersOutlined spin style={{ fontSize: 24 }} />}
      wrapperClassName="w-full"
    >
      <div
        className="w-full h-full flex  bg-opacity-[0.8] flex-col py-[0] px-[24px] md:px-[32px] min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)] lg:h-[1024px] lg:p-[60px_38px_35px_65px]"
        style={style.container}
      >
        <div className="flex flex-col">
          <div
            className="flex lg:mb-[24px] items-center"
            data-clickable
            id="[indicaid][/doctor/appointmentsDetails][Back to appointments]"
            onClick={() => {
              navigate('/doctor/appointments');
            }}
          >
            <div>
              <img src={back} alt="back" className="object-cover lg:w-[32px] cursor-pointer" />
            </div>
            <div className="lg:ml-[22px] font-[600] text-[#52575C] lg:text-[16px] lg:leading-[20px] lg:tracking-[0.1px]">
              Back to appointments
            </div>
          </div>
          <div className="font-[700] lg:text-[28px] lg:leading-[42px] text-[#25282B] lg:mb-[30px]">
            Appointments Details
          </div>
          <div className="sm:p-[18px_67px_18px_25px] bg-[#FFFFFF]  sm:shadow-[0_12px_26px_rgba(16,30,115,0.06)] sm:rounded-[8px] flex justify-between items-center">
            <div className="flex flex-col">
              <div className="flex flex-row items-center font-[600] sm:text-[18px] sm:leading-[26px] text-[#25282B]">
                <div className="flex-shrink-0 w-[36px] h-[36px] rounded-[100px] bg-[#009FAF] flex justify-center items-center sm:mr-[25px] sm:w-[48px] sm:h-[48px] sm:rounded-[100px]">
                  <div className={classNames('font-[600] sm:text-[18px] text-[#FFFFFF]')}>{avatarTxt}</div>
                </div>
                {`${allValue?.patient?.firstName || ''} ${allValue?.patient?.lastName || ''}`}
                &nbsp;&nbsp;&nbsp;{allValue?.patient?.hkid && '|'}&nbsp;&nbsp;
                {`${
                  (allValue?.patient?.timeslotDate &&
                    new Date(allValue.patient.timeslotDate).toLocaleDateString('zh-HK')) ||
                  ''
                } ${allValue?.patient?.timeslotStartTime || ''}`}
              </div>
            </div>
            {allValue?.patient?.consultType === ConsultationMethod.Online && renderButton()}
          </div>
          <div className="flex flex-col lg:mt-[46px]">
            <div className="flex">
              <div
                className={classNames(
                  selectStatus === SelectDetailType.Detail
                    ? 'text-[#009FAF] bg-[#FFFFFF] lg:shadow-[inset_-1px_0px_0px_#F0F0F0,inset_1px_0px_0px_#F0F0F0]'
                    : 'text-[rgba(0,0,0,0.25)] bg-[#FBFBFB]  lg:border-[1px] border-[solid] border-[#F0F0F0] lg:shadow-[4px_10px_16px_rgba(16,30,115,0.06)]',
                  'lg:px-[25px] lg:py-[8px] lg:rounded-[8px_8px_0px_0px]  font-[600] lg:text-[16px] lg:leading-[20px] lg:tracking-[0.1px] lg:mr-[10px] select-none cursor-pointer',
                )}
                data-clickable
                id="[indicaid][/doctor/appointmentsDetails][Tab][Details]"
                onClick={() => {
                  setSelectStatus(SelectDetailType.Detail);
                }}
              >
                Detail
              </div>
              <div
                className={classNames(
                  selectStatus === SelectDetailType.ScreeningResult
                    ? 'text-[#009FAF] bg-[#FFFFFF] lg:shadow-[inset_-1px_0px_0px_#F0F0F0,inset_1px_0px_0px_#F0F0F0]'
                    : 'text-[rgba(0,0,0,0.25)] bg-[#FBFBFB]  lg:border-[1px] border-[solid] border-[#F0F0F0] lg:shadow-[4px_10px_16px_rgba(16,30,115,0.06)]',
                  'lg:px-[25px] lg:py-[8px] lg:rounded-[8px_8px_0px_0px]  font-[600] lg:text-[16px] lg:leading-[20px] lg:tracking-[0.1px] lg:mr-[10px] select-none cursor-pointer',
                )}
                data-clickable
                id="[indicaid][/doctor/appointmentsDetails][Tab][Screening Result]"
                onClick={() => {
                  setSelectStatus(SelectDetailType.ScreeningResult);
                }}
              >
                Screening Result
              </div>
              <div
                className={classNames(
                  selectStatus === SelectDetailType.Prescriptions
                    ? 'text-[#009FAF] bg-[#FFFFFF] lg:shadow-[inset_-1px_0px_0px_#F0F0F0,inset_1px_0px_0px_#F0F0F0]'
                    : 'text-[rgba(0,0,0,0.25)] bg-[#FBFBFB]  lg:border-[1px] border-[solid] border-[#F0F0F0] lg:shadow-[4px_10px_16px_rgba(16,30,115,0.06)]',
                  'lg:px-[25px] lg:py-[8px] lg:rounded-[8px_8px_0px_0px] font-[600] lg:text-[16px] lg:leading-[20px] lg:tracking-[0.1px] lg:mr-[10px] select-none cursor-pointer',
                )}
                data-clickable
                id="[indicaid][/doctor/appointmentsDetails][Tab][Prescriptions]"
                onClick={() => {
                  setSelectStatus(SelectDetailType.Prescriptions);
                }}
              >
                Prescriptions
              </div>
              <div
                className={classNames(
                  selectStatus === SelectDetailType.Remarks
                    ? 'text-[#009FAF] bg-[#FFFFFF] lg:shadow-[inset_-1px_0px_0px_#F0F0F0,inset_1px_0px_0px_#F0F0F0]'
                    : 'text-[rgba(0,0,0,0.25)] bg-[#FBFBFB]  lg:border-[1px] border-[solid] border-[#F0F0F0] lg:shadow-[4px_10px_16px_rgba(16,30,115,0.06)]',
                  'lg:px-[25px] lg:py-[8px] lg:rounded-[8px_8px_0px_0px] font-[600] lg:text-[16px] lg:leading-[20px] lg:tracking-[0.1px] select-none cursor-pointer  lg:mr-[10px] ',
                )}
                data-clickable
                id="[indicaid][/doctor/appointmentsDetails][Tab][Remarks]"
                onClick={() => {
                  setSelectStatus(SelectDetailType.Remarks);
                }}
              >
                Remarks
              </div>
              {/* 只有online的时候才展示地址选项 */}
              {allValue?.patient?.consultType === ConsultationMethod.Online && allValue?.shipment && (
                <div
                  className={classNames(
                    selectStatus === SelectDetailType.ShipmentDetails
                      ? 'text-[#009FAF] bg-[#FFFFFF] lg:shadow-[inset_-1px_0px_0px_#F0F0F0,inset_1px_0px_0px_#F0F0F0]'
                      : 'text-[rgba(0,0,0,0.25)] bg-[#FBFBFB]  lg:border-[1px] border-[solid] border-[#F0F0F0] lg:shadow-[4px_10px_16px_rgba(16,30,115,0.06)]',
                    'lg:px-[25px] lg:py-[8px] lg:rounded-[8px_8px_0px_0px] font-[600] lg:text-[16px] lg:leading-[20px] lg:tracking-[0.1px] select-none cursor-pointer',
                  )}
                  data-clickable
                  id="[indicaid][/doctor/appointmentsDetails][Tab][Shipment Details]"
                  onClick={() => {
                    setSelectStatus(SelectDetailType.ShipmentDetails);
                  }}
                >
                  Shipment Details
                </div>
              )}
            </div>
            {renderSelectTabs(allValue?.bookingId)}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default AppointmentsDetail;
