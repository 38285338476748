import { useMemoizedFn } from 'ahooks';
import { useEffect, useState } from 'react';
import RtcAdapter from '../RtcTool';
import { ConnectionDisconnectedReason, ConnectionState } from '../types.rtc';

export default function useRtcConnection(
  cb?: (
    curState: ConnectionState,
    preState: ConnectionState,
    reason?: ConnectionDisconnectedReason | undefined,
  ) => void,
) {
  const cbMemo = useMemoizedFn(cb || (() => {}));

  const [connectionState, setConnectionState] = useState<ConnectionState>(RtcAdapter.connectionState);
  useEffect(() => {
    return RtcAdapter.on('connect-status-change', (cur, pre, reason) => {
      cbMemo(cur, pre, reason);
      setConnectionState(cur);
    });
  }, [cbMemo]);

  return connectionState;
}
