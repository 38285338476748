import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const CustomUserSpeakingSvg = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.3724 21.9049C17.1819 21.9049 21.0807 18.0061 21.0807 13.1966C21.0807 8.38713 17.1819 4.48828 12.3724 4.48828C7.56292 4.48828 3.66406 8.38713 3.66406 13.1966C3.66406 18.0061 7.56292 21.9049 12.3724 21.9049Z"
      stroke="#2DBDB6"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <mask
      id="mask0_1912_15265"
      // style="mask-type:luminance"
      maskUnits="userSpaceOnUse"
      x="3"
      y="4"
      width="19"
      height="19"
    >
      <path
        d="M12.375 21.5078C16.9659 21.5078 20.6875 17.7862 20.6875 13.1953C20.6875 8.60445 16.9659 4.88281 12.375 4.88281C7.78413 4.88281 4.0625 8.60445 4.0625 13.1953C4.0625 17.7862 7.78413 21.5078 12.375 21.5078Z"
        fill="white"
        stroke="#1B7E5D"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </mask>
    <g mask="url(#mask0_1912_15265)">
      <path
        d="M12.3724 13.194C13.9027 13.194 15.1432 11.9535 15.1432 10.4232C15.1432 8.89289 13.9027 7.65234 12.3724 7.65234C10.8421 7.65234 9.60156 8.89289 9.60156 10.4232C9.60156 11.9535 10.8421 13.194 12.3724 13.194Z"
        fill="#2DBDB6"
        stroke="#2DBDB6"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </g>
    <mask
      id="mask1_1912_15265"
      // style="mask-type:luminance"
      maskUnits="userSpaceOnUse"
      x="3"
      y="4"
      width="19"
      height="19"
    >
      <path
        d="M12.375 21.5078C16.9659 21.5078 20.6875 17.7862 20.6875 13.1953C20.6875 8.60445 16.9659 4.88281 12.375 4.88281C7.78413 4.88281 4.0625 8.60445 4.0625 13.1953C4.0625 17.7862 7.78413 21.5078 12.375 21.5078Z"
        fill="white"
        stroke="#1B7E5D"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </mask>
    <g mask="url(#mask1_1912_15265)">
      <path
        d="M12.375 27.4453C15.6542 27.4453 18.3125 24.787 18.3125 21.5078C18.3125 18.2286 15.6542 15.5703 12.375 15.5703C9.09581 15.5703 6.4375 18.2286 6.4375 21.5078C6.4375 24.787 9.09581 27.4453 12.375 27.4453Z"
        fill="#2DBDB6"
        stroke="#2DBDB6"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default function CustomUserSpeaking(props: Partial<CustomIconComponentProps>) {
  return (
    <Icon
      component={CustomUserSpeakingSvg}
      style={{
        transform: 'scale(0.8)',
        paddingBottom: '3px',
      }}
      {...props}
    />
  );
}
