import * as amplitude from '@amplitude/analytics-browser';
import LocalStore from './localStore/localStore';

amplitude.init(process.env.AMPLITUDE_KEY!, undefined, {
  appVersion: '1.0',
  defaultTracking: {
    sessions: true,
    pageViews: true,
    formInteractions: false,
    fileDownloads: false,
  },
});

export function track(event: string | amplitude.Types.BaseEvent) {
  return amplitude.track(event);
}

export function getDataTrackConstantId(key: string) {
  let constantId = LocalStore.get(key);

  if (!constantId) {
    constantId = Date.now().toString();
    LocalStore.set(key, constantId);
  }
  return constantId;
}

export function clearDataTrackConstantId(key: string) {
  LocalStore.remove(key);
}
