// import { GlobalOutlined } from '@ant-design/icons';
import logo from '@images/INDICAIDLogo.png';
// import { useState } from 'react';
import { useLocalNavigate } from 'src/router/routerTools';

export default function HeaderToolBar() {
  const navigate = useLocalNavigate();
  // const [languageStatus, setLanguageStatus] = useState(false);
  // const languageInfos = [
  //   { text: 'EN', url: '' },
  //   { text: '繁', url: '' },
  //   // { text: '简', url: '' },
  // ];
  // const closeAllMenu = () => {
  //   setLanguageStatus(false);
  // };

  return (
    <div className="header-container">
      <div className="w-[100%] h-[128px] lg:h-[79px]"></div>
      <div className="flex w-[100%] justify-center fixed top-0 left-0 z-20 bg-[#FDFDFD]">
        <div className="flex w-[88.4%] sm:w-[86.9%] lg:w-[84.7%] py-[24px] lg:h-[79.9992px] items-center lg:justify-between justify-center lg:pt-[12.0007px] lg:pb-[12.0007px]">
          <img
            src={logo}
            alt="logo"
            className="object-cover w-[175px] lg:w-[210px] cursor-pointer"
            onClick={() => {
              navigate('/');
            }}
          />
          <div className="lg:block hidden"></div>
        </div>
      </div>
    </div>
  );
}
// <div
// className="flex lg:mr-[45px] py-[0px]"
// onMouseEnter={() => {
//   setLanguageStatus(true);
// }}
// onMouseLeave={() => closeAllMenu()}
// >
// <GlobalOutlined
//   className="active:text-[#3c4148] active:bg-[#eaf3f3] lg:mr-[12px]"
//   style={{
//     color: '#2DBDB6',
//     fontSize: '19px',
//   }}
// />
// {/* EN/繁 */}
// <div className="font-[600] text-[16px] text-[#323232]lg:ml-[14px] lg:leading-[75px]">EN</div>
// {languageStatus && (
//   <div className="absolute lg:top-[70px] z-10 shadow-lg rounded-[10px] overflow-hidden">
//     <div className="bg-[#FFFFFF] relative pt-[8px] pb-[8px]">
//       <div className="lg:w-[77px] lg:h-[0px] lg:border-[#2DBDB6] lg:border-solid lg:border-[3px] absolute lg:top-[-7px]"></div>
//       {languageInfos.map((info) => (
//         <div className="lg:mb-[8px] lg:mx-[8px]">
//           {/* lg:mx-[16px]  lg:my-[12px]*/}
//           <div
//             className="bg-[#FFFFFF] hover:bg-[#56bdb6] hover:bg-opacity-[0.19] w-[180px] h-[44px] text-center leading-[44px] rounded-[8px] text-[14px]"
//             onClick={() => {
//               if (info?.url) {
//                 navigate(info?.url);
//               } else {
//                 return;
//               }
//             }}
//           >
//             {info.text}
//           </div>
//         </div>
//       ))}
//     </div>
//   </div>
// )}
// </div>
