import TextInput, { TextInputStatus } from '@components/TextInput/TextInput';

import { useEffect, useState } from 'react';

export type FiledInputPropsOnCheckValidType = (
  v: string,
  option: {
    setStatus: React.Dispatch<React.SetStateAction<TextInputStatus>>;
    setHelpText: React.Dispatch<React.SetStateAction<string>>;
  },
  isSubmitCheck?: boolean | string,
) => void;

export type FiledInputProps = {
  title: string | null;
  placeholder: string;
  value: string;
  onChange?: (v: string) => void;
  /**
   * 检查数据合法性
   * @param v 值
   * @param option
   * @returns
   */
  onCheckValid?: FiledInputPropsOnCheckValidType;
  isSubmitCheck?: boolean | string;
  id?: string;
  errorId?: string;
  className?: string;
  required?: boolean;
  number?: boolean;
};

export function FiledTitle(props: { title: string; required?: boolean }) {
  return (
    <p className="font-[600] text-[16px] text-[#1D1D1D] lg:mt-[26px] mt-[26px] lg:mb-[8px] mb-[5px] lg:text-[16px] flex">
      {props.title}
      {(props.required ?? true) && <span className="font-[600] text-[#FF0000]">*</span>}
    </p>
  );
}

export default function FiledInput(props: FiledInputProps) {
  const { value, onCheckValid, isSubmitCheck } = props;

  const [helpText, setHelpText] = useState('');
  const [status, setStatus] = useState<TextInputStatus | undefined>();

  useEffect(() => {
    onCheckValid?.(
      value,
      {
        setHelpText,
        setStatus,
      },
      isSubmitCheck,
    );
  }, [value, onCheckValid, isSubmitCheck]);

  return (
    <div className={props.className ?? 'lg:w-[762px] m-auto'}>
      {props.title && <FiledTitle title={props.title} required={props.required} />}
      <TextInput
        value={props.value}
        status={status}
        placeholder={props.placeholder}
        inputClassName={'h-[48px] lg:text-[16px] text-[16px] md:h-[48px] lg:h-[43px]'}
        onChange={(e) => props.onChange?.(e.target.value)}
        helpText={helpText}
        id={props.id}
        errorId={props.errorId}
        number={props.number}
      ></TextInput>
    </div>
  );
}
