import React, { useState, useEffect, Fragment } from 'react';
import BaseButton from '@components/BaseButton/BaseButton';
import { useMemoizedFn } from 'ahooks';

import UpcomingAppointment from './UpcomingAppointment';
import CompletedAppointments from './CompletedAppointments';
import { MEDIA_SIZE } from '@consts/consts';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { BookingStatus } from '@consts/enums';
import classNames from 'classnames';

import empty from '../../images/empty.svg';
import { useLocalNavigate } from 'src/router/routerTools';
import { useTranslation } from 'react-i18next';
import { track } from '@utils/dataTrack';
import { AppointmentsInfo } from '@services/types/appointmentsInfos.type';

type AppointmentsProps = {
  value?: string | ReadonlyArray<string> | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  width?: number | string;
  placeholder?: string;
  disable?: boolean;
  helpText?: string;
  appointmentsInfo?: AppointmentsInfo;
};

export default function Appointments(props: AppointmentsProps = {}) {
  const [t] = useTranslation();
  const [isEmpty, setIsEmpty] = useState(false);
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const navigate = useLocalNavigate();

  // TODO 跳转至问卷页面，后续可能需要调整
  const onClick = useMemoizedFn(async () => {
    navigate('/product-listing');
    // window.open(`${location.origin}/selectResult`, '_blank');
  });

  useEffect(() => {
    const noArr = !(
      Array.isArray(props?.appointmentsInfo?.completed) && Array.isArray(props?.appointmentsInfo?.upcoming)
    );

    setIsEmpty(
      noArr ||
        Boolean(
          props?.appointmentsInfo?.completed &&
            props?.appointmentsInfo?.completed.length === 0 &&
            props?.appointmentsInfo?.upcoming &&
            props?.appointmentsInfo?.upcoming.length === 0,
        ),
    );
  }, [props?.appointmentsInfo?.completed, props?.appointmentsInfo?.upcoming]);
  useEffect(() => {
    track({
      event_type: 'view my appointments',
      event_properties: {
        source: window.document.referrer || 'direct',
      },
    });
  }, []);
  if (!props.appointmentsInfo) {
    return null;
  }

  return (
    <div className={classNames(!isLg && isEmpty && 'mt-[27px] mb-[83px]')}>
      {isEmpty ? (
        <>
          {isLg && (
            <div className="font-[600] lg:text-[28px] lg:leading-[36px] text-[#212121] lg:mt-[86px]">
              {t('Appointments')}
            </div>
          )}

          <div
            className={classNames(
              // props?.isLastOne ? 'lg:mb-[144px]' : 'lg:mb-[0px]',
              'flex rounded-[10px] shadow-[0_10px_30px_rgba(171,190,209,0.2)] flex-col bg-[#FFFFFF] pt-[11px] px-[22px] lg:rounded-[10px] lg:shadow-[0_10px_30px_rgba(171,190,209,0.2)] lg:pt-[36px] lg:px-[33px] lg:w-[843px] lg:flex-row lg:items-center lg:mb-[317px] lg:mt-[40px] lg:pb-[30px]',
            )}
          >
            {!isLg && <div className="font-[600] text-[22px] leading-[36px] text-[#212121]">{t('Appointments')}</div>}
            <div className="flex mt-[40px] items-center mb-[16px] lg:mt-[0px] lg:mb-[0px]">
              {/* 上下相差6px */}
              <div className="lg:w-[76px] lg:mr-[38px]  lg:mb-[3px]">
                <img src={empty} alt="empty" className="object-cover w-[38px] lg:w-[76px]" />
              </div>
              {!isLg && (
                <div className="font-[600] text-[18px] leading-[24px] text-[#2DBDB6] ml-[15px] lg:w-[275px] lg:text-[16px] lg:leading-[24px]">
                  {t('MyAccountPage.SpeakWithALicensedDoctor')}
                </div>
              )}
            </div>
            {isLg && (
              <div className="flex flex-col">
                <div className="font-[600] text-[18px] leading-[24px] text-[#2DBDB6] lg:text-[16px] lg:leading-[28px] lg:mb-[20px]">
                  {t('MyAccountPage.SpeakWithALicensedDoctor')}
                </div>
                <div className="font-[400] text-[16px] leading-[24px] text-[#212121] lg:w-[437px] lg:text-[16px] lg:leading-[24px]">
                  {t('MyAccountPage.GetAnAll-in-oneServiceText')}
                </div>
              </div>
            )}

            {!isLg && (
              <div className="font-[400] text-[16px] leading-[24px] text-[#212121] lg:w-[275px] lg:text-[16px] lg:leading-[24px]">
                {t('MyAccountPage.GetAnAll-in-oneServiceText')}
              </div>
            )}
            <BaseButton
              htmlType="submit"
              buttonClassName="w-full h-[50px] mt-[43px] mb-[64px] md:w-[187px] md:h-[37px] md:text-[16px] md:mt-[0px] md:mb-[0px] md:ml-[50px]"
              value={t('MyAccountPage.BookNow')}
              status={'default'}
              onClick={onClick}
              id="myAppointment__bookNow-button"
            ></BaseButton>
          </div>
        </>
      ) : (
        <div className="lg:flex lg:flex-col lg:mt-[83px]">
          <div className=" text-[22px] leading-[36px] mt-[27px]  mb-[25px] font-[600] lg:text-[28px] lg:leading-[36px] text-[#212121]">
            {t('Appointments')}
          </div>
          {props?.appointmentsInfo?.upcoming &&
            props?.appointmentsInfo?.upcoming.map((upcoming, index) => (
              <Fragment key={upcoming.meetingLink}>
                {/* isLg && */}
                {index === 0 && (
                  <div className="text-[16px] leading-[24px] mb-[25px] font-[600] lg:text-[16px] lg:leading-[28px] text-[#2DBDB6] lg:mb-[26px] lg:mt-[49px] lg:tracking-[0.25px]">
                    {t('MyAccountPage.AppointmentPart.CurrentAppointment')}
                    {/* {upcoming?.status === BookingStatus.Prescription
                      ? t('MyAccountPage.AppointmentPart.On-goingAppointments')
                      : t('MyAccountPage.AppointmentPart.UpcomingAppointments')} */}
                  </div>
                )}
                <UpcomingAppointment
                  upcoming={upcoming}
                  isRemove={index !== 0}
                  completedLength={props?.appointmentsInfo?.completed ? props?.appointmentsInfo?.completed.length : 0}
                  isLastOne={
                    index === (props?.appointmentsInfo?.upcoming ? props?.appointmentsInfo?.upcoming.length - 1 : -1)
                  }
                  index={index}
                />
              </Fragment>
            ))}
          {props?.appointmentsInfo?.completed &&
            props?.appointmentsInfo?.completed.map((completed, index) => (
              <Fragment key={index}>
                {index === 0 && (
                  <div className="text-[16px] leading-[24px] mb-[25px] font-[600] mt-[60px] lg:text-[16px] lg:leading-[28px] text-[#2DBDB6] lg:mb-[41px] lg:mt-[49px] lg:tracking-[0.25px]">
                    {completed?.status === BookingStatus.Prescription
                      ? t('MyAccountPage.AppointmentPart.CompletedAppointments')
                      : t('MyAccountPage.AppointmentPart.CompletedAppointments')}
                  </div>
                )}
                <CompletedAppointments
                  completed={completed}
                  isKeepTitle={
                    index === 0 && !(props?.appointmentsInfo?.upcoming && props?.appointmentsInfo?.upcoming.length > 0)
                  }
                  isLastOne={
                    index === (props?.appointmentsInfo?.completed ? props?.appointmentsInfo?.completed.length - 1 : -1)
                  }
                  isFirstOne={index === 0}
                />
              </Fragment>
            ))}
        </div>
      )}
    </div>
  );
}
