import React, { useState, useEffect, useRef } from 'react';
import { Spin } from 'antd';
import BaseButton from '@components/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { clearDataTrackConstantId, getDataTrackConstantId, track } from '@utils/dataTrack';
import { LoadingOutlined } from '@ant-design/icons';
import ComboTestResult from './components/ComboTestResult';
import CovidAndFluSelect from './components/CovidAndFluSelect';
import { useMemoizedFn } from 'ahooks';
import {
  CONTROL_LINE,
  COVID19AndFluSelectResultType,
  COVID_19,
  ComboBaseResult,
  FLU_A,
  FLU_B,
  ResultType,
} from './covidAndFluConst';
import { useLocalNavigate } from 'src/router/routerTools';
import OrderNewKitText from './components/OrderNewKit';
import alertHealthWarning from '@utils/globalComponents/HealthWarningAlert/HealthWarningAlert';
import { patientTokenStore } from '@utils/localStore/tokenStore';

const dataTrackHelpInfos = {
  sympotoms: {
    [CONTROL_LINE]: 'control line',
    [FLU_A]: 'flu a',
    [FLU_B]: 'flu b',
    [COVID_19]: 'covid-19',
  },
  result: {
    [ResultType.Covid_POSITIVE]: 'covid-19 positive',
    [ResultType.Negative]: 'negative',
    [ResultType.INVALID]: 'invalid',
    [ResultType.FLU_A_POSITIVE]: 'flu a positive',
    [ResultType.FLU_B_POSITIVE]: 'flu b positive',
    [ResultType.FLU_A_Covid_POSITIVE]: 'flu a & covid-19 positive',
    [ResultType.FLU_B_Covid_POSITIVE]: 'flu b & covid-19 positive',
  },
};

const style = {
  container: {
    background: '#FDFDFD',
  },
};

const Covid19AndFluSelectResult: React.FC = () => {
  useEffect(() => {
    const t = setTimeout(() => {
      track({
        event_type: 'view combo test result',
        event_properties: {
          'testing id': getDataTrackConstantId('TestingId'),
        },
      });
    }, 30);
    return () => {
      clearTimeout(t);
    };
  }, []);

  const [t] = useTranslation();
  const [loading] = useState(false);
  const [selectResultType, setSelectResultType] = useState<ResultType>(ResultType.INVALID);

  const selectedSet = useRef<Array<ComboBaseResult>>([]);

  const handleResultSelectChange = useMemoizedFn((r: ResultType, selectedList) => {
    selectedSet.current = selectedList;
    if ((r & CONTROL_LINE) === 0) {
      setSelectResultType(ResultType.INVALID);
    } else {
      setSelectResultType(r);
    }
  });
  const showOrderNewKit = selectResultType === ResultType.INVALID;
  const navigate = useLocalNavigate();

  return (
    <Spin spinning={loading} indicator={<LoadingOutlined />}>
      <div
        className="w-full h-full flex lg:pb-0 pb-[40px]  bg-opacity-[0.8] flex-col py-[0] px-[24px] md:px-[32px] md:items-center lg:px-[0px] lg:items-center min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)]"
        style={style.container}
      >
        <div className="flex flex-col justify-start w-full">
          <div className="mt-[37px] font-[600] text-[28px] leading-[36px] w-full lg:flex text-center items-center justify-center text-[#2DBDB6] lg:mt-[80px] lg:text-[40px] lg:leading-[58px]">
            <p>{t('Covid19AndFluSelectResult.SelectYourTestResult')}</p>
            <ComboTestResult resultType={selectResultType} />
          </div>
          <div className="mt-[35px] mb-[44px] font-[400] text-[16px] leading-[24px] w-full flex items-center justify-center text-[#212121] lg:mt-[45px] lg:mb-[65px] lg:text-[16px] lg:leading-[28px]  lg:w-[1127px] lg:text-center lg:mx-[auto]">
            {t('Covid19AndFluSelectResult.SelectSubTitle')}
          </div>
          <CovidAndFluSelect onResultChange={handleResultSelectChange} />
          <div className="w-full flex lg:items-center lg:justify-center pt-[40px] pb-[100px] lg:pt-[67px] lg:pb-[127px]">
            {showOrderNewKit ? (
              <OrderNewKitText
                type="covid&flu"
                id="covid19Flu-order-new-kit__button"
                dataTrackFunc={() => {
                  return track({
                    event_type: 'proceed after combo test',
                    event_properties: {
                      'testing id': getDataTrackConstantId('TestingId'),
                      'button type': 'order new',
                      symptoms: selectedSet.current.map((r) => dataTrackHelpInfos.sympotoms[r]).join(' + '),
                      'test result': dataTrackHelpInfos.result[selectResultType],
                    },
                  }).promise;
                }}
              />
            ) : (
              <BaseButton
                id="covid19Flu-select-result-submit__button"
                htmlType="submit"
                buttonClassName="w-full h-[50px] text-[16px] md:w-[326px] md:h-[50px] md:text-[16px] w-[100%] h-[50px]"
                value={t('Covid19AndFluSelectResult.CTAText')}
                onClick={() => {
                  track({
                    event_type: 'proceed after combo test',
                    event_properties: {
                      'testing id': getDataTrackConstantId('TestingId'),
                      'button type': 'book appointment',
                      symptoms: selectedSet.current.map((r) => dataTrackHelpInfos.sympotoms[r]).join(' + '),
                      'test result': dataTrackHelpInfos.result[selectResultType],
                    },
                  });
                  alertHealthWarning({
                    onOk: () => {
                      clearDataTrackConstantId('TestingId');
                      const destination = `/selectTimeSlot?selectResultType=${COVID19AndFluSelectResultType[selectResultType]}`;
                      if (patientTokenStore.get()) {
                        navigate(destination);
                      } else {
                        window.location.href = `${window.location.origin}/login?redirect=${encodeURIComponent(
                          window.location.origin + destination,
                        )}`;
                      }
                    },
                    pageIdName: 'selectResult-covidflu',
                  });
                }}
                sectionClassName="w-full md:w-[326px] m-auto"
              ></BaseButton>
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default Covid19AndFluSelectResult;
