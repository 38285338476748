import { Wifi } from '@icon-park/react';
import useNetworkQuality from '@utils/RtcAdapter/hooks/networksQuality';
import useRtcConnection from '@utils/RtcAdapter/hooks/rtcConnectionStatus';
import { useAudioStatus, useVideoStatus } from '@utils/RtcAdapter/hooks/streamStatus';
import RtcAdapter from '@utils/RtcAdapter/RtcTool';
import classNames from 'classnames';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import MeetingInfosContext from '../context/MeetingInfosContext';
import CustomUserSpeaking from './CustomUserSpeaking';
import CustomVoiceIcon from './CustomVoiceIcon';
import VoiceOffIcon from './VoiceOffIcon';
import { useTranslation } from 'react-i18next';

type UserCardProps = {
  rtcUid: string;
  sizeClassName?: string;
  userName: string;
  isMine: boolean;
  isSmall: boolean;
  onClick?: () => void;
};

export default function UserCard(props: UserCardProps) {
  const [t] = useTranslation();
  const meetingInfo = useContext(MeetingInfosContext)!;
  const sizeClassName = props.sizeClassName ?? 'w-[340px] h-[340px] sm:w-[340px] sm:h-[340px]';
  const RtcVideoCardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (RtcVideoCardRef.current) {
      return RtcAdapter.playVideo(RtcVideoCardRef.current, props.rtcUid);
    }
  }, [props.rtcUid]);
  const connectionState = useRtcConnection();
  const networksQuality = useNetworkQuality(props.rtcUid);
  const getNetworkSignalColor = () => {
    if (connectionState !== 'CONNECTED') {
      return 'red';
    }
    if (networksQuality <= 2) {
      return '#2DBDB6';
    } else if (networksQuality <= 4) {
      return 'yellow';
    } else {
      return 'red';
    }
  };
  const audioStatus = useAudioStatus(props.rtcUid);
  const videoStatus = useVideoStatus(props.rtcUid);
  const voiceIcon = audioStatus ? <CustomVoiceIcon /> : <VoiceOffIcon scale={0.7} className="ml-[3px] mt-[1px]" />;

  const connectionText = useMemo(() => {
    if (!props.isMine) {
      return undefined;
    }
    if (connectionState === 'CONNECTED' || connectionState === 'CONNECTING') {
      return undefined;
    }
    if (connectionState === 'RECONNECTING') {
      return 'RECONNECTING'.toLocaleLowerCase();
    } else {
      return connectionState.toLocaleLowerCase();
    }
  }, [connectionState, props.isMine]);

  const [isUserLeft, setIsUserLeft] = useState(false);
  useEffect(() => {
    const c1 = RtcAdapter.on('user-left', (remoteRtcUid) => {
      if (remoteRtcUid === props.rtcUid) {
        setIsUserLeft(true);
      }
    });
    const c2 = RtcAdapter.on('user-joined', (remoteRtcUid) => {
      if (remoteRtcUid === props.rtcUid) {
        setIsUserLeft(false);
      }
    });
    return () => {
      c1();
      c2();
    };
  }, [props.rtcUid]);

  const renderExceptionText = () => {
    return (
      <>
        {(!videoStatus || connectionText || isUserLeft) && (
          <div
            className={classNames(
              props.isSmall ? 'text-[18px]' : 'text-[24px]',
              'w-full h-full absolute bg-[#00000040] top-0 left-0 flex flex-col items-center justify-center',
            )}
          >
            {isUserLeft && !props.isMine && (
              <p className="text-[#ffffff] font-medium lg:text-[24px]">
                Reconnecting your {meetingInfo.role === 2 ? 'patient' : 'doctor'} ...
              </p>
            )}
            {!videoStatus && !isUserLeft && (
              <p className="text-[#ffffff] font-medium lg:text-[24px]">{t('CameraIsOff')}</p>
            )}
            {connectionText && <p className="text-[#ffffff] font-medium lg:text-[24px]">{connectionText}</p>}
          </div>
        )}
      </>
    );
  };

  return (
    <div
      className={classNames(
        'rtc-preview-card absolute bg-[#1c1c1c90] rounded-[10px] sm:rounded-[10px] overflow-hidden',
        sizeClassName,
      )}
    >
      <div className="w-full h-full relative" onClick={props.onClick}>
        <div ref={RtcVideoCardRef} className="w-full h-full"></div>
        {renderExceptionText()}
      </div>
      <Wifi
        className="absolute top-[3.5%] right-[2%] lg:top-[3.5%] lg:right-[3%]"
        theme="two-tone"
        size="26"
        fill={[getNetworkSignalColor()]}
      />
      <div
        className={classNames(
          'h-[32px] rounded-[4px] bg-[#141414] absolute  flex items-center pl-[6px] pr-[12px]',
          'lg:left-[2%] lg:bottom-[3%] lg:top-[initial]',
          'top-[3.5%] left-[3.5%]',
        )}
      >
        {props.isMine ? <CustomUserSpeaking /> : voiceIcon}
        <p className="font-semibold text-[14px] text-white">{props.userName}</p>
      </div>
    </div>
  );
}
