const stopStreamTracks = (stream: MediaStream) => {
  if (!stream || !stream.getTracks) {
    return;
  }
  try {
    const tracks = stream.getTracks();
    tracks.forEach((it) => {
      try {
        it.stop();
      } catch (errMsg) {
        // debugger;
      }
    });
  } catch (errMsg) {
    // debugger;
  }
};

export const requestMediaPermission = async () => {
  try {
    const stream = await global.navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    stopStreamTracks(stream);
    if (stream) {
      return true;
    }
  } catch (errMsg: any) {
    if (errMsg && 'NotAllowedError' === errMsg.name) {
      return false;
    }
  }
  return true;
};
