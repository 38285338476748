import TextInput from '@components/TextInput/TextInput';
import { useState } from 'react';

export default function EditableInput(props: {
  defaultValue: string;
  onBlur?: (v: string) => void;
  integer?: boolean;
  halfInteger?: boolean;
  id?: string;
}) {
  const [value, setValue] = useState(props.defaultValue);
  return (
    <TextInput
      id={props.id}
      value={value}
      number={!props.integer && !props.halfInteger}
      inputClassName="sm:w-[77px] sm:h-[46px] border-[1px] border-[#D5D4DC]"
      onChange={(e) => {
        const val = e.target.value;
        if (val.includes('e')) {
          return;
        }

        if (props.integer) {
          if (val === '') {
            setValue('');
            return;
          }
          const intValue = parseInt(val, 10);
          setValue(`${isNaN(intValue) ? '' : intValue}`);
        } else if (props.halfInteger) {
          if (val === '') {
            setValue('');
            return;
          }
          if (val.endsWith('.')) {
            setValue(val);
            return;
          }
          const floatValue = parseFloat(val);
          if (isNaN(floatValue)) {
            setValue('');
          } else {
            if (floatValue % 0.5 === 0) {
              setValue(`${floatValue}`);
            }
          }
        } else {
          setValue(e.target.value);
        }
      }}
      onBlur={() => {
        if (props.integer) {
          setValue((val) => parseInt(val, 10) + '');
        } else if (props.halfInteger) {
          setValue((val) => parseFloat(val) + '');
        }
        props.onBlur?.(value);
      }}
    />
  );
}
