import { UserInfoType } from '@services/types/userInfos.type';
import userService from '@services/userService';
import { atom, selector, useRecoilValueLoadable } from 'recoil';
export const patientInfosState = atom<UserInfoType | null>({
  key: 'patientInfosState',
  default: null,
});

export const patientInfosSelectorState = selector({
  key: 'patientInfosSelectorState',
  get: async ({ get }) => {
    let curPatientInfos = get(patientInfosState);
    if (curPatientInfos) {
      return curPatientInfos;
    } else {
      try {
        const patientInfos = await userService.getUseInfos({ redirect: false });
        return patientInfos;
      } catch {
        return null;
      }
    }
  },
});

export const isLoginState = selector({
  key: 'isLoginState',
  get: ({ get }) => {
    const curPatientInfos = get(patientInfosSelectorState);
    return !!curPatientInfos;
  },
});

export const usePatientInfos = () => {
  const patientInfos = useRecoilValueLoadable(patientInfosSelectorState);
  console.log('patientInfos', patientInfos);

  return {
    patientInfos: patientInfos.state === 'hasValue' ? patientInfos.contents : null,
    isLoading: patientInfos.state === 'loading',
    isLogin: patientInfos.state === 'hasValue' ? !!patientInfos.contents : false,
  };
};
