import { useImperativeHandle, useState, forwardRef, useEffect, useRef } from 'react';
import './inputOpt.scss';
import classNames from 'classnames';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { MEDIA_SIZE } from '@consts/consts';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import OptSingleBox, { OptSingleBoxRef } from './OptSingleBox';

const PIN_LENGTH = 4;
type InputOtpProps = {
  onValueChange?: (val: string) => void;
  isError?: boolean;
  InputOtpClassName?: string;
  errorText?: string | null;
  idPrefix?: string;
  errorId?: string;
};
export type InputOptRef = {
  reset: () => void;
};
const InputOtp = forwardRef<{}, InputOtpProps>((props: InputOtpProps, ref) => {
  const [value, setValue] = useState('');
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;

  const handleValueChange = (newVal: string) => {
    if (newVal) {
      const concatVal = value + newVal;
      setValue(concatVal);
      props.onValueChange?.(concatVal);
    }
  };
  const handleOnBackspaceDown = () => {
    const newVal = value.substring(0, value.length - 1);
    setValue(newVal);
    props.onValueChange?.(newVal);
  };

  const InputRefList = useRef<(OptSingleBoxRef | null)[]>([]);
  useEffect(() => {
    InputRefList.current[value.length]?.focus();
  }, [value]);
  useEffect(() => {
    setTimeout(() => {
      InputRefList.current[0]?.focus();
    }, 100);
  }, []);
  useImperativeHandle(ref, () => ({
    // changeVal 就是暴露给父组件的方法
    reset: () => {
      // TODO 这个清空目前存在问题=>需要看一下怎么解决
      // https://phasescientific.atlassian.net/browse/DPMOD-379
      setValue('');
      props.onValueChange?.('');
      InputRefList.current.forEach((c) => c?.reset());
    },
  }));
  return (
    <div className="comp-input-opt">
      <div
        className={classNames(
          props.isError ? 'border-[#C83532] bg-[#FBEFEF]' : 'border-[#D5D4DC] bg-white',
          `container w-[366px] h-[48px] outline-none border-[1px] rounded-[8px] max-w-[100%] ${props.InputOtpClassName}`,
        )}
        style={{
          justifyContent: isLg ? 'start' : 'start',
        }}
      >
        {Array.from({ length: PIN_LENGTH }).map((_, index) => {
          return (
            <span key={index} className="relative flex justify-center items-center">
              <OptSingleBox
                ref={(r) => (InputRefList.current[index] = r)}
                onBackspaceDown={handleOnBackspaceDown}
                active={value.length > index}
                onValueChange={handleValueChange}
                onClick={() => {
                  InputRefList.current[value.length]?.focus();
                }}
                id={`${props.idPrefix}single-box-${index + 1}`}
              />
              {value.length <= index && (
                <div className="border-b-[0.03rem] border-b-[#898686] absolute w-[20px] bottom-[6px] left-[10px]"></div>
              )}
            </span>
          );
        })}
      </div>
      {props.isError && (
        <div data-ts className="flex items-center leading-[0.35rem] mt-[0.3rem]" id={props.errorId}>
          <ExclamationCircleFilled
            style={{
              color: '#C83532',
              width: '0.32rem',
            }}
          />
          <span
            className="text-[0.32rem] ml-[0.1rem]"
            style={{
              color: '#C83532',
            }}
          >
            {props?.errorText || 'Invalid verification code'}
          </span>
        </div>
      )}
    </div>
  );
});

export default InputOtp;
