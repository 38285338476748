export default {
  site_name: 'UUTube',
  access_token_key: 'access_token',
  doctor_access_token_key: 'doctor_access_token',
  cookie_expires: 604800000,
  auth_header: 'Authorization',
  payment_error: 'paymentError',
  step_timeslot_date: 'step_timeslot_date',
  step_consult_type: 'step_consult_type',
  step_doctor_id: 'step_doctor_id',
  step_timeslot_id: 'step_timeslot_id',
  amount: 'amount',
  answers: 'answers',
  currency: 'currency',
  email: 'email',
  phone_number: 'phoneNumber',
  payment_data: 'paymentData',
  fill_params: 'fillParams',
  doctor_details: 'doctorDetails',
  doctor_can_resend_after: 'doctorCanResendAfter',
  doctor_login_message: 'doctorLoginMessage',
  doctor_remark_status: 'doctorRemarkStatus',
};
