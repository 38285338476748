import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import { Input, InputRef } from 'antd';
import React from 'react';
import { CSSProperties } from 'react';

const borderColor = {
  default: '#D5D4DC',
  warning: '#EF8943',
  error: '#C83532',
  success: '#2BAC47',
};
const bgColor = {
  default: '#ffffff',
  warning: '#FDF3EC',
  error: '#FBEFEF',
  success: '#F1F8F2',
};
const textColor = {
  default: '#D5D4DC',
  warning: '#EF8943',
  error: '#EB5757',
  success: '#2BAC47',
};
export type TextInputStatus = 'error' | 'warning' | 'success' | undefined;
type TextInputProps = {
  value?: string;
  onFocus?: () => void;
  onBlur?: React.ChangeEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  status?: TextInputStatus;
  placeholder?: string;
  disable?: boolean;
  helpText?: string;
  inputClassName?: string;
  iconClassName?: string;
  number?: boolean;
  helpTextStyle?: CSSProperties;
  maxLength?: number;
  id?: string;
  errorId?: string;
};

export default function TextInput(props: TextInputProps, ref: React.Ref<InputRef>) {
  const renderHelpTextIcon = () => {
    if (!props.helpText || !props.status) {
      return null;
    }
    if (props.status === 'success') {
      return (
        <CheckCircleFilled
          className={props.iconClassName}
          style={{
            color: borderColor[props.status],
          }}
        />
      );
    } else {
      return (
        <ExclamationCircleFilled
          className={props.iconClassName ?? 'text-[16px] sm:text-[16px]'}
          style={{
            color: borderColor[props.status],
          }}
        />
      );
    }
  };

  return (
    <section>
      <Input
        id={props.id}
        maxLength={props.maxLength}
        ref={ref}
        value={props.value}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        style={{
          backgroundColor: bgColor[props.status || 'default'],
          borderColor: borderColor[props.status || 'default'],
          fontFamily: 'Montserrat',
        }}
        placeholder={props.placeholder}
        disabled={props.disable}
        className={props.inputClassName ?? 'text-[16px] w-[328px] h-[50px]'}
        type={props.number ? 'number' : undefined}
      ></Input>
      {props.helpText && (
        <div className="flex items-center lg:mt-[8px]" id={props.errorId}>
          {renderHelpTextIcon()}
          <span
            className="text-[14px] ml-[4px]"
            style={{
              color: textColor[props.status || 'default'],
              ...props?.helpTextStyle,
            }}
          >
            {props.helpText}
          </span>
        </div>
      )}
    </section>
  );
}

export const TextInputWithRef = React.forwardRef(TextInput);
