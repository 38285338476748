import { RefObject, useContext } from 'react';
import { IconAccount, IconArrowDown, IconShopCart } from './icons/Icons';
import LocaleSwitcher from './LocaleSwitcher';
import { HeaderContext } from './useHeaderCtx';
import { Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import logo from '@images/INDICAIDLogo.png';
import { patientTokenStore } from '@utils/localStore/tokenStore';

export default ({ headerRef }: { headerRef: RefObject<HTMLDivElement> }) => {
  const { host, hostSelf, navData, isLogin, logoUrl } = useContext(HeaderContext);
  const [t] = useTranslation();

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <a href={`${hostSelf}/myAccount?selectPage=personalInformation`}>{t('header.My Profile')}</a>,
    },
    {
      key: '2',
      label: <a href={`${hostSelf}/myAccount?selectPage=appointments`}>{t('header.My Appointments')}</a>,
    },
    // href={`${hostSelf}/login`}
    {
      key: '3',
      label: (
        <a
          onClick={() => {
            patientTokenStore.remove();
            // 强制刷新页面
            window.location.href = `${hostSelf}/login`;
          }}
        >
          {t('header.Logout')}
        </a>
      ),
    },
  ];

  return (
    <header className="hidden lg:block bg-white sf-header__desktop logo-left-menu-center">
      <div className="sf-header__bg pointer-events-none" />
      <div className="sf-header__dropdown-bg absolute top-full left-0 right-0 pointer-events-none" />
      <div className="container-fluid relative z-[1]">
        <div className="sf-menu-logo-bar flex -mx-4 items-center">
          <h1 className="sf-logo px-4 flex-1 justify-start">
            <a href={logoUrl} className="block py-2.5 logo-img relative" title="INDICAID®妥析®">
              <div className="sf-image sf-logo-default">
                <img src={logo} alt="INDICAID®妥析®" />
              </div>
            </a>
          </h1>
          <div className="flex-grow-[2] flex-shrink flex justify-center items-center sf-no-scroll-bar sf-menu-wrapper__desktop">
            <ul className="sf-nav flex text-base font-medium whitespace-nowrap clearfix sf-no-scroll-bar">
              {navData.map((v, i) => (
                <li
                  onMouseEnter={() => {
                    v.mega && headerRef.current?.classList.add('sf-mega-active');
                  }}
                  onMouseLeave={() => {
                    v.mega && headerRef.current?.classList.remove('sf-mega-active');
                  }}
                  key={i}
                  className={`sf-menu-item list-none sf-menu-item--${v.mega ? '' : 'no-'}mega sf-menu-item-parent`}
                >
                  <a href={v.data[1]} className="px-4 py-5 sf__sub-menu-link1 flex items-center sf__parent-item">
                    {t('header.nav.' + v.data[0])}
                    {v.sub && IconArrowDown}
                  </a>
                  {v.sub && (
                    <div
                      className={`total-columns-${
                        v.columns || 3
                      } sf-menu__submenu sf-menu__desktop-sub-menu pointer-events-none absolute z-50 inset-x-0 ${
                        v.mega ? '' : 'sf-menu__dropdown min-w-max bg-white'
                      }`}
                    >
                      <div className="sf-menu__inner">
                        {v.mega ? (
                          <div className="container mx-auto wc__custom-mega-menu">
                            <div className="sf-menu-submenu__content flex py-12">
                              <ul className="sf-menu-submenu__items flex -mx-2 w-2/3">
                                <li className="list-none sf__menu-item-level2 w-1/2 xl:w-1/3 2xl:w-1/4 mb-4">
                                  <div className="sf__sub-menu-column mt-4">
                                    <ul className="leading-8">
                                      {v.sub?.map((vv, ii) => (
                                        <li key={ii} className="list-none">
                                          <a href={vv[1]} className="sf-sub-menu__link">
                                            {t('header.nav.' + vv[0])}
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                              <div className="sf-menu-submenu__addon pl-5 w-1/3" style={{ minHeight: '300px' }}>
                                <div className="sf-menu__banner h-full">
                                  <div className="sf-menu__banner-inner">
                                    <a href={`${host}/pages/indicaidlab`} className="sf__hover-zoom-in">
                                      <div className="sf-image">
                                        <img
                                          className="lazyautosizes ls-is-cached lazyloaded"
                                          src="https://cdn.shopify.com/s/files/1/0334/9885/7609/files/211111_indicaid80927_1_540x.jpg"
                                        />
                                      </div>
                                    </a>
                                    <div className="mt-4 flex flex-col">
                                      <p className="font-base text-black opacity-60">
                                        INDICAID lab<sup>TM</sup> {t('header.HKTestingCenter')}
                                      </p>
                                      <p>
                                        <a
                                          href={`${host}/pages/indicaidlab`}
                                          className="text-black sf__btn sf__btn-link mt-2"
                                        >
                                          {t('header.Discovernow')}
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="mx-auto">
                            <div className="sf-menu-submenu__content flex p-4">
                              <ul className="sf-menu-submenu__items flex flex-col w-full">
                                {v.sub?.map((vv, ii) => (
                                  <li key={ii} className="list-none sf__menu-item-level2 w-full leading-9">
                                    <a
                                      href={vv[1]}
                                      className="sf__sub-menu-link2 whitespace-normal block sf-sub-menu__link"
                                    >
                                      {t('header.nav.' + vv[0])}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-1 justify-end items-center">
            <LocaleSwitcher />
            {isLogin ? (
              <Dropdown menu={{ items }} placement="bottom" rootClassName="sf-header-ant-dropdown" arrow>
                <a onClick={(e) => e.preventDefault()} className="px-2 py-3.5">
                  <span className="sf__tooltip-item block sf__tooltip-bottom sf__tooltip-style-2">
                    <IconAccount />
                  </span>
                </a>
              </Dropdown>
            ) : (
              <a
                onClick={(e) => {
                  e.preventDefault();
                  location.href = hostSelf + '/login';
                }}
                className="px-2 py-3.5"
              >
                <span className="sf__tooltip-item block sf__tooltip-bottom sf__tooltip-style-2">
                  <IconAccount />
                  <span className="sf__tooltip-content">{t('header.Account')}</span>
                </span>
              </a>
            )}
            <a
              href={`${host}/cart`}
              className="relative py-2 sf-cart-icon px-2 whitespace-nowrap cursor-pointer cart-icon"
              style={{}}
            >
              <span className="sf__tooltip-item block sf__tooltip-bottom sf__tooltip-style-2">
                {IconShopCart}
                <span className="sf__tooltip-content">{t('header.ShoppingCart')}</span>
              </span>
              <span className="sf-cart-count font-medium">0</span>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};
