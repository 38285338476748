/* eslint-disable @typescript-eslint/indent */
import { formatBeDate } from '@utils/index';
import greenCorrect from '../../images/greenCorrect.svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import { useLocalNavigate } from 'src/router/routerTools';

// TODO 这个内容需要调整
type PersonalInformationProps = {
  userInfo?: {
    firstName?: string;
    lastName?: string;
    dob?: string;
    email?: string;
    phone?: string;
    phoneUnconfirmed?: string;
    emailUnconfirmed?: string;
  };
};

export default function PersonalInformation(props: PersonalInformationProps = {}) {
  const [t] = useTranslation();
  const avatarTxt = (props.userInfo?.firstName || props.userInfo?.lastName || '').substring(0, 1);
  const isCNName =
    /[\u4e00-\u9fa5]/.test(props?.userInfo?.firstName ? props?.userInfo?.firstName?.substr(0, 1) : '') ||
    /[\u4e00-\u9fa5]/.test(props?.userInfo?.lastName ? props?.userInfo?.lastName?.substr(0, 1) : '');

  const navigate = useLocalNavigate();
  return (
    <div className="flex flex-col lg:mt-[80px]">
      <div className="flex flex-col mb-[32px] lg:flex-row lg:items-center lg:mb-[71px] mt-[39px] lg:mt-[0px]">
        <div className="w-[36px] h-[36px] rounded-[100px] bg-[#2DBDB6] flex justify-center items-center lg:mr-[48px] lg:w-[48px] lg:h-[48px] lg:rounded-[100px]">
          <div
            className={classNames(
              isCNName ? 'lg:text-[20px]' : 'lg:text-[28px]',
              'font-[600] text-[18px] text-[#FFFFFF]',
            )}
          >
            {avatarTxt}
          </div>
        </div>
        <div className="mt-[20px] font-[600] text-[22px] leading-[36px] text-[#212121] lg:mt-[0px] lg:text-[28px] lg:leading-[36px]">
          {`${t('Welcome')}, ${props?.userInfo?.firstName || ''}`}
        </div>
      </div>
      {/* TODO 代码写到这里，需要再看一下 */}
      <div className="flex mb-[26px] lg:mb-[26px]">
        <div className="w-[151px] font-[600] text-[16px] leading-[24px] text-[#212121] lg:w-[297px] lg:text-[16px] lg:leading-[24px]">
          {t('FirstName')}
        </div>
        <div className="font-[400] text-[16px] leading-[24px] text-[#212121] lg:text-[16px] lg:leading-[28px]  w-[180px] break-all lg:w-[400px]">
          {props?.userInfo?.firstName}
        </div>
      </div>
      <div className="flex mb-[26px] lg:mb-[26px]">
        <div className="w-[151px] font-[600] text-[16px] leading-[24px] text-[#212121] lg:w-[297px] lg:text-[16px] lg:leading-[24px]">
          {t('LastName')}
        </div>
        <div className="font-[400] text-[16px] leading-[24px] text-[#212121] lg:text-[16px] lg:leading-[28px]  w-[180px] break-all lg:w-[400px]">
          {props?.userInfo?.lastName}
        </div>
      </div>
      <div className="flex mb-[26px] lg:mb-[26px]">
        <div className="w-[151px] font-[600] text-[16px] leading-[24px] text-[#212121] lg:w-[297px] lg:text-[16px] lg:leading-[24px]">
          {t('PaymentPage.DateOfBirth')}
        </div>
        <div className="font-[400] text-[16px] leading-[24px] text-[#212121] lg:text-[16px] lg:leading-[28px] w-[180px] break-all lg:w-[400px]">
          {formatBeDate(props?.userInfo?.dob)}
        </div>
      </div>

      <div className="flex mb-[26px] lg:mb-[26px] items-center">
        <div className="w-[151px] font-[600] text-[16px] leading-[24px] text-[#212121] lg:w-[297px] lg:text-[16px] lg:leading-[24px]">
          {t('loginPage.Email')}
        </div>
        <div className="font-[400] text-[16px] leading-[24px] text-[#212121] w-[180px] break-all lg:w-[400px] lg:text-[16px] lg:leading-[28px] flex  items-center">
          {props?.userInfo?.email || props?.userInfo?.emailUnconfirmed}
          {props?.userInfo?.email && (
            <img
              src={greenCorrect}
              alt="greenCorrect"
              className="object-cover h-[15px] ml-[11px] lg:h-[15px] lg:ml-[30px]"
            />
          )}
        </div>
      </div>

      <div className="flex mb-[104px] items-center lg:mb-[153px]">
        <div className="w-[151px] font-[600] text-[16px] leading-[24px] text-[#212121] lg:w-[297px] lg:text-[16px] lg:leading-[24px]">
          {t('loginPage.Phone')}
        </div>
        <div className="font-[400] text-[16px] leading-[24px] text-[#212121] lg:text-[16px] lg:leading-[28px]  w-[180px] break-all lg:w-[400px] flex  items-center">
          {props?.userInfo?.phone || props?.userInfo?.phoneUnconfirmed}
          {props?.userInfo?.phone && (
            <img
              src={greenCorrect}
              alt="greenCorrect"
              className="object-cover h-[15px] ml-[11px] lg:h-[15px] lg:ml-[30px]"
            />
          )}
        </div>
      </div>

      <div
        onClick={() => {
          navigate('/myAccount?selectPage=delAccount');
        }}
        className="cursor-pointer hover:opacity-80 flex items-center justify-between lg:h-[98px] mb-[80px] border-t border-b border-[#E2E2E2]"
      >
        <div>
          <p>
            <InfoCircleOutlined className="text-[18px]" />
            <span className="lg:text-[#212121] lg:ml-[5px] lg:leading-[28px] font-semibold text-[16px]">
              Delete account
            </span>
          </p>
          <p className="text-[#6B7280] lg:ml-[23px] lg:leading-[28px] lg:text-[14px]">
            Attention: Once the account is deleted, it cannot be recovered.
          </p>
        </div>
        <RightOutlined className="text-[20px]" />
      </div>
    </div>
  );
}
