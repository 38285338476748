import dayjs from 'dayjs';

import debounce from 'lodash-es/debounce';

/**
 * 校验val是否为邮箱
 * @param {string | null} val 需要校验的邮箱
 * @returns {boolean} 是否为邮箱
 */
export const isEmail = (val: string | null) => {
  if (!val) {
    return false;
  }
  const reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(val);
};

// TODO 需要写测试
/**
 * 校验val是否符合密码规则
 * @param {string | null} val 需要校验的密码
 * @returns {boolean}         是否为符合规则的密码
 */
export const isPasswordValid = (val: string | null) => {
  if (!val) {
    return false;
  }
  const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,20}$/;
  return reg.test(val);
};

/**
 * loacalStore存取函数
 */
const handleLocalStorage = {
  set(name: string, value: string | null | number | object) {
    localStorage.setItem(name, JSON.stringify(value));
  },
  get(name: string) {
    const value = localStorage.getItem(name);
    if (value && value !== 'undefined') {
      return JSON.parse(value);
    } else {
      return null;
    }
  },
  remove(name: string) {
    localStorage.removeItem(name);
  },
};
export { handleLocalStorage };

/**
 * sessionStorage存取函数
 */
const handleSessionStorage = {
  set(name: string, value: string | null | number | object) {
    sessionStorage.setItem(name, JSON.stringify(value));
  },
  get(name: string) {
    const value = sessionStorage.getItem(name);
    if (value && value !== 'undefined') {
      return JSON.parse(value);
    } else {
      return null;
    }
  },
  remove(name: string) {
    sessionStorage.removeItem(name);
  },
};
export { handleSessionStorage };

/**
 * 字符串中间替换成星号
 * @param {string} str  需要操作的字符串
 * @param {number} len1 开头不需要变成星号的长度
 * @param {number} len2 末尾不需要变成星号的长度，设置默认值为0，不传的时候就除了设置的开头几位剩余全部变成星号
 * @returns {string}    转换之后的字符串
 */
export const changeToStar = (str: string | null, len1: number, len2: number = 0) => {
  if (!str) {
    return null;
  }
  let strLen = str.length;
  let stars = '';
  for (let i = 0; i < strLen - len1 - len2; i++) {
    stars += '*';
  }
  str = str.substr(0, len1) + stars + str.substr(strLen - len2);
  return str;
};

/**
 * 格式化数据，如把1_1转换成，id为1，answer为1的对象
 * @param {string} str  需要操作的字符串
 * @returns {object}    转换之后的对象
 */
export const formatData = (str: string | null) => {
  if (!str) {
    return null;
  }
  const strArr = str.split('_');
  const obj = {
    id: strArr[0],
    answer: strArr[1],
  };
  return obj;
};

/**
 * 格式化后端日期，如将’2023-01-01‘转为’1 / 1 / 2023‘
 * @param {string} str  需要操作的字符串
 * @param {boolean} noSpace  是否需要空格
 * @returns {string}    转换之后的字符串
 */
export const formatBeDate = (str: string | null | undefined, noSpace?: boolean) => {
  if (!str) {
    return null;
  }

  if (noSpace) {
    return dayjs(str).format('DD/MM/YYYY');
  }

  return dayjs(str).format('DD / MM / YYYY');
  // const val = str && new Date(str).toLocaleDateString('zh-HK');
  // const newVal = val && val.replaceAll('/', ' / ');
  // return newVal;
};

export function formatDate(str: string) {
  return dayjs(str).format('DD / MM / YYYY');
}

/**
 * 将当前日期转换为标准数据格式，如’22 Mar 2023 12:30pm ‘
 * @returns {string}    转换之后的字符串
 */
export const formatCurDate = () => {
  return dayjs().format('DD MMM YYYY hh:mma');
};

/**
 * 获取星期几，其中星期日为0、星期六为6
 * @returns {string}    代表着星期几
 */
export const getCurrentWeekDay = () => {
  const weeks = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return weeks[dayjs().day()];
};

/**
 * 创建防抖函数
 * @param time 防抖时间
 * @returns
 */
export const createDebounceWrapper = (time: number = 1000) => {
  return debounce(
    (func: () => void) => {
      func();
    },
    time,
    {
      leading: false,
      trailing: true,
    },
  );
};

/**
 * 生成随机字符串
 * @param e 生成随机字符串的位数
 * @returns
 */
export function randomString(e: number = 32) {
  var t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
    a = t.length,
    n = '';
  for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
  return n;
}

/**
 *
 * @param func
 * @returns
 */
export function createWaitPromise<P extends (...args: any) => Promise<any>>(
  func: P,
): (...args: Parameters<P>) => ReturnType<P> {
  let p: any | undefined = undefined;
  let argsStringfySet = new Set<string>();
  return (...args: Parameters<P>) => {
    const argsStringfyInner = JSON.stringify(args);
    // 正在执行中的函数 和 此次函数调用重合
    if (p && argsStringfySet.has(argsStringfyInner)) {
      return p;
    }
    argsStringfySet.add(argsStringfyInner);
    if (p) {
      // 链式调用
      p = p.then(() => func(...args));
      p.then(() => {
        // 调用结束后删除key
        argsStringfySet.delete(argsStringfyInner);
        // 没有函数正在执行
        if (argsStringfySet.size === 0) {
          p = undefined;
        }
      });
      return p;
    } else {
      p = func(...args);
      p.then(() => {
        argsStringfySet.delete(argsStringfyInner);
        if (argsStringfySet.size === 0) {
          p = undefined;
        }
      });
      return p;
    }
  };
}

const TIME_FORMAT = 'HH:mma';
/**
 * 比较两个时间字符串的大小
 * @param time1
 * @param time2
 * @returns time1 < time2 返回 -1，time1 > time2 返回 1，time1 = time2 返回 0
 */
export function compareTime(time1: string, time2: string): number {
  // 解析时间字符串并转换为UTC时间
  const date1 = dayjs.utc(time1, TIME_FORMAT);
  const date2 = dayjs.utc(time2, TIME_FORMAT);

  // 比较两个时间
  if (date1.isBefore(date2)) {
    return -1;
  } else if (date1.isAfter(date2)) {
    return 1;
  } else {
    return 0;
  }
}
/**
 * 是否为医生端
 * @returns
 */
export function isDoctorPortal2() {
  return window.location.href.includes(`${location.origin}/doctor/`);
}
