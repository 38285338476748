import BaseButton from '@components/BaseButton/BaseButton';
import RTCPreviewCard, { DeviceSwitcher } from '../components/RTCPreviewCard';
import classNames from 'classnames';
import { useMediaDevicesInit } from '@utils/RtcAdapter/hooks/mediaDevices';
import { useRouterChangeAutoScrollTop } from 'src/hooks/tools/RouterAutoScrollTop';
import { useContext, useMemo, useState } from 'react';
import MeetingInfosContext from '../context/MeetingInfosContext';
import dayjs from 'dayjs';
import { AlarmClock } from '@icon-park/react';
import RtcAdapter from '@utils/RtcAdapter/RtcTool';
import { useMemoizedFn } from 'ahooks';
import { useTranslation } from 'react-i18next';
import alertTerms from '@utils/globalComponents/TermsAndPrivacyAlert/TermsAlert';

export default function WaitingRoom() {
  const [t] = useTranslation();
  useRouterChangeAutoScrollTop();
  useMediaDevicesInit();
  const meetingInfos = useContext(MeetingInfosContext);

  const [loading, setLoading] = useState(false);

  const joinMeeting = useMemoizedFn(async () => {
    if (!meetingInfos) {
      return;
    }
    setLoading(true);

    await RtcAdapter.join(`${meetingInfos?.meeting.bookingId}`, `${meetingInfos?.userId}`);
    if (RtcAdapter.localVideoEnable) {
      RtcAdapter.toogleVideoPublish(true);
    }
    if (RtcAdapter.localAudioEnable) {
      RtcAdapter.toogleAudioPublish(true);
    }
    setLoading(false);
  });

  const [showDeviceSwitch, setShowDeviceSwitch] = useState(false);
  const meetingTimeInfos = useMemo(() => {
    const startDateTime = dayjs(meetingInfos?.meeting.appointmentTime);
    const now = dayjs();
    let startTimeFormat = startDateTime.format('hh:mm a');

    const startDate = startDateTime.format('YYYY-MM-DD');
    const nowDate = now.format('YYYY-MM-DD');
    if (startDate !== nowDate) {
      startTimeFormat = startDateTime.format('YYYY-MM-DD hh:mm a');
    } else {
      startTimeFormat = t('MeetingPage.TodayAt') + startDateTime.format(' hh:mm a');
    }
    // const diffMinute = startDateTime.diff(now, 'minutes');
    // let diff = '';
    // if (diffMinute === 0) {
    //   diff = startDateTime.diff(now, 'seconds') + ' senonds';
    // } else if (diffMinute >= 60 || diffMinute <= -60) {
    //   const h = Math.floor(diffMinute / 60);
    //   const m = diffMinute % 60;
    //   diff = h + 'hour' + (m !== 0 ? ` ${m}min` : '');
    // } else {
    //   diff = diffMinute + ' min';
    // }

    return { startTime: startTimeFormat };
  }, [meetingInfos?.meeting.appointmentTime, t]);
  return (
    <div>
      <div className="relative w-full lg:mt-[43px] flex flex-1 flex-col lg:flex-row-reverse items-center justify-center">
        <div className="lg:mt-[20px] lg:ml-[107px] lg:leading-[28px] lg:text-left text-center leading-[24px]">
          <p className="font-semibold lg:text-[28px] lg:leading-[36px] text-[18px]">{t('MeetingPage.ReadyToJoin')}</p>
          <p className="font-medium lg:text-[18px] lg:mt-[20px] text-[16px] mt-[16px]">
            {t('MeetingPage.OnlineConsultationWith')}{' '}
            {meetingInfos?.role === 1 ? meetingInfos?.meeting.doctorName : meetingInfos?.meeting.patientName}
          </p>
          <p className="lg:text-[16px] lg:mt-[17px] mt-[11px] text-[16px] flex items-center lg:justify-start justify-center">
            <AlarmClock className="mb-[2px]" theme="outline" size="18" fill="#212121" />
            <span className="ml-[6px]">{meetingTimeInfos.startTime}</span>
          </p>
          <BaseButton
            id="meeting__join-button"
            loading={loading}
            onClick={joinMeeting}
            buttonClassName={classNames(
              'mt-[10px] text-[16px] w-[328px] h-[50px] hidden',
              'lg:mt-[48px] lg:text-[16px] lg:w-[326px] lg:h-[50px] lg:inline-block',
            )}
            status="default"
            value={t('MeetingPage.JoinNow')}
          />
          <p className="lg:text-[16px] lg:mt-[34px] hidden lg:inline-block">
            {t('MeetingPage.QuestionsGoTo')} <a className="text-[#2DBDB6] cursor-pointer underline">FAQ</a>
          </p>
        </div>
        <RTCPreviewCard onClickMore={() => setShowDeviceSwitch(!showDeviceSwitch)} />
      </div>

      <div className="flex justify-center">
        <BaseButton
          id="meeting__join-button__mobile"
          loading={loading}
          onClick={joinMeeting}
          buttonClassName={classNames('mt-[29px] text-[16px] w-[366px] h-[50px] inline-block', 'lg:hidden')}
          status="default"
          value={t('MeetingPage.JoinNow')}
        />
      </div>

      {showDeviceSwitch ? (
        <div className="mt-[34px] px-[30px]">
          <div className="w-[40px] h-[3px] rounded-full bg-[#a9a9a9] mb-[40px] m-auto"></div>
          <DeviceSwitcher />
        </div>
      ) : (
        <>
          <p className="lg:hidden text-[14px] mt-[41px] inline-block text-center w-full">
            {t('MeetingPage.QuestionsGoTo')} <a className="text-[#2DBDB6] cursor-pointer underline">FAQ</a>
          </p>
          {/* <p className="font-medium lg:text-[18px] text-[12px] leading-[28px] mt-[11px] lg:leading-[28px] text-center lg:mt-[39px]">
            You have agreed to the
            <a href="/termsAndCondition" target="_blank" className="text-[#2DBDB6] cursor-pointer underline">
              Telemed Terms and Conditions.
            </a>
          </p> */}
        </>
      )}
      <p className="text-[12px] leading-[28px] lg:text-[16px] lg:leading-[24px] font-semibold text-center lg:mt-[94px] lg:mb-[66px] mt-[5px]">
        {t('MeetingPage.YouHaveAgreedToThe')}{' '}
        <a id="meeting__terms-and-conditions" className="underline text-[#2dbdb6]" onClick={() => alertTerms()}>
          {t('MeetingPage.TelemedTermsAndConditions')}
        </a>
      </p>
    </div>
  );
}
