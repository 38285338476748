export default function AlertTermsListTitle(props: { children: React.ReactNode }) {
  return (
    <p
      className="
    text-[#2DBDB6] lg:mb-[22px] lg:text-[28px] lg:leading-[36px] font-semibold text-left
      text-[18px] leading-[24px] mb-[14px]
    "
    >
      {props.children}
    </p>
  );
}
