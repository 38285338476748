import { useState, useEffect } from 'react';
import { useStripe, Elements } from '@stripe/react-stripe-js';
import { CarryOutOutlined, CheckCircleFilled } from '@ant-design/icons';
import BaseButton from '@components/BaseButton/BaseButton';
import BookingSummaryItem from './components/BookingSummaryItem';
import MedicineSummaryItem from './components/MedicineSummaryItem';
import { post } from '@utils/request';
import { handleLocalStorage } from '@utils/index';
import website from '@configs/website';
import { ProductType } from '@consts/enums';
import { formatBeDate } from '@utils/index';
import getStripePromise from './getStripePromise';
import classNames from 'classnames';
import medicine from '../../images/medicine.svg';
import { useTranslation } from 'react-i18next';
import { useLocalNavigate } from 'src/router/routerTools';
import { PaymentData } from './Payments';
import { clearDataTrackConstantId, getDataTrackConstantId, track } from '@utils/dataTrack';
import { Skeleton } from 'antd';
import { MEDIA_SIZE } from '@consts/consts';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';

const PaymentStatus = () => {
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const [t] = useTranslation();
  const navigate = useLocalNavigate();
  const stripe = useStripe();
  const [, setMessage] = useState('');
  const [fillParams, setFillParams] = useState<any>({});
  const [callback, setCallback] = useState<any>();
  const [paymentData, setPaymentData] = useState<PaymentData>();
  console.log(paymentData, 'paymentData');

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

    const paymentIntentId = new URLSearchParams(window.location.search).get('payment_intent');

    post('/care/booking/callback', {
      clientSecret,
      paymentIntentId,
    })
      .then((result) => {
        console.log(result);
        setCallback(result);
      })
      .catch((error) => console.log('error', error));

    // Retrieve the PaymentIntent
    stripe.retrievePaymentIntent(clientSecret!).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          setMessage('Success! Payment received.');
          break;

        case 'processing':
          setMessage("Payment processing. We'll update you when payment is received.");
          break;

        case 'requires_payment_method':
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setMessage('Payment failed. Please try another payment method.');
          break;

        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  useEffect(() => {
    const fillParamsTemp = handleLocalStorage.get(website.fill_params);
    setFillParams(fillParamsTemp);
    let paymentDataTemp;
    try {
      let d = sessionStorage.getItem(website.payment_data);
      if (d == null) {
        return;
      }
      paymentDataTemp = JSON.parse(d);
      setPaymentData(paymentDataTemp);
    } catch {}
    const isConsultation = paymentDataTemp?.productType != ProductType.Medication;
    if (isConsultation && paymentDataTemp && callback?.bookingId) {
      track({
        event_type: 'view booking successful',
        event_properties: {
          'booking id': getDataTrackConstantId('BookingId'),
          'booking number': callback?.bookingId || 'unknown',
          'sechedue doctor': paymentDataTemp.doctorName,
          'appointment method':
            paymentDataTemp.productType == ProductType.OnlineConsultationAndMedication ? 'online' : 'in-clinic',
          date: formatBeDate(paymentDataTemp.date),
          time: paymentDataTemp.time,
          'first name': fillParamsTemp.personalInformation?.firstName,
          'last name': fillParamsTemp.personalInformation?.lastName,
          email: fillParamsTemp.personalInformation?.email,
          'contact number': fillParamsTemp.personalInformation?.contactNumber,
          'date of birth': formatBeDate(fillParamsTemp.personalInformation?.dateOfBirth),
          ID: fillParamsTemp.personalInformation?.idNumber,
        },
      });
      clearDataTrackConstantId('BookingId');
    }
  }, [callback?.bookingId]);

  // TODO 展示错误状态
  if (!paymentData) {
    return <></>;
  }

  const isOnline = paymentData.productType == ProductType.OnlineConsultationAndMedication;
  const isConsultation = paymentData?.productType != ProductType.Medication;
  const renderList = isConsultation ? (
    <div
      style={{ boxShadow: '0px 6px 25px rgba(34, 34, 34, 0.1)' }}
      className="booking-info-box lg:mt-[66px] mt-[45px] m-auto lg:w-[842px] lg:min-h-[775px] w-[366px] md:w-[90vw] rounded-[10px] overflow-hidden lg:mb-0 mb-[50px] lg:pb-0 pb-[20px] "
    >
      <div className="lg:flex lg:flex-row flex-col lg:h-[775px] relative top-[-19px] lg:top-0">
        <div className="lg:w-[77px] lg:h-full h-[42px] w-full flex justify-center bg-[#2dbdb6] items-center absolute rounded-[10px_10px_0px_0px] lg:rounded-[0px_0px_0px_0px]">
          <CarryOutOutlined className="text-white lg:text-[30px] text-[26px]" />
        </div>
        {/* pb-[25px] */}
        <div className="lg:w-[765px] lg:py-[45px] lg:px-[110px] px-[20px] text-left pb-[0px]">
          <p className="text-[#212121] pt-[55px] lg:pt-[0px] lg:text-[22px] text-[18px] font-medium my-[20px] lg:mb-[34px]">
            {t('PaymentPage.BookingDetails')}
          </p>
          <BookingSummaryItem
            left={t('PaymentPage.BookedWith')}
            right={paymentData?.doctorName || 'Dr. scott M.C.Tang'}
          />
          <BookingSummaryItem
            left={t('PaymentPage.AppointmentMethod')}
            right={isOnline ? t('OnlineConsultation') : t('In-ClinicVisit')}
          />
          <BookingSummaryItem left={t('Date')} right={paymentData.date || '17 / 05 / 2023'} />
          <BookingSummaryItem left={t('Time')} right={paymentData.time} />
          {paymentData?.location && !isOnline && (
            <BookingSummaryItem
              left={t('location')}
              right={paymentData?.location || 'Shop 136, 3/F, Lok Fu Plaza, 198 Junction Road, Kowloon, Hong Kong'}
            />
          )}
          <hr className="border-[#B5B5B5] lg:mt-[40px] mt-[35px]" />
          <p className="text-[#212121] lg:text-[22px] text-[18px] font-medium my-[20px] lg:mt-[34px] lg:mb-[38px]">
            {t('PersonalInformation')}
          </p>
          {/* TODO 这个name还没有添加 */}
          <BookingSummaryItem left={t('FirstName')} right={fillParams?.personalInformation?.firstName || 'Tom'} />
          <BookingSummaryItem left={t('LastName')} right={fillParams?.personalInformation?.lastName || 'Too'} />
          <BookingSummaryItem
            left={t('loginPage.Email')}
            right={
              fillParams?.personalInformation?.email || handleLocalStorage.get(website.email) || 'testing@phasesci.com'
            }
          />
          <BookingSummaryItem
            left={t('loginPage.ContactNumber')}
            right={
              (fillParams?.personalInformation?.phone &&
                fillParams?.personalInformation?.phone.replaceAll('+852', '+852 ')) ||
              (handleLocalStorage.get(website.phone_number) &&
                handleLocalStorage.get(website.phone_number).replaceAll('+852', '+852 ')) ||
              '+852 12345678'
            }
          />
          <BookingSummaryItem
            left={t('PaymentPage.DateOfBirth')}
            right={
              (fillParams?.personalInformation?.dob && formatBeDate(fillParams?.personalInformation?.dob)) ||
              '30 / 10 / 1992'
            }
          />
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{ boxShadow: '0px 6px 25px rgba(34, 34, 34, 0.1)' }}
      className="booking-info-box lg:mt-[66px] mt-[45px] m-auto lg:w-[842px] lg:h-[882px] w-[366px] md:w-[90vw] rounded-[10px] overflow-hidden lg:mb-0 mb-[100px] lg:pb-0 pb-[20px] "
    >
      <div className="lg:flex lg:flex-row flex-col h-full relative  top-[-19px] lg:top-0">
        <div className="lg:w-[77px] lg:h-full h-[42px] w-full flex justify-center bg-[#2dbdb6] items-center absolute rounded-[10px_10px_0px_0px] lg:rounded-[0px_0px_0px_0px]">
          <img src={medicine} alt="medicine" className="object-cover w-[18px] lg:w-[36px]" />
        </div>
        <div className="lg:w-[765px] px-[20px] text-left lg:pl-[97px] lg:pr-[152px] lg:pt-[64px] lg:pb-[65px]">
          <p className="text-[#212121] lg:text-[22px] text-[18px] font-medium my-[20px] pt-[65px] lg:pt-[0px] lg:mt-[0px] lg:mb-[34px] lg:font-[600] lg:leading-[36px]">
            {t('PaymentPage.MedicationFee')}
          </p>
          <MedicineSummaryItem left={t('PaymentPage.PrescribedBy')} right={paymentData?.doctorName || ''} />
          {/* TODO: 68这里太大了，可能需要再看一下 */}
          {/* Patient Information */}
          <hr className="border-[#B5B5B5] my-[36px] lg:my-[50px]" />
          <p className="text-[18px] text-[#212121] lg:text-[22px] font-medium mb-[20px] lg:mt-[0px] lg:mb-[35px] lg:leading-[24px] lg:font-[600]">
            {t('PatientInformation')}
          </p>
          <MedicineSummaryItem left={t('FirstName')} right={paymentData?.firstName || ''} />
          <MedicineSummaryItem left={t('LastName')} right={paymentData?.lastName || ''} />
          {/* Prescriptions */}
          <hr className="border-[#B5B5B5]  my-[36px] lg:my-[50px]" />
          <p className="text-[18px] text-[#212121] lg:text-[22px] font-medium mb-[20px] lg:mt-[0px] lg:mb-[35px] lg:leading-[24px] lg:font-[600]">
            {t('Prescriptions')}
          </p>

          {/* TODO 这个name还没有添加 */}
          <MedicineSummaryItem
            left={t('PaymentPage.DiagnosisSymptoms')}
            right={paymentData?.symptoms || 'Helicobacter pylori (H. pylori) infection'}
          />
          <MedicineSummaryItem
            left={t('Prescriptions')}
            right={paymentData?.prescription || '7days prescription for Helicobacter pylori'}
          />
          <hr className="border-[#B5B5B5] lg:mb-[0px]  mt-[36px] lg:mt-[50px]" />
          <div className="flex font-semibold w-full justify-between mt-[18px] lg:mt-[18px]">
            <span className="text-[#212121] text-[16px] sm:text-[16px] w-[50%] inline-block lg:leading-[24px]">
              {t('Total')}
            </span>
            <span className="text-[#000000] text-[16px] sm:text-[18px] w-[50%] inline-block lg:leading-[28px]">
              {paymentData?.total?.text || '$300 HKD'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="text-center mt-[40px] min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)]">
      <CheckCircleFilled
        className="lg:mt-[60px]"
        style={{
          color: '#2DBDB6',
          fontSize: '50px',
        }}
      />

      <p
        className={classNames(
          isConsultation
            ? 'text-[#2DBDB6] lg:mt-[20px] lg:text-[32px] text-[28px] font-semibold'
            : 'text-[#2DBDB6] lg:mt-[39px] lg:text-[32px] lg:leading-[42px] text-[28px] font-semibold',
        )}
      >
        {isConsultation ? t('PaymentPage.BookingSuccessful') : t('PaymentPage.PaymentSuccessful')}
      </p>
      <p
        className={classNames(
          isConsultation ? '' : 'lg:leading-[28px] lg:text-[400]',
          'lg:mt-[50px] mt-[13px] text-[#212121] lg:text-[16px] text-[16px] px-[30px]',
        )}
      >
        {isConsultation ? t('PaymentPage.thanksForBookingText') : t('PaymentPage.thanksForPaymentText')}
      </p>
      <p
        className={classNames(
          isConsultation
            ? 'lg:mt-[20px] mt-[38px] lg:w-full w-[200px] m-auto text-center text-[#212121] lg:text-[16px] text-[16px]'
            : 'lg:mt-[21px] mt-[38px] lg:w-full w-[200px] m-auto text-center text-[#212121] lg:text-[16px] text-[16px] lg:leading-[24px] lg:font-[600]',
        )}
      >
        {/* TODO 这里也要调整=>要展示内容=>通过bookingId */}
        <span className="font-semibold">
          {isConsultation ? `${t('BookingNumber')} : ` : `${t('OrderNumber')} : `}
        </span>{' '}
        {!isLg && <br />}
        {paymentData.displayId}
      </p>
      {renderList}
      <div className="px-[24px]">
        {callback ? (
          <BaseButton
            value={t('PaymentPage.ManageMyAppointments')}
            buttonClassName="mb-[113px] w-full h-[50px] text-[16px] md:w-[326px] md:h-[50px] md:text-[16px] lg:my-[91px]"
            onClick={() => {
              navigate('/myAccount?selectPage=appointments');
            }}
            id="paymentStatus__manageMyAppointment-button"
          />
        ) : (
          <div className="mb-[113px] w-full h-[50px] text-[16px] md:w-[326px] md:h-[50px] md:text-[16px] lg:my-[91px] inline-block">
            <Skeleton.Button active={true} size={'large'} shape={'square'} block={true} style={{ height: '50px' }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default function PaymentStatusWrapper() {
  return (
    <Elements stripe={getStripePromise()}>
      <PaymentStatus />
    </Elements>
  );
}
