import { IconAccount, IconArrowRight, IconBack } from './icons/Icons';
import { supportLocale } from 'src/router/routerTools';
import { HeaderContext } from './useHeaderCtx';
import { MouseEventHandler, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { switchLanguage } from './LocaleSwitcher';
import { patientTokenStore } from '@utils/localStore/tokenStore';

const transitionDuration = 300;
export default ({ show, setShow }: { show: boolean; setShow: (show: boolean) => void }) => {
  const { host, navData, hostSelf, language, isLogin, lastName } = useContext(HeaderContext);
  const [t] = useTranslation();
  const [showSelect, setShowSelect] = useState(false);
  const [activeIdx, setActiveIdx] = useState(-1);
  const menuWrapperRef = useRef<HTMLDivElement>(null);
  const menuContentWrapperRef = useRef<HTMLDivElement>(null);
  const menuWrapper = menuWrapperRef.current;
  const menuContentWrapper = menuContentWrapperRef.current;
  useEffect(() => {
    if (show) {
      if (menuWrapper == null) {
        return;
      }
      document.documentElement.classList.add('prevent-scroll');
      menuWrapper.style.setProperty('--window-inner-height', `${window.innerHeight}px`);
      menuWrapper.classList.remove('hidden');
      menuWrapper.style.setProperty('--tw-bg-opacity', '0.3');
      setTimeout(() => {
        menuWrapper?.firstElementChild!.classList.remove('-translate-x-full');
      });
    }
  }, [show, menuWrapper]);
  const onHide: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (menuWrapper == null || e.target !== menuWrapper) {
        return;
      }
      setShow(false);
      menuWrapper.style.setProperty('--tw-bg-opacity', '0');
      menuWrapper.firstElementChild?.classList.add('-translate-x-full');
      document.documentElement.classList.remove('prevent-scroll');
      setTimeout(() => {
        menuWrapper?.classList.add('hidden');
        menuContentWrapper?.classList.remove('sf-sub-menu-open');
        menuContentWrapper?.classList.remove('-translate-x-full'); // Close search
      }, transitionDuration);
    },
    [setShow, menuContentWrapper?.classList, menuWrapper],
  );
  function closeSubMenu() {
    menuContentWrapper?.classList.remove('sf-sub-menu-open');
    menuContentWrapper?.classList.remove('-translate-x-full');
    setTimeout(() => {
      setActiveIdx(-1);
    }, transitionDuration);
  }
  return (
    <div
      onClick={onHide}
      ref={menuWrapperRef}
      className="h-screen sf-menu-wrapper sf-menu-wrapper-mobile touch-none hidden fixed z-[80] inset-0 transition-colors duration-300 bg-black"
    >
      <div className="sf-menu__content overscroll-contain transition-transform -translate-x-full duration-300 md:w-[450px] z-50 bg-white text-black overflow-x-hidden h-full overflow-y-scroll">
        <div className="flex px-4" />
        <div className="flex flex-col">
          <div
            ref={menuContentWrapperRef}
            className="sf-menu-content flex-grow flex flex-col justify-between transition-all opacity-100 duration-300"
          >
            <div className="w-full sf-menu-links pt-4">
              {navData.map((v, i) => (
                <li key={i} className="list-none sf-link flex items-center">
                  <a href={v.data[1]} className="w-full px-4 py-3 flex items-center justify-between relative">
                    <span>{t('header.nav.' + v.data[0])}</span>
                  </a>
                  {v.sub && (
                    <span
                      onClick={(e: any) => {
                        const parentNode = e.target.parentNode;
                        if (e.target.classList.contains('back') || parentNode.classList.contains('back')) {
                          return;
                        }
                        setActiveIdx(i);
                        menuContentWrapper?.classList.add('-translate-x-full');
                        setTimeout(() => {
                          menuContentWrapper?.classList.add('sf-sub-menu-open');
                        }, 300);
                      }}
                      className="sf__toggle-submenu-mb flex items-center justify-center cursor-pointer"
                    >
                      {IconArrowRight}
                    </span>
                  )}
                  {v.sub && (
                    <div
                      className={`sf-sub-links ${
                        activeIdx == i ? 'flex' : 'hidden'
                      } absolute inset-y-0 bg-white flex-col left-full w-full`}
                    >
                      <div className="h-full overflow-y-scroll overscroll-contain">
                        <button className="back p-4 font-medium flex items-center" onClick={closeSubMenu}>
                          {IconBack}
                          <span className="ml-3"> {t('header.Return')}</span>
                        </button>
                        <ul className="pb-4">
                          {v.sub.map((vv, ii) => (
                            <li key={ii} className="list-none">
                              <a href={vv[1]} className={`block px-4 ${v.mega ? 'py-2' : 'py-3'}`}>
                                {t('header.nav.' + vv[0])}
                              </a>
                            </li>
                          ))}
                        </ul>
                        {v.mega && (
                          <div className="sf-menu__banner h-full">
                            <div className="sf-menu__banner-inner">
                              <a href={`${host}/pages/indicaidlab`} className="sf__hover-zoom-in">
                                <div
                                  data-image-id={21525782495369}
                                  className="sf-image"
                                  data-image-wrapper
                                  data-image-loading
                                >
                                  <img
                                    className="lazyautosizes ls-is-cached lazyloaded"
                                    src="https://cdn.shopify.com/s/files/1/0334/9885/7609/files/211111_indicaid80927_1_540x.jpg"
                                  />
                                </div>
                              </a>
                              <div className="mt-4 flex flex-col">
                                <p className="font-base text-black opacity-60">
                                  INDICAID lab<sup>TM</sup> {t('header.HKTestingCenter')}
                                </p>
                                <p>
                                  <a
                                    href={`${host}/pages/indicaidlab`}
                                    className="text-black sf__btn sf__btn-link mt-2"
                                  >
                                    {t('header.Discovernow')}
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </div>
            <div className="mega-menu-customer">
              {isLogin ? (
                <div className="block xl:hidden p-4">
                  <h2 className="font-medium text-xl mb-6">{t('header.My Account')}</h2>
                  <a href={`${hostSelf}/myAccount`} className="w-full py-3 mb-3 rounded-md flex items-center">
                    <div className="flex flex-grow">
                      <IconAccount size={24} />
                      <span className="ml-2">{lastName}</span>
                    </div>
                  </a>
                  <a
                    href={`${hostSelf}/myAccount?selectPage=personalInformation`}
                    className="w-full rounded-md flex items-center"
                    style={{ marginBottom: 18, fontSize: 14 }}
                  >
                    {t('header.My Profile')}
                  </a>
                  <a
                    href={`${hostSelf}/myAccount?selectPage=appointments`}
                    className="w-full rounded-md flex items-center"
                    style={{ marginBottom: 18, fontSize: 14 }}
                  >
                    {t('header.My Appointments')}
                  </a>
                  <button className="w-full text-center btn-logout rounded-md border border-black whitespace-nowrap">
                    {/* href={`${hostSelf}/login`} */}
                    <a
                      className="block py-3"
                      onClick={() => {
                        patientTokenStore.remove();
                        // 强制刷新页面
                        window.location.href = `${hostSelf}/login`;
                      }}
                    >
                      {t('header.Logout')}
                    </a>
                  </button>
                </div>
              ) : (
                <div className="block xl:hidden mb-16 p-4">
                  <h2 className="font-medium text-xl mb-6">{t('header.My Account')}</h2>
                  <button
                    className="sf__btn sf__btn-primary mb-3 my-account-btn w-full signin"
                    data-tab="signin"
                    onClick={() => {
                      location.href = hostSelf + '/login';
                    }}
                  >
                    {t('header.Log in')} / {t('header.Create Account')}
                  </button>
                  {/* <button
                    className="sf__btn sf__btn-secondary my-account-btn w-full register"
                    data-tab="register"
                    onClick={() => {
                      location.href = hostSelf + '/login';
                    }}
                  >
                    {t('header.Create Account')}
                  </button> */}
                </div>
              )}
              <div className="flex">
                <div className="sf-language-switcher block xl:hidden py-3.5 lg:py-3.5">
                  <div className="px-4">
                    <div className="sf__custom-select sf__select-top">
                      <select value={language} className="border-transparent bg-transparent" onChange={() => {}}>
                        {supportLocale.map((v) => (
                          <option value={v} key={v}>
                            {t('header.locale.' + v)}
                          </option>
                        ))}
                      </select>
                      <div
                        className={`select-selected ${showSelect ? 'select-arrow-active' : ''}`}
                        tabIndex={0}
                        onBlur={() => {
                          setShowSelect(false);
                        }}
                        onClick={() => {
                          setShowSelect(!showSelect);
                        }}
                      >
                        <span>{t('header.locale.' + language)}</span>
                        <span className="select-arrow">
                          <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"></path>
                          </svg>
                        </span>
                      </div>
                      <div className={`select-items ${showSelect ? '' : 'select-hide'}`}>
                        {supportLocale.map((v) => (
                          <div
                            className={v == language ? 'same-as-selected' : ''}
                            key={v}
                            onClick={() => {
                              switchLanguage(language, v);
                            }}
                          >
                            {t('header.locale.' + v)}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
