import { getMeetingInfos } from '@services/meetingService';
import { MeetingInfos } from '@services/types/meetingService.type';
import isMeetingJoinedState from '@states/meeting.state';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import HeaderToolBar from './components/HeaderToolBar';
import InMeetingContainer from './subPages/InMeetingContainer';
import WaitingRoom from './subPages/WaitingRoom';
import MeetingInfosContext from './context/MeetingInfosContext';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import EarlyReminder from './subPages/EarlyReminded';
import { useLocalNavigate } from 'src/router/routerTools';
import { useRouterChangeAutoScrollTop } from '@hooks/tools/RouterAutoScrollTop';

// 提前进入meeting显示meeting reminder的时间
const EarlyReminderTime = 24 * 60 * 60 * 1000;
const ExpireTime = 24 * 60 * 60 * 1000;

export default function Meeting() {
  useRouterChangeAutoScrollTop();
  const meetingId = useParams().meetingId;

  const [meetingInfos, setMeetingInfos] = useState<MeetingInfos>();
  const navigate = useLocalNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isEarly, setIsEarly] = useState(false);
  useEffect(() => {
    if (!meetingId) return;
    getMeetingInfos({ suffix: meetingId })
      .then((data) => {
        if (data.meeting.status === 2) {
          if (data.role === 2) {
            navigate(`/en/meeting/exception?type=doctorEnd&bookingId=${data.meeting.bookingId}`);
          } else {
            navigate('/meeting/exception?type=end');
          }
          return;
        } else if (data.meeting.status === 3) {
          const expiredType = data.role === 2 ? 'doctorExpired' : 'expired';
          navigate('/meeting/exception?type=' + expiredType);
          navigate(`/meeting/exception?type=${expiredType}&bookingId=${data.meeting.bookingId}`);
          return;
        }
        const startTimeStamp = new Date(data.meeting.appointmentTime).getTime();
        const now = Date.now();
        if (startTimeStamp - now > EarlyReminderTime) {
          setIsEarly(true);
        } else if (startTimeStamp - now <= -ExpireTime) {
          const expiredType = data.role === 2 ? 'doctorExpired' : 'expired';
          navigate(`/meeting/exception?type=${expiredType}&bookingId=${data.meeting.bookingId}`);
        }
        setMeetingInfos(data);
      })
      .catch((error) => {
        try {
          const { status } = error.response;
          if (status !== 401) {
            navigate('/meeting/error');
          }
        } catch {
          navigate('/meeting/error');
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMeetingJoined = useRecoilValue(isMeetingJoinedState);

  const renderContent = useMemo(() => {
    if (isMeetingJoined) {
      return <InMeetingContainer />;
    }
    return (
      <>
        <HeaderToolBar />
        {isLoading ? (
          <div className="w-full h-[100vh] bg-[#00000020] flex justify-center items-center">
            <Loading3QuartersOutlined
              spin
              style={{
                color: '#b2b2b2',
                fontSize: '30px',
              }}
            />
          </div>
        ) : (
          <>{isEarly ? <EarlyReminder /> : <WaitingRoom />}</>
        )}
      </>
    );
  }, [isEarly, isLoading, isMeetingJoined]);

  return <MeetingInfosContext.Provider value={meetingInfos}>{renderContent}</MeetingInfosContext.Provider>;
}
