import { MEDIA_SIZE } from '@consts/consts';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { useTranslation } from 'react-i18next';

import productMobile1 from '@images/productMobile1.png';
import productMobile2 from '@images/productMobile2.png';
import productMobile3 from '@images/productMobile3.png';
import productDesktop1 from '@images/productDesktop1.png';
import productDesktop2 from '@images/productDesktop2.png';
import productDesktop3 from '@images/productDesktop3.png';
import productArrow from '@images/productArrow.svg';

import { useLocalNavigate } from 'src/router/routerTools';
import { UUTUBE_TYPE, CFF_TYPE, COVID_TYPE } from '@consts/consts';
import { useEffect } from 'react';
import { getDataTrackConstantId, track } from '@utils/dataTrack';

const style = {
  container: {
    background: '#F2F7F9',
  },
};

const trackSelectService = (serviceType: string) => {
  track({
    event_type: 'select service',
    event_properties: {
      'testing id': getDataTrackConstantId('TestingId'),
      'service type': serviceType,
    },
  });
};

const ProductList: React.FC = () => {
  const [t] = useTranslation();

  const navigate = useLocalNavigate();
  // TODO 这个需要调整回来
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;

  const renderProduct = () => {
    // PC端代码
    if (isLg) {
      return (
        <div className="flex flex-row lg:mt-[100px] lg:mb-[175px] justify-center items-center">
          {/* 选项一 */}
          <div
            className="bg-[#FFFFFF] lg:shadow-[0_4px_38px_rgba(16,24,64,0.08)] lg:rounded-[15px] lg:p-[30px_9px_40px_23px] flex flex-col justify-between transition-transform hover:scale-[1.13]  lg:hover:border-[1px] hover:border-[solid] hover:border-[#2DBDB6] text-[#212121] hover:text-[#2DBDB6] lg:w-[395px] lg:h-[397px] cursor-pointer"
            id="product-listing__UUTube"
            onClick={() => {
              trackSelectService('UUTube');
              navigate(`/selectResult?productType=${UUTUBE_TYPE}`);
            }}
          >
            <img src={productDesktop1} alt={'productDesktop1'} className="object-contain lg:w-[362px]" />
            <div className="font-[600] lg:text-[18px] lg:leading-[28px] lg:pr-[14px] text-center lg:tracking-[0.25px]">
              {t('ProductListing.select1Name')} <br />
              {t('ProductListing.select1Description')}
            </div>
          </div>
          {/* 选项二 */}
          <div
            className="bg-[#FFFFFF] lg:shadow-[0_4px_38px_rgba(16,24,64,0.08)] lg:rounded-[15px] lg:p-[31px_16px_40px_15px] flex flex-col justify-between transition-transform hover:scale-[1.13]  lg:hover:border-[1px] hover:border-[solid] hover:border-[#2DBDB6] lg:mx-[48px] text-[#212121] hover:text-[#2DBDB6] lg:w-[395px] lg:h-[397px]  cursor-pointer"
            id="product-listing__Combo"
            onClick={() => {
              trackSelectService('Combo');
              navigate(`/selectResult-covidflu?productType=${CFF_TYPE}`);
            }}
          >
            <img src={productDesktop2} alt={'productDesktop2'} className="object-contain lg:w-[364px]" />
            <div className="font-[600] lg:text-[18px] lg:leading-[28px]  lg:w-[307px] lg:tracking-[0.25px] flex items-center justify-left  text-center  lg:ml-[92px]">
              {t('ProductListing.select2Name')} <br />
              {t('ProductListing.select2Description')}
            </div>
          </div>
          {/* 选项三 */}
          <div
            className="bg-[#FFFFFF] lg:shadow-[0_4px_38px_rgba(16,24,64,0.08)] lg:rounded-[15px] lg:p-[31px_25px_40px_7px] flex flex-col justify-between transition-transform hover:scale-[1.13]  lg:hover:border-[1px] hover:border-[solid] hover:border-[#2DBDB6] text-[#212121] hover:text-[#2DBDB6] lg:w-[395px] lg:h-[397px]  cursor-pointer"
            id="product-listing__Covid"
            onClick={() => {
              trackSelectService('COVID-19');
              navigate(`/selectResult-covid?productType=${COVID_TYPE}`);
            }}
          >
            <img src={productDesktop3} alt={'productDesktop3'} className="object-contain lg:w-[362px]" />
            <div className="font-[600] lg:text-[18px] lg:leading-[28px] lg:pl-[15px] text-center lg:tracking-[0.25px]">
              {t('ProductListing.select3Name')} <br />
              {t('ProductListing.select3Description')}
            </div>
          </div>
        </div>
      );
    } else {
      // 移动端代码
      // TODO 移动端的图片有问题，需要调整一下
      return (
        <div className="flex flex-col mt-[44px]">
          {/* 选项一 */}
          <div
            className="bg-[#FFFFFF] shadow-[0_4px_38px_rgba(16,24,64,0.08)] rounded-[10px] p-[18px_21px_7px_3px] flex w-full justify-between"
            id="product-listing__UUTube-mobile"
            onClick={() => {
              navigate(`/selectResult?productType=${UUTUBE_TYPE}`);
            }}
          >
            <div className="flex">
              <img src={productMobile1} alt={'productMobile1'} className="object-contain w-[105px] mr-[21px]" />
              <div className="font-[600] text-[14px] leading-[18px] w-[192px] flex items-center justify-left text-[#212121] mb-[5px]">
                {t('ProductListing.select1Name')} <br />
                {t('ProductListing.select1Description')}
              </div>
            </div>
            <div className="w-[14px] flex justify-center  mb-[5px]">
              <img src={productArrow} alt={'productArrow'} className="object-contain w-[14px]" />
            </div>
          </div>
          {/* 选项二 */}
          <div
            className="bg-[#FFFFFF] shadow-[0_4px_38px_rgba(16,24,64,0.08)] rounded-[10px] p-[18px_21px_17px_6px] flex w-full justify-between mt-[15px]"
            id="product-listing__Combo-mobile"
            onClick={() => {
              navigate(`/selectResult-covidflu?productType=${CFF_TYPE}`);
            }}
          >
            <div className="flex">
              <img src={productMobile2} alt={'productMobile2'} className="object-contain w-[105px] mr-[21px]" />
              <div className="font-[600] text-[14px] leading-[18px] w-[192px] flex items-center justify-left text-[#212121] mb-[5px]">
                {t('ProductListing.select2Name')} <br />
                {t('ProductListing.select2Description')}
              </div>
            </div>
            <div className="w-[14px] flex justify-center  mb-[5px]">
              <img src={productArrow} alt={'productArrow'} className="object-contain w-[14px]" />
            </div>
          </div>
          {/* 选项三 */}
          <div
            className="bg-[#FFFFFF] shadow-[0_4px_38px_rgba(16,24,64,0.08)] rounded-[10px] p-[18px_21px_17px_7px] flex w-full justify-between mt-[15px] mb-[56px]"
            id="product-listing__Covid-mobile"
            onClick={() => {
              navigate(`/selectResult-covid?productType=${COVID_TYPE}`);
            }}
          >
            <div className="flex">
              <img src={productMobile3} alt={'productMobile3'} className="object-contain w-[105px] mr-[21px]" />
              <div className="font-[600] text-[14px] leading-[18px] w-[192px] flex items-center justify-left text-[#212121] mb-[5px]">
                {t('ProductListing.select3Name')} <br />
                {t('ProductListing.select3Description')}
              </div>
            </div>
            <div className="w-[14px] flex justify-center  mb-[5px]">
              <img src={productArrow} alt={'productArrow'} className="object-contain w-[14px]" />
            </div>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    track({
      event_type: 'view service',
      event_properties: {
        'testing id': getDataTrackConstantId('TestingId'),
        source: window.document.referrer,
      },
    });
  }, []);

  return (
    <div
      className="w-full h-full flex flex-col py-[0] px-[24px] md:px-[32px] md:items-center lg:px-[0px] lg:items-center min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)]"
      style={style.container}
    >
      <div className="flex flex-col justify-start w-full">
        <div className="mt-[35px] font-[600] text-[14px] leading-[28px] w-full flex items-center justify-center text-[#2DBDB6] lg:mt-[97px] lg:text-[18px] lg:leading-[28px]">
          {/* {t('ProductListing.title1')} */}
        </div>
        <div className="font-[600] text-[28px] leading-[58px] w-full flex items-center justify-center text-[#212121] lg:text-[40px] lg:leading-[58px] lg:mt-[2px]">
          {t('ProductListing.title2')}
        </div>
        <div className="font-[500] text-[14px] leading-[22px] w-full flex items-center justify-center text-[#212121] text-left lg:text-center  tracking-[0.25px] lg:w-[803px] lg:text-[18px] lg:leading-[28px] lg:tracking-[0.25px] lg:m-[auto] lg:mt-[30px]">
          {t('ProductListing.title3')}
        </div>
        {renderProduct()}
      </div>
    </div>
  );
};

export default ProductList;
