import { CheckCircleFilled } from '@ant-design/icons';
import HospitalIcon from '../HospitalIcon';
import MedicalIcon from '../MedicalIcon';
import positiveImg from '../assets/positive.png';
import negativeImg from '../assets/negative.png';
import invalidImg from '../assets/Invalid.png';
import { useTranslation } from 'react-i18next';

export function useItemsMap() {
  const [t] = useTranslation();

  return {
    Positive: [
      {
        title: t('selectResultConfirmAlert.positive.NextStep'),
        content: t('selectResultConfirmAlert.positive.NextStepDesc'),
        icon: <CheckCircleFilled className="lg:text-[20px] lg:mr-[12px]" />,
      },
      {
        title: t('selectResultConfirmAlert.positive.GeneralDoctorConsultation'),
        content: t('selectResultConfirmAlert.positive.GeneralDoctorConsultationDesc'),
        icon: <HospitalIcon className="lg:mr-[12px]" />,
      },
      {
        title: t('selectResultConfirmAlert.positive.IncaidCareDoctorConsultation'),
        content: (
          <>
            <p>{t('selectResultConfirmAlert.positive.IncaidCareDoctorConsultationDesc1')}</p>
            <p>{t('selectResultConfirmAlert.positive.IncaidCareDoctorConsultationDesc2')}</p>
          </>
        ),
        icon: <MedicalIcon className="lg:mr-[12px]" />,
      },
    ],
    Negative: [
      {
        title: t('selectResultConfirmAlert.ResultInterpretation'),
        content: t('SelectResultPage.negativeDesc'),
        icon: <HospitalIcon className="lg:mr-[12px]" />,
      },
      {
        title: t('selectResultConfirmAlert.positive.NextStep'),
        content: (
          <>
            {t('SelectResultPage.nagativeNextStep.1')}
            <span className="font-[600]">{t('SelectResultPage.nagativeNextStep.2')}</span>
            {t('SelectResultPage.nagativeNextStep.3')}
          </>
        ),
        icon: <CheckCircleFilled className="lg:text-[20px] lg:mr-[12px]" />,
      },
    ],
    Invalid: [
      {
        title: t('selectResultConfirmAlert.ResultInterpretation'),
        content: t('SelectResultPage.invalidDesc'),
        icon: <HospitalIcon className="lg:mr-[12px]" />,
      },
      {
        title: t('selectResultConfirmAlert.positive.NextStep'),
        content: t('SelectResultPage.invalidStep'),
        icon: <CheckCircleFilled className="lg:text-[20px] lg:mr-[12px]" />,
      },
    ],
  };
}

export const AlertTypeImgMap = {
  Positive: positiveImg,
  Negative: negativeImg,
  Invalid: invalidImg,
};
