import { Outlet } from 'react-router-dom';
import SideBar from '@components/DoctorComponents/SideBar/SideBar';
import { useRouterChangeAutoScrollTop } from './hooks/tools/RouterAutoScrollTop';
import './DoctorApp.css';
import { ConfigProvider } from 'antd';
function DoctorApp() {
  useRouterChangeAutoScrollTop();
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#009FAF',
          fontFamily:
            "'Inter','Montserrat', -apple-system, blinkmacsystemfont, 'Helvetica Neue', helvetica, 'segoe ui', arial, roboto,'PingFang SC', miui, 'Hiragino Sans GB', 'Microsoft Yahei', sans-serif",
        },
        components: {
          Notification: {
            colorInfo: '#009FAF',
          },
          Checkbox: {
            colorPrimary: '#009FAF',
          },
        },
      }}
    >
      <div className="doctor-app flex h-[100vh]">
        <SideBar />
        <Outlet />
      </div>
    </ConfigProvider>
  );
}

export default DoctorApp;
