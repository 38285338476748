import { deleteFunc, get, patch, post } from '@utils/request';
import { DrugList, NewDrugInfo, UpdateDrug } from './types/medicationService.type';

export function getDrugsList(params?: {
  page?: number;
  size?: number;
  searchName?: string;
  sortField?: string;
  sortBy?: string;
}) {
  return get<DrugList>('/care/drug/list', params).then((data) => data);
}

export function addDrug(params: NewDrugInfo) {
  return post('/care/drug', params);
}

export function deleteDrug(params: { id: string }) {
  return deleteFunc('/care/drug', params);
}

export function updateDrug(params: UpdateDrug) {
  return patch('/care/drug', params);
}

export default {
  getDrugsList,
  addDrug,
  deleteDrug,
  updateDrug,
};
