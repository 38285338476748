export default function AlertPrivacyText(props: { children: React.ReactNode }) {
  return (
    <p
      className="
      w-full lg:mb-[32px] flex text-[#000000]
      lg:text-[16px] lg:leading-[28px] text-left
      text-[14px] leading-[22px] mb-[24px]
  "
    >
      {props.children}
    </p>
  );
}
