import { useContext } from 'react';
import { LanguageContext, hkLanguage } from 'src/router/routerTools';

export function useIsHkLanguage() {
  const language = useContext(LanguageContext);
  if (hkLanguage === language) {
    return true;
  } else {
    return false;
  }
}
