import { CloseOutlined } from '@ant-design/icons';
import { globalAlert } from '../GlobalAlert';
import AlertTermsListTitle from './components/AlertTermsListTitle';
import AlertTermsTitle from './components/AlertTermsTitle';
import AlertPrivacyText from './components/AlertPrivacyText';
import { useTranslation } from 'react-i18next';

type TermsAlertContentProps = { closeFunction: () => void; id?: string };
const TermsAlertContent: React.FC<TermsAlertContentProps> = (props) => {
  const [t] = useTranslation();

  return (
    <div className="lg:w-[1024px] lg:h-[762px] w-full h-[650px] rounded-[30px] lg:rounded-[30px] bg-white">
      <div className="lg:px-[41px] lg:pt-[36px] pt-[27px] px-[22px] text-[22px] font-bold text-right">
        <CloseOutlined className="cursor-pointer" onClick={props.closeFunction} id={props?.id} />
      </div>
      <div className="lg:px-[59px] px-[30px] lg:h-[670px] h-[566px] overflow-y-scroll custom-scroll-bar">
        <AlertTermsTitle>{t('privacyText.title')}</AlertTermsTitle>
        <AlertPrivacyText>{t('privacyText.0')}</AlertPrivacyText>
        <AlertTermsListTitle>{t('privacyText.1')}</AlertTermsListTitle>
        <AlertPrivacyText>{t('privacyText.1_1')}</AlertPrivacyText>
        <AlertTermsListTitle>{t('privacyText.2')}</AlertTermsListTitle>
        <AlertPrivacyText>{t('privacyText.2_1')}</AlertPrivacyText>
        <AlertTermsListTitle>{t('privacyText.3')}</AlertTermsListTitle>
        <AlertPrivacyText>{t('privacyText.3_1')}</AlertPrivacyText>
        <AlertTermsListTitle>{t('privacyText.4')}</AlertTermsListTitle>
        <AlertPrivacyText>{t('privacyText.4_1')}</AlertPrivacyText>
        <AlertTermsListTitle>{t('privacyText.5')}</AlertTermsListTitle>
        <AlertPrivacyText>{t('privacyText.5_1')}</AlertPrivacyText>
        <AlertTermsListTitle>{t('privacyText.6')}</AlertTermsListTitle>
        <AlertPrivacyText>{t('privacyText.6_1')}</AlertPrivacyText>
        <AlertTermsListTitle>{t('privacyText.7')}</AlertTermsListTitle>
        <AlertPrivacyText>{t('privacyText.7_1')}</AlertPrivacyText>
        <AlertTermsListTitle>{t('privacyText.8')}</AlertTermsListTitle>
        <AlertPrivacyText>{t('privacyText.8_1')}</AlertPrivacyText>
        <AlertTermsListTitle>{t('privacyText.9')}</AlertTermsListTitle>
        <AlertPrivacyText>{t('privacyText.9_1')}</AlertPrivacyText>
        <AlertTermsListTitle>{t('privacyText.10')}</AlertTermsListTitle>
        <AlertPrivacyText>{t('privacyText.10_1')}</AlertPrivacyText>
      </div>
    </div>
  );
};

export default function alertPrivacy(id?: string) {
  return globalAlert((close) => ({
    content: <TermsAlertContent closeFunction={close} id={id} />,
    wraperBackground: 'rgba(0, 0, 0, 0.4)',
  }));
}
