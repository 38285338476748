import { get } from '@utils/request';

function getAuthLoginUrl(channel: 'google' | 'facebook' | 'linkedin') {
  return get<{ url: string }>(`/user/url/${channel}`, {
    isCare: true,
  });
}

function thirdPartyLogin(params: { code: string; state: string }, AboutRef?: { current: AbortController | null }) {
  return get<{ token: string }>(
    '/user/oauth2callback',
    { ...params, isCare: true },
    {
      abortControllerRef: AboutRef,
    },
  );
}

export default {
  getAuthLoginUrl,
  thirdPartyLogin,
};
