import { handleLocalStorage } from '..';
import { resultTypeText } from '../../pages/SelectResult/SelectResult';

const KEY = 'QuestionnaireConsultationMethod';
export function saveConsultationMethod(answers: { id: string; answer: number }[]) {
  const rs = answers.some((v, i) => {
    return v.answer == 1 + i * 2;
  })
    ? 1
    : 0;
  localStorage.setItem(KEY, rs + '');
}

export function getConsultationMethod() {
  const rs = localStorage.getItem(KEY);
  if (rs === '1') {
    return {
      offline: true,
      online: false,
    };
  }
  // 添加逻辑：问卷可以线上+检测结为阳性=只能线上
  const result = handleLocalStorage.get('testResult') as keyof typeof resultTypeText;
  let testResult = resultTypeText[result];
  if (testResult == 'positive') {
    return {
      offline: false,
      online: true,
    };
  }
  return {
    offline: true,
    online: true,
  };
}
