import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const CustomVoiceIconSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="11" y="7" width="2.5" height="12" rx="1.25" fill="#099DFD" />
    <rect x="16" y="10" width="2.5" height="6" rx="1.25" fill="#099DFD" />
    <rect x="6" y="10" width="2.5" height="6" rx="1.25" fill="#099DFD" />
  </svg>
);

export default function CustomVoiceIcon(props: Partial<CustomIconComponentProps>) {
  //   return <div className="flex items-center">
  //   <div className="w-[4px] h-[9px] rounded-[full] bg-[#099DFD]"></div>
  //   <div className="w-[4px] h-[18px] rounded-full ml-[2px] bg-[#099DFD]"></div>
  //   <div className="w-[4px] h-[9px] rounded-full ml-[2px] bg-[#099DFD]"></div>
  // </div>
  return <Icon component={CustomVoiceIconSvg} {...props} />;
}
