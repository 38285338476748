import EventsMap from '@utils/EventsMap';
import { useEffect, useState } from 'react';
import RtcAdapter from '../RtcTool';

const AudioStatusChangedEventMap = new EventsMap<(b: boolean) => void>();
RtcAdapter.on('remote-audio-start', (rtcUid) => {
  AudioStatusChangedEventMap.triggerEventMap(rtcUid, true);
});
RtcAdapter.on('remote-audio-stop', (rtcUid) => {
  AudioStatusChangedEventMap.triggerEventMap(rtcUid, false);
});

export const useAudioStatus = (rtcUid: string) => {
  const [audioStatus, setAudioStatus] = useState(RtcAdapter.getRemoteAudioStatus(rtcUid));
  useEffect(() => {
    return AudioStatusChangedEventMap.addEventMapListener(rtcUid, setAudioStatus);
  }, [rtcUid]);
  return audioStatus;
};

const VideoStatusChangedEventMap = new EventsMap<(b: boolean) => void>();
RtcAdapter.on('remote-video-start', (rtcUid) => {
  VideoStatusChangedEventMap.triggerEventMap(rtcUid, true);
});
RtcAdapter.on('remote-video-stop', (rtcUid) => {
  VideoStatusChangedEventMap.triggerEventMap(rtcUid, false);
});

export const useVideoStatus = (rtcUid: string) => {
  const [videoStatus, setVideoStatus] = useState(RtcAdapter.getRemoteVideoStatus(rtcUid));
  useEffect(() => {
    return VideoStatusChangedEventMap.addEventMapListener(rtcUid, setVideoStatus);
  }, [rtcUid]);
  return videoStatus;
};
