import { createContext, useContext } from 'react';
import { LanguageContext, defaultLanguage } from 'src/router/routerTools';
import { usePatientInfos } from '@states/patient.state';

const navDataConf: {
  data: string[];
  sub?: string[][];
  mega?: number;
  columns?: number;
}[] = [
  // {
  //   data: ['Testing Services', '/collections/services'],
  //   sub: [
  //     ['PCR Test', '/products/pcr-test'],
  //     ['Rapid Antigen Test', '/products/rapid-antigen-test'],
  //     ['PCR Test (Premium Outreach Service)', '/products/pcr-outreach'],
  //     ['Online Supervised Rapid Antigen Test', '/products/online-rapid-antigen-test'],
  //   ],
  //   mega: 1,
  //   columns: 1,
  // },
  {
    data: ['Products', '/collections/products'],
    sub: [
      ['COVID-19 Rapid Antigen Test Kit', '/products/covidantigen'],
      ['COVID-19/FLU Rapid Antigen Test Kit', '/products/covid-flu-rat'],
      ['UU Tube Helicobacter Pylori Test', '/products/uutube'],
      // ['INDICAID APP', '/products/indicaid-app'],
      ['INDICAID Mask', '/products/mask-cgrey'],
    ],
  },
  {
    data: ['Doctor Appointment', '', '1'],
  },
  {
    data: ['FAQs', '/pages/faqs'],
  },
  {
    // data: ['INDICAID Blog', '/pages/indicaidblog'],
    data: ['Blog', '/pages/indicaidblog'],
    // sub: [
    //   ['Blog', '/pages/indicaidblog'],
    //   ["CHIU's Channel", '/blogs/interview'],
    // ],
  },
  {
    data: ['About INDICAID', '/pages/aboutus'],
    sub: [
      ['About Us', '/pages/aboutus'],
      ['News', '/pages/news'],
      ['Contact Us', '/pages/contactus'],
    ],
  },
];
export const HeaderContext = createContext({
  lastName: '',
  isLogin: false,
  host: '',
  hostSelf: '',
  navData: navDataConf,
  language: '',
  logoUrl: '',
});

const useHeaderCtx = () => {
  const { patientInfos, isLoading, isLogin } = usePatientInfos();

  const lastName = patientInfos?.lastName || '';

  const language = useContext(LanguageContext);
  const localePath = language == defaultLanguage ? '' : '/' + language;
  const localePath2 = '/' + language;
  const host = `https://www.indicaid.com${localePath}`;
  const hostSelf = `${localePath2}`;
  const navData = navDataConf.map((v) => {
    let obj: (typeof navDataConf)[number] = JSON.parse(JSON.stringify(v));
    if (v.data[2] == '1') {
      obj.data[1] = hostSelf + v.data[1];
      return obj;
    }
    obj.data[1] = host + v.data[1];
    obj.sub?.forEach((vv) => (vv[1] = host + vv[1]));
    return obj;
  });
  return {
    logoUrl: `https://www.indicaid.com/${language === 'en' ? 'en/' : ''}pages/indicaid-care`,
    lastName,
    isLogin,
    isLoading,
    host,
    hostSelf,
    navData,
    language,
  };
};
export default useHeaderCtx;
