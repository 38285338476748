import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useEffect, useMemo, useState } from 'react';
import { useMemoizedFn } from 'ahooks';
import { CSSProperties } from 'react';

import classNames from 'classnames';

import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import { MEDIA_SIZE } from '@consts/consts';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import './PhoneNumberInput.css';

const wrapperDefaultClass =
  'rounded-[0.192rem] w-[7.9rem] text-[0.386rem] h-[1.15rem] flex items-center justify-center md:justify-start border-[0.04rem] md:h-[48px] lg:h-[43px] md:px-[12px]';

export type PhoneNumberInputStatus = 'error' | 'warning' | 'success' | undefined;

const borderColor = {
  default: '#D5D4DC',
  warning: '#EF8943',
  error: '#C83532',
  success: '#2BAC47',
};
const bgColor = {
  default: '#ffffff',
  warning: '#FDF3EC',
  error: '#FBEFEF',
  success: '#F1F8F2',
};
const textColor = {
  default: '#D5D4DC',
  warning: '#EF8943',
  error: '#EB5757',
  success: '#2BAC47',
};

// TODO 这个需要再看一下
// OTP input field should be a number input field
// https://phasescientific.atlassian.net/browse/DPMOD-383
type PhoneNumberInputProps = {
  value?: string;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onIsValidChange?: (v: boolean) => void;
  style?: CSSProperties;
  wrapperClassName?: string;
  isSubmitCheck?: boolean;
  status?: PhoneNumberInputStatus;
  helpText?: string;
  placeholder?: string;
  iconClassName?: string;
  helpTextStyle?: CSSProperties;
  id?: string;
  errorId?: string;
};
export default function PhoneNumberInput(props: PhoneNumberInputProps) {
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const [isValid, setValid] = useState(true);
  const [allPhonenumberStr, setAllPhonenumberStr] = useState('');
  const [showStatus, setShowStatus] = useState<keyof typeof bgColor>('default');
  const [t] = useTranslation();
  // const [countryCodeVal, setCountryCode] = useState('+852')

  const phoneNumberData = useMemo(() => {
    if (!allPhonenumberStr) {
      return {
        phoneNumber: '',
        countryCode: '+852',
      };
    }
    const asYouType = new AsYouType();
    asYouType.input(allPhonenumberStr);
    return { phoneNumber: asYouType.getNationalNumber(), countryCode: '+' + (asYouType.getCallingCode() || '') };
  }, [allPhonenumberStr]);

  const handlePhoneinputChange: React.ChangeEventHandler<HTMLInputElement> = useMemoizedFn((e) => {
    let phonenumberWithCountyCode = '';
    if (e.target.value.startsWith('+')) {
      setAllPhonenumberStr(e.target.value);
      return;
    } else {
      phonenumberWithCountyCode = '+852' + e.target.value;

      // 根据PO需求，HK的number限制在12位
      if (phonenumberWithCountyCode.length > 12) {
        const afterSlicePhoneNumber = phonenumberWithCountyCode.slice(0, 12);
        // 更新phonenumberWithCountyCode的值
        phonenumberWithCountyCode = afterSlicePhoneNumber;
        setAllPhonenumberStr(afterSlicePhoneNumber);
      } else {
        setAllPhonenumberStr(phonenumberWithCountyCode);
      }
    }

    if (isValidPhoneNumber(phonenumberWithCountyCode)) {
      setValid(true);
      props.onIsValidChange?.(true);
    } else {
      e.type == 'blur' && setValid(false);
      e.type == 'change' && setValid(true);
      props.onIsValidChange?.(false);
    }
    props.onChange?.(phonenumberWithCountyCode || '');
  });

  const isValidNumber = useMemoizedFn((val: string) => {
    if (val && isValidPhoneNumber(val)) {
      setValid(true);
      props.onIsValidChange?.(true);
    } else {
      setValid(false);
      props.onIsValidChange?.(false);
    }
    props.onChange?.(val || '');
  });

  const renderErrorMessage = () => {
    if (!isValid) {
      return <p className="text-[#C83532]  ml-[4px] text-[14px]">{t('PhoneNumberInput.invalidPhoneText')}</p>;
    } else if (props.status === 'error' && isValid && props.helpText) {
      return (
        <p
          className="text-[#C83532]  ml-[4px]  text-[14px]"
          style={{
            color: textColor[props.status || 'default'],
            ...props.helpTextStyle,
          }}
        >
          {props.helpText}
        </p>
      );
    } else {
      return null;
    }
  };

  const renderHelpTextIcon = () => {
    if (!isValid) {
      return (
        <ExclamationCircleFilled
          className={props.iconClassName ?? 'text-[16px] sm:text-[16px]'}
          style={{
            color: borderColor[props.status || 'error'],
          }}
        />
      );
    }
    if (!props.helpText || !props.status) {
      return null;
    }
    if (props.status === 'success') {
      return (
        <CheckCircleFilled
          className={props.iconClassName}
          style={{
            color: borderColor[props.status],
          }}
        />
      );
    } else {
      return (
        <ExclamationCircleFilled
          className={props.iconClassName ?? 'text-[16px] sm:text-[16px]'}
          style={{
            color: borderColor[props.status],
          }}
        />
      );
    }
  };

  const renderErrorCol = () => {
    return (
      <div className="flex items-center lg:mt-[8px]" id={props.errorId}>
        {renderHelpTextIcon()}
        {renderErrorMessage()}
      </div>
    );
  };

  useEffect(() => {
    const forceCheck = () => {
      let input = document.getElementById(props.id || 'phone-number-id');
      input && input.focus();
      input && input.blur();
    };

    if (props.isSubmitCheck) {
      forceCheck();
    }
  }, [props.isSubmitCheck, props.id]);

  useEffect(() => {
    if (props.value && isValidPhoneNumber(props.value)) {
      setValid(true);
    }
    // when PhoneNumberInput mounted, we need know that is the default value valid,
    // but there is no need to re-determine the value has changed is valid
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  useEffect(() => {
    if (props.value === '') {
      setShowStatus('default');
    } else if (props.status === 'error') {
      setShowStatus('error');
    } else if (isValid && props.value && props.value.length === 12) {
      setShowStatus('success');
    } else {
      setShowStatus('error');
    }
  }, [props.value, isValid, props.status]);

  return (
    <div>
      <div
        className={classNames(
          isValid || props.value === '' ? 'bg-[#ffffff] border-[#D5D4DC]' : 'bg-[#FBEFEF] border-[#C83532]',
          props.wrapperClassName ?? wrapperDefaultClass,
        )}
        style={{
          backgroundColor: bgColor[(showStatus as keyof typeof bgColor) || 'default'],
          borderColor: borderColor[(showStatus as keyof typeof bgColor) || 'default'],
          fontFamily: window.location.href.includes(`${location.origin}/doctor`) ? 'Inter' : 'Montserrat',
          borderWidth: !isLg ? '2px' : '2px',
          borderRadius: '6px',
          // WebkitBoxShadow: `${borderColor[(showStatus as keyof typeof bgColor) || 'default']} !important`,
          // width: '300px!important',
          ...props.style,
        }}
      >
        {/* showStatus */}
        <PhoneInput
          // id={props.id}
          onFocus={props.onFocus}
          value={props.value}
          className="lg:w-[40px] w-[40px]  border-transparent"
          numberInputProps={{
            className: 'bg-[#00000000] outline-0 text-[0.386rem] w-[6rem] border-transparent hidden',
            'data-testid': 'phonenumber-input',
          }}
          style={{
            borderColor: '#fff',
            fontFamily: window.location.href.includes(`${location.origin}/doctor`) ? 'Inter' : 'Montserrat',
          }}
          onCountryChange={(c) => {
            console.log(c);
          }}
          withCountryCallingCode={false}
          addInternationalOption={false}
          international
          defaultCountry="HK"
          onChange={isValidNumber}
          countries={['HK']}
        />
        <p className="lg:w-[40px] w-[40px] font-[Montserrat]">{phoneNumberData.countryCode}</p>
        <input
          id={props.id || 'phone-number-id'}
          style={{
            fontFamily: 'Montserrat',
            backgroundColor: bgColor[(showStatus as keyof typeof bgColor) || 'default'],
            boxShadow: `inset 0 0 0 200000px ${bgColor[(showStatus as keyof typeof bgColor) || 'default']}`,
            // fontFamily: window.location.href.includes(`${location.origin}/doctor`) ? 'Inter' : 'Montserrat',
            // backgroundColor: bgColor[(showStatus as keyof typeof bgColor) || 'default'],
            // backgroundColor: 'transparent',
            // borderColor: borderColor[(showStatus as keyof typeof bgColor) || 'default'],
          }}
          value={phoneNumberData.phoneNumber}
          onChange={handlePhoneinputChange}
          onBlur={handlePhoneinputChange}
          className="outline-0 w-[70%] border-transparent bg-transparent"
          placeholder={props.placeholder || t('PhoneNumberInput.enterPhoneText') || undefined}
        />
      </div>
      {renderErrorCol()}
    </div>
  );
}
