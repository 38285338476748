import { atom } from 'recoil';
import { setRecoil } from 'recoil-nexus';

const isMeetingJoinedState = atom({
  key: 'isMeetingJoinedState',
  default: false,
});
export default isMeetingJoinedState;

export const shouldOpenCameraOrMicrophone = atom({
  key: 'shouldOpenCameraOrMicrophone',
  default: {
    camera: localStorage.getItem('shouldOpenCamera') === 'true',
    microphone: localStorage.getItem('shouldOpenMicrophone') === 'true',
  },
});

export const updateShouldOpenCameraStatus = (cameraStatus: boolean) => {
  localStorage.setItem('shouldOpenCamera', `${cameraStatus}`);
  setRecoil(shouldOpenCameraOrMicrophone, (old) => {
    return {
      ...old,
      camera: cameraStatus,
    };
  });
};

export const updateShouldOpenMicrophoneStatus = (MicrophoneStatus: boolean) => {
  localStorage.setItem('shouldOpenMicrophone', `${MicrophoneStatus}`);

  setRecoil(shouldOpenCameraOrMicrophone, (old) => {
    return {
      ...old,
      microphone: MicrophoneStatus,
    };
  });
};
