/* eslint-disable @typescript-eslint/indent */
// import React, { useState, useEffect } from 'react';
// import type { RadioChangeEvent } from 'antd';
// import { Form, Radio, message } from 'antd';
// import { Form, Spin, message, Radio } from 'antd';
import 'react-phone-number-input/style.css';
// import { useMemoizedFn } from 'ahooks';
import { post } from '@utils/request';
import BaseButton from '@components/BaseButton/BaseButton';
import { useLocalNavigate } from 'src/router/routerTools';
import { useSelectResultType } from 'src/hooks/tools/useSelectResultType';

const style = {
  container: {
    background: '#FDFDFD',
  },
};

// TODO 应该在医生端生成=>但是问题不大
const TestMedicinePayments: React.FC = () => {
  const { isHaveSelectResultType, selectResultType } = useSelectResultType();
  const navigate = useLocalNavigate();
  const onClick = async () => {
    const params = {
      bookingId: 349,
    };
    // TODO 需要再进行测试
    const data = await post('/care/booking/medication', params);

    navigate(
      `/payments?intent_id=${data?.intentId}${isHaveSelectResultType ? `&selectResultType=${selectResultType}` : ''}`,
    );
  };

  return (
    <div
      className="w-full h-full flex justify-center  bg-opacity-[0.8] flex-col py-[0] px-[24px] md:px-[32px] md:items-center lg:px-[0px] lg:items-center min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)]"
      style={style.container}
    >
      <BaseButton
        htmlType="submit"
        buttonClassName="md:w-[370px] md:h-[40px] md:text-[16px]"
        value={'click me,test medicine payment'}
        // disable={buttonStatus === 'disabled'}
        status="default"
        onClick={onClick}
      ></BaseButton>
    </div>
  );
};

export default TestMedicinePayments;
