import { TimeStamp } from 'types/types';

// export const TimePointEnum = {
//   1: '9:00am',
//   2: '9:30am',
//   3: '10:00am',
//   4: '10:30am',
//   5: '11:00am',
//   6: '11:30am',
//   7: '12:00pm',
//   8: '12:30pm',
//   9: '2:00pm',
//   10: '2:30pm',
//   11: '3:00pm',
//   12: '3:30pm',
//   13: '4:00pm',
//   14: '4:30pm',
//   15: '5:00pm',
//   16: '5:30pm',
//   17: '6:00pm',
//   18: '6:30pm',
//   19: '7:00pm',
//   20: '7:30pm',
//   21: '8:00pm',
// };

export const TimePointEnum = {
  '74742298314800001': '12:00am',
  '74742298314800002': '12:30am',
  '74742298314800003': '1:00am',
  '74742298314800004': '1:30am',
  '74742298314800005': '2:00am',
  '74742298314800006': '2:30am',
  '74742298314800007': '3:00am',
  '74742298314800008': '3:30am',
  '74742298314800009': '4:00am',
  '74742298314800010': '4:30am',
  '74742298314800011': '5:00am',
  '74742298314800012': '5:30am',
  '74742298314800013': '6:00am',
  '74742298314800014': '6:30am',
  '74742298314800015': '7:00am',
  '74742298314800016': '7:30am',
  '74742298314800017': '8:00am',
  '74742298314800018': '8:30am',
  '74742298314800019': '9:00am',
  '74742298314800020': '9:30am',
  '74742298314800021': '10:00am',
  '74742298314800022': '10:30am',
  '74742298314800023': '11:00am',
  '74742298314800024': '11:30am',
  '74742298314800025': '12:00pm',
  '74742298314800026': '12:30pm',
  '74742298314800027': '1:00pm',
  '74742298314800028': '1:30pm',
  '74742298314800029': '2:00pm',
  '74742298314800030': '2:30pm',
  '74742298314800031': '3:00pm',
  '74742298314800032': '3:30pm',
  '74742298314800033': '4:00pm',
  '74742298314800034': '4:30pm',
  '74742298314800035': '5:00pm',
  '74742298314800036': '5:30pm',
  '74742298314800037': '6:00pm',
  '74742298314800038': '6:30pm',
  '74742298314800039': '7:00pm',
  '74742298314800040': '7:30pm',
  '74742298314800041': '8:00pm',
  '74742298314800042': '8:30pm',
  '74742298314800043': '9:00pm',
  '74742298314800044': '9:30pm',
  '74742298314800045': '10:00pm',
  '74742298314800046': '10:30pm',
  '74742298314800047': '11:00pm',
  '74742298314800048': '11:30pm',
};
// TODO 问问祥哥能不能传这样的数据，如果不行就需要进行数据的转换
// export type TimePointEnum = {
//   [prop: string]: string,
// };
// = {
//   1: '9:00am',
//   2: '9:30am',
//   3: '10:00am',
//   4: '10:30am',
//   5: '11:00am',
//   6: '11:30am',
//   7: '12:00pm',
//   8: '12:30pm',
//   9: '2:00pm',
//   10: '2:30pm',
//   11: '3:00pm',
//   12: '3:30pm',
//   13: '4:00pm',
//   14: '4:30pm',
//   15: '5:00pm',
//   16: '5:30pm',
//   17: '6:00pm',
//   18: '6:30pm',
//   19: '7:00pm',
//   20: '7:30pm',
//   21: '8:00pm',
// };

export type AvailableTimeKey = keyof typeof TimePointEnum;

export type TimeSlotAvailableData = {
  [prop: TimeStamp]: {
    [key in AvailableTimeKey]?: {
      offline: boolean;
      online: boolean;
    };
  };
};
export type TimeSlotStringAvailableData = {
  [prop: string]: {
    [key in AvailableTimeKey]?: {
      offline: boolean;
      online: boolean;
    };
  };
};
