import BaseButton from '@components/BaseButton/BaseButton';
// import { Progress, Statistic } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useLocalNavigate } from 'src/router/routerTools';
import { useRouterChangeAutoScrollTop } from '@hooks/tools/RouterAutoScrollTop';
import { useMemoizedFn } from 'ahooks';

// const { Countdown } = Statistic;
type ExceptionPageType = 'end' | 'doctorEnd' | 'expired' | 'doctorExpired' | string;

const typeMap: {
  [prop: ExceptionPageType]: {
    title: string;
    subTitle: string;
  };
} = {
  end: {
    title: 'MeetingEndOrExpiredPage.MeetingEnded',
    subTitle: 'MeetingEndOrExpiredPage.MeetingEndedSubTitle',
  },
  doctorEnd: {
    title: 'MeetingEndOrExpiredPage.ConsultationEnded',
    subTitle: 'MeetingEndOrExpiredPage.pleaseContactUs',
  },
  expired: {
    title: 'MeetingEndOrExpiredPage.MeetingExpired',
    subTitle: 'MeetingEndOrExpiredPage.MeetingExpiredSubTitle',
  },
  doctorExpired: {
    title: 'MeetingEndOrExpiredPage.MeetingExpired',
    subTitle: '',
  },
};
function getTitleText(type: ExceptionPageType) {
  return typeMap[type];
}

function getBtnText(type: ExceptionPageType) {
  if (type === 'end') {
    return 'MeetingEndOrExpiredPage.BookNewAppointment';
  } else if (type === 'doctorEnd') {
    return 'View Consultation Detail';
  } else if (type === 'doctorExpired') {
    return 'View Consultation Detail';
  }
  return 'MeetingEndOrExpiredPage.BookNewAppointment';
}

export default function MeetingEndOrExpired() {
  useRouterChangeAutoScrollTop();
  const [t] = useTranslation();
  const type = useSearchParams()[0].get('type') || '';
  const bookingId = useSearchParams()[0].get('bookingId') || '';

  let { title, subTitle } = getTitleText(type) || {
    title: 'MeetingEndOrExpiredPage.SomethingWrong',
    subTitle: '',
  };
  // const deadline = useMemo(() => {
  //   return Date.now() + 1000 * 10;
  // }, []);
  // const [percent, setPercent] = useState(100);

  const btnText = getBtnText(type);

  const showBtn = type === 'expired' || type === 'end' || type === 'doctorEnd' || type === 'doctorExpired';
  const navigate = useLocalNavigate();

  const handleClickBtn = useMemoizedFn(() => {
    if (type === 'expired') {
      navigate('/selectResult');
    } else if (type === 'end') {
      navigate('/selectResult');
    } else if (type === 'doctorEnd') {
      navigate('/doctor/appointmentsDetails?appointmentsId=' + bookingId);
    } else if (type === 'doctorExpired') {
      navigate('/doctor/appointmentsDetails?appointmentsId=' + bookingId);
    }
  });
  return (
    <div className="bg-[#383838] relative w-full h-[100vh] flex flex-col text-[#ffffff] items-center justify-center">
      <p className="text-[32px] font-semibold leading-[42px]">{t(title)}</p>
      {subTitle && (
        <p className="text-[18px] text-center mt-[52px] leading-[28px] lg:w-[663px] w-[390px]">{t(subTitle)}</p>
      )}
      {showBtn && (
        <BaseButton
          buttonClassName={classNames('mt-[47px] text-[16px] w-[326px] h-[50px] inline-block')}
          status="default"
          onClick={handleClickBtn}
          value={t(btnText)}
        />
      )}
      <p className="text-[16px] mt-[23px] inline-block text-center w-full">
        {t('MeetingEndOrExpiredPage.NeedHelp')}
        <a onClick={() => navigate('/contactUs')} className="text-[#2DBDB6] cursor-pointer">
          {' '}
          {t('ContactUs')}
        </a>
      </p>
      {/* <div className="absolute bottom-[150px] text-center text-[#ffffff]">
        <Progress
          size={40}
          type="circle"
          percent={percent}
          strokeColor="#2DBDB6"
          format={() => (
            <Countdown
              valueStyle={{
                color: '#ffffff',
              }}
              onChange={(v) => {
                if (!v) {
                  return;
                }
                v = +v;
                if (v <= 0) {
                  setPercent(0);
                  // if (false) {
                  navigate('/');
                  // }
                } else {
                  setPercent(v / 100);
                }
              }}
              value={deadline}
              format="s"
            />
          )}
        />
        <p className="text-[14px] mt-[10px]">Returning to the home screen</p>
      </div> */}
    </div>
  );
}
