import deleteAccountImg from './deletePage.svg';
import csSvg from './csSvg.svg';
import emailSvg from './emailSvg.svg';

export default function DeleteAccountPage() {
  return (
    <div className="lg:mt-[39px] lg:mb-[39px] flex flex-col justify-center items-center w-full">
      <img src={deleteAccountImg} alt="" className="lg:w-[235px] lg:h-[220px] w-[192px] h-[180px] object-cover" />
      <span className="lg:text-[#212121] lg:leading-[36px] font-semibold lg:text-[28px] text-[22px] leading-[36px] mt-[20px]">
        Delete account?
      </span>
      <p className="lg:text-[18px] lg:w-[715px] font-medium lg:leading-[28px] lg:mt-[40px] text-[#000] mt-[30px] text-[16px] leading-[24px]">
        To request the deletion of your account, please contact our customer service.
      </p>
      <p className="lg:text-[16px] lg:leading-[28px] lg:mt-[20px] lg:w-[715px] leading-[22px] tracking-[.25px] text-[14px] mt-[10px]">
        Please be aware that deleting your account is a permanent action, and once it is completed, all of your account
        information, including your personal data, purchase history, and preferences, will be permanently removed from
        our system. It is important to note that this action cannot be undone.
      </p>
      <div className="lg:w-[759px] hidden lg:px-[38px] lg:flex items-center justify-between lg:mt-[50px] lg:h-[107px] lg:rounded-[9px] bg-[#FBFDFC] border-[1px_solid_rgba(0,0,0,0.02)] shadow-[6px_4px_10px_0px_rgba(151,184,188,0.10)]">
        <div className="flex justify-between">
          <img src={csSvg} alt="" className="lg:w-[54px] lg:h-[54px] object-cover" />
          <div className="lg:ml-[23px]">
            <p className="lg:text-[16px] lg:leading-[24px] font-medium">
              <span className="font-semibold">Phone</span> 37008888
            </p>
            <p className="lg:text-[12px] lg:leading-[23px]">Mon-Fri: 9:00am – 1:00pm, 2:00pm – 7:00pm</p>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <img src={emailSvg} alt="" className="lg:w-[54px] lg:h-[54px] w-[54px] h-[54px] object-cover" />
          <div className="lg:ml-[23px]">
            <p className="lg:text-[16px] lg:leading-[24px] font-medium">
              <span className="font-semibold">Email</span> cs@indicaid.com
            </p>
          </div>
        </div>
      </div>
      <div className="flex px-[19px] lg:hidden h-[88px] rounded-[9px] items-center mt-[30px] w-full bg-[#FBFDFC] border-[1px_solid_rgba(0,0,0,0.02)] shadow-[6px_4px_10px_0px_rgba(151,184,188,0.10)]">
        <img src={csSvg} alt="" className="w-[54px] h-[54px] object-cover" />
        <div className="lg:ml-[23px]">
          <p className="ml-[10px] font-medium text-[16px] leading-[24px]">
            <span className="font-semibold">Phone</span> 37008888
          </p>
          <p className="text-[12px] leading-[23px]">Mon-Fri: 9:00am – 1:00pm, 2:00pm – 7:00pm</p>
        </div>
      </div>

      <div className="flex px-[19px] lg:hidden h-[88px] items-center rounded-[9px] mt-[30px] mb-[30px] w-full bg-[#FBFDFC] border-[1px_solid_rgba(0,0,0,0.02)] shadow-[6px_4px_10px_0px_rgba(151,184,188,0.10)]">
        <img src={emailSvg} alt="" className="w-[54px] h-[54px] object-cover" />
        <div className="lg:ml-[23px]">
          <p className="ml-[10px] font-medium text-[16px] leading-[24px]">
            <span className="font-semibold">Email</span> cs@indicaid.com
          </p>
        </div>
      </div>
    </div>
  );
}
