import Collapse from '@components/Collapse/Collapse';
import FooterCustomSupportContent from './FooterCustomSupportContent';
import FooterFocusUsContent from './FooterFocusUsContent';

import './footer.css';
import { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from 'src/router/routerTools';

type FooterData = {
  title: string;
  list: string[];
};

const AllFooter: FooterData[][] = [
  // [
  //   {
  //     title: 'serviceByIndicaidLab',
  //     list: [
  //       '/products/pcr-test',
  //       '/products/rapid-antigen-test',
  //       '/products/pcr-outreach',
  //       '/products/online-rapid-antigen-test',
  //       '/pages/indicaidlab',
  //     ],
  //   },
  //   {
  //     title: 'indicaidCareText',
  //     list: ['https://care.indicaid.com/'],
  //   },
  // ],
  [
    {
      title: 'Products',
      list: [
        '/products/covidantigen',
        '/products/covid-flu-rat',
        '/products/uutube',
        // '/products/indicaid-app',
      ],
    },
  ],
  [
    {
      title: 'INDICAIDCare',
      list: ['/pages/indicaid-care'],
    },
    {
      title: 'OtherProducts',
      list: ['/products/mask-cgrey'],
    },
  ],
  [
    {
      title: 'AboutINDICAID',
      list: ['/pages/aboutus', '/pages/news', '/pages/indicaidblog', '/pages/faqs', '/pages/contactus'],
    },
    {
      title: 'PaymentDeliveries',
      list: [
        '/pages/faq-shopping-flow',
        '/pages/faq-payment-and-delivery-method',
        '/pages/faq-exchange-policy',
        'https://www.indicaid.com/apps/trackorder',
      ],
    },
  ],
];

// TODO: Jenny说暂时去掉链接地址
// const AllFooter: FooterData[][] = [
//   [
//     {
//       title: 'serviceByIndicaidLab',
//       list: ['1', '2', '3', '4', '5'],
//     },
//   ],
//   [
//     {
//       title: 'Products',
//       list: ['1', '3', '2', '4'],
//     },
//     {
//       title: 'OtherProducts',
//       list: ['1'],
//     },
//   ],
//   [
//     {
//       title: 'AboutINDICAID',
//       list: ['3', '1', '4', '7', '9'],
//     },
//     {
//       title: 'PaymentDeliveries',
//       list: ['3', '4', '7', '9'],
//     },
//   ],
// ];

function FooterColumnItemTitle(props: { title: string; idx: number }) {
  const titlePaddingTop = { paddingTop: '32px' };

  return (
    <p className="footer_column_item__title" style={props.idx > 0 ? titlePaddingTop : undefined}>
      {props.title}
    </p>
  );
}

function FooterColumnItemText(props: { text: string; href: string }) {
  return (
    <li className="footer_column_item__text_wrapper">
      <a href={props.href} className="footer_column_item__text cursor-pointer">
        {props.text}
      </a>
    </li>
  );
}

function FooterColumn(props: { data: FooterData[] }) {
  const [t] = useTranslation();
  const language = useContext(LanguageContext);
  const hrefPrefix = language === 'en' ? 'https://www.indicaid.com/en' : 'https://www.indicaid.com';
  return (
    <div className="footer_column_item__1024 footer_column_item__1280 footer_column_item footer_column_item__768">
      {props.data.map((d, idx) => {
        return (
          <Fragment key={d.title}>
            <FooterColumnItemTitle title={t(`FooterPart.${d.title}.title`)} idx={idx} />
            <ul>
              {d.list.map((dl, idx2) => (
                <FooterColumnItemText key={dl} href={hrefPrefix + dl} text={t(`FooterPart.${d.title}.${idx2 + 1}`)} />
              ))}
            </ul>
          </Fragment>
        );
      })}
    </div>
  );
}

function Footer() {
  const [t] = useTranslation();
  const [isOpenIdx, setOpenIdx] = useState('');
  const language = useContext(LanguageContext);

  return (
    <footer className="footer_wrapper footer_wrapper__1024 bg-[#525252] w-[100vw]">
      <div className="flex flex-wrap m-auto global_footer global_footer_less_than_768 global_footer__768 global_footer__1024 global_footer__1530 transition-all">
        {AllFooter.map((af) => {
          return <FooterColumn key={af[0].title} data={af} />;
        })}
        <div className="footer_column_item__1024 footer_column_item__1280 footer_column_item footer_column_item__768">
          <FooterColumnItemTitle title={t('FooterPart.support')} idx={0} />
          <FooterCustomSupportContent />

          <FooterColumnItemTitle title={t('FooterPart.FollowUs')} idx={1} />

          <FooterFocusUsContent />
        </div>
      </div>

      <div className="global_footer__mobile global_footer__mobile__768">
        {AllFooter.map((af, idx1) =>
          af.map((f, idx2) => (
            <Collapse
              key={f.title}
              isOpen={isOpenIdx === `${idx1}_${idx2}`}
              onOpenChange={() => setOpenIdx(`${idx1}_${idx2}`)}
              className="bg-transparent w-full text-[#ffffff]"
              titleWrapperClassName="flex justify-between"
              titleClassName="footer_column_item__title sm:py-[10px]"
              downClassName="relative h-[fit-content]"
              downColor="#212121"
              title={t(`FooterPart.${f.title}.title`)}
            >
              <>
                <ul>
                  {f.list.map((dl, idx3) => (
                    <FooterColumnItemText key={dl} href={dl} text={t(`FooterPart.${f.title}.${idx3 + 1}`)} />
                  ))}
                </ul>
                <div className="py-[10px]"></div>
              </>
            </Collapse>
          )),
        )}

        <Collapse
          isOpen={isOpenIdx === '3'}
          onOpenChange={() => setOpenIdx('3')}
          className="bg-transparent w-full text-[#ffffff]"
          titleWrapperClassName="flex justify-between"
          titleClassName="footer_column_item__title sm:py-[10px]"
          downClassName="relative h-[fit-content]"
          downColor="#212121"
          title={t('FooterPart.support')}
        >
          <>
            <FooterCustomSupportContent />
            <div className="py-[10px]"></div>
          </>
        </Collapse>
        <Collapse
          isOpen={isOpenIdx === '4'}
          onOpenChange={() => setOpenIdx('4')}
          className="bg-transparent w-full text-[#ffffff]"
          titleWrapperClassName="flex justify-between"
          titleClassName="footer_column_item__title sm:py-[10px]"
          downClassName="relative h-[fit-content]"
          downColor="#212121"
          title={t('FooterPart.FollowUs')}
        >
          <>
            <FooterFocusUsContent />
            <div className="py-[10px]"></div>
          </>
        </Collapse>
      </div>
      <div className="lg:pb-[32px] pt-[10px] lg:py-[20px] flex flex-col lg:flex-row justify-between items-center m-auto global_footer global_footer__1024 global_footer__1530">
        <div className="flex-1 text-center w-full md:text-left mb-6 lg:mb-0 lg:w-auto lg:order-none text-[#ffffff99]">
          <span className="text-left">
            © 2023 INDICAID<sup>®</sup>&nbsp;
            {t('FooterPart.indicaidChinese')}
            <sup>{t('FooterPart.CicleR')}</sup>&nbsp;
            {t('FooterPart.RightReserved')}
          </span>

          <a href={`${language === 'en' ? '/en' : ''}/terms-of-use`} className="text-left hover:text-[#2dbdb6]">
            &nbsp;{t('FooterPart.termsOfService')}
          </a>

          <span>&nbsp;|&nbsp;</span>
          <a href={`${language === 'en' ? '/en' : ''}/privacy-policy`} className="text-left hover:text-[#2dbdb6]">
            {t('FooterPart.privacyPolicy')}
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
