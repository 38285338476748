import { get } from '@utils/request';
import { AppointmentDetail } from './types/appointmentDetails.type';

const getAppointmentDetails = (bookingId: string) => {
  return get<AppointmentDetail>('/care/booking/booking-details', {
    bookingId: bookingId,
  });
};

export default { getAppointmentDetails };
