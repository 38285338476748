import { isEmail } from '@utils/index';
import { RadioChangeEvent } from 'antd';
import { useMemo, useState } from 'react';
import { useMemoizedFn } from 'ahooks';
import { useSearchParams } from 'react-router-dom';

export function usePhoneNumberVerify() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  return {
    phoneNumber,
    setPhoneNumber,
    isPhoneNumberValid,
    handleIsPhoneNumberValidChange: setIsPhoneNumberValid,
  };
}

export function useEmailVerify() {
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const newEmail = e.target.value;
    setIsEmailValid(isEmail(newEmail));
    setEmail(newEmail);
  };

  return {
    email,
    setEmail,
    isEmailValid,
    handleEmailChange,
  };
}

const getAccountType = (t: string) => {
  if (t === 'phoneNumber') {
    return 'phoneNumber';
  }
  return 'Email';
};

export function useAccoutVerify() {
  const { phoneNumber, setPhoneNumber, isPhoneNumberValid, handleIsPhoneNumberValidChange } = usePhoneNumberVerify();

  const { email, setEmail, isEmailValid, handleEmailChange } = useEmailVerify();

  const accountTypeFromParam = (useSearchParams()[0].get('accountType') || 'phoneNumber') as 'phoneNumber' | 'Email';
  const [accountType, setAccountType] = useState<'phoneNumber' | 'Email'>(getAccountType(accountTypeFromParam));

  const handleAccountTypeChange = useMemoizedFn((e: RadioChangeEvent) => {
    setAccountType(e.target.value);
    if (e.target.value === 'phoneNumber') {
      setEmail('');
    } else {
      setPhoneNumber('');
    }
  });
  const continueDisabled = useMemo(() => {
    if (accountType === 'Email') {
      return !isEmailValid;
    }
    return !isPhoneNumberValid;
  }, [accountType, isEmailValid, isPhoneNumberValid]);
  return {
    accountType,
    handleAccountTypeChange,
    email,
    setEmail,
    phoneNumber,
    isEmailValid,
    handleEmailChange,
    isPhoneNumberValid,
    handleIsPhoneNumberValidChange,
    continueDisabled,
    setPhoneNumber,
  };
}

export function usePasswordVerify() {
  const [password, setPassword] = useState('');
  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = useMemoizedFn((e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
  });
  return {
    password,
    setPassword,
    handlePasswordChange,
  };
}
