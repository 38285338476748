/* eslint-disable @typescript-eslint/indent */

import classNames from 'classnames';
import { CSSProperties } from 'react';

export type ShipItemsType = {
  title?: string;
  content?: string;
  itemsClassName?: string;
  style?: CSSProperties;
};

export default function ShipItems(props: ShipItemsType) {
  const { title, content, itemsClassName, style } = props;

  return (
    <div
      className={classNames(itemsClassName || '', 'flex sm:mb-[19px]')}
      style={{
        ...style,
      }}
    >
      <div className="sm:w-[234px] font-[600] sm:text-[16px]  sm:leading-[20px] text-[#25282B] sm:tracking-[0.1px]">
        {title}
      </div>
      <div className="sm:w-[360px] font-[400] sm:text-[14px]  sm:leading-[22px] text-[#52575C] sm:tracking-[0.1px]">
        {content}
      </div>
    </div>
  );
}
