/* eslint-disable @typescript-eslint/indent */
import { useState, useEffect } from 'react';
// import BaseButton from '@components/BaseButton/BaseButton';
// import BaseCheckbox from '@components/BaseCheckbox/BaseCheckbox';
import BookingSteps from '@components/BookingSteps/BookingSteps';
// import { getDoctorList } from '@services/doctorList';
import { get } from '@utils/request';
import { formatDate } from '@utils/index';
import { message } from 'antd';
// import PhoneNumberInput from '@components/PhoneNumberInput/PhoneNumberInput';
// import { isEmail } from '@utils/index';
// import { S3Config, Upload$ } from '@utils/uploadS3/uploadS3';
// import { useMemoizedFn } from 'ahooks';
// import { Upload } from 'antd';
import bgAppointment from '../../images/bgAppointment.png';
import DoctorCard from './components/DoctorCard';
import { ConsultationMethod } from '@consts/enums';
import { MEDIA_SIZE } from '@consts/consts';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { handleLocalStorage } from '@utils/index';
import website from '@configs/website';
import { useTranslation } from 'react-i18next';
import { useSelectResultType } from 'src/hooks/tools/useSelectResultType';
import { getDataTrackConstantId, track } from '@utils/dataTrack';

export default function DoctorList() {
  useEffect(() => {
    const t = setTimeout(() => {
      track({
        event_type: 'view doctor',
        event_properties: {
          'booking id': getDataTrackConstantId('BookingId'),
        },
      });
    }, 30);
    return () => {
      clearTimeout(t);
    };
  }, []);

  const { productType } = useSelectResultType();
  const [t] = useTranslation();
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const [doctorInfo, setDoctorInfo] = useState<{
    date?: string;
    time?: string;
    // 代表线上和线下
    consultType?: number;
    doctorList?: {
      workingCondition?: string;
      doctorName?: string;
      // 现场问诊，需要返回地址
      location?: string;
      // 咨询费用
      price?: string;
      qualification?: string[];
      gender?: number;
      doctorUserId?: string;
    }[];
  }>({
    date: '',
    time: '',
    // 代表线上和线下
    consultType: 0,
    doctorList: [
      {
        workingCondition: '',
        doctorName: '',
        location: '',
        price: '',
        qualification: [],
        gender: 0,
      },
    ],
  });
  console.log(doctorInfo, 'doctorInfo');

  // TODO 设置localstorage，后续需要移除
  // useEffect(() => {
  //   handleLocalStorage.set(website.step_timeslot_date, '2023-03-25');
  //   handleLocalStorage.set(website.step_consult_type, '28');
  //   handleLocalStorage.set(website.step_timeslot_id, '1');
  // }, []);

  useEffect(() => {
    const getDoctorListFunction = async () => {
      // const data = await getPersonalInfos();
      // setUserInfo({
      //   ...data,
      // });
      try {
        const data = await get('/care/timeslot/ava-doctor', {
          timeslotDate: handleLocalStorage.get(website.step_timeslot_date),
          timeslotId: handleLocalStorage.get(website.step_timeslot_id),
          consultType: handleLocalStorage.get(website.step_consult_type),
          fromProductType: productType,
        });
        // const data = await getPersonalInfos();
        // TODO 等待与后端的联调
        // TODO 等后端好了，我们再进行页面的书写
        // const data = await getDoctorList();
        setDoctorInfo({
          ...data,
        });
      } catch (err: any) {
        const responseMessage = err?.response?.data?.message;
        const errorMessage = (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || 'error!';
        message.error(<span id="patientDoctorList____ava-doctor-error-message">{errorMessage}</span>);
      }
    };
    getDoctorListFunction();
  }, [productType]);
  return (
    <div className="relative flex w-full justify-center text-[#212121]">
      <div className="absolute top-0 left-0 w-full">
        <img className="w-full" src={bgAppointment} />
      </div>
      <div className="relative lg:w-[1280px] w-full px-[24px] lg:px-0  lg:pt-[68px] lg:pb-[46px] shadow-lg lg:rounded-[30px] lg:mt-[13.3vw] lg:mb-[18px] pb-[47px] bg-[#ffffff] lg:shadow-none">
        <BookingSteps step={3} />
        {/* 头部Date数据 */}
        <div className="flex flex-col mt-[27px] lg:mt-[93px] lg:ml-[186px]">
          {isLg && (
            <div className="font-[600] lg:text-[28px] lg:leading-[36px] text-[#212121] lg:mb-[20px]">
              {t('DoctorListPage.ChooseYourDoctor')}
            </div>
          )}
          {isLg && (
            <div className="lg:mb-[41px] font-[400] lg:text-[18px] lg:leading-[28px] text-[#212121] lg:tracking-[0.25px]">
              {t('DoctorListPage.BelowDoctorText')}
            </div>
          )}
          <div className="flex mb-[14px] lg:mb-[14px]">
            <div className="flex w-[128px] flex-col lg:w-[177px]">
              <div className="w-[128px] lg:w-[177px]"></div>
              <div className="w-[100px] font-[600] text-[16px] leading-[24px] text-[#212121] lg:w-[133px] lg:text-[18px] lg:leading-[28px] lg:text-[#2DBDB6] lg: tracking-[0.25px]">
                {t('Date')}:
              </div>
            </div>
            <div className="font-[400] text-[16px] leading-[24px] text-[#212121] lg:text-[16px] lg:leading-[24px] lg:tracking-[0.25px]">
              {doctorInfo?.date && formatDate(doctorInfo.date)}
            </div>
          </div>
          <div className="flex mb-[14px] lg:mb-[14px]">
            <div className="flex w-[128px] flex-col lg:w-[177px]">
              <div className="w-[128px] lg:w-[177px]"></div>
              <div className="w-[100px] font-[600] text-[16px] leading-[24px] text-[#212121] lg:w-[133px] lg:text-[18px] lg:leading-[28px] lg:text-[#2DBDB6] lg: tracking-[0.25px]">
                {t('Time')}:
              </div>
            </div>
            <div className="font-[400] text-[16px] leading-[24px] text-[#212121] lg:text-[16px] lg:leading-[24px] lg:tracking-[0.25px]">
              {doctorInfo?.time}
            </div>
          </div>
          <div className="flex mb-[40px] lg:mb-[79px]">
            <div className="flex w-[128px] flex-col lg:w-[177px]">
              <div className="w-[128px] lg:w-[177px]"></div>
              <div className="w-[100px] font-[600] text-[16px] leading-[24px] text-[#212121] lg:w-[133px] lg:text-[18px] lg:leading-[28px] lg:text-[#2DBDB6] lg: tracking-[0.25px]">
                {t('Method')}:
              </div>
            </div>
            <div className="font-[400] text-[16px] leading-[24px] text-[#212121] lg:text-[16px] lg:leading-[24px] lg:tracking-[0.25px]">
              {doctorInfo?.consultType === ConsultationMethod.Online ? t('OnlineConsultation') : t('In-ClinicVisit')}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center">
          {doctorInfo?.doctorList?.map((doctorList, index) => (
            <DoctorCard
              key={doctorList.doctorUserId}
              productType={productType}
              doctorList={doctorList}
              consultType={doctorInfo?.consultType}
              isLast={doctorInfo?.doctorList && doctorInfo?.doctorList?.length - 1 === index}
              curPosition={index + 1}
            ></DoctorCard>
            // <div>{obj?.price}</div>
          ))}
        </div>
        <div className="flex flex-col lg:ml-[77px] lg:mr-[85px]">
          <hr className="bg-[#D1D1D1] lg:mb-[10px] mb-[18px]" />
          <div
            className="lg:ml-[2px] font-[500] text-[14px] leading-[28px] text-[#2DBDB6] lg:text-[14px] lg:leading-[28px] cursor-pointer text-center underline lg:text-left"
            onClick={() => {
              window.open('https://www.mchk.org.hk/english/list_register/doctor_directories.html', '_blank');
            }}
            id="patientDoctorList__viewOther-button"
          >
            {t('DoctorListPage.ViewOtherText')}
          </div>
        </div>
      </div>
    </div>
  );
}
