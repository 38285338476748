export default function PrivacyText(props: { children: React.ReactNode }) {
  return (
    <p
      className="
            lg:text-[16px] lg:leading-[28px] lg:mb-[50px]
            text-[14px] leading-[22px] mb-[40px]
  "
    >
      {props.children}
    </p>
  );
}
