import { Pagination, Table } from 'antd';

import { APPOINTMENTS_METHOD, APPOINTMENTS_STATUS } from '@consts/consts';
import { BookingStatus } from '@consts/enums';
import { formatBeDate } from '@utils/index';
import pen from '../../../../images/doctorImages/pen.svg';
import './AppointmentsTable.css';
import { handleLocalStorage } from '@utils/index';
import website from '@configs/website';
import { useLocalNavigate } from 'src/router/routerTools';
import { useEffect, useState } from 'react';

// , ConsultationMethod

// import { useEffect, useState } from 'react';

// export type AppointmentsTableOnCheckValidType = (
//   v: string,
//   option: {
//     setStatus: React.Dispatch<React.SetStateAction<TextInputStatus>>;
//     setHelpText: React.Dispatch<React.SetStateAction<string>>;
//   },
// ) => void;
type TablePaginationPosition = 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight';

export type AppointmentsTableType = {
  title?: string;
  placeholder?: string;
  value?: string;
  onChange?: (v: string) => void;
  /**
   * 检查数据合法性
   * @param v 值
   * @param option
   * @returns
   */
  // onCheckValid?: AppointmentsTableOnCheckValidType;
  // array
  dataSource?: any;
  pagination?: {
    pageSize: number;
    total: number;
    showSizeChanger: boolean;
    current: number;
    hideOnSinglePage: boolean;
    onChange: (page: number) => void;
    // position: string[];
    position: TablePaginationPosition[];
    // position: string
  };
  loading?: boolean;
  actionRender?: any;
  bookingList?: {
    size?: number;
    total?: number;
    list?: {
      id?: number;
      displayId?: string;
      userId?: number;
      doctorId?: number;
      timeslotDate?: string;
      timeslotId?: number;
      timeslotStartTime?: string;
      timeslotEndTime?: string;
      amount?: string;
      currency?: number;
      consultType?: number;
      status?: number;
      createdAt?: string;
      phone?: string;
      email?: string;
      firstName?: string;
      lastName?: string;
      dob?: string;
      hkid?: string;
    }[];
  };
};

const APPOINTMENTS_STATUS_COLOR = {
  [BookingStatus.Consultation]: {
    backgroundColor: '#8F989C',
    textColor: '#F2F2F2',
  },
  [BookingStatus.Prescription]: {
    backgroundColor: '#464646',
    textColor: '#F2F2F2',
  },
  [BookingStatus.Completed]: {
    backgroundColor: '#009FAF',
    textColor: '#F2F2F2',
  },
  // 如果不属于其中之一，就不会报错
  100: {
    backgroundColor: '#009FAF',
    textColor: '#F2F2F2',
  },
};

// export const APPOINTMENTS_STATUS = {
//   [BookingStatus.Consultation]: 'On-going',
//   [BookingStatus.Prescription]: 'Up-coming',
//   [BookingStatus.Completed]: 'Completed',
// };

export default function AppointmentsTable(props: AppointmentsTableType) {
  const { pagination, loading, bookingList } = props;
  const navigate = useLocalNavigate();

  const cols = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      width: 100,
      ellipsis: true,
      render: (val: any, row: any) => {
        if (!row?.firstName) {
          return null;
        }
        return (
          <div className="font-[400] sm:text-[14px] sm:leading-[22px] text-[#52575C] sm:tracking-[0.1px]">{`${row?.firstName} ${row?.lastName}`}</div>
        );
      },
    },
    {
      title: 'Case ID',
      dataIndex: 'displayId',
      width: 150,
      ellipsis: true,
      render: (val: any) => {
        if (!val) {
          return null;
        }
        return (
          <div className="font-[400] sm:text-[14px] sm:leading-[22px] text-[#52575C] sm:tracking-[0.1px]">{val}</div>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'timeslotDate',
      width: 100,
      ellipsis: true,
      render: (val: any) => {
        if (!val) {
          return null;
        }
        return (
          <div className="font-[400] sm:text-[14px] sm:leading-[22px] text-[#52575C] sm:tracking-[0.1px]">
            {formatBeDate(val, true)}
          </div>
        );
      },
    },
    {
      title: 'Time',
      dataIndex: 'timeslotStartTime',
      width: 80,
      ellipsis: true,
      render: (val: any) => {
        if (!val) {
          return null;
        }
        return (
          <div className="font-[400] sm:text-[14px] sm:leading-[22px] text-[#52575C] sm:tracking-[0.1px]">{val}</div>
        );
      },
    },
    {
      title: 'HKID / Passport',
      dataIndex: 'hkid',
      width: 100,
      ellipsis: true,
      render: (val: any) => {
        if (!val) {
          return null;
        }
        return (
          <div className="font-[400] sm:text-[14px] sm:leading-[22px] text-[#52575C] sm:tracking-[0.1px]">{val}</div>
        );
      },
    },
    {
      title: 'Method',
      dataIndex: 'consultType',
      width: 80,
      ellipsis: true,
      render: (val: number) => {
        // TODO 这里需要调整
        return (
          <div className="font-[400] sm:text-[14px] sm:leading-[22px] text-[#52575C] sm:tracking-[0.1px]">
            {APPOINTMENTS_METHOD[val as keyof typeof APPOINTMENTS_METHOD]}
          </div>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 80,
      ellipsis: true,
      render: (val: number) => {
        if (!val) {
          return null;
        }
        // TODO 这里也需要调整
        return (
          <div
            className="font-[400] sm:text-[14px] sm:leading-[24px] text-[#52575C] sm:tracking-[0.1px] sm:w-[105px] sm:h-[25px] justify-center items-center text-center sm:rounded-[3px]"
            style={{
              color:
                APPOINTMENTS_STATUS_COLOR[val as keyof typeof APPOINTMENTS_STATUS]?.textColor ||
                APPOINTMENTS_STATUS_COLOR[100]?.textColor,
              backgroundColor:
                APPOINTMENTS_STATUS_COLOR[val as keyof typeof APPOINTMENTS_STATUS]?.backgroundColor ||
                APPOINTMENTS_STATUS_COLOR[100]?.backgroundColor,
            }}
          >
            {APPOINTMENTS_STATUS[val as keyof typeof APPOINTMENTS_STATUS] ===
            APPOINTMENTS_STATUS[BookingStatus.Prescription]
              ? 'In Progress'
              : APPOINTMENTS_STATUS[val as keyof typeof APPOINTMENTS_STATUS] || BookingStatus[val]}
          </div>
        );
      },
    },
    {
      title: '',
      key: 'action',
      width: 50,
      ellipsis: true,
      render: (val: any, row: any) => {
        if (!val) {
          return null;
        }
        return (
          <div className="flex justify-center items-center">
            <img
              src={pen}
              alt="pen"
              className="object-cover sm:w-[16px] cursor-pointer"
              data-clickable
              data-label="[indicaid][/doctor/appointments][TableRow][Edit]"
              onClick={() => {
                handleLocalStorage.set(website.doctor_details, row);
                navigate(`/doctor/appointmentsDetails?appointmentsId=${row?.id}`);
              }}
            />
          </div>
        );
      },
    },
  ];
  const [tableBodyH, setTableBodyH] = useState(0);
  useEffect(() => {
    setTableBodyH(window.innerHeight - 350);
  }, []);

  return (
    <>
      <Table
        className="appointment-tables"
        scroll={{ y: tableBodyH, x: '100%' }}
        dataSource={bookingList?.list}
        // pagination={pagination}
        pagination={false}
        rowKey="id"
        loading={loading}
        columns={cols}
      />
      {pagination && pagination.total > pagination.pageSize && (
        <div className="sm:h-[60px] flex justify-center items-center bg-[#ffffff]">
          <Pagination
            pageSize={pagination?.pageSize}
            current={pagination?.current}
            total={pagination?.total}
            onChange={pagination?.onChange}
          />
        </div>
      )}
    </>
  );
}
