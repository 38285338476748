import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageContext, supportLocale } from 'src/router/routerTools';
import { IconLang } from './icons/Icons';

export function switchLanguage(origin: string, target: string, targetUrl?: string) {
  if (target == origin) {
    return;
  }
  let newPath = (targetUrl || location.href).replace(location.origin, '');
  if (newPath.startsWith('/' + origin)) {
    newPath = newPath.replace(origin, target);
  } else {
    newPath = '/' + target + newPath;
  }
  location.href = newPath;
}

export default () => {
  const language = useContext(LanguageContext);
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <span className="ly-custom-0">
      <div
        data-breakpoint={0}
        className="ly-switcher-wrapper ly-breakpoint-1 custom ly-custom"
        style={{ fontSize: '13px' }}
        tabIndex={-2}
        onBlur={setOpen.bind(null, false)}
        onClick={setOpen.bind(null, !open)}
      >
        <div
          aria-label="Selected language: English"
          aria-description="Language selector"
          data-dropup="false"
          className={`ly-languages-switcher ly-custom-dropdown-switcher ly-bright-theme ${open ? 'ly-is-open' : ''}`}
          style={{ margin: '0px 0px' }}
        >
          <span
            role="button"
            aria-expanded="false"
            aria-controls="languagesSwitcherList-1"
            className="ly-custom-dropdown-current"
            style={{
              background: '#fff',
              color: '#000',
              borderStyle: 'solid',
              borderWidth: '0px',
              borderRadius: '0px',
              borderColor: '#000',
              padding: '0px 0px',
            }}
          >
            <span className="ly-custom-dropdown-current-inner ly-custom-dropdown-current-inner-text">
              {t('header.locale.' + language)}
            </span>
            <div
              className="ly-arrow ly-arrow-black stroke"
              style={{ marginLeft: 6.3, verticalAlign: 'middle', width: '8.32px', height: '8.32px' }}
            >
              {IconLang}
            </div>
          </span>
          <ul
            id="languagesSwitcherList-1"
            role="list"
            className="ly-custom-dropdown-list ly-is-open ly-bright-theme"
            style={{
              background: '#fff',
              color: '#000',
              borderStyle: 'solid',
              borderWidth: '0px',
              borderRadius: '0px',
              borderColor: '#000',
            }}
          >
            {supportLocale
              .filter((v) => v != language)
              .map((v) => (
                <li
                  key={v}
                  style={{ color: '#000', margin: '0px 0px', padding: '0px 0px' }}
                  onClick={() => {
                    switchLanguage(language, v);
                  }}
                >
                  <a
                    className="ly-custom-dropdown-list-element ly-languages-switcher-link ly-bright-theme"
                    data-language-code={v}
                  >
                    <span className="ly-custom-dropdown-list-element-right"> {t('header.locale.' + v)}</span>
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </span>
  );
};
