import { ArrowUpOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';
import TermsListItem from './components/TermsListItem';
import TermsListTitle from './components/TermsListTitle';
import TermsTitle from './components/TermsTitle';
import { useTranslation } from 'react-i18next';
export default function TermsAndCondition() {
  const [t] = useTranslation();
  return (
    <div className="lg:w-[1417px] m-auto lg:tracking-[.09px] md:w-[900px] w-[350px] tracking-[.20px] whitespace-pre-line">
      <TermsTitle>{t('termsText.title')}</TermsTitle>
      <p
        className="
        lg:text-[16px] lg:leading-[28px] lg:mb-[50px]
        text-[14px] leading-[22px] mb-[40px]"
      >
        {t('termsText.0')}
      </p>
      <TermsListTitle>{t('termsText.1')}</TermsListTitle>
      <TermsListItem orderPrefix="1.1">{t('termsText.1_1')}</TermsListItem>
      <TermsListItem orderPrefix="1.2">{t('termsText.1_2')}</TermsListItem>
      <TermsListItem orderPrefix="1.3">{t('termsText.1_3')}</TermsListItem>
      <TermsListItem orderPrefix="1.4">{t('termsText.1_4')}</TermsListItem>
      <TermsListItem orderPrefix="1.5">{t('termsText.1_5')}</TermsListItem>
      <TermsListItem orderPrefix="1.6">{t('termsText.1_6')}</TermsListItem>
      <TermsListItem orderPrefix="1.7">{t('termsText.1_7')}</TermsListItem>
      <TermsListTitle>{t('termsText.2')}</TermsListTitle>
      <TermsListItem orderPrefix="2.1">{t('termsText.2_1')}</TermsListItem>
      <TermsListItem orderPrefix="2.2">{t('termsText.2_2')}</TermsListItem>
      <TermsListItem orderPrefix="2.3">{t('termsText.2_3')}</TermsListItem>
      <TermsListItem orderPrefix="2.4">{t('termsText.2_4')}</TermsListItem>
      <TermsListItem orderPrefix="2.5">{t('termsText.2_5')}</TermsListItem>
      <TermsListItem orderPrefix="2.6">{t('termsText.2_6')}</TermsListItem>
      <TermsListItem orderPrefix="2.7">{t('termsText.2_7')}</TermsListItem>
      <TermsListItem orderPrefix="2.8">{t('termsText.2_8')}</TermsListItem>
      <TermsListItem orderPrefix="2.9">{t('termsText.2_9')}</TermsListItem>
      <TermsListItem orderPrefix="2.10">{t('termsText.2_10')}</TermsListItem>
      <TermsListItem orderPrefix="2.11">{t('termsText.2_11')}</TermsListItem>
      <TermsListTitle>{t('termsText.3')}</TermsListTitle>
      <TermsListItem orderPrefix="3.1">{t('termsText.3_1')}</TermsListItem>
      <TermsListItem orderPrefix="3.2">{t('termsText.3_2')}</TermsListItem>
      <TermsListItem orderPrefix="3.3">{t('termsText.3_3')}</TermsListItem>
      <TermsListItem orderPrefix="3.4">{t('termsText.3_4')}</TermsListItem>
      <TermsListItem orderPrefix="3.5">{t('termsText.3_5')}</TermsListItem>
      <TermsListItem orderPrefix="3.6">{t('termsText.3_6')}</TermsListItem>
      <TermsListItem orderPrefix="3.7">{t('termsText.3_7')}</TermsListItem>
      <TermsListItem orderPrefix="3.8">{t('termsText.3_8')}</TermsListItem>
      <TermsListTitle>{t('termsText.4')}</TermsListTitle>
      <TermsListItem orderPrefix="4.1">{t('termsText.4_1')}</TermsListItem>
      <TermsListItem orderPrefix="4.2">{t('termsText.4_2')}</TermsListItem>
      <TermsListTitle>{t('termsText.5')}</TermsListTitle>
      <TermsListItem orderPrefix="5.1">{t('termsText.5_1')}</TermsListItem>
      <TermsListItem orderPrefix="5.2">{t('termsText.5_2')}</TermsListItem>
      <TermsListItem orderPrefix="5.3">{t('termsText.5_3')}</TermsListItem>
      <TermsListItem orderPrefix="5.4">{t('termsText.5_4')}</TermsListItem>
      <TermsListItem orderPrefix="5.5">{t('termsText.5_5')}</TermsListItem>
      <TermsListItem orderPrefix="5.6">{t('termsText.5_6')}</TermsListItem>
      <TermsListItem orderPrefix="5.7">{t('termsText.5_7')}</TermsListItem>
      <TermsListTitle>{t('termsText.6')}</TermsListTitle>
      <TermsListItem orderPrefix="6.1">{t('termsText.6_1')}</TermsListItem>
      <TermsListItem orderPrefix="6.2">{t('termsText.6_2')}</TermsListItem>
      <TermsListTitle>{t('termsText.7')}</TermsListTitle>
      <TermsListItem orderPrefix="7.1">{t('termsText.7_1')}</TermsListItem>
      <TermsListItem orderPrefix="7.2">{t('termsText.7_2')}</TermsListItem>
      <TermsListItem orderPrefix="7.3">{t('termsText.7_3')}</TermsListItem>
      <TermsListTitle>{t('termsText.8')}</TermsListTitle>
      <TermsListItem orderPrefix="8.1">{t('termsText.8_1')}</TermsListItem>
      <TermsListItem orderPrefix="8.2">{t('termsText.8_2')}</TermsListItem>
      <TermsListItem orderPrefix="8.3">{t('termsText.8_3')}</TermsListItem>
      <TermsListItem orderPrefix="8.4">{t('termsText.8_4')}</TermsListItem>
      <TermsListTitle>{t('termsText.9')}</TermsListTitle>
      <TermsListItem orderPrefix="9.1">{t('termsText.9_1')}</TermsListItem>
      <TermsListTitle>{t('termsText.10')}</TermsListTitle>
      <TermsListItem orderPrefix="10.1">{t('termsText.10_1')}</TermsListItem>
      <TermsListItem orderPrefix="10.2">{t('termsText.10_2')}</TermsListItem>
      <TermsListItem orderPrefix="10.3">{t('termsText.10_3')}</TermsListItem>
      <TermsListItem orderPrefix="10.4">{t('termsText.10_4')}</TermsListItem>
      <TermsListItem orderPrefix="10.5">{t('termsText.10_5')}</TermsListItem>
      <TermsListItem orderPrefix="10.6">{t('termsText.10_6')}</TermsListItem>
      <TermsListItem orderPrefix="10.7">{t('termsText.10_7')}</TermsListItem>
      <TermsListTitle>{t('termsText.11')}</TermsListTitle>
      <TermsListItem orderPrefix="11.1">{t('termsText.11_1')}</TermsListItem>
      <TermsListItem orderPrefix="11.2">{t('termsText.11_2')}</TermsListItem>
      <FloatButton.BackTop type="primary" icon={<ArrowUpOutlined />} />
    </div>
  );
}
