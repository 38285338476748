import { ExclamationCircleOutlined, LoadingOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import BaseButton from '@components/BaseButton/BaseButton';
import { Delete } from '@icon-park/react';
import { deleteDrug, getDrugsList, updateDrug } from '@services/medicationService';
import { DrugInfo, UpdateDrug } from '@services/types/medicationService.type';
import { createDebounceWrapper } from '@utils/index';

import { useMemoizedFn } from 'ahooks';
import { AutoComplete, Input, notification, Pagination, Select, Table } from 'antd';
import { useEffect, useReducer, useState } from 'react';

import Highlighter from 'react-highlight-words';
import { alertConfimInfos } from '../components/LoadingConfirmBox';
import { useLocalNavigate } from 'src/router/routerTools';
import EditableInput from '../components/EditInput';
// import { track } from '@utils/dataTrack';
const Column = Table.Column<DrugInfo>;

const debounceSearchWrapper = createDebounceWrapper(500);
const pageSize = 10;
export default function MedicationManager() {
  const [notificationApi, contextHolder] = notification.useNotification();

  const navigate = useLocalNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [searchName, setSearchName] = useState('');
  const [sortFiled, setSortFiled] = useState('id');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const [drugList, setDrugList] = useState<DrugInfo[]>([]);
  const [refreshDataCount, refreshData] = useReducer((v) => v + 1, 0);

  const updateDrugInfo = useMemoizedFn(async (updateDrugData: UpdateDrug) => {
    try {
      await updateDrug(updateDrugData);
      notificationApi.info({
        message: 'Edit has been saved successfully',
        placement: 'bottomRight',
        duration: 3,
      });
    } catch (e: any) {
      // track({
      //   event_type: 'encounter_error',
      //   event_properties: {
      //     'event name': e.reason || 'unknown',
      //     'error type': 'edit_drug_error',
      //   },
      // });
      notificationApi.error({
        message: 'Edit has been saved Unsuccessful',
        placement: 'bottomRight',
        description: e.reason ?? undefined,
      });
    }
  });

  useEffect(() => {
    debounceSearchWrapper(() => {
      setIsLoading(true);
      getDrugsList({ searchName: searchName, sortBy: 'asc', sortField: sortFiled, page: 1, size: pageSize })
        .then((data) => {
          setDrugList(data.list);
          setTotal(data.total);
        })
        .finally(() => {
          setIsLoading(false);
          setPage(1);
        });
    });
  }, [searchName, sortFiled, refreshDataCount]);

  const queryDrugList: React.ChangeEventHandler<HTMLInputElement> = useMemoizedFn((e) => {
    setSearchName(e.target.value);
  });
  const deleteDrugConfirm = useMemoizedFn((id) => {
    alertConfimInfos(
      {
        content: (
          <div className="text-[#25282b] sm:text-[16px] font-medium sm:leading-[22px] flex items-start">
            <ExclamationCircleOutlined className="text-[#FF7A45] sm:text-[22px] sm:mr-[10px]" />
            <div>
              <p>Are you sure you would like to delete this record. This data will be removed permanently</p>
            </div>
          </div>
        ),
        onConfirm: async () => {
          try {
            await deleteDrug({ id });
            notificationApi.info({
              message: 'Delete success. Information has been removed successfully',
              placement: 'bottomRight',
              duration: 10000,
            });
            refreshData();
          } catch (e: any) {
            // track({
            //   event_type: 'encounter_error',
            //   event_properties: {
            //     'event name': e.reason || 'unknown',
            //     'error type': 'remove_drug_error',
            //   },
            // });
            notificationApi.error({
              message: 'Drug remove Unsuccessful',
              placement: 'bottomRight',
              description: e.reason ?? undefined,
            });
          }
        },
      },
      'indicaid-doctor-medicationManager__confirm-button',
      'indicaid-doctor-medicationManager__cancelId-button',
    );
  });
  return (
    <div className="sm:px-[65px] sm:py-[60px] bg-[#f4f7fa] h-[100vh] w-full overflow-scroll">
      {contextHolder}
      <p className="font-bold sm:text-[40px] sm:leading-[70px]">Medication Manager</p>
      <div className="flex items-center justify-between w-full sm:mt-[42px]">
        <div className="flex items-center sm:w-[643px] shrink-0">
          <AutoComplete className="sm:w-[332px] rounded-[10px]">
            <Input
              id="indicaid-doctor-medicationManager__searchName-input"
              value={searchName}
              onChange={queryDrugList}
              className="sm:w-[332px] sm:h-[42px] sm:text-[14px] border-none sm:leading-[22px] bg-white border-[1px]"
              placeholder="Search by medication name"
              prefix={<SearchOutlined className="sm:mr-[5px]" />}
            />
          </AutoComplete>
          <div className="flex items-center sm:ml-[24px]">
            <span className="sm:text-[14px] text-[#bbbbbb] sm:mr-[12px]">Sort by:</span>
            <Select
              id="indicaid-doctor-medicationManager__Sortby-input"
              value={sortFiled}
              className="sm:w-[222px] sm:h-[42px] flex items-center"
              size="large"
              onChange={(v) => {
                setSortFiled(v);
              }}
              options={[
                { label: 'ID', value: 'id' },
                { label: 'Medication Name (a-z)', value: 'name' },
              ]}
            ></Select>
          </div>
        </div>

        <div className="flex justify-center ">
          {/* <BaseButton
            status="doctorClear"
            onClick={() => setIsEditing(!isEditing)}
            buttonClassName="sm:w-[129px] sm:h-[32px] sm:mr-[12px]"
            value={isEditing ? 'Save' : 'Edit'}
          /> */}
          <BaseButton
            id="indicaid-doctor-medicationManager__add-button"
            status="doctorDefault"
            onClick={() => navigate('/doctor/addNewDrug')}
            buttonClassName="sm:w-[129px] sm:h-[32px]"
            value={
              <span>
                <PlusOutlined /> Add
              </span>
            }
          />
        </div>
      </div>
      <div className="sm:mt-[30px]">
        <Table
          loading={{
            spinning: isLoading,
            indicator: <LoadingOutlined />,
          }}
          rowKey="id"
          dataSource={drugList}
          pagination={false}
          className="sm:text-[14px] font-bold font-[Lato]"
          rowClassName="text-[#52575c] sm:text-[14px] font-normal"
        >
          <Column title="Drug ID" dataIndex="id" key="id" />
          <Column
            className="sm:w-[250px] sm:max-w-[400px] text-ellipsis"
            title="Name"
            dataIndex="name"
            key="name"
            render={(_, record) => (
              <Highlighter
                className="block overflow-hidden whitespace-nowrap text-ellipsis"
                searchWords={[searchName]}
                autoEscape={false}
                textToHighlight={record.name}
              ></Highlighter>
            )}
          />
          <Column
            title="Dosage"
            dataIndex="dosage"
            key="dosage"
            render={(_, record) => (record.dosageUnit === 0 ? `${record.dosage} mg` : `${record.dosage} ml`)}
          />
          <Column
            title="Time/Day"
            dataIndex="times"
            key="times"
            render={(timePerDay, record) => (
              <EditableInput
                id="indicaid-doctor-medicationManager__TimeDay-button"
                integer
                defaultValue={timePerDay}
                onBlur={(v) => {
                  record.times = +v;
                  updateDrugInfo({
                    id: record.id,
                    units: record.units,
                    duration: record.duration,
                    times: +v,
                  });
                }}
              />
            )}
          />
          <Column
            title="Units"
            dataIndex="units"
            render={(Units, record) => (
              <EditableInput
                id="indicaid-doctor-medicationManager__Units-button"
                halfInteger
                defaultValue={Units}
                onBlur={(v) => {
                  record.units = v;
                  updateDrugInfo({
                    id: record.id,
                    units: v,
                    duration: record.duration,
                    times: record.times,
                  });
                }}
              />
            )}
            key="units"
          />
          <Column
            title="Duration (Days)"
            dataIndex="duration"
            render={(Duration, record) => (
              <EditableInput
                id="indicaid-doctor-medicationManager__Duration-button"
                halfInteger
                defaultValue={Duration}
                onBlur={(v) => {
                  record.duration = v;
                  updateDrugInfo({
                    id: record.id,
                    units: record.units,
                    times: record.times,
                    duration: v,
                  });
                }}
              />
            )}
            key="duration"
          />

          <Column
            className="sm:w-[50px]"
            title=""
            key="delete"
            render={(_, record) => {
              return (
                <div
                  onClick={() => deleteDrugConfirm(record.id)}
                  className="text-[red] text-center cursor-pointer"
                  id="indicaid-doctor-medicationManager__deleteRubbish-button"
                >
                  <Delete theme="two-tone" size="23" fill={['#FF6760', '#fff']} />
                </div>
              );
            }}
          />
        </Table>
        {total > pageSize && (
          <div className="sm:h-[60px] flex justify-center items-center bg-[#ffffff]">
            <Pagination
              pageSize={pageSize}
              current={page}
              total={total}
              onChange={(newPage) => {
                setIsLoading(true);
                setPage(newPage);
                getDrugsList({
                  searchName: searchName,
                  sortBy: 'asc',
                  sortField: sortFiled,
                  page: newPage,
                  size: pageSize,
                })
                  .then((data) => {
                    setDrugList(data.list);
                    setTotal(data.total);
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
