import appointments from '@images/doctorImages/appointments.svg';
import medications from '@images/doctorImages/medications.svg';
// import myProfile from '@images/doctorImages/myProfile.svg';
import appointmentsGreen from '@images/doctorImages/appointmentsGreen.svg';
import medicationsGreen from '@images/doctorImages/medicationsGreen.svg';
// import myProfileGreen from '@images/doctorImages/myProfileGreen.svg';
import logout from '@images/doctorImages/logout.svg';
import dayjs from 'dayjs';
import { getCurrentWeekDay } from '@utils/index';
import { getUTC } from '@utils/formatData/formatUTC';
import React, { useState, useEffect } from 'react';
import { handleLocalStorage } from '@utils/index';
import website from '@configs/website';
import { DoctorSidebarType } from '@consts/enums';
import alertInfo from '@utils/globalComponents/Alert';
import BaseButton from '@components/BaseButton/BaseButton';
import { useMemoizedFn } from 'ahooks';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import { useLocalNavigate } from 'src/router/routerTools';
import { get } from '@utils/request';
import { message } from 'antd';
import { patientTokenStore } from '@utils/localStore/tokenStore';

const LogoutContent = (props: { onClickConfirm: () => void; close: () => void }) => {
  return (
    <div className="sm:px-[32px]">
      <div className="flex items-start">
        <ExclamationCircleOutlined
          className="active:text-[#3c4148] active:bg-[#eaf3f3]"
          style={{
            color: '#FF7A45',
            fontSize: '22px',
          }}
        />
        <div className="font-medium sm:text-[16px] sm:leading-[22px] text-[500] text-[#25282B] text-left sm:ml-[16px] sm:max-w-[326px]">
          Are you sure you would like to log out of the doctor’s portal?
        </div>
      </div>
      {/* <div>
        <p className="sm:text-[18px] sm:leading-[26px] font-semibold">Select Medicine</p>
        <Select
          className="sm:w-[418px] sm:h-[42px] sm:text-[15px] sm:leading-[18px] flex items-center"
          showSearch
          onDropdownVisibleChange={(v) => {
            if (v) {
              searchDrugs('');
            }
          }}
          onSearch={searchDrugs}
          placeholder="Select from existing inventory"
          filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
          popupClassName="z-[10001]"
          fieldNames={{
            label: 'name',
            value: 'id',
          }}
          options={drugList}
          onSelect={(_, sd) => setSelectDrugInfo(sd)}
          notFoundContent={
            loading ? (
              <div className="w-full sm:h-[81px] flex items-center justify-center">
                <LoadingOutlined spin />
              </div>
            ) : undefined
          }
        />
      </div> */}
      <div className="flex justify-end sm:mt-[24px]">
        <BaseButton
          status="doctorDefault"
          data-clickable
          id="[indicaid][/doctor][Sidebar][Logout Confirm]"
          onClick={() => {
            props.close();
            props.onClickConfirm();
          }}
          buttonClassName="sm:w-[73px] sm:h-[32px] sm:mr-[6px] p-0"
          style={{
            backgroundColor: '#009FAF',
          }}
          value="Confirm"
        />
        <BaseButton
          status="doctorClear"
          data-clickable
          id="[indicaid][/doctor][Sidebar][Logout Cancel]"
          onClick={() => {
            props.close();
          }}
          buttonClassName="sm:w-[67px] sm:h-[32px] p-0"
          value="Cancel"
        />
      </div>
    </div>
  );
};

function SideBar() {
  const navigate = useLocalNavigate();
  const [doctorMessage, setDoctorMessage] = useState<any>({});
  const [doctorInfoObj, setDoctorInfoObj] = useState<any>({});
  const [selectType, setSelectType] = useState<keyof typeof DoctorSidebarType | null>(
    DoctorSidebarType[DoctorSidebarType.Appointments] as keyof typeof DoctorSidebarType,
  );

  useEffect(() => {
    setDoctorMessage(handleLocalStorage.get(website.doctor_login_message));
  }, []);

  useEffect(() => {
    const isMedicationManagerPage =
      window.location.href.includes(`${location.origin}/doctor/medicationManager`) ||
      window.location.href.includes(`${location.origin}/doctor/addNewDrug`);

    if (isMedicationManagerPage) {
      const val = DoctorSidebarType[DoctorSidebarType.Medications];
      setSelectType(val as keyof typeof DoctorSidebarType);
    } else {
      const val = DoctorSidebarType[DoctorSidebarType.Appointments];
      // TODO 默认在select页面
      setSelectType(val as keyof typeof DoctorSidebarType);
    }
  }, []);

  useEffect(() => {
    const abortControllerRef = { current: new AbortController() };
    const getDoctorInfoObjFunction = async () => {
      try {
        const data = await get('/user/info', undefined, {
          abortControllerRef,
        });
        setDoctorInfoObj({
          ...data,
        });
      } catch (err: any) {
        if (err?.code !== 'ERR_CANCELED') {
          const responseMessage = err?.response?.data?.message;
          const errorMessage = (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || 'error!';
          message.error(errorMessage);
        }
      }
    };
    getDoctorInfoObjFunction();
    return () => {
      abortControllerRef.current.abort();
    };
  }, []);

  const onClickAppointments = () => {
    const val = DoctorSidebarType[DoctorSidebarType.Appointments];
    setSelectType(val as keyof typeof DoctorSidebarType);
    navigate('/doctor/appointments');
  };

  const onClickMedications = () => {
    const val = DoctorSidebarType[DoctorSidebarType.Medications];
    setSelectType(val as keyof typeof DoctorSidebarType);
    // TODO: 这里应该是有问题的=>需要再看一下
    navigate('/doctor/medicationManager');

    // TODO 跳转页面
  };

  // const onClickMyProfile = () => {
  //   return;
  //   const val = DoctorSidebarType[DoctorSidebarType.MyProfile];
  //   setSelectType(val as keyof typeof DoctorSidebarType);

  //   // TODO 跳转页面
  // };

  const renderAppointments = () => {
    if (selectType === DoctorSidebarType[DoctorSidebarType.Appointments]) {
      return (
        <div
          className="flex lg:mb-[34px] cursor-pointer relative"
          onClick={onClickAppointments}
          data-clickable
          id="[indicaid][/doctor][Sidebar][Appointments]"
        >
          <div
            className="absolute lg:left-[-25px] lg:w-[292px] lg:h-[21px]  cursor-pointer z-10"
            onClick={onClickAppointments}
          ></div>
          <div className="lg:w-[3px] lg:h-[21px] absolute lg:left-[0px] lg:rounded-[9px] bg-[#009FAF]"></div>
          <img src={appointmentsGreen} alt="appointmentsGreen" className="lg:w-[16px] cursor-pointer lg:ml-[9px]" />
          <div className="font-[700] text-[#009FAF] lg:text-[16px] lg:leading-[20px] lg:tracking-[0.1px] lg:ml-[21px]">
            Appointments
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="flex lg:mb-[34px] cursor-pointer relative"
          onClick={onClickAppointments}
          data-clickable
          id="[indicaid][/doctor][Sidebar][Appointments]"
        >
          <div
            className="absolute lg:left-[-25px] lg:w-[292px] lg:h-[21px]  cursor-pointer z-10"
            onClick={onClickAppointments}
          ></div>
          <img src={appointments} alt="appointments" className="lg:w-[16px] cursor-pointer lg:ml-[9px]" />
          <div className="font-[700] text-[#52575C] lg:text-[16px] lg:leading-[20px] lg:tracking-[0.1px] lg:ml-[21px]">
            Appointments
          </div>
        </div>
      );
    }
  };

  const renderMedications = () => {
    if (selectType === DoctorSidebarType[DoctorSidebarType.Medications]) {
      return (
        <div
          className="flex lg:mb-[35px] cursor-pointer relative"
          onClick={onClickMedications}
          data-clickable
          id="[indicaid][/doctor][Sidebar][Medications]"
        >
          <div
            className="absolute lg:left-[-25px] lg:w-[292px] lg:h-[21px]  cursor-pointer z-10"
            onClick={onClickMedications}
          ></div>
          <div className="lg:w-[3px] lg:h-[21px] absolute lg:left-[0px] lg:rounded-[9px] bg-[#009FAF]"></div>
          <img src={medicationsGreen} alt="medicationsGreen" className="lg:w-[16px] cursor-pointer lg:ml-[9px]" />
          <div className="font-[700] text-[#009FAF] lg:text-[16px] lg:leading-[20px] lg:tracking-[0.1px] lg:ml-[21px]">
            Medications
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="flex lg:mb-[35px] cursor-pointer relative"
          onClick={onClickMedications}
          data-clickable
          id="[indicaid][/doctor][Sidebar][Medications]"
        >
          <div
            className="absolute lg:left-[-25px] lg:w-[292px] lg:h-[21px]  cursor-pointer z-10"
            onClick={onClickMedications}
          ></div>
          <img src={medications} alt="medications" className="lg:w-[16px] cursor-pointer lg:ml-[9px]" />
          <div className="font-[700] text-[#52575C] lg:text-[16px] lg:leading-[20px] lg:tracking-[0.1px] lg:ml-[21px]">
            Medications
          </div>
        </div>
      );
    }
  };

  // const renderMyProfile = () => {
  //   if (selectType === DoctorSidebarType[DoctorSidebarType.MyProfile]) {
  //     return (
  //       <div className="flex pointer-events-none cursor-default relative" onClick={onClickMyProfile}>
  //         <div className="lg:w-[3px] lg:h-[21px] absolute lg:left-[0px] lg:rounded-[9px] bg-[#009FAF]"></div>
  //         <img
  //           src={myProfileGreen}
  //           alt="myProfileGreen"
  //           className="lg:w-[16px] cursor-pointer lg:ml-[9px]"
  //         />
  //         <div className="font-[700] text-[#009FAF] lg:text-[16px] lg:leading-[20px] lg:tracking-[0.1px] lg:ml-[21px]">
  //           My Profile
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div className="flex  pointer-events-none cursor-default" onClick={onClickMyProfile}>
  //         <img src={myProfile} alt="myProfile" className="lg:w-[16px] cursor-pointer lg:ml-[9px]" />
  //         <div className="font-[700] text-[#52575C] lg:text-[16px] lg:leading-[20px] lg:tracking-[0.1px] lg:ml-[21px]">
  //           My Profile
  //         </div>
  //       </div>
  //     );
  //   }
  // };

  const onClickLogout = useMemoizedFn(() => {
    patientTokenStore.remove();
    navigate('/doctor/login');
  });

  // const onClickLogoutIcon= useMemoizedFn(() => {
  //   alertInfo((close) => ({
  //     content: <LogoutContent close={close} onClickConfirm={onClickLogout} />,
  //     background: '#F6F8FB',
  //     wraperBackground: '#00000020',
  //     closeIconShow: false,
  //   }));
  // });

  return (
    <div
      className="lg:w-[292px] sm:p-[50px_49px_60px_25px] bg-[#fff] sm:pb-[45px] z-10"
      style={{ boxShadow: '4px 0px 16px rgba(16, 30, 115, 0.08)' }}
      // box-shadow: ;
    >
      <div className="flex flex-col lg:mb-[35px] lg:w-[218px]">
        <div className="font-[400] text-[#25282B] lg:text-[28px] lg:leading-[42px]">Welcome back,</div>
        <div className="font-[700] text-[#25282B] lg:text-[28px] lg:leading-[42px]">
          {doctorInfoObj?.doctor?.prefix
            ? `${doctorInfoObj?.doctor?.prefix} ${doctorInfoObj?.fullName}`
            : doctorMessage?.user?.doctorName || 'Dr. Scott'}
        </div>
      </div>
      <div className="w-full lg:border-[#DADADA] lg:border-solid lg:border-[1px] lg:mb-[14px]"></div>
      <div className="font-[400] text-[#000000] lg:text-[14px] lg:leading-[22px] lg:mb-[7px] lg:tracking-[0.1px]">
        {/* {dayjs().format('DD MMM YYYY ')} */}
        {`${getCurrentWeekDay()}`}
      </div>
      <div className="font-[600] text-[#000000] lg:text-[20px] lg:leading-[28px] lg:mb-[17px]">
        {dayjs().format('DD MMM YYYY ')}
        {/* {`(${getCurrentWeekDay()})`} */}
      </div>
      <div className="font-[400] text-[#52575C] lg:text-[14px] lg:leading-[17px] lg:mb-[17px]">
        {dayjs().format('hh:mm a')} {getUTC()}
      </div>
      <div className="w-full lg:border-[#DADADA] lg:border-solid lg:border-[1px] lg:mb-[27px]"></div>
      {/* 第一项列表 */}
      <div className="lg:mb-[28px] font-[600] text-[#000000] lg:text-[18px] lg:leading-[26px]">Dashboard</div>
      {renderAppointments()}
      {/* 第二项列表 */}
      <div className="lg:mb-[40px] font-[600] text-[#000000] lg:text-[18px] lg:leading-[26px]">Administration</div>
      {renderMedications()}
      {/* {renderMyProfile()} */}
      {/* TODO: logout需要添加逻辑 */}
      {/* fixed lg:bottom-[60px] */}
      <div className="flex flex-col lg:w-[218px] sm:mt-[280px]">
        <div className="w-full lg:border-[#DADADA] lg:border-solid lg:border-[1px] lg:mb-[19px]  relative sm:bottom-[45px]"></div>
        {/* onClick={onClickLogout} */}
        <div
          className="flex cursor-pointer relative sm:bottom-[45px]"
          data-clickable
          id="[indicaid][/doctor][Sidebar][Logout]"
          onClick={() => {
            alertInfo((close) => ({
              content: <LogoutContent close={close} onClickConfirm={onClickLogout} />,
              background: '#fff',
              wraperBackground: '#00000020',
              closeIconShow: false,
            }));
          }}
        >
          {/* <div className='absolute lg:left-[-25px] lg:w-[292px] lg:h-[21px]  cursor-pointer' onClick={() => {
            alertInfo((close) => ({
              content: <LogoutContent close={close} onClickConfirm={onClickLogout} />,
              background: '#F6F8FB',
              wraperBackground: '#00000020',
              closeIconShow: false,
            }));
          }}></div> */}
          <img src={logout} alt="logout" className="lg:w-[16px] cursor-pointer lg:ml-[9px]" />
          <div className="font-[700] text-[#52575C] lg:text-[16px] lg:leading-[20px] lg:tracking-[0.1px] lg:ml-[21px]">
            Logout
          </div>
        </div>
      </div>
    </div>
  );
}
export default SideBar;
