import axiosRetry, { IAxiosRetryConfig } from 'axios-retry';
import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import website from '@configs/website';
import { getLocale } from 'src/router/routerTools';
import { track } from './dataTrack';
import { doctorTokenStore, patientTokenStore } from './localStore/tokenStore';

// TODO 先完成ajax请求再说
/**
 * RequestConfig中可以单独设置retry参数和timeout
 *
 * eg:
 *  request.get('url/xxx', {
 *    params: {a: 1},
 *    'axios-retry': { // 单独配置重试参数
 *        retries: 1000,
 *     },
 *     timeout: 300000, // 单独配置timeout
 *  })
 *
 */

// type HeadersType = {
//   'Content-Language'?: string;
//   'Content-Type'?: string;
//   'X-Request-With'?: string;
// };

// TODO 需要完善
// const codeMessage = {
//   '400': 'There was an error in the request. The server did not create or modify data.',
//   '403': 'The user is authorized, but access is prohibited.',
//   '404': 'The request sent is for a non-existent record. The server did not operate.',
//   '406': 'The requested format is not available.',
//   '410': 'The requested resource is permanently deleted and will not be obtained again.',
//   '422': 'A validation error occurred when creating an object.',
//   '500': 'An error occurred on the server. Please check the server.',
//   '502': 'Gateway error.',
//   '503': 'The service is unavailable, the server is temporarily overloaded or maintained.',
//   '504': 'Gateway timeout.',
// };

const retryConfig = {
  retries: 3,
  retryCondition: (error: AxiosError) => {
    // 请求超时
    if (error.code === 'ECONNABORTED' || error.message.includes('Network Error')) {
      return true;
    }
    if (error.response && error.response.status < 599 && error.response.status >= 500) {
      return true;
    }
    return false;
  },
  retryDelay: () => 1000,
  shouldResetTimeout: true,
};

const request = axios.create({
  baseURL: process.env.api_base,
  timeout: 60000,
});

axiosRetry(request, retryConfig);

function getLocalePath() {
  const { locale, hasPath } = getLocale();
  return hasPath ? '/' + locale : '';
}

export const isDoctorPortal = () => {
  return window.location.pathname.startsWith(getLocalePath() + '/doctor');
};
const isMeetingPage = () => {
  return window.location.href.includes('/meeting/');
};

function getCommonHeaders() {
  const headers: any = {
    'Content-Type': 'application/json',
    'X-Request-With': 'XMLHttpRequest',
    'x-language': window.location.href.includes('/en/') || isDoctorPortal() ? 'en-us' : 'zh-HK',
    'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  let token;
  if (isDoctorPortal() || isMeetingPage()) {
    token = doctorTokenStore.get();
  } else {
    token = patientTokenStore.get();
  }
  // const token = cookie.get(website.access_token_key);
  if (token) {
    headers[website.auth_header] = `Bearer ${token}`;
  }

  return headers;
}
type RequestInterceptorsType = (config: InternalAxiosRequestConfig) => InternalAxiosRequestConfig;
const requestInterceptors: RequestInterceptorsType = (config) => {
  const headers: AxiosRequestHeaders = { ...config.headers, ...getCommonHeaders() };
  const prefixPath = '/api/v1/';
  const baseURL = process.env.api_base + prefixPath;
  return { ...config, headers, baseURL };
};

const requestError = (error: any) => {
  return Promise.reject(error);
};

// 请求拦截器
request.interceptors.request.use(requestInterceptors, requestError);

// 响应拦截器
// TODO 响应拦截器需要再看一下
const interceptorsResponse = {
  normal: (res: AxiosResponse) => {
    try {
      return res.request && res.data && res.headers ? res.data : res;
    } catch {
      throw new Error('Network Error');
    }
  },
  error: (error: any) => {
    if (error.response) {
      const { status } = error.response;
      // TODO 后续需要展示错误信息
      if (status === 401 || status === '401') {
        if (isDoctorPortal() || isMeetingPage()) {
          doctorTokenStore.remove();
        } else {
          patientTokenStore.remove();
        }

        // 有些场景不需要重定向
        if (error.config.params?.redirect !== false) {
          const isDoctor = isDoctorPortal() || isMeetingPage();
          setTimeout(() => {
            window.location.href = `${location.origin}${isDoctor ? '/doctor' : ''}/login?redirect=${encodeURIComponent(
              window.location.href,
            )}`;
          }, 500);
        }
      }
      if (!isDoctorPortal()) {
        track({
          event_type: 'encounter error',
          event_properties: {
            'error type': 'request error',
            'error name': error?.config?.url || window.location.href,
            'error code': status,
          },
        });
      }

      // const errorText =
      //   (Array.isArray(data?.error?.details?.errors) && data?.error?.details?.errors[0]?.message) ||
      //   data?.error?.message;

      // notification.error({
      //   message: `request error ${status}`,
      //   description: errorText,
      // });
    }
    return Promise.reject(error);
  },
};

request.interceptors.response.use(interceptorsResponse.normal, interceptorsResponse.error);

export default request;

type AbortControllerRef = {
  current: AbortController | null;
};
function getSignal(abortControllerRef?: AbortControllerRef) {
  if (abortControllerRef) {
    abortControllerRef.current = new AbortController();
    return abortControllerRef.current.signal;
  }
  return undefined;
}
export function get<T = any>(
  url: string,
  params?: any,
  options?: {
    axiosRetryConfig?: IAxiosRetryConfig;
    abortControllerRef?: AbortControllerRef;
  },
) {
  return request.get<T, T>(url, {
    params,
    signal: getSignal(options?.abortControllerRef),
    'axios-retry': options?.axiosRetryConfig,
  });
}

export function post<T = any>(
  url: string,
  params: any,
  options?: {
    axiosRetryConfig?: IAxiosRetryConfig;
    abortControllerRef?: AbortControllerRef;
  },
) {
  return request.post<T, T>(
    url,
    {
      ...params,
    },
    {
      signal: getSignal(options?.abortControllerRef),
      'axios-retry': options?.axiosRetryConfig,
    },
  );
}

export function put<T = any>(
  url: string,
  params?: any,
  options?: {
    axiosRetryConfig?: IAxiosRetryConfig;
    abortControllerRef?: AbortControllerRef;
  },
) {
  return request.put<T, T>(
    url,
    {
      ...params,
    },
    {
      'axios-retry': options?.axiosRetryConfig,
      signal: getSignal(options?.abortControllerRef),
    },
  );
}

export function patch<T = any>(
  url: string,
  params?: any,
  options?: {
    axiosRetryConfig?: IAxiosRetryConfig;
    abortControllerRef?: AbortControllerRef;
  },
) {
  return request.patch<T, T>(
    url,
    {
      ...params,
    },
    {
      'axios-retry': options?.axiosRetryConfig,
      signal: getSignal(options?.abortControllerRef),
    },
  );
}

export function healthPost<T = any>(url: string, params?: any) {
  return request.post<T, T>(url, params);
}

export function deleteFunc<T = any>(url: string, params: any, axiosRetryConfig?: IAxiosRetryConfig) {
  return request.delete<T, T>(url, {
    data: { ...params },
    'axios-retry': axiosRetryConfig,
  });
}
