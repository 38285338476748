import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

export default function CameraOffIcon(props: Partial<CustomIconComponentProps> & { color?: string }) {
  const CameraOffSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.1001 19.7616L0.205391 1.3245C0.0684638 1.18322 0 1.00662 0 0.794702C0 0.582781 0.0770218 0.397351 0.231065 0.238411C0.385109 0.0794703 0.569277 0 0.783569 0C0.997176 0 1.181 0.0794703 1.33504 0.238411L19.2041 18.6755C19.341 18.8168 19.4095 18.9934 19.4095 19.2053C19.4095 19.4172 19.3325 19.6026 19.1784 19.7616C19.0244 19.9205 18.8447 20 18.6393 20C18.4339 20 18.2542 19.9205 18.1001 19.7616ZM16.38 12.7417L5.57125 1.61589H14.5058C15.0364 1.61589 15.4814 1.80132 15.8408 2.17219C16.2003 2.54305 16.38 2.99338 16.38 3.52318V8.37086L19.1784 5.48344C19.3325 5.3245 19.5081 5.28459 19.7053 5.36371C19.9018 5.44353 20 5.59823 20 5.82782V13.298C20 13.5276 19.9018 13.6819 19.7053 13.7611C19.5081 13.8409 19.3325 13.8013 19.1784 13.6424L16.38 10.755V12.7417ZM2.69576 1.64238L16.3543 15.7351C16.3372 16.2119 16.1404 16.6269 15.7638 16.9801C15.3872 17.3333 14.9593 17.5099 14.4801 17.5099H2.79846C2.28498 17.5099 1.84852 17.3245 1.48909 16.9536C1.12965 16.5828 0.949936 16.1325 0.949936 15.6026V3.52318C0.949936 3.04636 1.1211 2.61369 1.46341 2.22517C1.80573 1.83664 2.21652 1.64238 2.69576 1.64238Z"
        fill={props.color ?? '#FF414D'}
      />
    </svg>
  );
  return <Icon component={CameraOffSvg} {...props} />;
}
