import { MailFilled } from '@ant-design/icons';
import { LocalTwo, PhoneTelephone, Time } from '@icon-park/react';
import { useTranslation } from 'react-i18next';

export default function AboutUs() {
  const [t] = useTranslation();
  return (
    <div className="text-center lg:pb-[80px] lg:pt-[40px]">
      <p className="text-[#2DBDB6] text-center lg:text-[42px] font-medium text-[28px] pl-[24px] lg:mt-0 lg:mb-[10px] my-[25px]">
        {t('ContactUs')}
      </p>
      <p className="lg:text-[18px] text-[16px] font-medium lg:mt-[17px] lg:pl-[24px] lg:pr-[28px] pl-[45px] pr-[45px] text-left">
        {t('ContactUsPage.desc')}
      </p>
      <div className="lg:mt-[58px] lg:w-[1206px] lg:justify-between lg:items-start items-center w-full mt-[25px] mx-auto flex lg:flex-row flex-col lg:pb-0 pb-[80px]">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3688.0156311019755!2d114.20748831544114!3d22.42843748525721!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340401a7a52107cf%3A0xcf55fa0d7d53ee2f!2zUEhBU0UgU2NpZW50aWZpYyDnm7jpgZTnlJ_niannp5HmioA!5e0!3m2!1sen!2s!4v1679032663447!5m2!1sen!2s"
          className="lg:w-[547px] lg:h-[381px] w-[414px] h-[381px]"
          allowFullScreen={false}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <div className="lg:pl-[56px] pl-[27px] lg:w-[fit-content] w-full">
          <div className="flex lg:mt-[30px] mt-[25px]">
            <div className="lg:w-[36px] w-[38px] flex-shrink-0">
              <LocalTwo theme="filled" size="26" fill="#2DBDB6" />
            </div>

            <a
              href="https://www.google.com/maps/place/Hong+Kong,+Science+Park,+Science+Park+E+Ave,+22%E8%99%9FHK+New+Territories+Sha+Tin+1F+PHASE+Scientific+%E7%9B%B8%E9%81%94%E7%94%9F%E7%89%A9%E7%A7%91%E6%8A%80,+Building+22E/@22.4290474,114.2078746,17z/data=!4m6!3m5!1s0x340401a7a52107cf:0xcf55fa0d7d53ee2f!8m2!3d22.4284375!4d114.209677!16s%2Fg%2F11nn1zclkb"
              target="_blank"
              className="text-[#212121] lg:text-[16px] text-[16px] text-left"
              id="contactus__clickLocation-button"
            >
              1/F, Building 22E, Phase 3, Hong Kong Science Park, Shatin, Hong Kong
            </a>
          </div>
          <div className="flex lg:mt-[30px] mt-[25px]">
            <div className="lg:w-[36px] w-[38px] flex-shrink-0">
              <PhoneTelephone theme="filled" size="26" fill="#2DBDB6" />
            </div>
            <a
              href="tel:+852 3892 7200"
              target="_blank"
              className="text-[#212121] lg:text-[16px] text-[16px]"
              id="contactus__phone-button"
            >
              +852 3892 7200
            </a>
          </div>
          <div className="flex lg:mt-[30px] mt-[25px] ml-[4px]">
            <div className="lg:w-[36px] w-[38px] flex-shrink-0 text-left flex">
              <MailFilled className="text-[#2DBDB6] lg:text-[22px] text-[22px]" />
            </div>
            <a
              href="mailto:info@phasesci.com"
              target="_blank"
              className="text-[#212121] lg:text-[16px] text-[16px]"
              id="contactus__email-button"
            >
              info@phasesci.com
            </a>
          </div>
          <div className="flex lg:mt-[30px] mt-[25px] ml-[2px]">
            <div className="lg:w-[36px] w-[38px] flex-shrink-0 flex">
              <Time theme="filled" size="26" fill="#2DBDB6" />
            </div>
            <span className="text-[#212121] lg:text-[16px] text-[16px]">9:00 A.M - 6:00 P.M</span>
          </div>
        </div>
      </div>
    </div>
  );
}
