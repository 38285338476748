import { cameraListState, microphoneListState, speakerListState } from '@states/mediaDevicesList.state';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import RtcAdapter from '../RtcTool';

export function useMediaDevicesInit() {
  const [cameraList, setCameraList] = useRecoilState(cameraListState);
  const [microphoneList, setMicrophoneList] = useRecoilState(microphoneListState);
  const [speakerList, setSpeakerList] = useRecoilState(speakerListState);
  useEffect(() => {
    RtcAdapter.getCameraList().then(setCameraList);
    RtcAdapter.getMicrophoneList().then(setMicrophoneList);
    RtcAdapter.getSpeakerList().then(setSpeakerList);
    const removeListener = RtcAdapter.on('media-device-changed', (type, deviceInfo) => {
      const setDeviceList =
        type === 'camera' ? setCameraList : type === 'microphone' ? setMicrophoneList : setSpeakerList;
      if (deviceInfo.state === 'ACTIVE') {
        // TODO: 这里考虑用immuer
        setDeviceList((cl) => {
          cl.push(deviceInfo.device);
          return [...cl];
        });
      } else {
        setDeviceList((cl) => {
          const idx = cl.findIndex((d) => d.deviceId === deviceInfo.device.deviceId);
          if (idx !== -1) {
            cl.splice(idx, 1);
          }
          return [...cl];
        });
      }
    });
    return removeListener;
  }, [setCameraList, setMicrophoneList, setSpeakerList]);

  return { cameraList, microphoneList, speakerList };
}

export function useMediaDevices() {
  const cameraList = useRecoilValue(cameraListState);
  const microphoneList = useRecoilValue(microphoneListState);
  const speakerList = useRecoilValue(speakerListState);

  return {
    cameraList,
    microphoneList,
    speakerList,
  };
}

export function useLocalVidioStatus() {
  const [localVideoEnable, setLocalVideoEnable] = useState(RtcAdapter.localVideoEnable);
  useEffect(() => {
    RtcAdapter.on('local-video-status-changed', setLocalVideoEnable);
  }, []);

  return localVideoEnable;
}

export function useLocalAudioStatus() {
  const [localAudioEnable, setLocalAudioEnable] = useState(RtcAdapter.localAudioEnable);
  useEffect(() => {
    RtcAdapter.on('local-audio-status-changed', setLocalAudioEnable);
  }, []);

  return localAudioEnable;
}
