import { useMemoizedFn } from 'ahooks';
import { useEffect, useState } from 'react';

// 设置隐藏属性和改变可见属性的事件的名称
let hidden = '';
let visibilityChange = '';
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
  // @ts-ignore
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
  // @ts-ignore
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

export default function usePageVisiblity(cb: (visible: boolean) => void) {
  const memoCb = useMemoizedFn(cb);
  // @ts-ignore
  const [pageVisible, setPageVisible] = useState(document[hidden]);
  useEffect(() => {
    if (
      typeof document.addEventListener === 'undefined' ||
      //@ts-ignore
      typeof document[hidden] === 'undefined'
    ) {
      console.log('This requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.');
      return;
    }

    const handleVisibilityChange = () => {
      // @ts-ignore
      if (document[hidden]) {
        setPageVisible(false);
        memoCb(false);
      } else {
        memoCb(true);
        setPageVisible(true);
      }
    };
    document.addEventListener(visibilityChange, handleVisibilityChange, false);
    return () => {
      document.removeEventListener(visibilityChange, handleVisibilityChange, false);
    };
  }, [memoCb]);
  return pageVisible;
}
