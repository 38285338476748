import { Select } from 'antd';
import { useEffect, useState } from 'react';

type DeviceSelectorProps = {
  deviceList: MediaDeviceInfo[];
  defaultValue: string;
  onChange: (v: string) => void;
  className?: string;
};
export default function DeviceSelector(props: DeviceSelectorProps) {
  const [value, setValue] = useState(props.defaultValue);

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <Select
      disabled={props.deviceList.length === 0}
      className={`device-selector ${props.className || ''}`}
      value={value}
      onChange={(v) => {
        setValue(v);
        props.onChange(v);
      }}
      options={props.deviceList.map((m) => ({ label: m.label, value: m.deviceId }))}
    />
  );
}
