import { useSearchParams } from 'react-router-dom';

export function useProductType() {
  const productType = useSearchParams()[0].get('productType');
  const isHaveProductType =
    Object.prototype.toString.call(productType) === '[object Number]' ||
    Object.prototype.toString.call(productType) === '[object String]';
  return {
    productType,
    isHaveProductType,
  };
}
