import { InfoCircleFilled } from '@ant-design/icons';
import BaseButton from '@components/BaseButton/BaseButton';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from 'src/router/routerTools';

export default function OrderNewKitText(props: {
  type: 'covid' | 'covid&flu';
  id?: string;
  dataTrackFunc: () => Promise<any>;
}) {
  const language = useContext(LanguageContext);

  const prefix = language === 'en' ? '/en' : '';
  const [t] = useTranslation();
  return (
    <div>
      <BaseButton
        id={props.id}
        htmlType="submit"
        buttonClassName="w-full h-[50px] text-[16px] md:w-[326px] md:h-[50px] md:text-[16px] h-[50px]"
        value={t('orderNewKitText.btnText')}
        onClick={async () => {
          try {
            await props.dataTrackFunc();
          } catch {}
          window.location.href =
            props.type === 'covid'
              ? `https://www.indicaid.com${prefix}/products/covidantigen`
              : `https://www.indicaid.com${prefix}/products/covid-flu-rat`;
        }}
        sectionClassName="md:w-[326px] m-auto"
      ></BaseButton>
      <p className="text-[#212121] text-left w-full flex items-start font-medium lg:text-[12px] lg:leading-[18px] text-[12px] leading-[18px] lg:mt-[20px] mt-[15px]">
        <InfoCircleFilled className="text-[#2DBDB6] pr-[5px] pt-[3px]" />

        <span>{props.type === 'covid' ? t('orderNewKitText.text2') : t('orderNewKitText.text1')}</span>
      </p>
    </div>
  );
}
