import { useTranslation } from 'react-i18next';
import { ResultMap, ResultType } from '../covidAndFluConst';
import { useMemo } from 'react';

export default function ComboTestResult(props: { resultType: ResultType }) {
  const [t] = useTranslation();

  let textColor = '#57606A';
  if (props.resultType === ResultType.Negative) {
    textColor = '#57606A';
  } else if (props.resultType === ResultType.INVALID) {
    textColor = '#98979B';
  } else {
    textColor = '#DD524C';
  }

  const testResultText = useMemo(() => {
    return props.resultType === ResultType.INVALID
      ? t('Covid19AndFluSelectResult.Invalid')
      : t('Covid19AndFluSelectResult.' + ResultMap[props.resultType]);
  }, [props.resultType, t]);

  return (
    <div className="text-center inline-block lg:h-[40px] lg:ml-[18px] lg:w-auto w-full lg:border-b-[2px] border-b-[0] lg:mt-0 mt-[16px] border-solid border-b-[#B8E2DF]">
      <p className="lg:text-[30px] lg:leading-[37px] text-[30px] leading-[29px] font-bold" style={{ color: textColor }}>
        {testResultText}
      </p>
    </div>
  );
}
