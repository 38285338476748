import { Button } from 'antd';
import { ButtonHTMLType } from 'antd/es/button';
import { CSSProperties } from 'react';
import vector from '../../images/vector.svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const bgColor = {
  default: '#2DBDB6',
  disabled: '#F5F5F5',
  success: '#2DBDB6',
  dangerous: '#FF4D4F',
  orange: '#F37021',
  doctorDefault: '#009FAF',
  doctorClear: '#FFFFFF',
  doctorClearDisabled: '#FFFFFF',
  indicaidGreen: '#2DBDB6',
};
const textColor = {
  default: '#ffffff',
  disabled: 'rgba(0, 0, 0, 0.25)',
  success: '#ffffff',
  dangerous: '#ffffff',
  orange: '#ffffff',
  doctorDefault: '#ffffff',
  doctorClear: '#009FAF',
  doctorClearDisabled: 'rgba(0, 0, 0, 0.25)',
  indicaidGreen: '#FFFFFF',
};
const borderColor = {
  default: 'none',
  disabled: '1px solid #D9D9D9',
  success: 'none',
  dangerous: 'none',
  orange: 'none',
  doctorDefault: 'none',
  doctorClear: '1px solid #009FAF',
  doctorClearDisabled: '1px solid #D9D9D9',
  indicaidGreen: 'none',
};
export type ButtonStatus =
  | 'default'
  | 'doctorDefault'
  | 'disabled'
  | 'success'
  | 'dangerous'
  | 'orange'
  | 'doctorClear'
  | 'doctorClearDisabled'
  | 'indicaidGreen'
  | undefined;

type BaseButtonProps = {
  value?: string | React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement>;
  status?: ButtonStatus;
  placeholder?: string;
  disable?: boolean;
  helpText?: string;
  loading?: boolean;
  loadingText?: string;
  htmlType?: ButtonHTMLType;
  buttonClassName?: string;
  style?: CSSProperties;
  sectionClassName?: string;
  id?: string;
};

export default function BaseButton(props: BaseButtonProps = {}) {
  const [t] = useTranslation();
  return (
    <section className={props.sectionClassName}>
      <Button
        id={props.id}
        // TODO 这个可能需要进行修改
        onClick={props.onClick}
        style={{
          backgroundColor: bgColor[props.status || 'default'],
          // backgroundImage: props.status === 'default' ? 'linear-gradient(91.08deg, #A7DC4D 0%, #2DBDB6 100%)' : '',
          color: textColor[props.status || 'default'],
          border: borderColor[props.status || 'default'],
          boxShadow: '0px 2px 0px rgba(0, 0, 0, 0.043)',
          // fontFamily: window.location.href.includes(`${location.origin}/doctor`) ? 'Inter' : 'Montserrat',
          fontFamily: 'Montserrat',
          WebkitBoxShadow: props.status === 'doctorDefault' ? '0px 2px 0px rgba(0, 0, 0, 0.043)' : undefined,
          WebkitFilter: props.status === 'doctorClear' ? 'drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.043))' : undefined,
          ...props.style,
        }}
        placeholder={props.placeholder}
        disabled={props.disable}
        htmlType={props.htmlType}
        className={classNames(props.buttonClassName, 'hover:opacity-80')}
        loading={props.loading}
      >
        {/* default */}
        {/* success */}
        {props.status === 'success' ? (
          <div className="flex justify-center">
            <img src={vector} alt="vector" className="object-cover lg:w-[15px] cursor-pointer mr-[4px]" />
            <div>{t('BaseButton.Success')}</div>
          </div>
        ) : props.loading ? (
          props.loadingText ?? t('BaseButton.Loading')
        ) : (
          props.value
        )}
      </Button>
    </section>
  );
}
