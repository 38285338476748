import { FloatButton } from 'antd';
import PrivacyText from './components/PolicyText';
import TermsListTitle from './components/TermsListTitle';
import TermsTitle from './components/TermsTitle';
import { ArrowUpOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export default function PrivacyPolicy() {
  const [t] = useTranslation();
  return (
    <div className="lg:w-[1417px] m-auto lg:tracking-[.09px] md:w-[900px] w-[350px] tracking-[.25px] whitespace-pre-line">
      <TermsTitle>{t('privacyText.title')}</TermsTitle>
      <PrivacyText>{t('privacyText.0')}</PrivacyText>
      <TermsListTitle>{t('privacyText.1')}</TermsListTitle>
      <PrivacyText>{t('privacyText.1_1')}</PrivacyText>
      <TermsListTitle>{t('privacyText.2')}</TermsListTitle>
      <PrivacyText>{t('privacyText.2_1')}</PrivacyText>
      <TermsListTitle>{t('privacyText.3')}</TermsListTitle>
      <PrivacyText>{t('privacyText.3_1')}</PrivacyText>
      <TermsListTitle>{t('privacyText.4')}</TermsListTitle>
      <PrivacyText>{t('privacyText.4_1')}</PrivacyText>
      <TermsListTitle>{t('privacyText.5')}</TermsListTitle>
      <PrivacyText>{t('privacyText.5_1')}</PrivacyText>
      <TermsListTitle>{t('privacyText.6')}</TermsListTitle>
      <PrivacyText>{t('privacyText.6_1')}</PrivacyText>
      <TermsListTitle>{t('privacyText.7')}</TermsListTitle>
      <PrivacyText>{t('privacyText.7_1')}</PrivacyText>
      <TermsListTitle>{t('privacyText.8')}</TermsListTitle>
      <PrivacyText>{t('privacyText.8_1')}</PrivacyText>
      <TermsListTitle>{t('privacyText.9')}</TermsListTitle>
      <PrivacyText>{t('privacyText.9_1')}</PrivacyText>
      <TermsListTitle>{t('privacyText.10')}</TermsListTitle>
      <PrivacyText>{t('privacyText.10_1')}</PrivacyText>
      <FloatButton.BackTop type="primary" icon={<ArrowUpOutlined />} />
    </div>
  );
}
