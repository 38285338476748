import React, { useState, useEffect } from 'react';
import { Form, message, notification } from 'antd';
import { useMemoizedFn } from 'ahooks';
import { isValidPhoneNumber } from 'libphonenumber-js';

import TextInput, { TextInputStatus } from '@components/TextInput/TextInput';
import BaseButton, { ButtonStatus } from '@components/BaseButton/BaseButton';
import PhoneNumberInput, { PhoneNumberInputStatus } from '@components/PhoneNumberInput/PhoneNumberInput';

import { usePhoneNumberVerify } from 'src/hooks/pageHooks/accountVerify';
import classNames from 'classnames';
import loginBg from '@images/doctorImages/loginBg.png';
import logo from '@images/doctorImages/logo.svg';

import { VERIFY_CODE_WAITING_SECONDS } from '@consts/consts';
import { useLSCountDown as useCountDown } from 'src/hooks/tools/countDown';
import { post } from '@utils/request';
import { handleLocalStorage } from '@utils/index';

import website from '@configs/website';
import { useSearchParams } from 'react-router-dom';
import '../../../DoctorApp.css';
import { useLocalNavigate } from 'src/router/routerTools';

import { useRouterChangeAutoScrollTop } from '@hooks/tools/RouterAutoScrollTop';
import { doctorTokenStore } from '@utils/localStore/tokenStore';

interface IFormValues {
  otp: string;
}

const style = {
  container: {
    background: 'linear-gradient(97.23deg, rgba(0, 159, 175, 0.2) 7%, rgba(0, 0, 0, 0) 56.24%)',
    // background: 'red',
  },
};

const DoctorLogin: React.FC = () => {
  useRouterChangeAutoScrollTop();
  const isTooLong = false;
  // const isTooLong = useMedia('(min-height: 1015px)', false);
  const [phoneInputObj, setPhoneInputObj] = useState<{
    helpText: string;
    status: PhoneNumberInputStatus;
  }>({
    helpText: '',
    status: undefined,
  });
  const [notificationApi, contextHolder] = notification.useNotification();
  // const [notificationApi] = notification.useNotification();

  const navigate = useLocalNavigate();

  const [txt, setTxt] = useState<string>('');
  // TODO 成功登录之后，需要移除这个参数
  // const {
  //   count,
  //   startCount: sendCode,
  //   setStartCountNumber,
  // } = useCountDown(VERIFY_CODE_WAITING_SECONDS, website.doctor_can_resend_after);
  const timerId = website.doctor_can_resend_after;
  const { count, startCount: sendCode } = useCountDown(VERIFY_CODE_WAITING_SECONDS, timerId);
  const [buttonStatus, setButtonStatus] = useState<ButtonStatus>('disabled');
  const [otpButtonStatus, setOtpButtonStatus] = useState<ButtonStatus>('default');
  const [buttonLoading, setButtonLoading] = useState(false);
  // TODO 限制8位
  const { phoneNumber, setPhoneNumber, isPhoneNumberValid, handleIsPhoneNumberValidChange } = usePhoneNumberVerify();
  // Email表单的样式
  const [emailInputObj, setEmailInputObj] = useState<{
    helpText: string;
    status: TextInputStatus;
    emailValue: string | null;
  }>({
    helpText: '',
    status: undefined,
    emailValue: '',
  });

  const { helpText, status, emailValue = '' } = emailInputObj;

  // TODO 需要修改
  const onClickResendCode: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement> =
    useMemoizedFn(async (e) => {
      e.preventDefault();
      if (count !== 0) {
        return;
      }
      // 根据Rex的需求，需要清空
      setEmailInputObj({
        ...emailInputObj,
        emailValue: '',
      });
      sendCode();
      // childRef.current && childRef.current.reset();
      // setVerificationCode('');
      // TODO 向Liber那边发生验证码
      try {
        const params = {
          phone: phoneNumber,
        };
        // TODO 后续内容需要进行修改
        await post('/user/send-otp', params);
      } catch (err: any) {
        const responseMessage = err?.response?.data?.message;
        const errorMessage = (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || 'error!';

        setPhoneInputObj({
          ...emailInputObj,
          helpText: errorMessage,
          status: 'error',
        });
        setButtonStatus('disabled');
        // track({
        //   event_type: 'encounter_error',
        //   event_properties: {
        //     'event name': errorMessage || 'unknown',
        //     'error type': 'login unsuccessful',
        //     'error code': err?.response?.data?.code || 'unknown',
        //   },
        // });
        notificationApi.error({
          message: 'Login unsuccessful',
          placement: 'bottomRight',
          description: errorMessage ?? undefined,
        });

        if (
          errorMessage === 'Incorrect OTP, you have exceed the number of login trials. Please try again in 10 minutes.'
        ) {
          // setOtpErrorText(null);
          // setVerificationError(true);
          setButtonStatus('disabled');
        } else {
          // setVerificationError(true);
          // setOtpErrorText(errorMessage);
        }
      }
    });

  // 登录
  const onFinish = useMemoizedFn(async (values: IFormValues) => {
    if (!isPhoneNumberValid) {
      return;
    }

    try {
      setButtonLoading(true);
      const params = {
        phone: phoneNumber,
        otp: values?.otp,
      };

      // TODO 进行内容的校验
      // const value = await post('/user/send-otp', params);
      console.log(params, 'params');

      // const curValue = method === 'email' ? emailValue : phoneNumber;
      // handleLocalStorage.set(method, curValue);
      // handleLocalStorage.set('method', method);
      // handleLocalStorage.set('fromPage', 'login');
      // setUserRecoil({
      //   ...userRecoil,
      //   [method]: curValue,
      //   method: method,
      //   fromPage: 'login',
      // });
      // TODO 向后端发送请求

      // TODO 这里还需要进行修改
      // message.success(`go to verify your ${method}`);
      // setTimeout(() => {
      //   // 携带url参数过去，从而确定是手机还是验证码
      //   navigate('/verify');
      //   setLoading(false);
      // }, 1000);

      setButtonLoading(false);
    } catch (err: any) {
      setButtonLoading(false);
      const responseMessage = err?.response?.data?.message;
      const errorMessage = (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || 'error!';
      message.error(errorMessage);
    }
  });

  // 当Email表单的内容改变时
  const emailInputChange: React.ChangeEventHandler<HTMLInputElement> = useMemoizedFn((e) => {
    const inputValue = e.target.value;

    if (inputValue && inputValue.length > 4) {
      const afterSliceInputValue = inputValue.slice(0, 4);

      if (isPhoneNumberValid) {
        setButtonStatus('default');
      } else {
        setButtonStatus('disabled');
      }

      setEmailInputObj({
        ...emailInputObj,
        emailValue: afterSliceInputValue,
      });

      return;
    }

    setEmailInputObj({
      ...emailInputObj,
      emailValue: inputValue,
    });
  });
  const redirect = useSearchParams()[0].get('redirect');

  const clickLogin: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement> =
    useMemoizedFn(async (e) => {
      e.preventDefault();

      // console.log(val, 'val');
      if (!(emailValue && emailValue.length === 4)) {
        return;
      }

      // setVerificationCode(val);
      // if (val.length < 4) {
      //   setButtonStatus('disabled');
      // }
      // setButtonStatus('default');

      try {
        setButtonLoading(true);

        const params = {
          phone: phoneNumber,
          code: emailValue,
        };
        // TODO 需要再进行测试
        const data = await post('/user/otp', params);

        // localStorage.removeItem(website.doctor_can_resend_after)
        doctorTokenStore.set(data?.token);

        // handleLocalStorage.set(website.access_token_key, data?.access_token);

        handleLocalStorage.set(website.doctor_login_message, data);

        // 点击按钮
        message.success('The verification code is correct');
        setButtonLoading(false);
        setTimeout(() => {
          setButtonStatus('success');
        }, 500);
        setTimeout(() => {
          // TODO: 看看怎么移除Resend Code
          localStorage.setItem(`timer:${website.doctor_can_resend_after}`, '');
          localStorage.removeItem(`timer:${website.doctor_can_resend_after}`);
          if (redirect) {
            window.location.href = decodeURIComponent(redirect);
          } else {
            // TODO 跳转至医生页面
            navigate('/doctor/appointments');
          }
        }, 2000);
      } catch (err: any) {
        const responseMessage = err?.response?.data?.message;
        const errorMessage = (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || 'error!';
        // track({
        //   event_type: 'encounter_error',
        //   event_properties: {
        //     'event name': errorMessage || 'unknown',
        //     'error type': 'login unsuccessful',
        //     'error code': err?.response?.data?.code || 'unknown',
        //   },
        // });
        if (['11004', 11004].includes(err?.response?.data?.code)) {
          setPhoneInputObj({
            ...emailInputObj,
            helpText: errorMessage,
            status: 'error',
          });
          setButtonStatus('disabled');
          notificationApi.error({
            message: 'Login unsuccessful',
            placement: 'bottomRight',
            description: errorMessage ?? undefined,
          });
        } else if (
          errorMessage === 'Incorrect OTP, you have exceed the number of login trials. Please try again in 10 minutes.'
        ) {
          setEmailInputObj({
            ...emailInputObj,
            helpText: errorMessage,
            status: 'error',
          });
          setButtonStatus('disabled');
          notificationApi.error({
            message: 'Login unsuccessful',
            placement: 'bottomRight',
            description: errorMessage ?? undefined,
          });
        } else {
          setEmailInputObj({
            ...emailInputObj,
            helpText: errorMessage,
            status: 'error',
          });
          notificationApi.error({
            message: 'Login unsuccessful',
            placement: 'bottomRight',
            description: errorMessage ?? undefined,
          });
        }
        setButtonLoading(false);
      }
    });

  // 根据校验规则，显示按钮的样式
  // useEffect(() => {
  //   // setButtonStatus('disabled');
  //   // setEmailInputObj({
  //   //   helpText: '',
  //   //   status: undefined,
  //   //   emailValue: '',
  //   // });
  //   // helpText, status, emailValue
  //   console.log(helpText, emailValue);
  // }, [helpText, emailValue]);

  useEffect(() => {
    if (count === 0 && isPhoneNumberValid) {
      setOtpButtonStatus('default');
    } else {
      setOtpButtonStatus('disabled');
    }
  }, [count, isPhoneNumberValid]);

  useEffect(() => {
    if (localStorage.getItem(`timer:${website.doctor_can_resend_after}`)) {
      setTxt(count === 0 ? 'Resend Code' : `Resend [${count}s]`);
    } else {
      setTxt('Send OTP');
    }
  }, [count]);

  // useEffect(() => {
  //   let lastVal = localStorage.getItem(website.doctor_can_resend_after);
  //   let _count = 0;
  //   if (lastVal && +lastVal) {
  //     _count = +lastVal;
  //     setStartCountNumber(_count);
  //   }
  // }, [setStartCountNumber]);

  useEffect(() => {
    if (emailValue && emailValue.length === 4 && isPhoneNumberValid) {
      setButtonStatus('default');
    } else {
      setButtonStatus('disabled');
    }
  }, [emailValue, isPhoneNumberValid]);

  // TODO 这个没什么作用，需要移除
  useEffect(() => {
    if (phoneNumber && phoneNumber.length > 12) {
      const afterSlicePhoneNumber = phoneNumber.slice(0, 12);
      // setPhoneNumber(afterSlicePhoneNumber);
      // setButtonStatus('default');
      const passwordButtonCanClick = isValidPhoneNumber(afterSlicePhoneNumber) && emailValue && emailValue.length === 4;
      if (passwordButtonCanClick) {
        setButtonStatus('default');
      } else {
        setButtonStatus('disabled');
      }
    } else if (phoneNumber.length < 12) {
      setButtonStatus('disabled');
    }
  }, [phoneNumber, setPhoneNumber, emailValue]);

  return (
    <div
      className={classNames(
        isTooLong && 'items-center',
        'doctor-app w-full h-full flex  bg-opacity-[0.8] flex-row py-[0] sm:px-[0px] min-h-[calc(100vh)] sm:min-h-[calc(100vh)] relative',
      )}
      style={style.container}
      // : 'lg:mt-[100px] mb-[100px]'
    >
      <div
        className={classNames(
          !isTooLong && 'sm:mt-[100px] sm:mb-[100px] mt-[100px] mb-[100px]',
          'flex sm:w-[796px] sm:h-[815px] flex-col sm:p-[99px_80px_105px_112px] z-10 bg-[#FFFFFF] sm:backdrop-blur-[2px] sm:rounded-[0px_15px_15px_0px]',
        )}
      >
        {contextHolder}
        <div className="sm:w-[198px] sm:mb-[11px]">
          <img src={logo} alt="logo" className="object-cover sm:w-[198px] cursor-pointer" />
        </div>
        <div className="font-[700] text-[#25282B] sm:text-[22px] sm:leading-[30px] sm:mb-[20px]">Doctor’s Portal</div>
        <hr className="border-[#B5B5B5] sm:mt-[0px] sm:mb-[34px] sm:w-[326px]" />
        <div className="font-[700] text-[#25282B] sm:text-[45px] sm:leading-[62px]">Welcome back</div>
        <div className="font-[400] text-[#000000] sm:text-[18px] sm:leading-[22px] sm:mt-[19px] sm:mb-[70px]">
          Please login using your phone number.
        </div>
        <Form
          name="normal_login"
          className="w-[100%]"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <div className="font-[600] text-[16px] text-[#1D1D1D] mt-[39.9996px] mb-[7.9991px] sm:mt-[0px] sm:mb-[8px] sm:text-[16px] sm:leading-[20px] sm:tracking-[0.4px] flex">
            Contact number
            <div className="font-[600] text-[16px] text-[#FF0000] sm:text-[16px] sm:leading-[20px] sm:tracking-[0.4px] ">
              *
            </div>
          </div>
          <div className="flex">
            <PhoneNumberInput
              id="[indicaid][/doctor/login][input-phonenumber]"
              style={{ width: '437px', height: '48px' }}
              value={phoneNumber}
              onChange={setPhoneNumber}
              onIsValidChange={handleIsPhoneNumberValidChange}
              placeholder={'Enter phone number'}
              helpText={phoneInputObj?.helpText}
              status={phoneInputObj?.status}
            />
            <BaseButton
              htmlType="submit"
              id="[indicaid][/doctor/login][btn-send-otp]"
              buttonClassName="w-full h-[50px] mt-[43px] mb-[36px] sm:w-[147px] sm:h-[48px] sm:text-[16px] sm:mt-[0px] sm:mb-[0px] sm:ml-[20px]"
              value={txt}
              disable={otpButtonStatus === 'disabled'}
              status={otpButtonStatus}
              onClick={onClickResendCode}
            ></BaseButton>
          </div>

          <Form.Item
            name="otp"
            rules={[
              {
                required: true,
                message: 'Please enter 4-digit OTP ',
              },
            ]}
          >
            <div
              className={classNames(
                'font-[600] text-[16px] mt-[39.9996px] mb-[7.9991px] sm:mt-[33px] sm:mb-[8px] sm:text-[16px] flex',
                'sm:text-[16px]',
                emailValue && emailValue.length > 0 ? 'text-[#10002E]' : 'text-[#A49FB1]',
              )}
            >
              One-time password*
            </div>
            <TextInput
              placeholder="Enter 4 digit OTP code"
              id="[indicaid][/doctor/login][input-otp]"
              status={status}
              helpText={helpText}
              value={emailValue || ''}
              onChange={emailInputChange}
              inputClassName={'h-[1.15rem] sm:text-[16px] sm:w-[437px] sm:h-[48px]'}
              helpTextStyle={{
                fontSize: '12px',
                lineHeight: '18px',
                fontFamily: 'Montserrat',
              }}
            ></TextInput>
          </Form.Item>
          <BaseButton
            htmlType="submit"
            id="[indicaid][/doctor/login][Login]"
            buttonClassName="w-full h-[50px] mt-[43px] mb-[36px] sm:w-[326px] sm:h-[50px] sm:text-[16px] sm:mt-[33px] sm:mb-[33px]"
            value={'Login'}
            disable={buttonStatus === 'disabled'}
            status={buttonStatus}
            loading={buttonLoading}
            onClick={clickLogin}
          ></BaseButton>
          <div className="flex">
            <div className="font-[400] text-[#000000] sm:text-[14px] sm:leading-[17px] font-[Montserrat]">
              Need help?&nbsp;
            </div>
            <div
              className="font-[400] text-[#2DBDB6] sm:text-[14px] sm:leading-[17px] underline cursor-pointer font-[Montserrat]"
              onClick={() => {
                navigate('/contactUs');
              }}
              data-clickable
              id="[indicaid][/doctor/login][Contact system administrator]"
            >
              Contact system administrator
            </div>
          </div>
          {/* {helpText && (
            <div className="flex flex-col bg-[#fdefee] sm:w-[437px] sm:h-[80px] sm:p-[9px_18px_15px] sm:border-[#FFCCC7] sm:border-solid sm:border-[1px] sm:rounded-[2px] sm:mt-[105px]">
              <div className="flex items-center">
                <img src={error} alt="error" className="object-cover sm:w-[28px]" />
                <div className="sm:ml-[10px] font-[400] sm:text-[16px] sm:leading-[24px] text-[#EB5757]">
                  Login unsuccessful
                </div>
              </div>
              <div className="sm:mt-[6px] font-[400] sm:text-[14px] sm:leading-[22px] text-[#EB5757] sm:ml-[36px]">
                {helpText}
              </div>
            </div>
          )} */}

          {/* <div className='h-[1.15rem] sm:text-[16px] sm:w-[437px] sm:h-[48px]'></div> */}
        </Form>
      </div>
      {/* 图片区域 */}
      <div className="absolute h-full">
        {/* src={loginBg} */}
        {/* src={loginBg} */}
        <img
          className="min-w-[100vw] min-h-[100vh] w-full h-full bg-cover"
          style={{
            // background: 'green',
            // backgroundImage: `url${loginBg}, linear-gradient(yellowgreen, yellowgreen)`,
            backgroundImage: `linear-gradient(97.23deg, rgba(0, 159, 175, 0.2) 7%, rgba(0, 0, 0, 0) 56.24%), url("${loginBg}")`,
          }}
        />
      </div>
    </div>
  );
};

export default DoctorLogin;
