export type PrescriptionsInDetail = {
  id: string;
  bookingId: string;
  doctorId: string;
  userId: string;
  diagnosis: string;
  duration: string;
  price: string;
  currency: number;
  remarks: string;
  status: number;
  prescriptionDrugs: [
    {
      id: string;
      drugId: string;
      times: number;
      units: string;
      duration: string;
      price: string;
      drug: { id: string; name: string; dosage: string; dosageUnit: number };
    },
  ];
};

export type ShipmentDetailsType = {
  recipientName?: string;
  contactNumber?: string;
  country?: string;
  city?: string;
  districtName?: string;
  line1?: string;
  line2?: string;
};

export type SingleScreening = {
  questionNo: number;
  questionDesc: string;
  answerDesc: string;
}[];

export type AppointmentDetail = {
  bookingId: string;
  status: number;
  patient: {
    userId: string;
    firstName: string;
    lastName: string;
    firstNameCn: string;
    lastNameCn: string;
    gender: number;
    dob: string;
    hkid: string;
    caseId: string;
    timeslotDate: string;
    timeslotStartTime: string;
    timeslotEndTime: string;
    consultType: number;
    meetingLink: string;
  };
  // TODO: screening的值，BE可能活返回null
  screening?: SingleScreening | null;
  prescriptions: PrescriptionsInDetail;
  remarks: { text: string; updatedAt: string };
  shipment?: ShipmentDetailsType;
  consultation?: string;
  fromProductType?: number | string;
  testResult?: number | string;
};

export enum PrescriptionStatus {
  Created = 0,
  Arrived = 1,
  WaitingForPayment = 2,
  Expired = 3,
  PaySuccess = 4,
}
