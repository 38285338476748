import { CloseOutlined } from '@ant-design/icons';
import { globalAlert, useAlertCloseFunc } from '../GlobalAlert';
import { useTranslation } from 'react-i18next';
import helthWaringImg from './assets/helthWaringImg.png';
import BaseButton from '@components/BaseButton/BaseButton';
import { useEffect } from 'react';
import { getDataTrackConstantId, track } from '@utils/dataTrack';
type HealthWarningProps = { onOk: () => void; pageIdName?: string };
const HealthWarning: React.FC<HealthWarningProps> = (props) => {
  const [t] = useTranslation();
  const closeFunc = useAlertCloseFunc();
  useEffect(() => {
    const tid = setTimeout(() => {
      track({
        event_type: 'view wish to proceed',
        event_properties: {
          'testing id': getDataTrackConstantId('TestingId'),
        },
      });
    }, 30);
    return () => {
      clearTimeout(tid);
    };
  }, []);

  return (
    <div className="lg:w-[1024px] w-full rounded-[30px] lg:rounded-[30px] bg-white">
      <div className="text-center relative">
        <img
          src={helthWaringImg}
          className="lg:h-[215px] lg:w-[215px] lg:mt-[32px] h-[150px] w-[150px] mt-[23px] inline-block"
          alt="health warning"
        />
        <p className="text-[#2DBDB6] lg:text-[28px] lg:leading-[42px] font-semibold text-[22px] leading-[32px]">
          {t('healthWatningAlert.title')}
        </p>
        <CloseOutlined
          className="cursor-pointer absolute lg:right-[41px] lg:top-[35px] text-[22px] top-[27px] right-[36px]"
          id={`${props.pageIdName}-health-warning__close`}
          onClick={closeFunc}
        />
      </div>
      <div
        className="
                      overflow-y-scroll custom-scroll-bar custom-scroll-bar-bg_white
                      lg:text-[16px] lg:leading-[28px] text-[14px] leading-[22px] lg:m-auto tracking-[.25px] text-[#000000]
                      lg:px-[122px] px-[30px] lg:mt-[25px] mt-[17px]
                    "
      >
        <p>
          {t('healthWatningAlert.text1')}

          <br />
          <br />

          {t('healthWatningAlert.text2')}
        </p>
      </div>
      <div className="text-center mt-[35px] lg:mt-[50px] pb-[30px] lg:pb-[30px]">
        <BaseButton
          id={`${props.pageIdName}-health-warning__button`}
          onClick={async () => {
            try {
              await track({
                event_type: 'click wish to proceed',
                event_properties: {
                  'testing id': getDataTrackConstantId('TestingId'),
                },
              }).promise;
            } catch {}

            props.onOk();
            closeFunc();
          }}
          buttonClassName="lg:w-[363px] lg:h-[56px] w-[366px] h-[50px] lg:text-[18px] text-[16px]"
          value={t('healthWatningAlert.btnText')}
        />
      </div>
    </div>
  );
};

export default function alertHealthWarning(options: { onOk: () => void; pageIdName?: string }) {
  return globalAlert({
    content: <HealthWarning onOk={options.onOk} pageIdName={options.pageIdName} />,
    wraperBackground: 'rgba(0, 0, 0, 0.7)',
  });
}
