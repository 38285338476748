import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useMemoizedFn } from 'ahooks';
import { useSearchParams } from 'react-router-dom';

import PersonalInformation from '@components/MyAccount/PersonalInformation';
import Appointments from '@components/MyAccount/Appointments';
import { MEDIA_SIZE } from '@consts/consts';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
// import { getPersonalInfos } from '@services/personalInfo';
import { getAppointmentsInfos } from '@services/appointmentsInfos';
import { get } from '@utils/request';
import { message } from 'antd';
import { useLocalNavigate } from 'src/router/routerTools';
import { useTranslation } from 'react-i18next';
import { AppointmentsInfo } from '@services/types/appointmentsInfos.type';
import DeleteAccountPage from '@components/MyAccount/DeleteAccountPage';

const style = {
  container: {
    background: '#FDFDFD',
  },
};

const MyAccount: React.FC = () => {
  const [t] = useTranslation();
  const navigate = useLocalNavigate();
  const mediaSize = useMediaQuery();
  const [params] = useSearchParams();
  const selectPage = params.getAll('selectPage')[0] || 'personalInformation';

  const [userInfo, setUserInfo] = useState<{
    firstName?: string;
    lastName?: string;
    dob?: string;
    email?: string;
    phone?: string;
  }>({
    firstName: '',
    lastName: '',
    dob: '',
    email: '',
  });

  const [appointmentsInfo, setAppointmentsInfo] = useState<AppointmentsInfo>();

  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;

  const renderHelpTextIcon = () => {
    if (!selectPage) {
      return null;
    }
    if (selectPage === 'personalInformation') {
      return <PersonalInformation userInfo={userInfo} />;
    } else if (selectPage === 'appointments') {
      return <Appointments appointmentsInfo={appointmentsInfo} />;
    } else if (selectPage === 'delAccount') {
      return <DeleteAccountPage />;
    } else {
      // TODO
      // TODO ①My Appointments Tab页面=>区分0和1
      // TODO ②Manage Payment页面=>这个页面还没做
      return <></>;
    }
  };

  const onSelect = useMemoizedFn((page) => {
    navigate(`/myAccount?selectPage=${page}`);
  });

  useEffect(() => {
    const getPersonalInfosFunction = async () => {
      try {
        const data = await get('/user/info');
        setUserInfo({
          ...data,
        });
      } catch (err: any) {
        const responseMessage = err?.response?.data?.message;
        const errorMessage = (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || 'error!';
        message.error(<span id="myAppointment__user-me-error">{errorMessage}</span>);
      }
    };

    const getAppointmentsInfosFunction = async () => {
      try {
        const data = await getAppointmentsInfos();
        console.log(data);
        setAppointmentsInfo(data);
      } catch (err: any) {
        const responseMessage = err?.response?.data?.message;
        const errorMessage = (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || 'error!';
        message.error(<span id="myAppointment__booking-error">{errorMessage}</span>);
      }
    };

    if (selectPage === 'personalInformation') {
      getPersonalInfosFunction();
    } else if (selectPage === 'appointments') {
      getAppointmentsInfosFunction();
    }
  }, [selectPage]);

  return (
    <div
      className="w-full h-full flex  bg-opacity-[0.8] flex-col py-[0] px-[24px] mt-[7px] md:px-[32px] md:items-center lg:px-[0px] lg:items-center min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)]"
      style={style.container}
    >
      <div className="flex flex-col justify-start w-full lg:flex-row lg:w-[84.7%] lg:justify-center">
        <div className="flex flex-col justify-start w-full lg:flex-row">
          {/* 侧边栏 */}
          {isLg && (
            <div className="flex flex-col lg:mt-[80px] lg:w-[266px]">
              <div className="w-[266px]"></div>
              <div className="font-[600] lg:text-[22px] lg:leading-[36px] text-[#2DBDB6]">{t('MyAccount')}</div>
              <div
                className="lg:mt-[17px] lg:mb-[20px] cursor-pointer"
                onClick={() => {
                  onSelect('personalInformation');
                }}
              >
                <span
                  className={classNames(
                    selectPage === 'personalInformation' && 'bg-[#D2D2D2] bg-opacity-[0.3]',
                    ' lg:pl-[12px] lg:pr-[38px] lg:py-[10px] lg:rounded-[5px] text-[#212121] lg:text-[16px] font-[500] relative lg:left-[-12px]',
                  )}
                >
                  {t('PersonalInformation')}
                </span>
              </div>
              <div
                className="lg:mt-[10px] lg:mb-[32px] cursor-pointer"
                onClick={() => {
                  onSelect('appointments');
                }}
              >
                <span
                  className={classNames(
                    selectPage === 'appointments' && 'bg-[#D2D2D2] bg-opacity-[0.3]',
                    ' lg:pl-[12px] lg:pr-[91px] lg:py-[10px] lg:rounded-[5px] text-[#212121] lg:text-[16px] font-[500] relative lg:left-[-12px]',
                  )}
                >
                  {t('Appointments')}
                </span>
              </div>
            </div>
          )}
          {isLg && <div className="lg:h-full  lg:border-[1px] border-[solid] border-[#E2E2E2] lg:mr-[74px]"></div>}
          {renderHelpTextIcon()}
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
