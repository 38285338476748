import { Checkbox } from 'antd';
import { ButtonHTMLType } from 'antd/es/button';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import './BaseGrayCheckbox.css';
// const bgColor = {
//   default: '#2DBDB6',
//   disabled: '#F5F5F5',
// };
// const textColor = {
//   default: '#ffffff',
//   disabled: 'rgba(0, 0, 0, 0.25)',
// };
export type ButtonStatus = 'default' | 'disabled' | undefined;
type BaseCheckboxProps = {
  checked?: boolean;
  onChange?: (e: CheckboxChangeEvent) => void;
  status?: ButtonStatus;
  placeholder?: string;
  disable?: boolean;
  helpText?: string;
  loading?: boolean;
  htmlType?: ButtonHTMLType;
  className?: string;
};

export default function BaseGrayCheckbox(props: BaseCheckboxProps) {
  return (
    <Checkbox
      prefixCls={'custom-base-gray-checkbox'}
      className={props.className}
      onChange={props.onChange}
      checked={props.checked}
    ></Checkbox>
  );
}
