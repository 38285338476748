/* eslint-disable @typescript-eslint/indent */
import { useEffect, useState } from 'react';
import { useMemoizedFn } from 'ahooks';
import { message } from 'antd';

import failure from '../../images/failure.svg';
import BaseButton from '@components/BaseButton/BaseButton';
import { handleLocalStorage } from '@utils/index';
import website from '@configs/website';
import { post } from '@utils/request';
import { useLocalNavigate } from 'src/router/routerTools';
import { useTranslation } from 'react-i18next';
import { useSelectResultType } from 'src/hooks/tools/useSelectResultType';

const style = {
  container: {
    background: '#FDFDFD',
  },
};

const PaymentFailure: React.FC = () => {
  const [t, i18n] = useTranslation();
  const [errorObj, setErrorObj] = useState<any>({});
  const { isHaveSelectResultType, selectResultType } = useSelectResultType();

  const navigate = useLocalNavigate();
  const onClick = useMemoizedFn(async () => {
    navigate(`/payments${isHaveSelectResultType ? `?selectResultType=${selectResultType}` : ''}`);
  });

  useEffect(() => {
    setErrorObj(JSON.parse(handleLocalStorage.get(website.payment_error)));
  }, []);

  useEffect(() => {
    const callbackBeFunction = async () => {
      const clientSecret = errorObj.payment_intent?.client_secret;
      const paymentIntentId = errorObj.payment_intent?.id;
      if (!(clientSecret && paymentIntentId)) {
        return;
      }

      try {
        await post('/care/booking/callback', {
          clientSecret,
          paymentIntentId,
        });
      } catch (err: any) {
        const responseMessage = err?.response?.data?.message;
        const errorMessage = (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || 'error!';
        message.error(errorMessage);
      }
    };

    callbackBeFunction();
  }, [errorObj]);

  const hideBtnBack = errorObj?.type == 'custom_expire_medication';

  let msg = '';
  if (errorObj?.type == 'custom_expire_medication') {
    msg = t('PaymentFailure.onlinePaymentErrorCodes.custom_expire_medication');
  } else {
    msg = t('PaymentFailure.failureSorryText', {
      reason: i18n.exists('PaymentFailure.onlinePaymentErrorCodes.' + errorObj?.type)
        ? t('PaymentFailure.onlinePaymentErrorCodes.' + errorObj?.type)
        : errorObj?.message,
    });
  }
  return (
    // 移动端的items-center可能要被移除
    <div
      className="w-full h-full flex items-center bg-opacity-[0.8] flex-col py-[0] px-[24px] md:px-[32px] md:items-center lg:px-[0px] lg:items-center min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)]"
      style={style.container}
    >
      <div>
        <img
          src={failure}
          alt="failure"
          className="object-cover w-[60px] mt-[30px] mb-[32px] lg:mb-[32px] lg:w-[60px] lg:mt-[85px]"
        />
      </div>
      <div className="font-[600] mb-[20px] text-[32px] leading-[42px] text-[#FF4D4F] lg:text-[32px] lg:leading-[42px] lg:mb-[52px]">
        {t('PaymentPage.PaymentUnSuccessful')}
      </div>
      <div className="font-[400] text-[18px] leading-[28px] text-[#212121] mb-[50px] tracking-[0.25px] lg:text-[18px] lg:leading-[28px] lg:mb-[83px] lg:w-[733px] text-center lg:tracking-[0.25px]">
        {msg}
      </div>
      {!hideBtnBack && (
        <BaseButton
          htmlType="submit"
          buttonClassName="w-full h-[50px] mb-[44px] md:w-[326px] md:h-[50px] md:text-[16px] md:mb-[44px]"
          value={t('PaymentFailure.GoBackAndRetry')}
          status={'default'}
          onClick={onClick}
          id="paymentFailure__goBackAndRetry-button"
        ></BaseButton>
      )}
      <div
        className="flex font-[400] text-[16px] leading-[28px] text-[#212121] mb-[119px] tracking-[0.25px] lg:text-[16px] lg:leading-[28px] lg:mb-[119px] text-center lg:tracking-[0.25px] cursor-pointer"
        onClick={() => {
          navigate('/faq');
        }}
        id="paymentFailure__goToFaq-button"
      >
        <div>Questions? Go to </div>
        &nbsp;
        <div className="text-[#2DBDB6]">FAQ</div>
      </div>
    </div>
  );
};

export default PaymentFailure;
