import dayjs from 'dayjs';
import { useContext } from 'react';
import MeetingInfosContext from '../context/MeetingInfosContext';
import Footer from '@components/Footer/Footer';

export default function EarlyReminder() {
  const meetingInfos = useContext(MeetingInfosContext);
  return (
    <>
      <div className="w-full h-[calc(100vh-128px-300px)] lg:h-[calc(100vh-79px)] flex justify-center lg:items-center">
        <div
          className="lg:w-[917px] lg:h-[519px] h-[385px] w-[366px] lg:pt-[72px] pt-[40px] pb-[40px] lg:pb-0 px-[17px] lg:px-[77px]"
          style={{
            boxShadow: '0px 10px 30px rgba(171, 190, 209, 0.2)',
          }}
        >
          <div className="text-center font-semibold lg:text-[28px] lg:leading-[36px] leading-[36px] text-[22px]">
            <p className="tracking-[0.5px] leading-[28px]">INDICAID Care Telemedicine Service</p>
            <p>遠程醫療服務</p>
          </div>
          <div className="text-left lg:tracking-[0.28px] lg:text-[22px] lg:leading-[30px] text-[14px] tracking-[0.25px] leading-[22px] mt-[29px] lg:mt-[65px]">
            <p>You have successfully made an appointment.</p>
            <p className="lg:mt-[10px]">
              The online consultation will be ready for you on{' '}
              <span className="text-[#2DBDB6]">
                {dayjs(meetingInfos?.meeting.appointmentTime).format('DD/MM/YYYY')}{' '}
              </span>{' '}
              at{' '}
              <span className="text-[#2DBDB6]">{dayjs(meetingInfos?.meeting.appointmentTime).format('hh:mm a')}</span>.
              Please come back later.
            </p>
            <p className="lg:mt-[52px] mt-[20px]">您成功預約了醫生咨詢。</p>
            <p className="lg:mt-[10px]">
              遠程會診將會在{' '}
              <span className="text-[#2DBDB6]">
                {dayjs(meetingInfos?.meeting.appointmentTime).format('DD/MM/YYYY')}{' '}
              </span>{' '}
              的{' '}
              <span className="text-[#2DBDB6]">{dayjs(meetingInfos?.meeting.appointmentTime).format('hh:mm a')}</span>{' '}
              開始。請稍後再回來。
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
