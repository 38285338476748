import EventBase from '@utils/EventBase';
import { RtcEvents } from './types.rtc';

export default abstract class RtcAdapterBase extends EventBase<RtcEvents, keyof RtcEvents> {
  public localRtcUid: string = '';
  public channelId: string | undefined = undefined;
  public abstract localVideoEnable: boolean;
  public abstract localAudioEnable: boolean;
  public abstract join(channelId: string, rtcUid: string): Promise<void>;
  public abstract leave(): Promise<void>;
  public abstract toogleVideo(enable: boolean): Promise<void>;
  public abstract toogleAudio(enable: boolean): Promise<void>;
  public abstract toogleVideoPublish(enable: boolean): Promise<void>;
  public abstract toogleAudioPublish(enable: boolean): Promise<void>;
  public abstract setVolume(rtcUid: string, level: number): void;
  public abstract setAllVolume(level: number): void;

  /**
   * 开始播放视频流
   * @param container 播放的容器
   * @param rtcUid 视频流的uid
   * @return 返回一个函数用阿里取消视频流的播放
   */
  public abstract playVideo(container: HTMLElement, rtcUid: string): () => void;
  public abstract playLocalVideo(container: HTMLElement): void;
  public abstract switchCamera(deviceId: string): void;
  public abstract switchMicrophone(deviceId: string): void;
  public abstract switchSpeaker(deviceId: string): void;

  public abstract on(eventName: 'video-stoped', cb: RtcEvents['video-stoped']): () => void;
  public abstract on(eventName: 'audio-stoped', cb: RtcEvents['audio-stoped']): () => void;
  public abstract on(eventName: 'volumes-changed', cb: RtcEvents['volumes-changed']): () => void;
  public abstract on(eventName: 'user-joined', cb: RtcEvents['user-joined']): () => void;
  public abstract on(eventName: 'user-left', cb: RtcEvents['user-left']): () => void;
  public abstract on(eventName: 'local-user-joined', cb: RtcEvents['local-user-joined']): () => void;
  public abstract on(eventName: 'local-user-left', cb: RtcEvents['local-user-left']): () => void;
  public abstract on(eventName: 'networks-changed', cb: RtcEvents['networks-changed']): () => void;
  public abstract on(eventName: 'media-device-changed', cb: RtcEvents['media-device-changed']): () => void;
  public abstract on(eventName: 'local-audio-status-changed', cb: RtcEvents['local-audio-status-changed']): () => void;
  public abstract on(eventName: 'local-video-status-changed', cb: RtcEvents['local-video-status-changed']): () => void;
  public abstract on(eventName: 'connect-status-change', cb: RtcEvents['connect-status-change']): () => void;
  public abstract getCameraList(): Promise<MediaDeviceInfo[]>;
  public abstract getMicrophoneList(): Promise<MediaDeviceInfo[]>;
  public abstract getSpeakerList(): Promise<MediaDeviceInfo[]>;
  public abstract getRemoteRtcUidList(): string[];
  public abstract getRemoteVideoStatus(rtcUid: string): boolean;
  public abstract getRemoteAudioStatus(rtcUid: string): boolean;
}
