import BaseButton from '@components/BaseButton/BaseButton';
import { MEDIA_SIZE } from '@consts/consts';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { handleLocalStorage } from '@utils/index';
import website from '@configs/website';
import { ConsultationMethod } from '@consts/enums';
import { useTranslation } from 'react-i18next';
import { useLocalNavigate } from 'src/router/routerTools';
import { checkTimeslotAvailable } from '@services/timeslot';
import { notification } from 'antd';
import { getDataTrackConstantId, track } from '@utils/dataTrack';
import { useSelectResultType } from 'src/hooks/tools/useSelectResultType';

type AppointmentTimeItemProps = {
  timeText: string;
  onlineDisable: boolean | undefined;
  offlineDisable: boolean | undefined;
  t: string;
  selectedDate: string;
  // timeData?: {
  //   online?: boolean;
  //   offline?: boolean;
  // };
  timeStatus: 'morning' | 'afternoon' | 'evening';
};

const onlineBtnStyle = {
  backgroundColor: '#2DBDB6',
  color: '#ffffff',
};
const offlineBtnStyle = {
  backgroundColor: '#008B86',
  color: '#ffffff',
};
const disabledStyle = {
  backgroundColor: '#f5f5f5',
  color: '#00000025',
  border: '1px solid #d9d9d9',
  // display: 'none',
};
export default function AppointmentTimeItem(props: AppointmentTimeItemProps) {
  const { isHaveSelectResultType, selectResultType } = useSelectResultType();
  // const { timeData } = props;
  // const onlineHide = timeData?.online === false;
  // const offlineHide = timeData?.offline === false;
  const [t] = useTranslation();
  const [notificationApi, contextHolder] = notification.useNotification();

  const mediaSize = useMediaQuery();
  const onLineText = mediaSize === MEDIA_SIZE.LG_SIZE ? t('OnlineConsultation') : t('Online');
  const navigate = useLocalNavigate();
  const onClickButton = async (val: string) => {
    track({
      event_type: 'select time slot',
      event_properties: {
        'booking id': getDataTrackConstantId('BookingId'),
        'selected date': props.selectedDate,
        'selected timeslot': props.timeStatus,
        'selected time': props.timeText,
        'selected method': val,
      },
    });
    try {
      await checkTimeslotAvailable({
        timeslotId: props.t,
        timeslotDate: props.selectedDate,
      });
    } catch (e: any) {
      notificationApi.error({
        message: (
          <>
            <p>{e?.response?.data?.message ?? ''}</p>
          </>
        ),
        placement: 'bottomRight',
      });
      return;
    }

    handleLocalStorage.set(website.step_timeslot_date, props?.selectedDate);
    handleLocalStorage.set(
      website.step_consult_type,
      val === 'Online Consultation' ? ConsultationMethod.Online : ConsultationMethod.Offline,
    );
    handleLocalStorage.set(website.step_timeslot_id, props?.t);
    navigate(`/patientDoctorList${isHaveSelectResultType ? `?selectResultType=${selectResultType}` : ''}`);
  };
  {
    /*展示的逻辑
    ①如果为undefined，则隐藏，
    ②true 展示， false  disable掉 */
  }
  const showOnlineButton = typeof props.onlineDisable === 'boolean';
  const showOfflineButton = typeof props.offlineDisable === 'boolean';
  return (
    <div className="appointment-time-item lg:flex items-center lg:ml-[26px] lg:my-[13px] my-[13px]">
      {contextHolder}
      <p className="lg:mr-[54px] text-[#212121] lg:text-[18px] text-[16px] ml-[19px] font-medium lg:w-[90px] mt-[12px] mb-[6px] lg:mb-0">
        {props.timeText}
      </p>
      {/* undefined隐藏，  true 展示， false  disable */}
      <div className="flex ml-[19px] text-[14px]">
        {showOnlineButton && (
          <BaseButton
            style={!props.onlineDisable ? onlineBtnStyle : disabledStyle}
            value={props.onlineDisable ? t('Unavailable') : onLineText}
            disable={props.onlineDisable}
            status={props.onlineDisable ? 'disabled' : undefined}
            buttonClassName="lg:w-[187px] lg:h-[37px] lg:mr-[31px] w-[156px] h-[37px] ml-0"
            onClick={() => {
              onClickButton('Online Consultation');
            }}
            id={`selectTimeSlot__time-online-${props.timeText}`}
          />
        )}
        {showOfflineButton && (
          <BaseButton
            style={!props.offlineDisable ? offlineBtnStyle : disabledStyle}
            value={props.offlineDisable ? t('Unavailable') : t('In-ClinicVisit')}
            disable={props.offlineDisable}
            status={props.offlineDisable ? 'disabled' : undefined}
            buttonClassName="lg:w-[187px] lg:h-[37px] w-[156px] h-[37px] ml-[19px]"
            onClick={() => {
              onClickButton('In-Clinic Visit');
            }}
            id={`selectTimeSlot__time-offline-${props.timeText}`}
          />
        )}
      </div>
    </div>
  );
}
