import { useState } from 'react';
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
  AddressElement,
} from '@stripe/react-stripe-js';

import { handleLocalStorage } from '@utils/index';
import website from '@configs/website';
import { ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { MEDIA_SIZE } from '@consts/consts';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { getDataTrackConstantId, track } from '@utils/dataTrack';
import { PaymentData } from '../Payments';
import { ProductType } from '@consts/enums';
import { useIsHkLanguage } from 'src/hooks/tools/useIsHkLanguage';
import { useSelectResultType } from 'src/hooks/tools/useSelectResultType';
// import { Input } from 'antd';
// import { MailOutlined } from '@ant-design/icons';
// import PhoneNumberInput from '@components/PhoneNumberInput/PhoneNumberInput';
export type PaymentForm = {
  renderReminders: () => any;
  paymentData: PaymentData;
};

export default function CheckoutForm(props: PaymentForm) {
  const isHkLanguage = useIsHkLanguage();
  const [t] = useTranslation();
  const mediaSize = useMediaQuery();
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;
  const { renderReminders } = props;

  const stripe = useStripe();

  const elements = useElements();

  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { isHaveSelectResultType, selectResultType } = useSelectResultType();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    //获取当前网址，如： http://localhost:80/jflow-web/index.jsp
    const curPath = window.document.location.href;
    const pathName = window.document.location.pathname;
    const pos = curPath.indexOf(pathName);
    const localhostPaht = curPath.substring(0, pos);
    track({
      event_type: 'click pay now',
      event_properties: {
        'booking id': getDataTrackConstantId('BookingId'),
      },
    });
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // TODO:returnUrl这里感觉会出现问题=>需要跟liber确认一下
        // liber那边做了问号的处理，不用担心
        return_url: isHkLanguage
          ? `${localhostPaht}/paymentStatus${isHaveSelectResultType ? `?selectResultType=${selectResultType}` : ''}`
          : `${localhostPaht}/en/paymentStatus${isHaveSelectResultType ? `?selectResultType=${selectResultType}` : ''}`,
      },
    });

    if (!error) {
      // 成功的逻辑在这里面
      return;
    }

    handleLocalStorage.set(website.payment_error, JSON.stringify(error));
    // navigate('/paymentFailure');

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.code == 'incomplete_address') {
      setMessage(t('PaymentPage.AddressIncomplete') as string);
    } else {
      setMessage(error.message || (t('PaymentPage.Unknown') as string));
    }

    track({
      event_type: 'encounter error',
      event_properties: {
        'error type': error.type,
        'error code': error.code,
        'error name': error.message || 'unknown error',
      },
    });
    setIsLoading(false);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const shippingMode = props.paymentData.productType == ProductType.OnlineConsultationAndMedication;
  return (
    <form id="payment-form" className="lg:p-[40px] p-[20px] pb-[48px]" onSubmit={handleSubmit}>
      {/* <Input size="large" className="outline-0" placeholder="email" prefix={<MailOutlined />} />
      <PhoneNumberInput /> */}
      <div style={{ fontSize: 18, fontWeight: 600, color: '#1A1F36', marginBottom: 24 }}>
        {t('PaymentPage.PaymentDetail')}
      </div>
      <LinkAuthenticationElement id="indicaid-payment-form-email" />
      <PaymentElement
        id="indicaid-payment-form-payment"
        options={{
          layout: 'tabs',
          defaultValues: {
            billingDetails: {
              address: {
                country: 'HK',
              },
            },
          },
        }}
      />
      {shippingMode && (
        <div style={{ fontSize: 18, fontWeight: 600, color: '#1A1F36', marginBottom: 24, marginTop: 41 }}>
          {t('PaymentPage.ShippingAddress')}
        </div>
      )}
      <AddressElement
        id="indicaid-payment-form-addr"
        options={{
          mode: shippingMode ? 'shipping' : 'billing',
          allowedCountries: ['HK'],
          // defaultValues: {
          //   address: {
          //     country: 'HK',
          //   },
          // },
        }}
      />
      {!isLg && renderReminders && renderReminders()}

      <button
        className="submit-payments mt-[20px] w-full h-[50px] lg:w-[100%] lg:h-[50px]"
        disabled={isLoading || !stripe || !elements}
        id="indicaid-payment-form-submit"
        style={{
          width: isLg ? '100%' : '100%',
        }}
      >
        <span id="button-text">{isLoading ? antIcon : t('PaymentPage.PayNow')}</span>
      </button>
      {message && (
        <div className="text-left flex" id="payment-message" style={{ color: '#C83532', gap: 5 }}>
          <ExclamationCircleFilled className={'text-[16px] sm:text-[16px]'} />
          {message}
        </div>
      )}
    </form>
  );
}
