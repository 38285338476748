import InputOtp, { InputOptRef } from '@components/InputOpt/InputOpt';

import { useState, useEffect, useRef, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMemoizedFn } from 'ahooks';

import BaseButton, { ButtonStatus } from '@components/BaseButton/BaseButton';
import { useLSCountDown as useCountDown } from 'src/hooks/tools/countDown';
import { VERIFY_CODE_WAITING_SECONDS } from '@consts/consts';
import { handleLocalStorage } from '@utils/index';
import { useMediaQuery } from 'src/hooks/tools/useMediaQuery';
import { MEDIA_SIZE } from '@consts/consts';
import { changeToStar } from '@utils/index';
import { post } from '@utils/request';
import { useTranslation } from 'react-i18next';
import { useLocalNavigate } from 'src/router/routerTools';
import { clearDataTrackConstantId, getDataTrackConstantId, track } from '@utils/dataTrack';
import dayjs from 'dayjs';
import { patientTokenStore } from '@utils/localStore/tokenStore';
import userService from '@services/userService';

const Verify: React.FC = () => {
  const [t] = useTranslation();
  const childRef = useRef<InputOptRef>(null);
  const redirect = useSearchParams()[0].get('redirect');

  const [buttonStatus, setButtonStatus] = useState<ButtonStatus>('disabled');
  const [userObj, setUserObj] = useState<{
    email: string | null;
    phoneNumber: string | null;
    method: string | null;
    fromPage: string | null;
  }>({
    email: '',
    phoneNumber: '',
    method: '',
    fromPage: '',
  });
  const [verificationCode, setVerificationCode] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useLocalNavigate();
  const identify = useMemo(() => {
    return userObj.method === 'email' ? userObj.email : userObj.phoneNumber;
  }, [userObj]);
  useEffect(() => {
    if (!identify) {
      return clearTimeout.bind(
        null,
        setTimeout(() => {
          navigate('/login');
        }, 500),
      );
    }
  }, [identify, navigate]);

  const timerId = identify ? 'canResendAfter:' + identify : '';
  const { count, startCount: sendCode } = useCountDown(VERIFY_CODE_WAITING_SECONDS, timerId);
  const [verificationError, setVerificationError] = useState(false);
  const [otpErrorText, setOtpErrorText] = useState<string | null>(null);

  const mediaSize = useMediaQuery();
  const isMd = mediaSize === MEDIA_SIZE.MD_SIZE;
  const isLg = mediaSize === MEDIA_SIZE.LG_SIZE;

  const onInputOptValueChange = useMemoizedFn(async (val: string) => {
    setVerificationCode(val);
    if (val.length < 4) {
      setButtonStatus('disabled');
    }
    if (val.length === 4) {
      setButtonStatus('default');

      try {
        setButtonLoading(true);
        const method = userObj?.method;

        let params;
        if (method === 'email') {
          params = {
            email: userObj?.email,
            code: val,
          };
        } else {
          params = {
            phone: userObj?.phoneNumber,
            code: val,
          };
        }

        // TODO 需要再进行测试
        const data = await post('/user/otp', { ...params, autoRegister: true });
        try {
          await track({
            event_type: 'submit verification code',
            user_properties: {
              // 'account creation date': dayjs().format('YYYY-MM-DD'),
              'valid status': 'sucessed',
            },
            event_properties: {
              'valid status': 'sucessed',
              'sign id': getDataTrackConstantId('signId'),
            },
          }).promise;
        } catch {}

        clearDataTrackConstantId('signId');

        patientTokenStore.set(data.token);

        await userService.getUseInfos();

        // 点击按钮
        setButtonLoading(false);
        setTimeout(() => {
          setButtonStatus('success');
        }, 500);
        setTimeout(() => {
          if (redirect) {
            window.location.href = decodeURIComponent(redirect);
          } else {
            navigate('/product-listing');
          }
        }, 1000);
      } catch (err: any) {
        const responseMessage = err?.response?.data?.message;
        const errcode = err?.response?.data?.code;
        const errorMessage = (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || 'error!';
        if (errcode == 10012) {
          setButtonStatus('disabled');
        }
        setVerificationError(true);
        setOtpErrorText(errorMessage);
        setButtonLoading(false);
        setButtonStatus('disabled');
        track({
          event_type: 'submit verification code',
          user_properties: {
            'account creation date': dayjs().format('YYYY-MM-DD'),
            'valid status': 'failed',
          },
          event_properties: {
            'valid status': 'failed',
            'sign id': getDataTrackConstantId('signId'),
          },
        });
      }
    }
  });

  const onClickResendCode = useMemoizedFn(async () => {
    if (count !== 0) {
      return;
    }
    track({
      event_type: 'resend code',
      event_properties: {
        'sign id': getDataTrackConstantId('signId'),
      },
    });
    sendCode();
    childRef.current && childRef.current.reset();
    setVerificationCode('');
    // TODO 向Liber那边发生验证码
    try {
      let params;
      if (userObj?.method === 'email') {
        params = {
          email: userObj?.email,
        };
      } else {
        params = {
          phone: userObj?.phoneNumber,
        };
      }
      await post('/user/send-otp', params);
    } catch (err: any) {
      const responseMessage = err?.response?.data?.message;
      const errcode = err?.response?.data?.code;
      const errorMessage = (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || 'error!';
      if (errcode == 10012) {
        setButtonStatus('disabled');
      }
      setVerificationError(true);
      setOtpErrorText(errorMessage);
    }
  });

  useEffect(() => {
    // TODO: 逻辑更新
    const newEmail = handleLocalStorage.get('email');
    const newPhoneNumber = handleLocalStorage.get('phoneNumber');
    const newMethod = handleLocalStorage.get('method');
    const newFromPage = handleLocalStorage.get('email');
    setUserObj({
      email: newEmail,
      phoneNumber: newPhoneNumber,
      method: newMethod,
      fromPage: newFromPage,
    });
  }, []);

  return (
    <div
      className="w-full flex  bg-opacity-[0.8] flex-col py-[0] px-[24px] md:px-[32px] md:items-center min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)]"
      style={{
        background: '#FDFDFD',
      }}
    >
      <div>
        <p className="text-[22px] text-[#1E1F20] ml-[0px] font-[600] md:text-[32px] md:mt-[40px]">
          {t('verifyPage.EnterVerificationCode')}
        </p>
        <p className="text-[16px] text-[#4B4C4D] m-auto mt-[31px] mb-[41px] font-[400] md:text-[16px] md:mt-[26px] md:mb-[41px]">
          {t('verifyPage.verifyIntro', {
            phoneOrEmail: changeToStar(userObj.method === 'email' ? userObj?.email : userObj?.phoneNumber, 3, 4),
          })}
        </p>
        <section className="mb-[51px] md:mb-[52px]">
          <p
            className="font-semibold text-[14px]
        mb-[8px] md:text-[14px] md:mb-[8px] leading-[20px] tracking-[0.4px]  md:leading-[20px] md:tracking-[0.4px]"
          >
            {t('verifyPage.VerificationCode')}
          </p>
          <InputOtp
            ref={childRef}
            onValueChange={onInputOptValueChange}
            InputOtpClassName={
              'md:w-[500px] md:h-[48px] md:border-[1px] md:rounded-[8px] lg:w-[631px] lg:justify-start'
            }
            isError={verificationError}
            errorText={otpErrorText}
            idPrefix="verify__"
            errorId="verify__otp-error"
          />
        </section>
        {/* TODO 添加loading状态 */}
        <BaseButton
          loading={buttonLoading}
          buttonClassName="w-full text-[16px] font-[500] md:w-[326px] md:h-[50px]"
          value={t('verifyPage.Continue')}
          disable={buttonStatus === 'disabled'}
          status={buttonStatus}
          style={{
            height: isMd || isLg ? '50px' : '1.2195rem',
          }}
          onClick={() => onInputOptValueChange(verificationCode)}
          id="verify__submit"
        ></BaseButton>
        <p
          className="text-[#2DBDB6] text-center text-[16px] mt-[41px] font-[500] mb-[41px] md:text-[16px] md:mt-[34px] md:text-left cursor-pointer"
          style={{
            color: count === 0 ? '#2DBDB6' : '#BABABA',
          }}
          onClick={onClickResendCode}
          id="verify__resend-code"
        >
          {t('verifyPage.ResendCode', { countSecond: count === 0 ? '' : count + 's' })}
        </p>
        {/* <p className="mt-[5.748rem] text-center text-[0.386rem]">
          <span className="text-[#2DBDB6]">Back</span> to previous page
        </p> */}
      </div>
    </div>
  );
};

export default Verify;
