import Icon, { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

export default function HospitalIcon(props: Partial<CustomIconComponentProps> & { scale?: number; color?: string }) {
  const HospitalIconSvg = () => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 47779">
        <g id="Union">
          <path
            d="M6.81869 1.07898C6.19645 1.07898 5.69202 1.58341 5.69202 2.20565V4.51004C5.69202 5.13228 6.19645 5.6367 6.81869 5.6367H19.1813C19.8035 5.6367 20.3079 5.13228 20.3079 4.51004V2.20565C20.3079 1.58341 19.8035 1.07898 19.1813 1.07898H6.81869Z"
            fill="#2DBDB6"
          />
          <path
            d="M3.92139 9.37518C3.62103 9.80969 3.46014 10.3254 3.46014 10.8536V22.321C3.46014 23.7569 4.6242 24.921 6.06014 24.921H19.9398C21.3758 24.921 22.5398 23.7569 22.5398 22.321V10.8667C22.5398 10.3304 22.3739 9.80712 22.0649 9.36873L20.8827 7.6916C20.558 7.23094 20.0296 6.95692 19.466 6.95692H6.29612C5.85582 6.95692 5.44358 7.1731 5.19321 7.5353L3.92139 9.37518Z"
            fill="#2DBDB6"
          />
        </g>
        <path
          id="Vector"
          d="M7.79999 14.8321C7.79999 14.7216 7.88953 14.6321 7.99999 14.6321H18C18.1104 14.6321 18.2 14.7216 18.2 14.8321V16.6854C18.2 16.7959 18.1104 16.8854 18 16.8854H7.99999C7.88953 16.8854 7.79999 16.7959 7.79999 16.6854V14.8321Z"
          fill="white"
        />
        <path
          id="Vector_2"
          d="M13.9261 10.559C14.0365 10.559 14.1261 10.6485 14.1261 10.759V20.759C14.1261 20.8694 14.0365 20.959 13.9261 20.959H12.074C11.9635 20.959 11.874 20.8694 11.874 20.759V10.759C11.874 10.6485 11.9635 10.559 12.074 10.559H13.9261Z"
          fill="white"
        />
      </g>
    </svg>
  );
  return (
    <Icon
      style={{
        transform: `scale(${props.scale ?? 1})`,
      }}
      component={HospitalIconSvg}
      {...props}
    />
  );
}
