import './App.css';
import { Outlet } from 'react-router-dom';
import Header from '@components/Header/Header';
import Footer from '@components/Footer/Footer';
import { useRouterChangeAutoScrollTop } from './hooks/tools/RouterAutoScrollTop';
import { ConfigProvider } from 'antd';

function App() {
  useRouterChangeAutoScrollTop();
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#2DBDB6',
          fontFamily:
            "'Montserrat', -apple-system, blinkmacsystemfont, 'Helvetica Neue', helvetica, 'segoe ui', arial, roboto,'PingFang SC', miui, 'Hiragino Sans GB', 'Microsoft Yahei', sans-serif",
        },
        components: {
          Input: {
            lineWidth: 2,
          },
          Button: {
            colorPrimary: '#2DBDB6',
          },
          Radio: {
            colorPrimary: '#2DBDB6',
          },
          Checkbox: {
            colorPrimary: '#2DBDB6',
            borderRadius: 50,
            colorIconHover: '#2DBDB6',
            colorPrimaryBgHover: '#2DBDB6',
          },
          Calendar: {
            colorPrimary: '#2DBDB6',
          },
        },
      }}
    >
      <div className="App">
        <Header />
        <div className="min-h-[calc(var(--vh,100vh)-415px-128px)] lg:min-h-[calc(var(--vh,100vh)-318px-79px)]">
          <Outlet />
        </div>
        <Footer />
      </div>
    </ConfigProvider>
  );
}

export default App;
