import EventsMap from '@utils/EventsMap';
import { useEffect, useState } from 'react';
import RtcAdapter from '../RtcTool';

const NetworkQualityEventsMap = new EventsMap();

export function useNetworkQualityListen() {
  useEffect(() => {
    return RtcAdapter.on('networks-changed', (rtcUid, networkStatus) => {
      NetworkQualityEventsMap.triggerEventMap(rtcUid, networkStatus);
    });
  }, []);
}
/**
 * The network quality.
 *
 * It is calculated based on the uplink transmission bitrate, uplink packet loss rate, RTT (round-trip time) and jitter.
 *
 * - 0: The quality is unknown.
 * - 1: The quality is excellent.
 * - 2: The quality is good, but the bitrate is less than optimal.
 * - 3: Users experience slightly impaired communication.
 * - 4: Users can communicate with each other, but not very smoothly.
 * - 5: The quality is so poor that users can barely communicate.
 * - 6: The network is disconnected and users cannot communicate.
 * @param rtcUid rtcUid
 * @param cb
 */
export default function useNetworkQuality(rtcUid: string) {
  const [networkQuality, setNetworkQuality] = useState(0);
  useEffect(() => {
    return NetworkQualityEventsMap.addEventMapListener(rtcUid, setNetworkQuality);
  }, [rtcUid]);
  return networkQuality;
}
