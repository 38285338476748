/* eslint-disable @typescript-eslint/indent */
import React, { useEffect, useState } from 'react';
import { get } from '@utils/request';
import { message } from 'antd';

import AppointmentsTable from './components/AppointmentsTable';
import SearchBar from './components/SearchBar';
import { SimpleBookingStatus } from '@consts/enums';
import { PAGE_SIZE } from '@consts/consts';
import dayjs from 'dayjs';

const style = {
  container: {
    background: '#f5f7fa',
  },
};

const selectType = {
  Upcoming: SimpleBookingStatus.Upcoming,
  OnGoing: SimpleBookingStatus.Ongoing,
  Completed: SimpleBookingStatus.Completed,
  All: null,
};

const Appointments: React.FC = () => {
  const [bookingList, setBookingList] = useState<{
    size?: number;
    total?: number;
    list?: {
      id?: number;
      userId?: number;
      doctorId?: number;
      timeslotDate?: string;
      timeslotId?: number;
      timeslotStartTime?: string;
      timeslotEndTime?: string;
      amount?: string;
      currency?: number;
      consultType?: number;
      status?: number;
      createdAt?: string;
      phone?: string;
      email?: string;
      firstName?: string;
      lastName?: string;
      dob?: string;
      hkid?: string;
    }[];
  }>({});

  const [selectResult, setSelectResult] = useState<keyof typeof selectType>('All');

  // TODO 祥哥的dob有问题=>再看一下怎么解决
  // 初始值默认传当天的值
  const [timeStampObj, setTimeStampObj] = useState<{
    begin?: string | null;
    end?: string | null;
  }>({
    // 当天0点
    begin: '' + dayjs().startOf('day').format('x'),
    end: '' + dayjs().startOf('day').format('x'),
  });
  const [pageNo, setPageNo] = useState(1);

  const onChangeRangePicker = (val: any) => {
    if (val === null) {
      setTimeStampObj({
        begin: null,
        end: null,
      });
      return;
    }
    const momentBeginTime = dayjs(new Date(val[0])).format('x');
    const momentEndTime = dayjs(new Date(val[1])).format('x');
    setTimeStampObj({
      begin: momentBeginTime,
      end: momentEndTime,
    });
  };

  useEffect(() => {
    const getPersonalInfosFunction = async () => {
      try {
        const data = await get('/care/booking/booking-list', {
          page: pageNo,
          size: PAGE_SIZE,
          status: selectType[selectResult],
          startTime:
            !timeStampObj?.begin || timeStampObj?.begin === '0'
              ? null
              : isNaN(parseInt(timeStampObj?.begin || '0'))
              ? null
              : parseInt(timeStampObj?.begin || '0'),
          // 当天23:59
          endTime:
            !timeStampObj?.end || timeStampObj?.end === '0'
              ? null
              : isNaN(parseInt(timeStampObj?.end || '0') + (24 * 60 * 60 * 1000 - 1))
              ? null
              : parseInt(timeStampObj?.end || '0') + (24 * 60 * 60 * 1000 - 1),
        });
        setBookingList({
          ...data,
        });
      } catch (err: any) {
        const responseMessage = err?.response?.data?.message;
        const errorMessage = (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || 'error!';
        message.error(errorMessage);
      }
    };

    getPersonalInfosFunction();
  }, [pageNo, selectResult, timeStampObj]);

  // TODO Appointments目前需要设置初始值
  return (
    <div
      className="w-full h-full flex  bg-opacity-[0.8] flex-col py-[0] px-[24px] md:px-[32px] min-h-[calc(100vh-415px-128px)] lg:min-h-[calc(100vh-318px-79px)] lg:p-[60px_38px_35px_65px]"
      style={style.container}
    >
      <div className="flex justify-between lg:mb-[29px] items-center">
        <div className="font-[700] lg:text-[40px] lg:leading-[62px] text-[#25282B]">Appointments</div>
        <div className="font-[700] lg:text-[14px] lg:leading-[20px] text-[#52575C] lg:tracking-[0.1px]">
          {`${
            bookingList?.total && bookingList?.total > 1000 ? `${bookingList?.total / 1000}K` : bookingList?.total || 0
          } `}
          <span className="font-normal">Results Found</span>
        </div>
      </div>
      {/* 第二栏 */}
      <SearchBar
        setSelectResult={setSelectResult}
        selectResult={selectResult}
        onChangeRangePicker={onChangeRangePicker}
      />
      <AppointmentsTable
        bookingList={bookingList}
        pagination={{
          pageSize: PAGE_SIZE,
          total: bookingList?.total || 0,
          showSizeChanger: false,
          current: pageNo,
          hideOnSinglePage: true,
          position: ['bottomCenter'],
          onChange: (page: number) => {
            setPageNo(page);
          },
        }}
      />
    </div>
  );
};

export default Appointments;
