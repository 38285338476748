export default function FooterCustomSupportContent() {
  return (
    <div>
      <div className="flex justify-items-center items-center">
        <div className="mr-[8px]">
          <svg
            className="w-[16px] h-[16px]"
            fill="currentColor"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M493.09 351.3L384.7 304.8a31.36 31.36 0 0 0-36.5 8.9l-44.1 53.9A350 350 0 0 1 144.5 208l53.9-44.1a31.35 31.35 0 0 0 8.9-36.49l-46.5-108.5A31.33 31.33 0 0 0 125 .81L24.2 24.11A31.05 31.05 0 0 0 0 54.51C0 307.8 205.3 512 457.49 512A31.23 31.23 0 0 0 488 487.7L511.19 387a31.21 31.21 0 0 0-18.1-35.7zM456.89 480C222.4 479.7 32.3 289.7 32.1 55.21l99.6-23 46 107.39-72.8 59.5C153.3 302.3 209.4 358.6 313 407.2l59.5-72.8 107.39 46z"
              className=""
            ></path>
          </svg>
        </div>
        <a href="tel:37008888" className="hover:text-[#2dbdb6]">
          37008888
        </a>
      </div>

      <div className="mt-1 flex justify-items-center items-center">
        <div className="mr-[8px]">
          <svg
            className="w-[16px] h-[16px]"
            fill="currentColor"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            // @ts-ignore
            style={{ enableBackground: 'new 0 0 512 512' }}
          >
            <g>
              <g>
                <path
                  d="M499.2,51.2H12.8C5.734,51.2,0,56.934,0,64v384c0,7.066,5.734,12.8,12.8,12.8h486.4c7.066,0,12.8-5.734,12.8-12.8V64
      C512,56.934,506.266,51.2,499.2,51.2z M486.4,435.2H25.6V76.8h460.8V435.2z"
                ></path>
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M458.325,107.622c-4.19-5.726-12.211-6.946-17.903-2.748L256,240.128L71.578,104.875
      c-5.726-4.173-13.722-2.978-17.903,2.748c-4.173,5.7-2.953,13.722,2.748,17.903l192,140.8c2.261,1.647,4.915,2.475,7.578,2.475
      s5.316-0.828,7.578-2.475l192-140.8C461.278,121.353,462.507,113.323,458.325,107.622z"
                ></path>
              </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>
        </div>
        <a href="mailto:cs@indicaid.com" className="hover:text-[#2dbdb6]">
          cs@indicaid.com
        </a>
      </div>

      <div className="mt-1 flex justify-items-center items-center">
        <div className="mr-[8px]">
          <svg className="w-[16px] h-[16px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path
              fill="currentColor"
              d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
            ></path>
          </svg>
        </div>
        <a href="https://api.whatsapp.com/send?phone=37008888" target="_blank" className="hover:text-[#2dbdb6]">
          37008888
        </a>
      </div>
    </div>
  );
}
