import { Down } from '@icon-park/react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import ReactCollapse from '@kunukn/react-collapse';

type CollapseProps = {
  title: string;
  titleNotCollapseColor?: string;
  children: React.ReactElement;
  isOpen?: boolean;
  onOpenChange?: (o: boolean) => void;
  className?: string;
  titleWrapperClassName?: string;
  titleClassName?: string;
  downClassName?: string;
  downColor?: string;
  id?: string;
};

export default function Collapse(props: CollapseProps) {
  const [isOpen, setIsOpen] = useState(!!props.isOpen);
  useEffect(() => {
    setIsOpen(!!props.isOpen);
  }, [props.isOpen]);
  return (
    <div
      className={
        props.className ??
        classNames(
          'lg:w-[906px] lg:rounded-[10px]',
          'w-[366px] rounded-[10px]',
          'transition-colors',
          isOpen ? 'bg-[#ffffff] shadow-[0px_10px_30px_rgba(171,190,209,0.2)]' : 'bg-[#efefef]',
        )
      }
    >
      <div
        onClick={() => {
          const newOpen = !isOpen;
          setIsOpen(newOpen);
          props.onOpenChange?.(newOpen);
        }}
        id={props.id}
        className={
          props.titleWrapperClassName ??
          classNames(
            'uu-custom-collapse relative  cursor-pointer overflow-hidden',
            'lg:h-[63px] lg:px-[42px] lg:py-[16px]',
            'h-[63px] px-[42px] py-[19px]',
          )
        }
      >
        <p
          className={
            props.titleClassName ??
            classNames('font-semibold text-[#212121] select-none transition-colors', 'lg:text-[18px]', 'text-[16px]')
          }
          style={{
            color: isOpen && props.titleNotCollapseColor ? props.titleNotCollapseColor : '',
          }}
        >
          {props.title}
        </p>
        <Down
          theme="filled"
          size="26"
          fill={props.downColor ?? '#898989'}
          className={classNames(
            props.downClassName ?? 'absolute transition-transform right-[23px] lg:top-[16px] top-[19px]',
            isOpen ? 'rotate-[180deg]' : '',
          )}
        />
      </div>
      <ReactCollapse className="transition-all" isOpen={isOpen}>
        {props.children}
      </ReactCollapse>
    </div>
  );
}
