import { globalAlert } from '@utils/globalComponents/GlobalAlert';

import BaseButton from '@components/BaseButton/BaseButton';
import { AlertTypeImgMap, useItemsMap } from './consts/ConfirmAlert.const';
import { useTranslation } from 'react-i18next';

function ConfirmItems(props: { title: string; content: React.ReactNode; icon: React.ReactNode }) {
  return (
    <div className="select-result-confirm-items lg:px-[88px] lg:pt-[23px]">
      <div className="select-result-confirm-items__title flex items-center text-[#2DBDB6] lg:text-[18px] lg:leading-[36px] font-semibold">
        {props.icon} <span>{props.title}</span>
      </div>
      <p className="lg:text-[16px] lg:leading-[28px] lg:w-[1023px]">{props.content}</p>
    </div>
  );
}

type ConfirmAlertProps = {
  type: 'Positive' | 'Negative' | 'Invalid';
  onConfirm: () => Promise<any>;
  onCancel: () => Promise<any>;
  close: () => void;
};

function ConfirmAlert(props: ConfirmAlertProps) {
  const [t] = useTranslation();
  const ItemsMap = useItemsMap();
  return (
    <div className="lg:w-[1204px] lg:rounded-[30px] bg-[#ffffff] overflow-hidden">
      <div className="lg:pl-[81px] lg:pr-[127px] bg-[#F2F7F9] lg:h-[157px] flex justify-between">
        <div className="lg:pt-[52px]">
          <p className="lg:text-[28px] font-semibold text-[#2DBDB6] lg:leading-[36px]">
            {t('SelectResultPage.YourResult')}&nbsp;
            <span className="text-[#1e1f20] font-bold lg:text-[35px] lg:leading-[36px]">{t(props.type)}</span>
          </p>
          <p>
            <p className="text-[#212121] lg:text-[18px] lg:leading-[36px] lg:mt-[5px] font-semibold">
              {t('SelectResultPage.HelicobacterPyloriAntigenTest')}
            </p>
          </p>
        </div>
        <div className="relative">
          <img
            src={AlertTypeImgMap[props.type]}
            alt="positive result"
            className="lg:w-[104px] lg:h-[104px] lg:mt-[33px]"
          />
          <p className="absolute bottom-[30px] w-full text-center text-[#ffffff] lg:text-[12px] lg:leading-[15px] font-bold">
            {t(props.type)}
          </p>
        </div>
      </div>
      <div>
        {ItemsMap[props.type].map((item) => {
          return <ConfirmItems key={item.title} icon={item.icon} title={item.title} content={item.content} />;
        })}
      </div>
      {props.type === 'Positive' && (
        <div className="text-[#57606A] lg:text-[12px] lg:leading-[20px] lg:mt-[15.5px] lg:pl-[88px]">
          <p>{t('selectResultConfirmAlert.positive.note1')}</p>
          <p>{t('selectResultConfirmAlert.positive.note2')}</p>
        </div>
      )}
      <div className="flex justify-center lg:mt-[35px] items-center lg:pb-[61px]">
        <BaseButton
          htmlType="submit"
          buttonClassName="lg:w-[274px] lg:h-[50px] lg:text-[16px]"
          value={props.type === 'Invalid' ? t('SelectResultPage.OrderNewKit') : t('SelectResultPage.FindADoctorDow')}
          onClick={async () => {
            await props.onConfirm();
            props.close();
          }}
          status={'indicaidGreen'}
          sectionClassName="flex justify-center lg:mr-[20px]"
          id="select-result_popup-confirmation"
        ></BaseButton>
        <div
          className="font-[600] lg:ml-[20px] underline text-[#2DBDB6] text-center lg:text-[16px] lg:leading-[20px] cursor-pointer"
          id="select-result_do-it-later"
          onClick={async () => {
            await props.onCancel();
            props.close();
          }}
        >
          {t('SelectResultPage.DoItLater')}
        </div>
      </div>
    </div>
  );
}

export default function alertConfirmReminder(options: Omit<ConfirmAlertProps, 'close'>) {
  return globalAlert((close) => {
    return {
      content: <ConfirmAlert {...options} close={close} />,
      wraperBackground: 'rgba(0, 0, 0, 0.4)',
    };
  });
}
