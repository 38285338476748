import { useState, useEffect, useRef, useMemo } from 'react';
import { useMemoizedFn } from 'ahooks';
import { message } from 'antd';
import { VERIFY_CODE_WAITING_SECONDS } from '@consts/consts';

// 倒计时功能
export function useCountDown(countDownTime: number, name?: string) {
  const [count, setCount] = useState(0);
  const [startCountNumber, setStartCountNumber] = useState(0);
  const timerRef = useRef<NodeJS.Timeout>();

  const startCount = useMemoizedFn(() => {
    if (timerRef.current) {
      console.warn('Timer started...');
      return;
    }

    if (count === 0) {
      try {
        let fun = () => {
          setCount((val) => {
            let newVal;
            if (val === 0) {
              if (!startCountNumber) {
                name && localStorage.setItem(name, Date.now() + '');
              }
              newVal = startCountNumber || countDownTime;
            } else if (val === 1) {
              clearInterval(timerRef.current);
              timerRef.current = undefined;
              newVal = 0;
              setStartCountNumber(0);
            } else {
              newVal = val - 1;
            }
            return newVal;
          });
        };
        fun();
        timerRef.current = setInterval(fun, 1000);
      } catch (err: any) {
        const responseMessage = err?.response?.data?.message;
        const errorMessage = (Array.isArray(responseMessage) ? responseMessage[0] : responseMessage) || 'error!';
        message.error(errorMessage);
      }
    }
  });
  // 重置Count
  const resetCount = useMemoizedFn(() => {
    setCount(countDownTime);
  });
  useEffect(() => {
    if (name) {
      let lastSendTime = +(localStorage.getItem(name) || '');
      if (lastSendTime) {
        let duration = ~~((Date.now() - lastSendTime) / 1e3);
        if (duration < VERIFY_CODE_WAITING_SECONDS) {
          setStartCountNumber(VERIFY_CODE_WAITING_SECONDS - duration);
        }
      }
    }
  }, [name]);

  useEffect(() => {
    return () => {
      clearInterval(timerRef.current as NodeJS.Timeout);
      timerRef.current = undefined;
    };
  }, []);

  useEffect(() => {
    if (startCountNumber) {
      clearInterval(timerRef.current as NodeJS.Timeout);
      timerRef.current = undefined;
      startCount();
    }
  }, [startCountNumber, startCount]);

  return {
    count,
    startCount,
    resetCount,
    setStartCountNumber,
  };
}

/**
 * SRP: 倒计时
 * @param init 开始秒数
 * @returns 当前秒数
 */
export function useCountDown2(init: number, id: number) {
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (init > 0) {
      setCount(init);
      let t = setInterval(() => {
        setCount((pre) => {
          let _count = pre - 1;
          // console.log(_count);
          if (_count <= 0) {
            clearInterval(t);
            return 0;
          }
          return _count;
        });
      }, 1e3);
      return clearInterval.bind(null, t);
    }
  }, [init, id]);
  return count;
}

/**
 * SRP: 根据上次开始时间得出当前剩余秒
 * @param timerId
 * @param timerDuration 总时长
 * @returns
 */
// 总时长，
export function useLSCountDown(timerDuration: number, timerId: string) {
  const [id, setId] = useState(0);
  const remainSeconds = useMemo(() => {
    if (!timerId || !timerDuration) {
      return 0;
    }
    console.log(id);
    const lastStartTime = Number(localStorage.getItem('timer:' + timerId)) || 0;
    const now = Date.now();
    const lastDurationSeconds = ~~((now - lastStartTime) / 1e3);
    return timerDuration - lastDurationSeconds;
  }, [id, timerId, timerDuration]);

  return {
    count: useCountDown2(remainSeconds, id),
    startCount: () => {
      localStorage.setItem('timer:' + timerId, Date.now() + '');
      setId((v) => v + 1);
    },
  };
}
