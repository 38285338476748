import { useMedia } from 'react-use';
import { MEDIA_QUERY, MEDIA_SIZE } from '@consts/consts';

export function useMediaQuery(defaultValue = false) {
  // 默认size为mobile
  let mediaSize = MEDIA_SIZE.DEFAULT_SIZE;
  const isLg = useMedia(MEDIA_QUERY.LG_WIDTH, defaultValue);
  const isMd = useMedia(MEDIA_QUERY.MD_WIDTH, defaultValue);
  const isSm = useMedia(MEDIA_QUERY.SM_WIDTH, defaultValue);

  if (isLg) {
    mediaSize = MEDIA_SIZE.LG_SIZE;
  } else if (isMd) {
    mediaSize = MEDIA_SIZE.MD_SIZE;
  } else if (isSm) {
    mediaSize = MEDIA_SIZE.SM_SIZE;
  }
  return mediaSize;
}
